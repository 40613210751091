NgProject.directive('audioTeaser', ["$parse", "AudioPlayer", "Audio", "CurrentUser", function ($parse, AudioPlayer, Audio, CurrentUser) {
	'use strict';
	controller.$inject = ["$scope", "$window", "AudiosService"];
	var options = {
		template: 'widgets.audios.audio-teaser-default',
		buttons: {
			'update': false,
			'share': false,
			'deleteByOwner': false,
			'download': true,
			'bookmark': true
		},
		showInfoBtn: true
	};

	function controller($scope, $window, AudiosService) {
		$scope.togglePlay = function () {
			var currentAudio = AudioPlayer.getAudioObject();
			if (!currentAudio || $scope.$audio.id !== currentAudio.id) {
				AudioPlayer.setAudioObject($scope.$audio);
				AudioPlayer.play();
			} else {
				AudioPlayer.playPause();
			}
		};
		$scope.download = function (id) {
			$window.open(AudiosService.download(id), '_blank');
		};
	}

	function link(scope, element, attrs) {
		scope.$teaser = {options: angular.copy(options)};

		if (attrs.hasOwnProperty('type')) {
			scope.$teaser.options.template = 'widgets.audios.audio-teaser-' + attrs.type;
		}

		if (_.has(attrs, 'object')) {
			scope.$audio = $parse(attrs.object)(scope);
		}
		if (_.isPlainObject(scope.$audio)) {
			scope.$audio = Audio.create(scope.$audio);
		}

		angular.extend(scope.$teaser.options.buttons, {
			'update': scope.$audio.access.update,
			'share': Boolean(CurrentUser.id),
			'bookmark': Boolean(CurrentUser.id)
		});

		if (attrs.hasOwnProperty('options')) {
			angular.merge(scope.$teaser.options, $parse(attrs.options)(scope));
		}

		scope.$audio.playing = false;
	}

	return {
		restrict: 'E',
		controller: controller,
		link: link,
		templateUrl: 'widgets.audios.audio-teaser',
		scope: true
	};
}]);
