NgProject.directive('groupsFilters', ["_", "Deferred", "TermsService", "LocationService", "SETTINGS", "$filter", function (_, Deferred, TermsService, LocationService, SETTINGS, $filter) {
	'use strict';

	function loadDirections(callback) {
		Deferred.handlePromise(TermsService.directions(), function (response) {
			var directions = [];
			if (response.success) {
				directions = response.items;
			}
			callback(directions);
		});
	}

	function loadCountries(callback) {
		Deferred.handlePromise(LocationService.countriesList(), function (response) {
			var countries = [];
			if (response.success) {
				countries = response.items;
			}
			callback(countries);
		});
	}

	function loadCities(countryId, callback) {
		Deferred.handlePromise(LocationService.citiesList(countryId), function (response) {
			var cities = [];
			if (response.success) {
				cities = response.items;
			}
			callback(cities);
		});
	}

	function link(scope, element, attrs, controller) {
		function init() {
			scope.$filters = angular.copy(controller.getSearchParams());
			scope.$filtersData = {
				countries: [],
				cities: [],
				directions: [],
				orderTypes: SETTINGS.groups.filters.order
			};
			scope.$filtersControls = {
				reset: function () {
					scope.$filters = angular.copy(controller.getDefaultSearchParams());
					applyFilters();
				}
			};

			loadCountries(function (countries) {
				scope.$filtersData.countries = countries;
			});
			loadDirections(function (directions) {
				scope.$filtersData.directions = $filter('orderBy')(directions, 'title');
			});
		}

		function getRequestParams() {
			return {
				countryId: scope.$filters.countryId,
				cityId: scope.$filters.cityId,
				directionId: scope.$filters.directionId,
				orderType: scope.$filters.orderType
			};
		}

		function applyFilters() {
			controller.search(getRequestParams());
		}

		scope.$watch('$filters.countryId', function (value, oldValue) {
			if (value !== null && value !== oldValue) {
				applyFilters();
				if (oldValue) {
					scope.$filtersData.cities = [];
					scope.$filters.cityId = null;
				}
				loadCities(value, function (cities) {
					scope.$filtersData.cities = cities;
				});
			}
		});
		scope.$watch('$filters.cityId', function (value, oldValue) {
			if (value !== null && value !== oldValue)
				applyFilters();
		});
		scope.$watch('$filters.directionId', function (value, oldValue) {
			if (value !== null && value !== oldValue)
				applyFilters();
		});
		scope.$watch('$filters.orderType', function (value, oldValue) {
			if (value !== null && value !== oldValue)
				applyFilters();
		});

		init();
	}

	return {
		require: '^^groups',
		templateUrl: 'widgets.groups.groups-filters',
		restrict: 'E',
		scope: true,
		link: link
	};
}]);

