(function (ng, app) {
	'use strict';
	Controller.$inject = ["$rootScope", "$scope", "$q", "EventsService"];
	function Controller($rootScope, $scope, $q, EventsService) {
		var event = $scope.$modalWindow.data(0);
		var question = $scope.$modalWindow.data(1);

		$scope.model = {
			value: ''
		};

		if (question) {
			$scope.model.value = question.text;
			$scope.model.id = question.id;
		}

		$scope.$controls = {};
		$scope.$controls.submit = function() {
			var fn = $scope.model.id ? update : create;
			fn().then($scope.$close);
		};

		function create() {
			return EventsService.votingQuestions.create(event.id, $scope.model.value).then(function(response) {
				if (response.success) $rootScope.$broadcast('createdEventVotingQuestion', response.voting_question);
			});
		}

		function update() {
			return EventsService.votingQuestions.update(event.id, $scope.model.id, $scope.model.value).then(function(response) {
				if (response.success) $rootScope.$broadcast('updatedEventVotingQuestion', response.voting_question);
			});
		}
	}

	app.controller('ModalEventVotingQuestionsCtrl', Controller);
})(angular, NgProject);
