NgProject.controller('imChatCtrl', ["$q", "$timeout", "$window", "$scope", "$state", "Deferred", "ImService", "ImCounter", "ImData", "ImMessage", function ($q, $timeout, $window, $scope, $state, Deferred, ImService, ImCounter, ImData, ImMessage) {
	'use strict';
	var options = {requestParams: {per_page: 25}},
		$messages = $scope.$messages = getData(),
		chatId = parseInt($state.params.chatId);

	$scope.controls = {
		load: function () {
			if ($messages.firstLoading) return;
			if (!$messages.endOfList) var page = options.requestParams.page || 1;
			if ($scope.chatData.lastMessages > 0) {
				options.requestParams.per_page += $scope.chatData.lastMessages;
				$scope.chatData.lastMessages = 0;
			}
			return loadByPage(page + 1);
		},
		sendMessage: function () {
			var message = _.trim($scope.chatData.message);
			$scope.chatData.message = null;
			if (_.isEmpty(message)) return;
			var sync = Date.now();
			var messageData = ImMessage.setMessageData(message, sync);
			var messageSync = ImMessage.setMessageSync(message, sync);
			Deferred.handlePromise(ImService.message(messageData));
			$messages.items.push(messageSync);
			_.find($scope.$chats.items, {id: ImData.data.chatId}).last_message = messageSync;
			scrollToDown();
		}
	};

	$scope.chatData = {
		message: null,
		lastMessages: 0
	};

	$scope.$watchCollection('imFaye', function (data) {
		if (data.id === chatId) {
			var indexMessage = _.findIndex($messages.items, {'id': data['message'].sync});
			if (indexMessage > -1) {
				$messages.items.slice(indexMessage, 1, data['message']);
			} else {
				$messages.items.push(data['message']);
			}
			$scope.chatData.lastMessages++;
			scrollToDown();
		}
	});


	loadRemoteData().then(loadChat).then(function (chat) {
		$timeout(function () {
			$($window).scrollTop($($scope.element[0]).height());
		}, 10);

		try {
			if (chat['new_messages_count'] > 0) {
				ImCounter.readCounter(chat.id)
					.then(ImCounter.getCounter)
					.then(function () {
						ImCounter.resetChatCounter(chat);
					});
			}
			ImData.setData(chat);
		} catch (e) {
			$timeout(function () {
				chat = _.find($scope.$chats.items, {id: chatId});
				ImData.setData(chat);
			}, 100);
		}
	});

	function loadByPage(page) {
		if (page && page > 1)
			options.requestParams.page = page;
		return loadRemoteData(true);
	}

	function loadRemoteData(append) {
		var params = options.requestParams;
		$messages.isLoading = true;
		return Deferred.handlePromise(ImService.messages(chatId, params), function (response) {
			if (response.success) {
				var sort = _(response.items).reverse().value();
				if (append) {
					$messages = getData(response.count, sort.concat($messages.items));
				} else {
					$messages = getData(response.count, sort);
				}
				angular.extend($messages, {
					isLoading: false,
					firstLoading: false,
					endOfList: response.items.length === 0 || response.count === $messages.items.length
				});
				$scope.$messages = $messages;
			}
		});
	}

	function loadChat() {
		var d = $q.defer();
		if (!ImData.data.chatId) {
			Deferred.handlePromise(ImService.chats({id: chatId}), function (response) {
				if (response.success) {
					var chat = _.find($scope.$chats.items, {id: chatId});
					if ($scope.$chats.items.length && _.isEmpty(chat)) {
						chat = response.chat;
						$scope.$chats.items.unshift(chat);
					}
					d.resolve(chat);
				}
			});
		} else {
			$timeout(function () {
				d.resolve(_.find($scope.$chats.items, {id: chatId}));
			}, 100);
		}
		return d.promise;
	}

	function getData(count, items) {
		return {
			count: count || 0,
			items: items || [],
			isLoading: true,
			endOfList: true,
			firstLoading: true
		};
	}

	function scrollToDown() {
		$timeout(function () {
			$($window).scrollTop($(document).height());
		}, 10);
	}
}]);
