(function (ng, app) {
	'use strict';
	app.service('GroupsService', ["$resource", "serviceHelper", "SETTINGS", "GroupsSearchParams", "UsersSearchParams", function ($resource, serviceHelper, SETTINGS, GroupsSearchParams, UsersSearchParams) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/:command', {
			groupId: '@groupId',
			command: '@command'
		}, {
			allByUser: {url: SETTINGS.apiBaseUrl + 'api/v1/users/:userId/groups'},
			allByCurrentUser: {url: SETTINGS.apiBaseUrl + 'api/v1/profile/groups'},
			join: {
				method: 'POST',
				params: {command: 'join'}
			},
			leave: {
				method: 'POST',
				params: {command: 'leave'}
			},
			markAsRemoved: {
				method: 'PUT',
				params: {command: 'remove'}
			},
			addMaterial: {
				method: 'PUT',
				url: SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/add_material'
			},
			changeAvatar: {
				method: 'PUT',
				params: {command: 'avatar'}
			}
		});

		var membersResource = $resource(SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/members/:userId', {
			groupId: '@groupId',
			userId: '@userId'
		});

		var managersResource = $resource(SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/managers/:userId', {
			groupId: '@groupId',
			userId: '@userId'
		});

		var wallResource = $resource(SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/:command/:postId', {
			groupId: '@groupId',
			command: '@command',
			postId: '@postId'
		}, {
			list: {
				params: {command: 'all_news'}
			},
			create: {
				method: 'POST',
				params: {command: 'create_news'}
			},
			remove: {
				method: 'DELETE',
				params: {command: 'delete_news'}
			}
		});

		return {
			findAll: function (params) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: GroupsSearchParams.process(params)
				});
			},

			findAllByUser: function (id, params) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'allByUser',
					parameters: angular.extend(GroupsSearchParams.process(params), {userId: id})
				});
			},

			findAllByCurrentUser: function (params) {
				var parameters = GroupsSearchParams.process(params);
				if (params.user_status) parameters.user_status = params.user_status;
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'allByCurrentUser',
					parameters: parameters
				});
			},

			main: function (id) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: {groupId: id}
				});
			},

			wall: {
				list: function (groupId, params) {
					var data = params || {};
					data.groupId = groupId;
					var promise = serviceHelper.executeRequest({
						resource: wallResource,
						name: 'list',
						parameters: data
					});
					return (promise);
				},

				create: function (groupId, message) {
					var data = {
						groupId: groupId,
						news: {content: message}
					};
					return serviceHelper.executeRequest({
						resource: wallResource,
						name: 'create',
						parameters: data
					});
				},

				remove: function (groupId, postId) {
					return serviceHelper.executeRequest({
						resource: wallResource,
						name: 'remove',
						parameters: {groupId: groupId, postId: postId}
					});
				}
			},

			join: function (groupId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'join',
					parameters: {
						groupId: groupId
					}
				});
				return (promise);
			},

			leave: function (groupId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'leave',
					parameters: {
						groupId: groupId
					}
				});
				return (promise);
			},

			changeAvatar: function (groupId, imageId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'changeAvatar',
					parameters: {groupId: groupId, image_id: imageId}
				});

				return (promise);
			},

			managers: {
				list: function (groupId, params) {
					params = UsersSearchParams.process(params);
					params.groupId = groupId;
					var promise = serviceHelper.executeRequest({
						resource: managersResource,
						name: 'get',
						parameters: params
					});
					return (promise);
				},

				add: function (groupId, userId, role) {
					var promise = serviceHelper.executeRequest({
						resource: managersResource,
						name: 'save',
						parameters: {
							groupId: groupId,
							user_id: userId,
							role: role
						}
					});
					return (promise);
				},
				remove: function (groupId, userId) {
					return serviceHelper.executeRequest({
						resource: managersResource,
						name: 'delete',
						parameters: {groupId: groupId, userId: userId}
					});
				}
			},

			members: {
				list: function (groupId, params) {
					params = UsersSearchParams.process(params);
					params.groupId = groupId;
					return serviceHelper.executeRequest({
						resource: membersResource,
						name: 'get',
						parameters: params
					});
				},
				remove: function (groupId, userId) {
					return serviceHelper.executeRequest({
						resource: membersResource,
						name: 'delete',
						parameters: {groupId: groupId, userId: userId}
					});
				}
			},

			create: function (data) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'save',
					parameters: {group: data}
				});
				return (promise);
			},

			update: function (groupId, data) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'update',
					parameters: {groupId: groupId, group: data}
				});
				return (promise);
			},

			markAsRemoved: function (groupId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'markAsRemoved',
					parameters: {
						groupId: groupId,
						group: {is_removed: true}
					}
				});
				return (promise);
			},

			remove: function (groupId) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'delete',
					parameters: {groupId: groupId}
				});
			},

			addArticle: function (groupId, articleId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'addMaterial',
					parameters: {groupId: groupId, type: 'article', material_id: articleId}
				});

				return (promise);
			},

			getUploadImageUrl: function (groupId) {
				return SETTINGS.apiBaseUrl + 'api/v1/groups/' + parseInt(groupId).toString() + '/avatar';
			}
		};
	}]);
})(angular, NgProject);
