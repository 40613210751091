NgProject.directive('chatsItem', ["$compile", "$filter", "CurrentUser", function ($compile, $filter, CurrentUser) {
	'use strict';
	var template = '<div id="chatId" ui-sref-active="active" class="chat__item-wrapper">\
		<div class="chat__item">\
			<div class="chat__image">\
				<div class="chat__counter">-counter-</div>\
				<a href="-account-" class="chat__image-link" target="_blank">-avatar-</a>\
			</div>\
			<div class="chat__content">\
				<div class="chat__content-cell">\
					<div class="chat__content-item"><h2 class="chat__content-name">-recepient-</span></h2></div>\
					<div class="chat__content-item date">-date-</div>\
				</div>\
				<div class="chat__content-cell">-message-</div>\
			</div>\
		</div>\
		<div class="chat__exit">-exit-</div>\
		<a ui-sref="root.im.chat({&quot;chatId&quot;: {{::item.id}}})" class="link"></a>\
	</div>';

	var avatarTemplate = '<avatar data="dataAvatar" type="avatarType" size="medium" width="50" height="50" class="chat__image-recepient"></avatar>';
	var messageTemplate = '<div class="chat__content-item"><span class="chat__content-message">-message-</span></div>';
	var dateTemplate = '<span class="chat__content-date">-date-</span>';
	var counterTemplate = '<span class="chat__counter-item">-counter-</span>';
	return {
		restrict: 'E',
		link: link
	};

	function link(scope, element) {
		var data = scope.item, element$watch = null;
		element.replaceWith($compile(createChat(template, createAvatar(avatarTemplate, data), data))(scope));

		scope.$watch('item', function ($data, old$data) {
			if (!_.eq($data, old$data)) {
				element$watch = angular.element('#chat_' + $data.id.toString());
				element$watch.find('.chat__content-item.date').html(createTemplate(dateTemplate, $filter('dateTimeFilter')($data.last_message.created, 'chat'), /-date-/));
				element$watch.find('.chat__content-cell:last').html(createTemplate(messageTemplate, $data.last_message.message, /-message-/));
				element$watch.find('.chat__counter-item').text(setCount($data['new_messages_count']));
				if ($data['new_messages_count']) {
					element$watch.addClass('new');
				} else {
					element$watch.removeClass('new');
				}
			}
		}, true);
	}

	function createChat(template, avatar, data) {
		if (!data['recepient']) return;
		var recepient = data.type === 'group' ? data['recepient'].name : _.get(data['recepient'], 'nickname', data['recepient'].fullname);
		var message = _.isEmpty(data.last_message) ? '' : createTemplate(messageTemplate, data.last_message.message, /-message-/);
		var date = _.isEmpty(data.last_message) ? '' : createTemplate(dateTemplate, $filter('dateTimeFilter')(data.last_message.created, 'chats'), /-date-/);
		var counter = createTemplate(counterTemplate, setCount(data['new_messages_count']), /-counter-/);
		var exit = '<button ng-click="$controls.leaveConversation(item)" class="action"><md-tooltip>покинуть чат</md-tooltip><i class="icon icon-close"></i></button>';
		if (data['new_messages_count']) template = template.replace(/(class="chat__item-wrapper)(")/, '$1 new$2');
		template = isGroupChat(data) ? template.replace(/-exit-/, exit).replace(/(class="chat__item-wrapper)(")/, '$1 group$2') : template.replace(/-exit-/, '');
		return template.replace(/chatId/, 'chat_' + data.id.toString())
			.replace(/-counter-/, counter)
			.replace(/-recepient-/, recepient)
			.replace(/-date-/, date)
			.replace(/-account-/, '/' + data['recepient'].type + data['recepient'].id.toString())
			.replace(/-message-/, message)
			.replace(/-avatar-/, avatar);
	}

	function isGroupChat(data) {
		if (data['type'] !== 'group') return false;
		return !_.find(data['administrators'], {id: CurrentUser.id});
	}

	function createAvatar(avatar, data) {
		return avatar.replace(/dataAvatar/, 'item.recepient.avatar')
			.replace(/avatarType/, data.type);
	}

	function createTemplate(template, data, regex) {
		return template.replace(regex, data);
	}

	function setCount(count) {
		return count > 99 ? '99+' : count;
	}
}]);
