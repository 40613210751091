NgProject.filter('htmlContentIsEmpty', function () {
	'use strict';
	var rg = [
		/<content-video[^>]*>/gi,
		/<img[^>]*>/gi,
		/<iframe[^>]*>/gi
	];

	function hasElements(html) {
		return rg.some(function(pattern) {
			return pattern.test(html);
		});
	}

	return function (html) {
		if (hasElements(html)) return false;
		var element = document.createElement('DIV');
		element.innerHTML = html;
		return _.isEmpty(_.trim(element.textContent || element.innerText));
	};
});
