(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state", "Deferred", "ProfileSettingsService", "CurrentUser"];
	function Controller($scope, $state, Deferred, ProfileSettingsService, CurrentUser) {
		$scope.isLoading = true;
		$scope.isConfirmed = false;

		function sendToken(token) {
			Deferred.handlePromise(ProfileSettingsService.account.removeConfirmation(token), function (response) {
				$scope.isConfirmed = response.success;
				$scope.isLoading = false;
				if ($scope.isConfirmed) {
					CurrentUser.destroy();
				}
			});
		}

		sendToken($state.params.token);
	}

	app.controller('PageDeleteAccountConfirmationCtrl', Controller);
})(angular, NgProject);
