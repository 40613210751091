(function (ng, app) {
	'use strict';
	Controller.$inject = ["$rootScope", "$scope", "$state", "$filter", "$q", "_", "Deferred", "EventsService", "TermsService", "UsersService", "GroupsService", "CurrentUser", "FileUploader", "cfpLoadingBar", "moment", "EventsRoomsService", "SETTINGS", "UploadAvatar", "modalWindow"];
	var settings = {
		periodMinDiff: 3600000
	};

	function Controller($rootScope, $scope, $state, $filter, $q, _, Deferred, EventsService, TermsService, UsersService,
	                    GroupsService, CurrentUser, FileUploader, cfpLoadingBar, moment, EventsRoomsService, SETTINGS,
	                    UploadAvatar, modalWindow) {
		var options = $scope.$options = {
			allowChangeMaker: false,
			allowSetMakerAsGroup: false,
			allowViewMaker: false
		};

		$scope.fData = {
			isReady: false,
			isNewAvatar: false,
			imageAccept: SETTINGS.validation.image.types,
			uploader: {},
			types: [],
			participantsLabels: [],
			participants: [],
			rooms: [],
			errors: {},
			hasErrors: function () {
				return !_.isEmpty($scope.fData.errors);
			},
			hasError: function (name) {
				return $scope.fData.hasErrors() && $scope.fData.errors[name];
			},
			hasImage: function () {
				return ($scope.event && $scope.event.image) || ($scope.fData.uploader.queue && $scope.fData.uploader.queue.length);
			}
		};

		$scope.event = {
			image: null,
			name: null,
			description: null,
			teaser: '',
			online: true,
			allowQuestions: true,
			kindId: null,
			added: null,
			finish: null,
			country: null,
			city: null,
			venue: null,
			room: null,
			audios: [],
			participants: [
				{titleId: null, tags: []},
				{titleId: null, tags: []},
				{titleId: null, tags: []}
			],
			tags: []
		};

		$scope.$participants = {
			models: [],
			search: function (query) {
				query = $.trim(query);
				$scope.fData.participants = [];
				if (query.length > 1) {
					Deferred.handlePromise(TermsService.participants(query), function (response) {
						if (response.success) {
							$scope.fData.participants = _.isEmpty(response.items) ? [{
								tag_type: 'text',
								data: query
							}] : response.items;
						}
					});
				}
			},
			select: function ($index, participant) {
				var item = angular.copy(this.models[$index][0]);
				if (_.findIndex(participant.tags, item) === -1) participant.tags.push(item);
				this.models[$index] = [];
			},
			remove: function (participant, item) {
				_.remove(participant.tags, item);
			}
		};

		$scope.$period = {
			start: {
				render: function ($view, $dates) {
					var minDate = moment().startOf($view);
					var maxDate = null;
					var isDefaultDate = !$scope.event.added;
					if ($view === 'hour')
						minDate = minDate.add(minDate.utcOffset() + 1, 'minutes').valueOf();
					else if ($view === 'minute') {
						var day = new Date();
						var hour = moment(new Date(day.getFullYear(), day.getMonth(), day.getDate(), day.getHours()));
						minDate = hour.add(minDate.utcOffset(), 'minutes').add(60, 'minutes').valueOf();
					} else minDate = minDate.add(minDate.utcOffset(), 'minutes').valueOf();
					if ($scope.event.finish) {
						maxDate = moment($scope.event.finish).subtract(settings.periodMinDiff, 'milliseconds');
						maxDate = maxDate.startOf($view).add(maxDate.utcOffset(), 'minutes').valueOf();
					}
					_.map($dates, function (date) {
						if (date.utcDateValue < minDate) date.selectable = false;
						else if (maxDate && date.utcDateValue > maxDate) date.selectable = false;
						else if (isDefaultDate && date.utcDateValue === minDate) date.active = true;
					});
				}
			},
			finish: {
				render: function ($view, $dates) {
					var minDate = moment();
					var isDefaultDate = false;

					if ($scope.event.added && minDate.diff($scope.event.added) < 0) {
						minDate = moment($scope.event.added);
						isDefaultDate = !$scope.event.finish;
					}
					minDate.add(settings.periodMinDiff, 'milliseconds');
					minDate = minDate.startOf($view).add(minDate.utcOffset(), 'minutes').valueOf();
					_.map($dates, function (date) {
						if (date.utcDateValue < minDate) date.selectable = false;
						else if (isDefaultDate && date.utcDateValue === minDate) date.active = true;
					});
				}
			}
		};

		$scope.$tags = {
			querySearch: function (query) {
				query = $.trim(query);
				var defer = $q.defer();
				if (query.length) TermsService.tags(query, 30).then(function (response) {
					if (response.success) defer.resolve(response.items);
				});
				else defer.resolve([]);
				return defer.promise;
			},
			replaceTag: function (item) {
				if (_.isObject(item)) return;
				return {name: item.toString()};
			},
			controls: {
				left: function (items, item) {
					var index = _.indexOf(items, item);
					if (index == 0) return;
					_.exchange(items, index, --index);
				},
				right: function (items, item) {
					var index = _.indexOf(items, item);
					if (items.length - 1 === index) return;
					_.exchange(items, index, ++index);
				}
			}
		};

		function initUserAccess() {
			var maker = getDefaultMaker();
			options.allowChangeMaker = CurrentUser.is_admin;
			options.allowViewMaker = options.allowChangeMaker || maker.type === 'group';
			options.allowSetMakerAsGroup = options.allowViewMaker && maker.type === 'group';
		}

		function loadData(event_id) {
			var deferred = $q.defer();
			var requests = {types: null, participants: null};
			var promises = [TermsService.contentTypes(), TermsService.participantsLabels()];
			var methods = {};

			methods.loadMaker = function (maker_type, maker_id) {
				requests.maker = null;
				loadMaker(maker_type, maker_id).then(function (response) {
					deferred.notify(['maker', true, {type: maker_type, data: response}]);
				});
			};

			methods.loadRooms = function (maker_type, maker_id) {
				requests.rooms = null;
				loadRoomsList(maker_type, maker_id).then(function (items) {
					deferred.notify(['rooms', true, {data: items}]);
				});
			};

			Deferred.handleAllPromises(promises, function (types, participants) {
				deferred.notify(['types', types.success, {data: types.items}]);
				deferred.notify(['participants', participants.success, {data: participants.items}]);
			});

			if (event_id) {
				requests.event = null;
				Deferred.handlePromise(EventsService.view(event_id), function (response) {
					if (response.success) {
						if (options.allowViewMaker) methods.loadMaker(response.event.org.type, response.event.org.id);
						if (response.event.online) methods.loadRooms(response.event.org.type, response.event.org.id);
					}
					deferred.notify(['event', response.success, {data: response.event}]);
				});
			} else {
				var maker = getDefaultMaker();
				if (options.allowViewMaker) methods.loadMaker(maker.type, maker.id);
				if ($scope.event.online) methods.loadRooms(maker.type, maker.id);
			}

			return deferred.promise.then(angular.noop, angular.noop, function (request) {
				requests[request[0]] = angular.extend({status: request[1]}, request[2]);
				if (_.every(requests, 'status')) {
					if (requests.event) {
						setModelData(requests.event.data);
						setModelMaker(requests.event.data.org.type, requests.event.data.org);
					}
					if (requests.maker) setModelMaker(requests.maker.type, requests.maker.data);
					if (requests.rooms) setFormRooms(requests.rooms.data);

					setFormTypes(requests.types.data);
					setFormParticipantsLabels(requests.participants.data);
					deferred.resolve();
				}
			});
		}

		function getDefaultMaker() {
			var is_default = !$state.params.maker_type || !$state.params.maker_id;
			return {
				type: is_default ? 'user' : $state.params.maker_type,
				id: is_default ? CurrentUser.id : $state.params.maker_id
			};
		}

		function loadMaker(maker_type, maker_id) {
			var deferred = $q.defer();
			var promise = maker_type === 'user' ? UsersService.about : GroupsService.main;
			Deferred.handlePromise(promise(maker_id), function (response) {
				if (response.success) deferred.resolve(response[maker_type]);
			});
			return deferred.promise;
		}

		function loadRoomsList(maker_type, maker_id) {
			var deferred = $q.defer();
			Deferred.handlePromise(EventsRoomsService.list(maker_type, maker_id), function (response) {
				if (response.success) deferred.resolve(response.items);
			});
			return deferred.promise;
		}

		function setFormRooms(rooms) {
			$scope.fData.rooms = rooms;
		}

		function setFormTypes(types) {
			$scope.fData.types = types;
			if (!$scope.event.kindId && $scope.fData.types.length) {
				$scope.event.kindId = $scope.fData.types[0].id;
			}
		}

		function setFormParticipantsLabels(labels) {
			$scope.fData.participantsLabels = labels;
		}

		function _setFileUploaderUrl() {
			var url = EventsService.getUploadImageUrl($scope.event.id);
			$scope.fData.uploader.url = url;
			_.setProperty($scope.fData.uploader.queue, 'url', url);
		}

		function initFileUploader() {
			$scope.fData.uploader = new FileUploader({
				alias: 'avatar[file]'
			});
		}

		function setModelData(data) {
			var event = {
				id: data.id,
				name: data.name,
				image: data.image,
				teaser: data.short_description,
				online: data.online,
				allowQuestions: data.allow_questions,
				added: data.added ? new Date(data.added * 1000) : null,
				finish: data.finish ? new Date(data.finish * 1000) : null,
				kindId: _.get(data, 'kind.id', null),
				country: _.get(data, 'country', null),
				city: _.get(data, 'city', null),
				venue: data.venue,
				room: data.room,
				audios: data.audios || [],
				description: data.description || '',
				tags: data.tags
			};
			
			if (data.participants && data.participants.length) {
				data.participants = _.map(data.participants, function (participant) {
					return {
						titleId: participant.term_title.id,
						tags: _.map(participant.tags, $filter('convertHostTagToParticipantTag'))
					};
				});
				event.participants = ng.extend([], $scope.event.participants, data.participants);
			}

			if (event.tags) {
				event.tags = _.uniq(event.tags, 'id');
			}

			ng.extend($scope.event, event);
		}

		function setModelMaker(type, data) {
			$scope.event.org = {id: data.id, type: type, data: data};
		}

		function getSubmitData() {
			var data = {
				name: $scope.event.name || null,
				description: $scope.event.description,
				short_description: $scope.event.teaser || null,
				online: $scope.event.online,
				allow_questions: $scope.event.allowQuestions,
				kind_id: $scope.event.kindId || null,
				added: $scope.event.added || null,
				finish: $scope.event.finish || null,
				participants: [],
				audios: $scope.event.audios.length ? _($scope.event.audios).pluck('id').uniq().value() : [false],
				tags: $scope.event.tags.length ? getTagsValues($scope.event.tags) : [false]
			};

			if ($filter('htmlContentIsEmpty')(data.description)) data.description = '';

			if (_.isDate(data.added)) {
				data.added = parseInt(data.added.getTime() / 1000);
			}
			if (_.isDate(data.finish)) {
				data.finish = parseInt(data.finish.getTime() / 1000);
			}
			if (data.online === false) {
				data.country_id = _.get($scope.event, 'country.id', null);
				data.city_id = _.get($scope.event, 'city.id', null);
				data.venue = $scope.event.venue || null;
			}
			else {
				data.room_id = _.get($scope.event, 'room.id', null);
			}

			// разрешает изменять мэйкера только есть это админ
			// или пользователь создает мероприятие (не обновляет) от имени группы
			if (options.allowChangeMaker || (!$scope.event.id && options.allowSetMakerAsGroup)) {
				data.org = {type: $scope.event.org.type, id: $scope.event.org.data.id};
			}

			if ($scope.event.participants.length) {
				ng.forEach($scope.event.participants, function (role) {
					var performers = getPerformersValues(role.tags);
					if (role.titleId && performers.length) data.participants.push({
						term_title_id: role.titleId,
						tags: performers
					});
				});
			}
			if (!data.participants.length) {
				if ($scope.event.id) data.participants = [false];
				else delete data.participants;
			}

			return data;
		}

		function getPerformersValues(items) {
			return _(items).map(function (item) {
				return _.get(item, 'tag_type', 'text') === 'text' ? item.data : item.tag_id;
			}).uniq().reverse().value();
		}

		function _removeError(name) {
			delete $scope.fData.errors[name];
		}

		function _setFormErrors(errors) {
			var renamedFields = {
				short_description: 'teaser',
				country_id: 'country',
				city_id: 'city',
				ended: 'finish',
				kind: 'kindId'
			};

			_.forEach(renamedFields, function (evField, resField) {
				if (errors.hasOwnProperty(resField)) {
					errors[evField] = errors[resField];
					delete errors[resField];
				}
			});

			$scope.fData.errors = errors;
		}

		function _avatarUpdate(callback) {
			if (!$scope.fData.isNewAvatar) {
				callback();
				return false;
			}

			if(_.has($scope.fData.uploader.queue[0], '_file')) {
				$scope.fData.uploader.onSuccessItem = function (fileItem, response) {
					if (callback) callback(response);
				};

				$scope.fData.uploader.onErrorItem = function (fileItem, response) {
					response = $.trim(response);
					if (!response) response = {
						success: false,
						errors: {image: [SETTINGS.validation.image.errors.undefined]}
					};
					if (callback) callback(response);
				};

				_setFileUploaderUrl();
				$scope.fData.uploader.uploadItem(0);
			} else {
				EventsService.changeAvatar($scope.event.id, UploadAvatar.data.id).then(function (response) {
					if (callback) callback(response);
				});
			}
		}

		function _savingFailed(errors) {
			_setFormErrors(errors);
			cfpLoadingBar.complete();
		}

		function getTagsValues(items) {
			return _.map(items, function (item) {
				return _.isEmpty(item.host_type) ? item.name : item.id;
			});
		}

		function _savingComplete() {
			cfpLoadingBar.complete();
			$rootScope.$broadcast('events.updates');
			$state.go('root.event', {eventId: $scope.event.id});
		}

		function _processResponses(response, avatarResponse) {
			if (response.success) {
				if (avatarResponse && !avatarResponse.success) {
					response.success = false;
					response.errors = avatarResponse.errors;
				}
			}

			if (!response.success) {
				_savingFailed(response.errors);
			}
			else {
				_savingComplete();
			}
		}

		$scope.submitForm = function () {
			var data = getSubmitData();
			var promise = !$scope.event.id ? EventsService.create(data) : EventsService.update($scope.event.id, data);
			cfpLoadingBar.start();
			Deferred.handlePromise(promise, function (response) {
				if (response.success) {
					if (!$scope.event.id) {
						$scope.event.id = response.event.id;
					}

					_avatarUpdate(function (avatarResponse) {
						_processResponses(response, avatarResponse);
					});
				}
				else {
					_processResponses(response);
				}
			});
		};

		$scope.cancelForm = function () {
			$state.go('root.event', {eventId: $scope.event.id});
			if (cfpLoadingBar.status() !== 1) cfpLoadingBar.complete();
		};

		$scope.changeAvatar = function() {
			modalWindow.open('change-avatar');
		};

		$scope.eventDelete = function () {
			Deferred.handlePromise(EventsService.remove($scope.event.id), function () {
				$state.go('root.events.private');
			});
		};

		$scope.$watch('event', function (data, oldData) {
			_.forEach(data, function (value, field) {
				if (value !== oldData[field] && $scope.fData.hasError(field)) {
					_removeError(field);
				}
			});
		}, true);

		$scope.$watch('event.online', function (value, oldValue) {
			if (value !== oldValue && value) {
				_removeError('country');
				_removeError('city');
				_removeError('venue');
			}
		});

		$scope.$watch('event.org', function (maker) {
			$scope.fData.rooms = [];
			$scope.event.room = null;
			if (maker) loadRoomsList(maker.type, maker.data.id).then(setFormRooms);
		});

		var updateDataAvatar = function () {
			$scope.fData.isNewAvatar = true;
			$scope.fData.uploader.queue[0] = UploadAvatar.data;
		};

		UploadAvatar.reset();
		UploadAvatar.registerCallback(updateDataAvatar);

		// Initialization
		initUserAccess();
		initFileUploader();
		loadData($state.params.eventId).then(function () {
			$scope.fData.isReady = true;
		});
	}

	app.controller('PageEventFormCtrl', Controller);
})(angular, NgProject);
