NgProject.controller('PageSettingsEmailCtrl', ["$scope", "$state", "Deferred", "ProfileSettingsService", "cfpLoadingBar", function Controller($scope, $state, Deferred, ProfileSettingsService, cfpLoadingBar) {
	'use strict';

	var errors = {
		data: {},
		add: function (field, message) {
			if (!errors.data[field])
				errors.data[field] = [];
			errors.data[field].push(message);
		},
		list: function (field) {
			return errors.data[field];
		},
		remove: function (field) {
			delete errors.data[field];
		},
		has: function (field) {
			return errors.data[field] && errors.data[field].length;
		},
		isEmpty: function () {
			return _.isEmpty(errors.data);
		}
	};

	function loadRemoteData() {
		Deferred.handlePromise(ProfileSettingsService.email.get(), function (response) {
			if (response.success) {
				$scope.model.email = response.email;
				$scope.controls.isLoading = true;
			}
		});
	}

	$scope.model = {
		email: '',
		new_email: ''
	};

	$scope.controls = {
		isLoading: false,
		requestSend: false,
		hasError: errors.has,
		getErrors: errors.list,
		errorsIsEmpty: errors.isEmpty,

		submit: function () {
			if (!errors.isEmpty()) return;
			cfpLoadingBar.start();
			Deferred.handlePromise(ProfileSettingsService.email.update($scope.model.new_email), function (response) {
				if (response.success) $scope.controls.requestSend = response.success;
				else errors.add('new_email', response.errors.email[0]);
				cfpLoadingBar.complete();
			});
		}
	};

	$scope.$watch('model', function (data, oldData) {
		_.forEach(data, function (value, field) {
			if (value !== oldData[field] && errors.has(field)) errors.remove(field);
		});
	}, true);

	loadRemoteData();
}]);
