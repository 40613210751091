NgProject.service('ContentStabilizer', function () {
	'use strict';

	var data = this;
	var observerCallbacks = [];

	var defaults = {
		scrollWidth: scrollBarWidth()
	};

	var dataStabilizer = _.clone(defaults);

	data.setData = setData;
	data.getData = getData;
	data.resetData = resetData;
	data.resetObservers = resetObservers;
	data.registerCallback = registerCallback;
	data.notifyObservers = notifyObservers;

	return data;

	function setData(object) {
		return angular.extend(dataStabilizer, object);
	}

	function getData() {
		return dataStabilizer;
	}

	function resetData() {
		dataStabilizer = _.clone(defaults);
	}

	function resetObservers() {
		observerCallbacks = [];
	}

	function registerCallback(callback) {
		observerCallbacks.push(callback);
	}

	function notifyObservers() {
		angular.forEach(observerCallbacks, function (callback) {
			callback();
		});
	}

	function scrollBarWidth() {
		var div = $('<div style="height:20px;position:absolute;top:-50px;left:-50px;"></div>');
		$('body').append(div);
		var w1 = div.innerWidth();
		div.css('overflow-y', 'scroll');
		var w2 = div.innerWidth();
		$(div).remove();
		return (w2 - w1);
	}
});
