(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$q", "Deferred", "ArticlesQuestionsService", "TermsService", "cfpLoadingBar"];
	function Controller($scope, $q, Deferred, ArticlesQuestionsService, TermsService, cfpLoadingBar) {
		var articleId = null;
		$scope.questions = [];

		function init() {
			articleId = $scope.$layout.articleId;
			if (articleId) loadRemoteData();
		}

		function loadRemoteData() {
			Deferred.handlePromise(ArticlesQuestionsService.list(articleId), function (response) {
				if (response.success) {
					applyRemoteData(response.items);
				}
			});
		}

		function applyRemoteData(items) {
			var questions = [];
			_.forEach(items, function (object) {
				questions.push(processQuestion(object));
			});
			$scope.questions = _.sortBy(questions, 'sort');
		}

		function processQuestion(item) {
			var time = item.created;
			delete item.maker;
			delete item.created;
			delete item.allow_update;
			item.tags = item.tags ? _.uniq(item.tags, 'id') : [];
			return ({
				isNew: false,
				isViewMode: true,
				id: item.id,
				sort: time,
				data: item
			});
		}

		function replaceQuestion(question, newData) {
			var index = getIndexQuestion(question);
			if (index !== -1)
				$scope.questions[index] = processQuestion(newData);
		}

		function getSubmitData(question) {
			var data = {
				value: question.data.value,
				answer: question.data.answer,
				tags: []
			};

			_.forEach(question.data.tags, function (tag) {
				data.tags.push(_.isEmpty(tag.host_type) ? tag.name : tag.id);
			});

			return data;
		}


		function getIndexQuestion(question) {
			return _.indexOfWithProperty($scope.questions, 'id', question.id);
		}

		function spliceQuestion(question) {
			var index = getIndexQuestion(question);
			if (index !== -1)
				$scope.questions.splice(index, 1);
		}

		function createQuestion(question) {
			if (articleId) cfpLoadingBar.start();
			Deferred.handlePromise(ArticlesQuestionsService.create(articleId, getSubmitData(question)), function (response) {
				if (response.success)
					replaceQuestion(question, response.question);
				if (articleId) cfpLoadingBar.complete();
			});
		}

		function updateQuestion(question) {
			cfpLoadingBar.start();
			Deferred.handlePromise(ArticlesQuestionsService.update(articleId, question.id, getSubmitData(question)), function (response) {
				if (response.success)
					replaceQuestion(question, response.question);
				cfpLoadingBar.complete();
			});
		}

		function deleteQuestion(question) {
			cfpLoadingBar.start();
			Deferred.handlePromise(ArticlesQuestionsService.remove(articleId, question.id), function () {
				spliceQuestion(question);
				cfpLoadingBar.complete();
			});
		}


		$scope.$tags = {
			search: function (query) {
				query = $.trim(query);
				var defer = $q.defer();
				if (query.length) TermsService.tags(query, 30).then(function (response) {
					if (response.success) defer.resolve(response.items);
				});
				else defer.resolve([]);
				return defer.promise;
			},
			transform: function (item) {
				return _.isObject(item) ? item : {name: item.toString()};
			}
		};

		$scope.controls = {
			save: function (question) {
				if (!articleId) {
					question.isViewMode = true;
					return false;
				}
				if (question.isNew)
					createQuestion(question);
				else
					updateQuestion(question);
			},

			remove: function (question) {
				if (question.isNew)
					spliceQuestion(question);
				else
					deleteQuestion(question);
			},

			addNew: function () {
				$scope.questions.push({
					isNew: true,
					isViewMode: false,
					id: new Date().getTime(),
					data: {
						value: '',
						answer: '',
						tags: []
					}
				});
			},

			btnSaveIsDisabled: function (question) {
				return $.trim(question.data.value).length === 0;
			},

			toggleMode: function (question) {
				question.isViewMode = !question.isViewMode;
			}
		};

		init();
	}

	app.controller('ArticleQuestionsFormCtrl', Controller);
})(angular, NgProject);
