(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state", "$stateParams", "$filter", "$timeout", "$q", "Deferred", "EventsService", "DateHelper", "CurrentUser", "SETTINGS", "EventContent", "MemberData"];
	function Controller($scope, $state, $stateParams, $filter, $timeout, $q, Deferred, EventsService, DateHelper, CurrentUser, SETTINGS, EventContent, MemberData) {
		$scope.event = {};
		$scope.msBtnOptions = {
			state: true,
			buttons: {
				do: {
					content: '<i class="icon icon-plus"></i><span>Участвовать</span>',
					class: 'btn3-green'
				},
				status: {
					content: '<i class="icon icon-checkmark"></i><span>Я участвую</span>',
					class: 'btn3-transparent action-ms-hover'
				},
				undo: {
					content: '<i class="icon icon-close"></i><span>Не участвовать</span>',
					class: 'btn3-transparent action-ms-hover'
				}
			}
		};
		$scope.controls = {
			allowUpdate: false,
			showConnectBtn: false,
			showQuestionsBtn: false,
			showQuestionsBadge: false,
			showQuestionsBadgeNewCounter: false,
			showVotingQuestions: false,
			showWebinarSections: false,
			showFinishDate: false
		};

		function initRefreshTimer(event) {
			// обновить мероприятие, если дата начала больше текущей даты
			if (DateHelper.fromNow(event.start) < 0) {
				var interval = DateHelper.fromNow(event.added);
				if (interval < DateHelper.getMsByView('week')) {
					$timeout(reloadState, interval);
				}
			}
		}

		function initControls(event) {
			ng.extend($scope.controls, {
				allowUpdate: event.allow_update,
				showConnectBtn: !event.is_maker && (!event.isExpired || (event.isExpired && event.member)),
				showWebinarSections: !event.isExpired && event.online && !!event.room,
				showFinishDate: !!event.finish && !!DateHelper.daysBetweenDates(event.added, event.finish)
			});

			if (event.allow_questions) {
				$scope.controls.showVotingQuestions = event.kind.name.toLowerCase() === 'ченнелинг';
				if (!$scope.controls.showVotingQuestions) {
					$scope.controls.showQuestionsBtn = !event.isExpired || !!event.published_questions;
					if ($scope.controls.showQuestionsBtn) {
						$scope.controls.showQuestionsBadge = !!event.published_questions || !!event.new_questions;
						$scope.controls.showQuestionsBadgeNewCounter = !!event.new_questions;
					}
				}
			}
		}

		function loadRemoteData(eventId) {
			Deferred.handlePromise(EventsService.view(eventId), function (response) {
				if (response.success) {
					applyRemoteData(response.event);
				}
			});
		}

		function applyRemoteData(event) {
			processData(event);
			$scope.msBtnOptions.state = !event.member;
			initRefreshTimer(event);
			initControls(event);
			$scope.event = event;
			EventContent.setData(event);
			EventContent.notifyObservers();
		}

		function processData(event) {
			event.finish = (event.finish || event.added + SETTINGS.events.filters.younger_older_correction) * 1000;
			event.added = event.added * 1000;
			event.audios = event.audios || [];

			event.isExpired = DateHelper.fromNow(event.finish) < 0;
			event.is_maker = event.org.type === 'user' && event.org.id === CurrentUser.id;
			if (!event.online) {
				event.location_name = $filter('locationName')(event);
				if (event.venue) {
					event.location_name = event.location_name + ', ' + event.venue;
				}
			}
			if (event.description && event.description.length) {
				event.description = $filter('contentImagesUrls')(event.description);
			}
		}

		function reloadState() {
			$state.transitionTo('root.event', {eventId: $scope.event.id}, {
				reload: true, inherit: false, notify: true
			});
		}

		$scope.join = function () {
			if (!!CurrentUser.id) {
				$scope.event.member = true;
				$scope.msBtnOptions.state = false;
				MemberData.registrationAuthUser($scope.event.id).then(function (response) {
					if (response.success) {
						$scope.event.members.unshift(response.members.headman);
						if ($scope.event.members.length > 8) $scope.event.members.pop();
						$scope.event.members_count++;
					}
				});
			} else $state.go('root.event.registration');
		};

		$scope.leave = function () {
			$scope.msBtnOptions.state = true;
			Deferred.handlePromise(EventsService.leave($scope.event.id), function (response) {
				if (response.success) {
					delete $scope.event.member;
					_.remove($scope.event.members, function (item) {
						if (_.get(item.user, 'id') === CurrentUser.id) return true;
					});
					$scope.event.members_count--;
				}
			});
		};

		loadRemoteData($stateParams.eventId);
		$scope.$on('$destroy', function () {
			EventContent.reset();
		});
	}

	app.controller('PageEventCtrl', Controller);
})(angular, NgProject);
