NgProject.directive('modal', function () {
	'use strict';
	return {
		restrict: 'A',
		compile: function (element) {
			element
				.attr('tabindex', 0)
				.attr('role', 'dialog')
				.attr('aria-hidden', 'true');

			if (element.attr('id') === 'modal-article') {
				element.prepend('<a class="modal-close" ng-click="$hide()" aria-label="Close"></a>');
				element.find('.modal-dialog').prepend('<a class="modal-dialog-close" ng-click="$hide()" aria-label="Close"><i class="icon icon-close" aria-hidden="true"></i></a>');
			}
			else if (element.hasClass('modal-style-6')) {
				element.prepend('<a class="modal-close" ng-click="$hide()" aria-label="Close"></a>');
				element.find('.modal-header').prepend('<a class="modal-dialog-close" ng-click="$hide()" aria-label="Close">Закрыть</a>');
			}
			else {
				element.prepend('<a class="modal-close" ng-click="$hide()" aria-label="Close"><i class="icon icon-close" aria-hidden="true"></i></a>');
			}
		}
	};
});
