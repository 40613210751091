NgProject.directive('srcResize', ["$filter", function ($filter) {
	'use strict';
	function link(scope, element, attrs) {
		attrs.$set('src', $filter('imageUrl')(setResizeFile(attrs['srcResize'], element)));

		function setResizeFile(url) {
			var wrap = element.parent();
			var size = attrs['srcResizeOpts'] || [Math.ceil(wrap.width()), Math.ceil(wrap.height())].join('x');
			var imageFile = url.split('/');
			var imageName = imageFile.pop().split('.');
			var ext = imageName.pop();

			imageName.push(size, ext);
			imageFile.push(imageName.join('.'));
			return imageFile.join('/');
		}
	}

	return {
		restrict: 'A',
		link: link,
		scope: true
	};
}]);
