'use strict';
NgProject.controller('MaterialsPrivateCtrl', ["$scope", "$state", "VideosService", "AudiosService", function ($scope, $state, VideosService, AudiosService) {
	var listOptions = {
		teaserOptions: {
			buttons: {deleteByOwner: true, add: false}
		}
	};

	$scope.videos = {
		promise: VideosService.findAllByCurrentUser,
		listOptions: listOptions
	};

	$scope.audios = {
		promise: AudiosService.findAllByCurrentUser,
		listOptions: listOptions
	};

	$scope.imagesControls = {
		isAlbumPage: function() {
			return $state.current.name === 'root.materials.private.images.album';
		},
		openImagesFrom: function() {
			$scope.$modal.open('materials-images-form', $scope.imagesControls.isAlbumPage() ? {album_id: $state.params.albumId} : {});
		}
	};
}]);
