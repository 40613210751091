NgProject.controller('EventsListCommonCtrl', ["$scope", "EventsService", "CurrentUser", function ($scope, EventsService, CurrentUser) {
	'use strict';
	var blocks = {common: false};

	$scope.controls = {
		isReady: false,
		showHeader: false
	};

	$scope.controls.blocks = {
		init: function (name) {
			blocks[name] = false;
		},
		update: function (name, value) {
			blocks[name] = value;
			processLoadedTriggers();
		},
		active: function (name) {
			return blocks[name];
		},
		remove: function (name) {
			delete blocks[name];
			processLoadedTriggers();
		}
	};

	$scope.events = {
		promise: EventsService.findAll,
		options: {
			requestParams: {
				younger_than: new Date().getTime()
			}
		},
		action: {show: !!CurrentUser.id, sref: 'root.create-event'},
		onLoadCallback: function () {
			if (!blocks.common) $scope.controls.blocks.update('common', true);
		}
	};

	function processLoadedTriggers() {
		$scope.controls.isReady = _.every(blocks);
		$scope.controls.showHeader = _.size(blocks) > 1;
	}
}]);

