'use strict';
(function ($) {
	var plugin = 'redactor_edges';

	$.Redactor.prototype[plugin] = function () {
		return {
			init: function () {
				var $editor = this.core.editor();

				$editor.on('click', $.proxy(function (e) {
					if (!$(e.target).is($editor)) return;

					var isTopEdge = e.offsetY < parseInt($editor.css('padding-top'));
					var isBottomEdge = e.offsetY > $editor.height();

					if (!isTopEdge && !isBottomEdge) return;

					if (!$editor.contents().length) return;

					if (isTopEdge && !$editor.contents().first().is('[contenteditable=false]')) return;
					if (isBottomEdge && !$editor.contents().last().is('[contenteditable=false]')) return;

					e.preventDefault();
					e.stopPropagation();

					var node = $(this.opts.emptyHtml);
					this.buffer.set();
					$editor[isTopEdge ? 'prepend' : 'append'](node);
					this.caret.start(node);
				}, this));
			}
		};
	};
})(jQuery);
