NgProject.controller('ArticlesListSubscribesCtrl', ["$rootScope", "$scope", "Deferred", "ArticlesService", "SubscribeService", "InterfaceCounters", function ($rootScope, $scope, Deferred, ArticlesService, SubscribeService, InterfaceCounters) {
	'use strict';
	var requests = {
		count: 0,
		is_all: false
	};

	$scope.$subscribes = {
		enabled: true,
		data: {count: 0, items: []},
		mode: {
			limit: 2,
			loading: false,
			all_loading: false
		},
		decide: function (accept, item) {
			(accept ? ArticlesService.reading.accept : SubscribeService.reject)(item.id).then(function () {
				if ($scope.$subscribes.enabled && $scope.$subscribes.data.items.length < 2) loadNextItems();
				if (accept && InterfaceCounters.articles.reading === 0) InterfaceCounters.articles.reading++;
				$rootScope.$broadcast('InterfaceCounters:update');
			});
			removeItem(item.id);
			disableIfEmpty();
		},
		rejectAll: function () {
			SubscribeService.rejectAll().then(function () {
				$rootScope.$broadcast('InterfaceCounters:update');
			});
			disable();
		},
		showAll: function () {
			if ($scope.$subscribes.mode.all_loading) return;
			requests.is_all = true;
			loadData(function () {
				disableIfEmpty();
				$scope.$subscribes.mode.limit = null;
			});
		},
		read: function (item) {
			var article = _.find($scope.$subscribes.data.items, 'id', item.id);
			article.is_reading = item.is_reading = true;
			SubscribeService.reject(item.id);
		}
	};

	function init() {
		$scope.controls.blocks.init('subscribes');
		loadData(function () {
			if (!$scope.$subscribes.data.count) {
				disable();
				return;
			}
			$scope.controls.blocks.update('subscribes', true);
		});
	}

	function loadData(callback) {
		var request_number = ++requests.count;
		var params = {
			limit: requests.is_all ? 100 : $scope.$subscribes.mode.limit * 3
		};
		$scope.$subscribes.mode.loading = true;
		$scope.$subscribes.mode.all_loading = requests.is_all;
		SubscribeService.getSubscribes(params).then(function (response) {
			if (request_number < requests.count) return;
			requests.count = 0;
			if (response.success) {
				$scope.$subscribes.data.count = _.get(response, 'count', 0);
				$scope.$subscribes.data.items = _.get(response, 'items', []);
				$scope.$subscribes.mode.loading = false;
				$scope.$subscribes.mode.all_loading = false;
				response.items.map(function (item) {
					item.is_reading = false;
				});
				if (callback) callback();
			}
		});
	}

	function loadNextItems() {
		if (DEBUG) console.log('Load Next Items');
		if ($scope.$subscribes.mode.limit && $scope.$subscribes.data.count) loadData();
	}

	function removeItem(id) {
		$scope.$subscribes.data.count--;
		_.remove($scope.$subscribes.data.items, 'id', id);
	}

	function disableIfEmpty() {
		if (!$scope.$subscribes.data.count) disable();
	}

	function disable() {
		$scope.$subscribes.enabled = false;
		$scope.$subscribes.data.count = 0;
		$scope.$subscribes.data.items = [];
		$scope.controls.blocks.remove('subscribes');
	}

	// initialization
	init();
}]);
