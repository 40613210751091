NgProject.controller('RedactorImagesModalCtrl', ["$scope", "$filter", "ImagesService", "CurrentUser", function ($scope, $filter, ImagesService, CurrentUser) {
	'use strict';

	var redactor = $scope.$modalWindow.data(0);
	var maker = _.get(redactor.opts, 'data.maker', {
		id: CurrentUser.id,
		type: 'user',
		data: CurrentUser
	});

	$scope.dataImages = {
		currentTab: 'one'
	};

	$scope.controls = {
		selected: null,
		type: maker.type,
		select: function ($image) {
			$image.selected = true;
			$scope.controls.selected = $image;
		},
		changeTab: function (tab) {
			$scope.dataImages.currentTab = tab;
			$scope.controls.selected = null;
			$scope.$broadcast('images-list-reload');
		},
		activeTab: function (request) {
			return request === $scope.dataImages.currentTab;
		},
		submit: function () {
			var image = $scope.controls.selected;
			redactor.redactor_images.insert($filter('imageUrl')(image.file.url));
			$scope.$close();
		}
	};


	function getPromise(params) {
		var promise = null;
		switch ($scope.dataImages.currentTab) {
			case 'one':
				if (maker.type === 'user') {
					promise = ImagesService.findAllByUser(maker.data.id, params);
				} else promise = ImagesService.findAllByCurrentUser(params);
				break;
			case 'two':
				if (maker.type === 'group') {
					promise = ImagesService.findAllByGroup(maker.data.id, params);
				} else promise = ImagesService.findAllByCurrentUser(params);
				break;
			case 'three':
				promise = ImagesService.findAll(params);
				break;
		}

		return promise;
	}

	$scope.images = {
		promise: getPromise,
		options: {
			requestParams: {limit: 24}
		},
		optionsList: {
			template: 'widgets.images.image-selector-modal',
			infiniteScrollContainer: '.modal-body'
		},
		searchOptions: {
			enableFilters: false
		}
	};
}]);
