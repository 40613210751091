(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state"];
	function Controller($scope, $state) {
		$scope.goToHome = function () {
			$state.go('root.home');
		};
	}

	app.controller('SystemLayoutCtrl', Controller);
})(angular, NgProject);
