NgProject.directive('phoneInput', ["$filter", "$browser", function ($filter, $browser) {
	'use strict';
	return {
		require: 'ngModel',
		link: link
	};

	function link(scope, element, attrs, Ctrl) {
		var listener = function () {
			var value = element.val().replace(/[^0-9]/g, '');
			element.val($filter('tel')(value, false));
		};

		Ctrl.$parsers.push(function (viewValue) {
			return viewValue.replace(/[^0-9]/g, '').slice(0, 10);
		});

		Ctrl.$render = function () {
			element.val($filter('tel')(Ctrl.$viewValue, false));
		};

		element.bind('change', listener);
		element.bind('keydown', function (event) {
			var key = event.keyCode;
			if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
				return;
			}
			$browser.defer(listener);
		});

		element.bind('paste cut', function () {
			$browser.defer(listener);
		});
	}
}]);

NgProject.filter('tel', function () {
	'use strict';
	return function (tel) {
		if (!tel) return '';
		var value = tel.toString().trim().replace(/^\+/, '');

		if (value.match(/[^0-9]/)) return tel;

		var country, city, number;

		switch (value.length) {
			case 1:
			case 2:
			case 3:
				city = value;
				break;
			default:
				city = value.slice(0, 3);
				number = value.slice(3);
				break;
		}

		if (number) {
			if (number.length > 3) {
				number = number.slice(0, 3) + '-' + number.slice(3, 7);
			}

			return ('(' + city + ') ' + number).trim();
		}
		else {
			return '(' + city;
		}
	};
});
