NgProject.provider('modalWindow', function () {
	'use strict';
	var provider = this;
	var instances = [];
	this.config = {
		animation: false,
		backdropAnimation: false
	};
	this.modals = {};

	this.$get = ["ModalWindowInstance", "ContentStabilizer", function (ModalWindowInstance, ContentStabilizer) {
		return {
			open: function (type) {
				var args = Array.prototype.slice.call(arguments, 1);
				var config = _.isString(type) ? provider.modals[type] : type;
				var instance = new ModalWindowInstance(angular.extend({}, config, provider.config), args);

				instance.once('hide', function (event) {
					if (instances.length > 1) {
						event.preventDefault();
						instance.modal.destroy();
					}
				});

				instance.once('destroy', function () {
					_.remove(instances, instance);
				});

				instances.push(instance);
				ContentStabilizer.setData({instance: instance});
				ContentStabilizer.notifyObservers();
				return instance;
			},
			closeAllAndOpen: function () {
				_.forEach(instances, function (instance) {
					instance.modal.destroy();
				});
				this.open.apply(null, arguments);
			},
			close: function () {
				// close all modals
				_.forEach(instances, function (instance) {
					if (instance) instance.modal.hide();
				});
			},
			isOpened: function () {
				return Boolean(instances.length);
			}
		};
	}];

	return (this);
});
