(function (ng, app) {
	'use strict';
	app.service('ArticlesQuestionsManageService', ["$resource", "serviceHelper", "SETTINGS", "ArticlesQuestionsSearchManageParams", function ($resource, serviceHelper, SETTINGS, ArticlesQuestionsSearchManageParams) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/administration/content/questions', null, {
			setTagVisibility: {
				url: SETTINGS.apiBaseUrl + 'api/v1/administration/content/questions/:id/tags/:tag_id/visibility',
				params: {id: '@id', tag_id: '@tag_id'},
				method: 'PUT'
			}
		});

		return {
			setTagVisibility: function (item_id, tag_id, visibility) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'setTagVisibility',
					parameters: {
						id: item_id,
						tag_id: tag_id,
						visibility: visibility
					}
				});
			},

			findAll: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: ArticlesQuestionsSearchManageParams.process(params)
				});
				return promise;
			}
		};
	}]);
})(angular, NgProject);
