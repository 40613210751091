'use strict';
(function ($) {
	var plugin = 'redactor_terms';
	var options = {
		termTemplate: '<span />',
		controlsTemplateId: 'module.Redactor.term-controls'
	};

	$.Redactor.prototype[plugin] = function () {
		return {
			langs: {
				ru: {'redactor-terms': 'Определение'}
			},

			init: function () {
				this[plugin].createButton();
				this[plugin].attachControlsEvents();
			},

			createButton: function () {
				var button = this.button.add('terms', this.lang.get('redactor-terms'));
				this.button.addCallback(button, this[plugin].buttonCallback);
			},

			buttonCallback: function () {
				this.marker.remove();
				var element = this[plugin].getTermNode.call(this);
				if (element) {
					this[plugin].buttonUpdateCallback.call(this, element);
					return;
				}

				var errors = this[plugin].selection.validate.call(this);
				if (errors.length) {
					this.openModal('redactor-validate', errors);
					return;
				}

				this.selection.save();
				this.openModal('redactor-terms', this[plugin], {
					query: this[plugin].selection.text.call(this)
				});
			},

			buttonUpdateCallback: function (element) {
				this.marker.remove();
				var node = this[plugin].getTermNode.call(this, element);
				if (!node) return;
				this.selection.save();
				this.openModal('redactor-terms', this[plugin], {
					json: $.parseJSON(node.attr('term-definition'))
				});
			},

			buttonRemoveCallback: function (element) {
				this[plugin].unlink.call(this, element);
			},

			getTermNode: function (element) {
				if (element === undefined) {
					element = $(this.selection.current());
				}

				var node = element.is('[term-definition]') ? element : element.closest('[term-definition]');
				if (!node) return null;
				if (node instanceof jQuery && !node.length) return null;

				return node;
			},

			selection: {
				validate: function () {
					var errors = [];
					var text = this[plugin].selection.text.call(this);
					if (/[.,;:!?]/g.test(text)) errors.push('UAC_SYM');
					if (text.split(' ').length > 4) errors.push('LONG_DEF');
					if (_.isEmpty(text)) errors.push('UNDF_DEF');
					return errors;
				},
				text: function () {
					return _.trim(this.selection.text());
				}
			},

			insert: function (data) {
				this.selection.restore();
				var element = this[plugin].getTermNode.call(this);
				var params = {
					term: data.term,
					definition: data.definition
				};

				if (!element) {
					// insert definition
					this.buffer.set();
					element = this[plugin].buildLinkFromObject.call(this, this[plugin].selection.text.call(this), params);
					this.insert.node(element[0], true);
				}
				else {
					// update definition
					this.buffer.set();
					element.attr(this[plugin].getLinkAttrs(params));
				}
			},

			unlink: function (element) {
				var node = this[plugin].getTermNode.call(this, element);
				if (!node) return;
				var text = node.text();
				this.buffer.set();
				node.remove();
				if (text.length) {
					this.selection.get();
					this.insert.text(text);
				}
			},

			buildLinkFromObject: function (text, data) {
				return $(options.termTemplate)
					.attr(this[plugin].getLinkAttrs(data))
					.text(text);
			},

			getLinkAttrs: function (data) {
				return this.$injector.invoke(['$state', function ($state) {
					return {
						'term-definition': JSON.stringify(data),
						'href': $state.href('root.wiki.view.term', {termId: data.term})
					};
				}]);
			},

			attachControlsEvents: function () {
				this.core.editor().on('click touchstart', '[term-definition]', $.proxy(function (e) {
					var target = $(e.target);
					var position = target.offset();
					var tooltip = this[plugin].getControlsTooltip.call(this, target);

					tooltip.css({
						top: (position.top + 20) + 'px',
						left: position.left + 'px'
					});

					this.observe.closeAllTooltip();
					tooltip.appendTo('body');

					// callback
					this.core.callback('tooltipShow', {tooltip: tooltip});

					this.core.editor().on('touchstart.redactor-term-tooltip.' + this.uuid + ' click.redactor-term-tooltip.' + this.uuid, $.proxy(this[plugin].closeTooltip, this));
					$(document).on('touchstart.redactor-term-tooltip.' + this.uuid + ' click.redactor-term-tooltip.' + this.uuid, $.proxy(this[plugin].closeTooltip, this));

					return false;
				}, this));
			},

			closeTooltip: function (e) {
				e = e.originalEvent || e;

				var target = e.target;
				var $parent = $(target).closest('span[term-definition]', this.$editor[0]);
				if ($parent.length !== 0) {
					return;
				}
				else if ($(target).is('span[term-definition]') && this.utils.isRedactorParent(target)) {
					return;
				}

				this.observe.closeAllTooltip();

				this.core.editor().off('touchstart.redactor-term-tooltip.' + this.uuid + ' click.redactor-term-tooltip.' + this.uuid);
				$(document).off('touchstart.redactor-term-tooltip.' + this.uuid + ' click.redactor-term-tooltip.' + this.uuid);
			},

			getControlsTooltip: function (element) {
				var redactor = this;
				return this.$injector.invoke(['$rootScope', '$templateCache', '$compile', function ($rootScope, $templateCache, $compile) {
					var $scope = $rootScope.$new();
					$scope.$controls = {
						update: function () {
							redactor[plugin].buttonUpdateCallback(element);
							$scope.$destroy();
						},
						unlink: function () {
							redactor[plugin].buttonRemoveCallback(element);
							$scope.$destroy();
						}
					};

					var $tooltip = $compile($templateCache.get(options.controlsTemplateId))($scope);
					$scope.$on('$destroy', function () {
						$tooltip.remove();
					});

					return $tooltip;
				}]);
			}
		};
	};
})(jQuery);
