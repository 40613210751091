NgProject.factory('ArticlesSearchParams', ["SETTINGS", function (SETTINGS) {
	'use strict';
	return {
		process: function (params) {
			if (_.isEmpty(params)) return {};
			var options = {};

			if (!_.isEmpty(params.q))
				options.q = params.q;

			if (params.page && params.page > 1)
				options.page = params.page;

			if (params.limit)
				options.per_page = params.limit;

			if (params.seriesId)
				options.seriesId = params.seriesId;

			if (params.typeId)
				options.publ_type = params.typeId;

			if (params.maker)
				options.maker = params.maker;

			if (params.order && _.indexOfWithProperty(SETTINGS.articles.filters.order, 'key', params.order) !== -1)
				options.order = params.order;

			if (params.hasOwnProperty('pinned')) options.pinned = params.pinned;

			if (params.pin) options.pin = params.pin;

			if (params.watching) options.watching = params.watching;

			if (params.status) options.status = params.status;

			if(params.views_count_for_period) options.views_count_for_period = params.views_count_for_period;

			return options;
		},

		processAuthorsList: function (params) {
			var options = {
				limit: 15
			};

			if (!_.isEmpty(params.q))
				options.q = params.q;

			if (params.limit)
				options.limit = params.limit;
			else if (params.limit === false)
				delete options.limit;

			return options;
		}
	};
}]);
