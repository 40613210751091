(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state", "Deferred", "ProfileSubscribesSettingsService", "cfpLoadingBar"];
	var subscribes = [
		{type: 'news', label: 'Новости'},
		{type: 'articles', label: 'Статьи'},
		{type: 'events', label: 'Мероприятия'},
		{type: 'videos', label: 'Видео'},
		{type: 'audios', label: 'Аудио'},
	];

	function Controller($scope, $state, Deferred, ProfileSubscribesSettingsService, cfpLoadingBar) {
		$scope.options = {
			types: subscribes,
			groups: {
				promise: ProfileSubscribesSettingsService.groups,
				listOptions: {template: 'pages.profile.settings.subscribes.groups-list-item'}
			},
			users: {
				promise: ProfileSubscribesSettingsService.users,
				listOptions: {template: 'pages.profile.settings.subscribes.users-list-item'},
				options: {followButton: false}
			}
		};

		$scope.controls = {
			isType: function (type) {
				return $state.is('root.settings.subscribes.' + type);
			},
			change: function (object, type) {
				object.error = false;
				if (_.sum(object.subscribed_for) === 0) {
					object.error = true;
					object.subscribed_for[type] = !object.subscribed_for[type];
				}
				else {
					cfpLoadingBar.start();
					Deferred.handlePromise(ProfileSubscribesSettingsService.update(object.subscribe_id, object.subscribed_for), function (response) {
						object.subscribed_for = response.subscribed_for;
						cfpLoadingBar.complete();
					});
				}
			}
		};

		$scope.$on('$stateChangeSuccess', function (event, toState) {
			if (toState.name === 'root.settings.subscribes') {
				$state.go('.users');
			}
		});
	}

	app.controller('PageSettingsSubscribesCtrl', Controller);
})(angular, NgProject);
