/**
 * A generic confirmation for risky actions.
 * Usage: Add attributes: ng-really-message="Are you sure"? ng-really-click="takeAction()" function
 */
NgProject.directive('ngReallyClick', function () {
	'use strict';
	return {
		restrict: 'A',
		link: function (scope, element, attrs) {
			element.bind('click', function (event) {
				var message = attrs.ngReallyMessage;
				if (message && window.confirm(message)) {
					scope.$apply(attrs.ngReallyClick);
				}
				event.stopPropagation();
				event.preventDefault();
			});
		}
	};
});
