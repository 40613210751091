'use strict';
NgProject.directive('socialAuthButton', ["$state", "$window", "$document", "AuthService", "AUTH_EVENTS", function ($state, $window, $document, AuthService, AUTH_EVENTS) {

	function link(scope, element, attrs) {
		var provider = attrs['socialAuthButton'];
		var authWindow = null;

		element.on('click', function () {
			var url = $window.location.origin + '/api/v1/users/auth/' + provider;
			//url = '//localhost:3000/auth/' + provider;
			if (authWindow) authWindow.close();
			authWindow = $window.open(url, 'Authenticate', getWindowParams());
			authWindow.focus();
		});

		scope.$on('$destroy', function () {
			if (authWindow) authWindow.close();
		});

		/**
		 * authWindow Popup Window Handler.
		 */
		$window.socialAuthHandler = function (status, data) {
			if (!status) {
				scope.$emit(AUTH_EVENTS.socialAuthFailed);
				return false;
			}

			switch (status) {
				case 'UserIsAuthenticated':
					AuthService.authenticateByToken(data.token);
					break;
				case 'UserIsNotRegistered':
					$state.go('modal.registration', {credentials: data});
					break;
			}
		};
	}

	// calculate position of popup window
	function getWindowParams() {
		//fixes dual-screen position (Most browsers/Firefox)
		var dualScreen = {
			left: $window.screenLeft !== undefined ? $window.screenLeft : $window.screen.left,
			top: $window.screenTop !== undefined ? $window.screenTop : $window.screen.top,
			width: $window.innerWidth ? $window.innerWidth : $document.documentElement.clientWidth ? $document.documentElement.clientWidth : $window.screen.width,
			height: $window.innerHeight ? $window.innerHeight : $document.documentElement.clientHeight ? $document.documentElement.clientHeight : $window.screen.height
		};

		var size = {height: 500, width: 500};

		var params = [
			'toolbar=no',
			'location=no',
			'directories=no',
			'status=no',
			'menubar=no',
			'scrollbars=no',
			'resizable=no',
			'copyhistory=no',
			'width=' + size.width,
			'height=' + size.height,
			'top=' + (((dualScreen.height / 2) - (size.height / 2)) + dualScreen.top),
			'left=' + (((dualScreen.width / 2) - (size.width / 2)) + dualScreen.left)
		];

		return params.join(',');
	}

	return {restrict: 'A', link: link};
}]);
