(function (ng, app) {
	'use strict';
	app.service('ImagesAlbumsService', ["$resource", "serviceHelper", "SETTINGS", "ImagesAlbumsSearchParams", "ImageAlbum", "CurrentUser", function ($resource, serviceHelper, SETTINGS, ImagesAlbumsSearchParams, ImageAlbum, CurrentUser) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/images/albums/:albumId', {albumId: '@albumId'}, {
			byUser: {url: SETTINGS.apiBaseUrl + 'api/v1/users/:userId/images/albums/:albumId'},
			byCurrentUser: {url: SETTINGS.apiBaseUrl + 'api/v1/profile/images/albums/:albumId'},
			byGroup: {url: SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/images/albums/:albumId'}
		});

		function processResponseCollection(response, owner) {
			if (response.success) {
				response.items = response.items.map(function(item) {
					var model = ImageAlbum.create(item);
					if (owner) model.setOwner(owner.type, owner.id);
					return model;
				});
			}
		}

		function findAll(resourceName, options) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: resourceName,
				parameters: options.params
			});
			promise.then(function(response) {
				processResponseCollection(response, options.owner);
			});
			return promise;
		}

		function findOne(resourceName, options) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: resourceName,
				parameters: options.params
			});
			promise.then(function(response) {
				if (response.success) response.album = ImageAlbum.create(response.album);
			});
			return promise;
		}

		return {
			create: function (data) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'save',
					parameters: {album: data}
				});
				promise.then(function(response) {
					if (response.success) response.album = ImageAlbum.create(response.album);
				});
				return promise;
			},

			findAllByCurrentUser: function (params) {
				return findAll('byCurrentUser', {
					params: ImagesAlbumsSearchParams.process(params),
					owner: {type: 'user', id: CurrentUser.id}
				});
			},
			findAllByUser: function (userId, params) {
				return findAll('byUser', {
					params: angular.extend(ImagesAlbumsSearchParams.process(params), {userId: userId}),
					owner: {type: 'user', id: userId}
				});
			},
			findAllByGroup: function (groupId, params) {
				return findAll('byGroup', {
					params: angular.extend(ImagesAlbumsSearchParams.process(params), {groupId: groupId}),
					owner: {type: 'group', id: groupId}
				});
			},

			findOneByCurrentUser: function (albumId) {
				return findOne('byCurrentUser', {params: {albumId: albumId}});
			},
			findOneByUser: function (userId, albumId) {
				return findOne('byUser', {params: {userId: userId, albumId: albumId}});
			},
			findOneByGroup: function (groupId, albumId) {
				return findOne('byGroup', {params: {groupId: groupId, albumId: albumId}});
			}
		};
	}]);
})(angular, NgProject);
