NgProject.directive('commentsForm', ["$timeout", function ($timeout) {
	'use strict';
	function link(scope, element, attrs, controller) {
		scope.$comments.form = {
			message: '',
			submit: function () {
				if (scope.$comments.form.message.length) {
					controller.addComment(scope.$comments.form.message);
					scope.$comments.form.message = '';
					element.off('focusout');
				}
			}
		};

		if (scope.$comments.show && !scope.$comments.count) {
			element.find('textarea').focus();
			element.on('focusout', function () {
				$timeout(function () {
					if (element.find(':focus').length === 0 && scope.$comments.form.message.length === 0) {
						scope.$comments.show = false;
					}
				}, 100);
			});
		}
	}

	return {
		require: '^^comments',
		restrict: 'E',
		link: link,
		templateUrl: 'widgets.comments.comments-form'
	};
}]);
