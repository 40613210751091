NgProject.controller('RedactorAudiosModalCtrl', ["$scope", "AudiosService", "CurrentUser", function ($scope, AudiosService, CurrentUser) {
	'use strict';
	var redactor = $scope.$modalWindow.data(0);
	var maker = _.get(redactor.opts, 'data.maker', {
		id: CurrentUser.id,
		type: 'user',
		data: CurrentUser
	});

	$scope.dataAudios = {
		currentTab: 'one'
	};

	$scope.controls = {
		selected: null,
		type: maker.type,
		select: function ($audio) {
			$audio.selected = true;
			$scope.controls.selected = $audio;
		},
		changeTab: function (tab) {
			$scope.dataAudios.currentTab = tab;
			$scope.controls.selected = null;
			$scope.$broadcast('audios-list-reload');
		},
		activeTab: function (request) {
			return request === $scope.dataAudios.currentTab;
		},
		submit: function () {
			var audio = $scope.controls.selected;
			redactor.$redactorAudio.add(audio);
			$scope.$close();
		}
	};

	function getPromise(params) {
		var promise = null;
		switch ($scope.dataAudios.currentTab) {
			case 'one':
				if (maker.type === 'user') {
						promise = AudiosService.findAllByUser(maker.data.id, params);
				} else promise = AudiosService.findAllByCurrentUser(params);
				break;
			case 'two':
				if (maker.type === 'group') {
					promise = AudiosService.findAllByGroup(maker.data.id, params);
				} else promise = AudiosService.findAllByCurrentUser(params);
				break;
			case 'three':
				promise = AudiosService.findAll(params);
				break;
		}

		return promise;
	}

	$scope.audios = {
		promise: getPromise,
		options: {
			requestParams: {limit: 10}
		},
		optionsList: {
			template: 'widgets.audios.audio-selector-modal',
			infiniteScrollContainer: '.modal-body'
		},
		searchOptions: {
			enableFilters: false
		}
	};
}]);
