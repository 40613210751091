NgProject.directive('commentsList', function () {
	'use strict';
	return {
		restrict: 'E',
		templateUrl: 'widgets.comments.comments-list',
		compile: compile
	};

	function compile(tElement, tAttrs) {
		var commentsWall = [
			'<div ng-if="$comments.showMoreButton && $comments.count > $comments.countOfVisibleComments">',
			'<button type="button" ng-click="$comments.controls.showAllComments()" class="button-show-all-comments">Показать комментарии</button>',
			'</div>'
		].join('');
		if (tAttrs.type !== 'articles') {
			tElement.append(commentsWall);
		}
	}
});
