NgProject.service('SubscribeService', ["$resource", "serviceHelper", "SETTINGS", function ($resource, serviceHelper, SETTINGS) {
	'use strict';
	var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/profile/articles/subscribes/:articleId',
		{articleId: '@articleId'}, {
			allSubscribes: {url: SETTINGS.apiBaseUrl + 'api/v1/profile/articles/subscribes'},
			reject: {method: 'DELETE'},
			rejectAll: {method: 'DELETE', url: SETTINGS.apiBaseUrl + 'api/v1/profile/articles/subscribes'}
		});

	return {
		getSubscribes: function (params) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'allSubscribes',
				parameters: params
			});
			return (promise);
		},

		reject: function (articleId) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'reject',
				parameters: {articleId: articleId}
			});
			return (promise);
		},

		rejectAll: function () {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'rejectAll'
			});
			return (promise);
		}
	}
}]);
