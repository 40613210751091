'use strict';
NgProject.directive('termDefinition', ["$mdPanel", function ($mdPanel) {
	var defaults = {
		controller: 'TermDefinitionCtrl',
		controllerAs: 'ctrl',
		templateUrl: 'module.Redactor.directives.termDefinition.template',
		panelClass: 'term-definition-popover',
		clickOutsideToClose: true,
		focusOnOpen: false,
		zIndex: 90,
		hasBackdrop: true
	};

	function link(scope, element, attrs) {
		if (element.prop('tagName') !== 'A') return;

		var panel = null;

		element.on('click', function (ev) {
			var config = angular.extend({}, defaults, {
				attachTo: angular.element(document.body),
				openFrom: ev,
				locals: {
					params: $.parseJSON(attrs['termDefinition'])
				}
			});

			if (element.closest('.modal').length) {
				config.zIndex = 1060;
			}

			config.position = $mdPanel.newPanelPosition()
				.relativeTo(element)
				.addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW);

			panel = $mdPanel.open(config);

			return false;
		});

		scope.$on('$destroy', function () {
			if (panel) panel.$$state.value.close();
		});
	}

	return {
		restrict: 'A',
		link: link
	};
}]);

NgProject.controller('TermDefinitionCtrl', ["$state", "$window", "TermsService", "DefinitionHelper", function ($state, $window, TermsService, DefinitionHelper) {
	var that = this;
	var params = this.params;
	this.ready = false;
	this.data = null;

	this.open = function () {
		var id = _.get(that.data, 'id', params.term);
		$window.open($state.href('root.wiki.view.term.info', {termId: id}), '_blank');
	};

	TermsService.termDefinition(params.term, params.definition).then(function (response) {
		that.ready = true;
		that.data = {
			id: response.definition.term.id,
			title: response.definition.term.title,
			text: getDefinition(response.definition.value)
		};
	});

	function getDefinition(data) {
		var value = /<span|<p/g.test(data) ? $(data).text() : data.value;
		return DefinitionHelper.to(DefinitionHelper.clean(value));
	}
}]);
