NgProject.directive('navSearchBar', function () {
	'use strict';
	function link(scope, element) {
		scope.$watch('$navSearch.model', function () {
			if (scope.$navSearch.model.length > 0) {
				scope.$navSearch.showClearButton = true;
				element.find('.nav-blur_focus').hide();
			} else {
				scope.$navSearch.showClearButton = false;
				element.find('.nav-blur_focus').show();
			}
		});
		element.find('input').focus().on('keyup', function (e) {
			if (e.keyCode === 13 && scope.$navSearch.model === '') {
				return false;
			}
			if (e.keyCode === 27) {
				if(scope.$navSearch.model === '') {
					$(this).trigger('blur');
				}
				if (scope.$navSearch.model.length > 0) {
					scope.$navSearch.model = '';
					scope.$navSearch.showClearButton = false;
				}
			}
			scope.$digest();
		});
	}

	return {
		require: '^^navSearch',
		templateUrl: 'widgets.nav-search.nav-search-bar',
		restrict: 'A',
		link: link
	};
});
