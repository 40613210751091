NgProject.filter('articleAuthorFilter', ["$state", function ($state) {
	'use strict';
	function getContentAuthorObgect(type, data) {
		var model = {type: type};
		switch (type) {
			case 'text':
				model.title = data;
				break;
			case 'user':
				model.title = data.nickname;
				model.avatar = data.avatar;
				model.href = $state.href('root.user.main', {userId: data.id});
				break;
			case 'group':
				model.title = data.name;
				model.avatar = data.avatar;
				model.href = $state.href('root.group.main', {groupId: data.id});
				break;
		}
		return model;
	}
	
	return function (article) {
		if (article.article_author) {
			article.author = article.article_author;
			delete article.article_author;
		}
		
		article.content_author = getContentAuthorObgect(article.maker.type, article.maker.data);
		if (!!_.get(article.author, 'data', false)) {
			article.content_author = getContentAuthorObgect(article.author.type, article.author.data);
		}
	};
}]);
