'use strict';
NgProject.directive('ngMessageErrors', function () {
	return {
		restrict: 'A',
		require: 'ngModel',
		scope: {
			errors: '=ngMessageErrors'
		},
		link: function (scope, element, attrs, ngModel) {
			// scope.$watch('errors', function(data) {
			// 	console.log('ngMessageErrors', data);
			// 	ngModel.$setValidity('customerrors', _.isEmpty(data));
			// });
			//
			// ngModel.$viewChangeListeners.push(function() {
			// 	scope.errors = undefined;
			// 	ngModel.$setValidity('customerrors', true);
			// });
		}
	};
});
