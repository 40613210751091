NgProject.directive('upstairs', ["$window", function ($window) {
	'use strict';

	var template = [
		'<span class="upstairs-button">',
		'<i class="icon icon-chevron-with-circle-up"></i>',
		'<md-tooltip md-direction="top">Наверх</md-tooltip></span>'
	].join('');

	function link(scope) {
		var window = angular.element($window);
		var showScroll = 650;
		scope.upstairs = function () {
			window.scrollTop(0);
		};
		window.on('scroll', scope.$apply.bind(scope, handler));

		function handler() {
			scope.isShow = window.scrollTop() > showScroll;
		}
	}

	return {
		restrict: 'E',
		link: link,
		template: template
	};
}]);
