(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state", "$filter", "$q", "Deferred", "GroupsService", "TermsService", "FileUploader", "SETTINGS", "cfpLoadingBar", "ContactsService", "modalWindow", "UploadAvatar"];
	function Controller($scope, $state, $filter, $q, Deferred, GroupsService, TermsService, FileUploader, SETTINGS,
	                    cfpLoadingBar, ContactsService, modalWindow, UploadAvatar) {
		$scope.group = {
			name: null,
			short_description: null,
			description: null,
			country: null,
			city: null,
			directions: [],
			contacts: [],
			background_image_number: null
		};

		$scope.$directions = {
			model: null,
			items: [],
			select: function () {
				if (!this.exist(this.model)) $scope.group.directions.push(this.model);
				this.model = null;
			},
			remove: function (direction) {
				_.remove($scope.group.directions, direction);
			},
			exist: function (direction) {
				return _.findIndex($scope.group.directions, 'id', direction.id) !== -1;
			}
		};
		$scope.$contacts = {
			model: null,
			items: $filter('orderBy')(SETTINGS.contactsValues, 'label'),
			add: function (key, value) {
				$scope.group.contacts.push({
					id: $scope.group.contacts.length + 1,
					key: key,
					value: value || null
				});
				this.model = null;
			},
			remove: function (contact) {
				_.remove($scope.group.contacts, contact);
			},
			validate: function (item) {
				ContactsService.validate(!ContactsService.is[item.key](item.value, item.key), item);
			}
		};
		$scope.$image = {
			uploader: new FileUploader({alias: 'image[file]'}),
			attrs: {accept: SETTINGS.validation.image.types},
			exist: function () {
				return $scope.$group.avatar || $scope.$image.isNewAvatar;
			},
			validate: function () {
				var file = $scope.$image.uploader.queue[0]._file;
				// validate accept
				if (SETTINGS.validation.image.types.indexOf(file.type) === -1) $scope.$form.errors.add('image', SETTINGS.validation.image.errors.type);
			}
		};

		$scope.$form = {
			isReady: false,
			save: function () {
				var deferred = $q.defer();
				var responses = {group: null, image: null, contacts: null};
				var data = requestData();
				var promise = GroupsService[$scope.$group.id ? 'update' : 'create'];
				promise = $scope.$group.id ? promise($scope.$group.id, data) : promise(data);

				cfpLoadingBar.start();
				Deferred.handlePromise(promise, function (response) {
					responses.group = response;
					if (responses.image) deferred.resolve();
					if ($scope.$controls.mode === 'create') {
						if (response.success) {
							updateImage(response.group.id).then(function (response) {
								responses.image = response;
								deferred.resolve();
							});
							validateContacts().then(function (response) {
								responses.contacts = response;
								deferred.resolve();
							});
						}
						else {
							deferred.resolve();
						}
					}

				});

				if ($scope.$controls.mode === 'update') {
					updateImage($scope.$group.id).then(function (response) {
						responses.image = response;
						if (responses.group) deferred.resolve();
					});

					validateContacts().then(function (response) {
						responses.contacts = response;
						if (responses.group) deferred.resolve();
					});
				}

				deferred.promise.then(function () {
					cfpLoadingBar.complete();
					updateErrors(responses.group, responses.image, responses.contacts);
					if (responses.group.success && _.get(responses, 'image.success', true) && _.get(responses, 'contacts.success', true) && $scope.$form.errors.isEmpty()) {
						$state.go('root.group.main', {groupId: responses.group.group.id});
					}
				});
			},

			cancel: function () {
				$state.go('root.group.main', {groupId: $scope.$group.id});
				if (cfpLoadingBar.status() !== 1) cfpLoadingBar.complete();
			},
			errors: {
				data: {},
				add: function (field, message) {
					if (!$scope.$form.errors.data[field]) $scope.$form.errors.data[field] = [];
					$scope.$form.errors.data[field].push(message);
				},
				list: function (field) {
					return $scope.$form.errors.data[field];
				},
				remove: function (field) {
					delete $scope.$form.errors.data[field];
				},
				has: function (field) {
					return !_.isEmpty($scope.$form.errors.data[field]);
				},
				isEmpty: function () {
					return _.isEmpty($scope.$form.errors.data);
				},
				reset: function () {
					$scope.$form.errors.data = {};
				},
				extend: function (errors) {
					angular.extend($scope.$form.errors.data, errors);
				}
			},
			changeAvatar: function () {
				modalWindow.open('change-avatar');
			}
		};

		function updateErrors(group, image, contacts) {
			$scope.$form.errors.reset();
			if (group && !_.get(group, 'success', false)) $scope.$form.errors.extend(_.get(group, 'errors', {}));
			if (image && !_.get(image, 'success', false)) $scope.$form.errors.add('image', image.errors.avatar);
			if (contacts && !_.get(contacts, 'success', false)) $scope.$form.errors.add('contacts', contacts.errors.contacts);
		}

		function loadFormData() {
			Deferred.handleAllPromises([TermsService.directions()], function (directions) {
				if (directions.success)
					$scope.$directions.items = $filter('orderBy')(directions.items, 'title');
				$scope.$form.isReady = _.every([directions.success]);
			});
		}

		function setModelData(group) {
			angular.extend($scope.group, {
				name: group.name,
				short_description: group.short_description,
				description: group.description,
				country: _.get(group, 'country', null),
				city: _.get(group, 'city', null),
				background_image_number: _.get(group, 'background_image_number', null),
				directions: _.isEmpty(group.courses) ? [] : _(group.courses).pluck('direction').uniq('id').value()
			});

			_.forEach(group.contacts, function (contact) {
				$scope.$contacts.add(contact.key, contact.value);
			});
		}

		function requestData() {
			var data = angular.copy($scope.group);
			// directions
			if (data.directions.length)
				data.directions = _.pluck(data.directions, 'id');
			else if ($scope.$controls.mode === 'update')
				data.directions = [false];
			else delete data.directions;

			data.contacts = []; // for validation contacts
			if ($scope.group.contacts.length) {
				angular.forEach($scope.group.contacts, function (contact) {
					$scope.$contacts.validate(contact);
					if (contact.value && contact.value.length && !contact.error)
						data.contacts.push({key: contact.key, value: contact.value});
				});
			}

			if (!data.contacts.length) {
				if ($scope.$group.contacts && $scope.$group.contacts.length) data.contacts = [false];
				else delete data.contacts;
			}

			//location
			data.country_id = _.get(data, 'country.id', null);
			data.city_id = _.get(data, 'city.id', null);
			delete data.country;
			delete data.city;

			// trim empty values
			if ($scope.$controls.mode === 'create') {
				_.forIn(data, function (value, key) {
					if (_.isEmpty(data[key])) delete data[key];
				});
			}

			return data;
		}

		function initFileUploader() {
			var uploader = $scope.$image.uploader;
			uploader.onAfterAddingAll = function () {
				$scope.$form.errors.remove('image');
				while (uploader.queue.length > 1) {
					_.first(uploader.queue).remove();
				}
				$scope.$image.validate();
				$scope.$image.isNewAvatar = true;
			};
		}

		function updateImage(groupId) {
			var deferred = $q.defer();
			var uploader = $scope.$image.uploader;
			if ($scope.$image.isNewAvatar) {
				if (_.has($scope.$image.uploader.queue[0], '_file')) {
					uploader.url = GroupsService.getUploadImageUrl(groupId);
					_.setProperty(uploader.queue, 'url', uploader.url);
					uploader.onSuccessItem = function (fileItem, response) {
						deferred.resolve(response);
					};
					uploader.onErrorItem = function (fileItem, response) {
						response = _.trim(response);
						if (!response) response = {
							success: false,
							errors: {image: [SETTINGS.validation.image.errors.undefined]}
						};
						deferred.resolve(response);
					};
					uploader.uploadItem(0);
				} else {
					GroupsService.changeAvatar(groupId, UploadAvatar.data.id).then(function (response) {
						deferred.resolve(response);
					});
				}
			} else {
				deferred.resolve({success: true});
			}

			return deferred.promise;
		}

		function validateContacts() {
			var deferred = $q.defer(), response = {success: true};
			if ($scope.group.contacts.length) {
				_.each($scope.group.contacts, function (item) {
					$scope.$contacts.validate(item);
					if (item.error) response = {
						success: false,
						errors: {contacts: [SETTINGS['contactsValidate'].error]}
					};
				});
			}
			deferred.resolve(response);
			return deferred.promise;
		}

		function attachWatchers() {
			var updateDataAvatar = function () {
				$scope.$image.isNewAvatar = true;
				$scope.$image.uploader.queue[0] = UploadAvatar.data;
			};

			UploadAvatar.reset();
			UploadAvatar.registerCallback(updateDataAvatar);

			$scope.$watch('group', function (data, oldData) {
				_.forIn(data, function (value, field) {
					if ($scope.$form.errors.has(field) && value !== oldData[field]) $scope.$form.errors.remove(field);
				});
			}, true);
		}

		function attachEvents() {
			$scope.$on('headerBg:selected', function (ev, headerBgIndex) {
				$scope.group.background_image_number = headerBgIndex;
			});
		}

		loadFormData();
		if ($scope.$controls.mode === 'update') {
			setModelData(angular.copy($scope.$group));
		}
		initFileUploader();
		attachWatchers();
		attachEvents();
	}

	app.controller('GroupSettingsProfileCtrl', Controller);
})(angular, NgProject);
