NgProject.directive('lightboxImage', ["$parse", "$document", "modalWindow", "Image", function ($parse, $document, modalWindow, Image) {
	'use strict';
	function link(scope, element, attrs) {
		function init() {
			var lightbox = {
				count: 0,
				index: 0,
				data: $parse(attrs['lightboxImage'])(scope),
				items: attrs.hasOwnProperty('images') ? $parse(attrs['images'])(scope) : [],
				is_slider: false,
				slider_is_on: false
			};

			if (_.isPlainObject(lightbox.data)) lightbox.data = Image.create(lightbox.data);

			if (!_.isEmpty(lightbox.items)) {
				lightbox.index = _.findIndex(lightbox.items, {id: lightbox.data.id});
				lightbox.count = lightbox.items.length;
				lightbox.is_slider = lightbox.index >= 0 && lightbox.count > 1;
				lightbox.slider_is_on = lightbox.is_slider;
			}

			if (lightbox.is_slider) {
				lightbox.next = function () {
					if (!lightbox.slider_is_on) return;
					lightbox.index = ((lightbox.index + 1) < lightbox.count) ? lightbox.index + 1 : 0;
					lightbox.data = lightbox.items[lightbox.index];
				};

				lightbox.prev = function () {
					if (!lightbox.slider_is_on) return;
					lightbox.index = (lightbox.index === 0) ? lightbox.count - 1 : lightbox.index - 1;
					lightbox.data = lightbox.items[lightbox.index];
				};

				lightbox.reset = function () {
					$document.off('keydown.lightbox');
				};

				$document.on('keydown.lightbox', function(event) {
					var keys = {37: 'prev', 39: 'next'};
					if (lightbox.slider_is_on && keys.hasOwnProperty(event.keyCode)) {
						scope.$apply(lightbox[keys[event.keyCode]]);
						event.stopPropagation();
					}
				});
			}

			modalWindow.open('image', lightbox);
		}

		element.on('click', init);
	}

	return {
		restrict: 'A',
		link: link
	};
}]);
