NgProject.directive('audiosList', ["$parse", function ($parse) {
	'use strict';
	var defaults = {
		loadMore: true,
		template: 'widgets.audios.audios-list-item',
		teaserOptions: {}
	};

	function link(scope, element, attrs, controller) {
		var options = angular.extend({}, angular.copy(defaults), attrs.options ? $parse(attrs.options)(scope) : {});
		scope.$audiosList = {options: options};

		if (options.loadMore) {
			scope.$audiosList.load = function () {
				var page = controller.getSearchParams().page || 1;
				controller.loadByPage(page + 1);
			};
			scope.$audiosList.disabled = function () {
				var data = scope.$audios;
				return data.isLoading || data.endOfList;
			};
		}
	}

	return {
		require: '^^audios',
		templateUrl: 'widgets.audios.audios-list',
		restrict: 'E',
		link: link
	};
}]);
