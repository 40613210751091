NgProject.factory('DateHelper', function () {
	'use strict';
	var coefficients = {
		week: 604800000,
		day: 86400000,
		hour: 3600000,
		minute: 60000,
		second: 1000
	};

	return {
		getMsByView: function(view) {
			return coefficients[view];
		},
		daysBetweenDates: function (ms1, ms2) {
			var x0 = new Date(ms1);
			var x1 = new Date(ms2);
			// Set to noon - avoid DST errors
			x0.setHours(0, 0, 0, 0);
			x1.setHours(0, 0, 0, 0);
			// Round to remove daylight saving errors
			return Math.round((x1 - x0) / coefficients.day);
		},

		fromNow: function (ms) {
			return this.from(ms, new Date().getTime());
		},

		from: function (ms1, ms2) {
			return ms1 - ms2;
		},

		getUTCTime: function (date) {
			return date.getTime() - date.getTimezoneOffset() * coefficients.minute;
		}
	};
});
