NgProject.directive('inputRoomsMatrix', ["$parse", "Deferred", "EventsRoomsService", "_", function ($parse, Deferred, EventsRoomsService, _) {
	'use strict';
	var defaults = {
		owner: {
			type: null,
			id: null
		}
	};
	function link(scope, element, attrs) {
		var options = {};
		var roomsTriggers = {
			count: 0,
			savedCount: 0,
			failedCount: 0,
			isAllSavedSuccess: function () {
				return this.savedCount === this.count;
			},
			isFinished: function () {
				return this.savedCount + this.failedCount === this.count;
			}
		};

		function init() {
			// init options
			angular.extend(options, defaults, attrs.options ? $parse(attrs.options)(scope) : {});

			scope.$rooms = [];
			loadRemoteData();
		}

		function loadRemoteData() {
			var section = options.owner.type === 'user' ? 'users' : 'groups';
			Deferred.handlePromise(EventsRoomsService.list(section, options.owner.id), function (response) {
				if (response.success) applyRoomsData(response.items);
			});
		}

		function applyRoomsData(rooms) {
			_.map(rooms, function(room) {
				room.isNew = false;
				room.errors = [];
				delete room.owner;
			});
			scope.$rooms = rooms;
		}

		function saveAllRooms() {
			roomsTriggers.count = scope.$rooms.length;
			roomsTriggers.savedCount = 0;
			roomsTriggers.failedCount = 0;
			_.forEach(scope.$rooms, function (room) {
				var data = getRoomSubmitData(room);
				var promise = room.isNew ? EventsRoomsService.create(data) : EventsRoomsService.update(room.id, data);
				room.errors = [];
				Deferred.handlePromise(promise, function (response) {
					if (response.success)
						roomsTriggers.savedCount++;
					else {
						roomsTriggers.failedCount++;
						room.errors = response.errors;
					}
					processCompleteSave();
				});
			});
		}

		function processCompleteSave() {
			if (roomsTriggers.isFinished())
				scope.$emit('onInputRoomsMatrixSaveFinished', roomsTriggers.isAllSavedSuccess());
		}

		function getRoomSubmitData(room) {
			return ({
				name: $.trim(room.name),
				url: $.trim(room.url),
				owner: {
					id: options.owner.id,
					type: options.owner.type
				}
			});
		}

		function getIndexOfRoom(room) {
			return _.indexOfWithProperty(scope.$rooms, 'id', room.id);
		}

		function spliceRoom(room) {
			var index = getIndexOfRoom(room);
			if (index !== -1)
				scope.$rooms.splice(index, 1);
		}

		function removeRoom(room) {
			if (room.isNew) spliceRoom(room);
			else {
				scope.$emit('onInputRoomsMatrixBeforeDelete');
				Deferred.handlePromise(EventsRoomsService.remove(room.id), function () {
					spliceRoom(room);
					scope.$emit('onInputRoomsMatrixAfterDelete');
				});
			}
		}

		scope.controls = {
			addRoom: function() {
				var room = {
					id: new Date().getTime(),
					name: '',
					url: '',
					isNew: true,
					errors: []
				};
				scope.$rooms.push(room);
			},

			removeRoom: removeRoom
		};

		scope.$on('inputRoomsMatrixSave', saveAllRooms);

		init();
	}

	return {
		scope: true,
		templateUrl: 'widgets.input-rooms-matrix.input-rooms-matrix',
		restrict: 'E',
		link: link
	};
}]);
