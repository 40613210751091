NgProject.controller('GroupSettingsEventsRoomsCtrl', ["$scope", "Deferred", "cfpLoadingBar", function ($scope, Deferred, cfpLoadingBar) {
	'use strict';
	$scope.roomsMatrix = {
		options: {
			owner: {type: 'group', id: $scope.$group.id}
		}
	};

	$scope.controls = {
		hasErrors: false,
		submit: function () {
			cfpLoadingBar.start();
			$scope.$broadcast('inputRoomsMatrixSave');
		}
	};

	$scope.$on('onInputRoomsMatrixBeforeDelete', function () {
		cfpLoadingBar.start();
	});
	$scope.$on('onInputRoomsMatrixAfterDelete', function () {
		cfpLoadingBar.complete();
	});
	$scope.$on('onInputRoomsMatrixSaveFinished', function (e, isSuccess) {
		cfpLoadingBar.complete();
		$scope.controls.hasErrors = !isSuccess;
	});
}]);
