(function (ng, app) {
	'use strict';
	app.filter('locationName', function () {
		return function (user) {
			if (user.country) {
				return user.city ? user.country.name + ', ' + user.city.name : user.country.name;
			}
			else if (user.city) {
				return user.city.name;
			}
			return null;
		};
	});
})(angular, NgProject);
