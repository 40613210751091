NgProject.controller('ModalEventMembersCtrl', ["$scope", "EventsService", function ($scope, EventsService) {
	'use strict';

	$scope.membersList = {
		user: {
			membersControls: true
		},
		options: {
			showBadge: true,
			template: 'widgets.users.users-list-item-members',
			getBadgeType: function ($user) {
				if ($user.id === $scope.event.org.id)
					return 'admin';
				return null;
			}
		},
		promise: function (params) {
			return EventsService.members($scope.event.id, params);
		}
	};
}]);
