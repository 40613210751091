NgProject.directive('chatScroll', ["$timeout", "$window", function ($timeout, $window) {
	'use strict';
	return {
		restrict: 'A',
		link: link
	};

	function link(scope, element, attrs) {
		scope.element = element;
		var raw = element[0], window = $($window), sh = null;
		window.on('scroll', function (e) {
			if (scope.$messages.endOfList || scope.$messages.isLoading || window.scrollTop() === 0) return;
			if (window.scrollTop() <= 100) {
				e.stopPropagation();
				sh = raw.scrollHeight;
				scope.$apply(attrs['chatScroll']).then(function () {
					$timeout(function () {
						window.scrollTop(raw.scrollHeight + window[0].scrollY - sh);
					}, 10);
				});
			}
		});

		scope.$on('$destroy', function () {
			window.off('scroll');
		});
	}
}]);


NgProject.directive('chatResize', ["$window", function ($window) {
	'use strict';
	var h1 = angular.element('.navigation'),
		h2 = angular.element('.audio-player-container'),
		headerH = h2.length ? h1.outerHeight() + h2.outerHeight() : h1.outerHeight();

	return {
		restrict: 'A',
		link: link
	};

	function link(scope, element) {
		var onResize = function () {
			return {
				height: $window.innerHeight,
				width: $window.innerWidth
			};
		};

		function chatResize() {
			element[0].style.height = onResize().height - headerH + 'px';
		}

		chatResize();
		angular.element($window).bind('resize', chatResize);
		scope.$watch('chatHeight', function (newHeight) {
			element[0].style.paddingBottom = newHeight + 'px';
			angular.element($window).scrollTop(element.height());
		});
	}
}]);


NgProject.directive('chatKeydown', function () {
	'use strict';
	return {
		restrict: 'A',
		link: link
	};

	function link(scope, element, attrs) {
		element.on('keydown', function (e) {
			if (e.keyCode === 13 && e.ctrlKey) {
				var content = this.value,
					t = $(this),
					tr = t.textrange();
				this.value = content.substring(0, tr.start) + '\n' + content.substring(tr.end, content.length);
				t.textrange('setcursor', tr.position + 1);
			} else if (e.keyCode === 13) {
				scope.$apply(function () {
					scope.$eval(attrs['chatKeydown']);
				});
				e.preventDefault();
			}
		});
	}
});

NgProject.directive('chatHeight', function () {
	'use strict';
	return {
		link: function (scope, element) {
			scope.$watch(function () {
				scope.chatHeight = element.outerHeight();
			});
		},
		restrict: 'A'
	};
});
