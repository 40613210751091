'use strict';
(function ($) {
	var plugin = 'redactor_links';

	$.Redactor.prototype[plugin] = function () {
		return {
			init: function () {
				this[plugin].createModal();
			},

			createModal: function () {
				this.opts.modal['link'] = String()
					+ '<div class="redactor-modal-tab" data-title="General">'
					+ '<section>'
					+ '<label>URL</label>'
					+ '<input type="url" id="redactor-link-url" aria-label="URL" />'
					+ '</section>'
					+ '<section>'
					+ '<label>' + this.lang.get('text') + '</label>'
					+ '<input type="text" id="redactor-link-url-text" aria-label="' + this.lang.get('text') + '" />'
					+ '</section>'
					+ '<section>'
					+ '<button id="redactor-modal-button-action">' + this.lang.get('insert') + '</button>'
					+ '<button id="redactor-modal-button-cancel">' + this.lang.get('cancel') + '</button>'
					+ '</section>'
					+ '</div>'
			}
		};
	};
})(jQuery);
