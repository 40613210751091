NgProject.service('ContactsService', ["SETTINGS", function (SETTINGS) {
	'use strict';

	var regex = {
		// /(^https?:\/\/[\w]+)|(^https?:\/\/)?(www\.)?([\w-]+\.[\w]+\/?)(\S*)/i
		website: /(^https?:\/\/)?([\w-]+\.)?([\w-]+\.[\w]+\/?)(\S*)/i,
		phone: /\(?([0-9]{3})\)?[\-. ]?([0-9]{3})[\-. ]?([0-9]{2}[\-. ]?[0-9]{2})$/,
		skype: /[a-z0-9\.\-_]*/gi,
		email: /[\w\-.]+@[\w\-.]+\.[\w]+/i,
		trash: /([#$%^&()!;:'\\\/\|{}\s]+)/i
	};

	return {
		is: {
			website: function (item) {
				if (/\s/g.test(item)) return false;
				return regex['website'].test(item);
			},
			phone: function (item) {
				return regex['phone'].test(item);
			},
			skype: function (item) {
				return regex['skype'].test(item) && !regex['trash'].test(item);
			},
			email: function (item) {
				return regex['email'].test(item);
			},
			twitter: social,
			facebook: social,
			vk: social,
			ok: social
		},
		validate: validate
	};

	function social(item, type) {
		if (!type) return false;
		if (regex['website'].test(item)) {
			return (new RegExp(type, 'i').test(item));
		} else {
			return !regex['trash'].test(item);
		}
	}

	function validate(ext, item) {
		var value = _.get(item, 'value', '');
		if (ext || !value || value.length < 5 || value.length > 256) {
			item.error = validateMessage(item);
		} else {
			delete item.error;
		}
	}

	function validateMessage(item) {
		var errorMessages = SETTINGS['contactsValidate'];
		var result = [];
		if (_.isEmpty(item.value)) {
			result.push(errorMessages['all']['empty']);
		} else if (item.value.length < 5) {
			result.push(errorMessages['all']['little']);
		} else if (item.value.length > 256) {
			result.push(errorMessages['all']['long']);
		} else {
			if (/\s/g.test(item.value)) result.push(errorMessages['all']['space']);
			if (errorMessages[item.key]) result.push(errorMessages[item.key]);
			else if (errorMessages.social[item.key]) {
				result.push(errorMessages.social['all']);
				result.push(errorMessages.social[item.key]);
			}
		}
		return result;
	}
}]);
