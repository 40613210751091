// jshint ignore: start
'use strict';
(function () {
	var module = angular.module('project.ErrorCatcher', ['project.Constants']);

	module.provider('$rollbar', function () {
		var provider = this;

		provider.config = {
			enabled: true,
			accessToken: 'POST_CLIENT_ITEM_ACCESS_TOKEN',
			captureUncaught: false,
			payload: {
				environment: 'non set',
				client: {
					javascript: {
						source_map_enabled: true,
						code_version: 'some version',
						guess_uncaught_frames: true
					}
				}
			}
		};

		this.$get = ['$window', function ($window) {
			var _rollbarConfig = provider.config;

			// Rollbar Snippet
			!function (r) {
				function o(e) {
					if (t[e])return t[e].exports;
					var n = t[e] = {exports: {}, id: e, loaded: !1};
					return r[e].call(n.exports, n, n.exports, o), n.loaded = !0, n.exports
				}

				var t = {};
				return o.m = r, o.c = t, o.p = "", o(0)
			}([function (r, o, t) {
				"use strict";
				var e = t(1).Rollbar, n = t(2);
				_rollbarConfig.rollbarJsUrl = _rollbarConfig.rollbarJsUrl || "https://d37gvrvc0wt4s1.cloudfront.net/js/v1.8/rollbar.min.js";
				var a = e.init(window, _rollbarConfig), i = n(a, _rollbarConfig);
				a.loadFull(window, document, !_rollbarConfig.async, _rollbarConfig, i)
			}, function (r, o) {
				"use strict";
				function t(r) {
					return function () {
						try {
							return r.apply(this, arguments)
						} catch (o) {
							try {
								console.error("[Rollbar]: Internal error", o)
							} catch (t) {
							}
						}
					}
				}

				function e(r, o, t) {
					window._rollbarWrappedError && (t[4] || (t[4] = window._rollbarWrappedError), t[5] || (t[5] = window._rollbarWrappedError._rollbarContext), window._rollbarWrappedError = null), r.uncaughtError.apply(r, t), o && o.apply(window, t)
				}

				function n(r) {
					var o = function () {
						var o = Array.prototype.slice.call(arguments, 0);
						e(r, r._rollbarOldOnError, o)
					};
					return o.belongsToShim = !0, o
				}

				function a(r) {
					this.shimId = ++s, this.notifier = null, this.parentShim = r, this._rollbarOldOnError = null
				}

				function i(r) {
					var o = a;
					return t(function () {
						if (this.notifier)return this.notifier[r].apply(this.notifier, arguments);
						var t = this, e = "scope" === r;
						e && (t = new o(this));
						var n = Array.prototype.slice.call(arguments, 0), a = {shim: t, method: r, args: n, ts: new Date};
						return window._rollbarShimQueue.push(a), e ? t : void 0
					})
				}

				function l(r, o) {
					if (o.hasOwnProperty && o.hasOwnProperty("addEventListener")) {
						var t = o.addEventListener;
						o.addEventListener = function (o, e, n) {
							t.call(this, o, r.wrap(e), n)
						};
						var e = o.removeEventListener;
						o.removeEventListener = function (r, o, t) {
							e.call(this, r, o && o._wrapped ? o._wrapped : o, t)
						}
					}
				}

				var s = 0;
				a.init = function (r, o) {
					var e = o.globalAlias || "Rollbar";
					if ("object" == typeof r[e])return r[e];
					r._rollbarShimQueue = [], r._rollbarWrappedError = null, o = o || {};
					var i = new a;
					return t(function () {
						if (i.configure(o), o.captureUncaught) {
							i._rollbarOldOnError = r.onerror, r.onerror = n(i);
							var t, a, s = "EventTarget,Window,Node,ApplicationCache,AudioTrackList,ChannelMergerNode,CryptoOperation,EventSource,FileReader,HTMLUnknownElement,IDBDatabase,IDBRequest,IDBTransaction,KeyOperation,MediaController,MessagePort,ModalWindow,Notification,SVGElementInstance,Screen,TextTrack,TextTrackCue,TextTrackList,WebSocket,WebSocketWorker,Worker,XMLHttpRequest,XMLHttpRequestEventTarget,XMLHttpRequestUpload".split(",");
							for (t = 0; t < s.length; ++t)a = s[t], r[a] && r[a].prototype && l(i, r[a].prototype)
						}
						return r[e] = i, i
					})()
				}, a.prototype.loadFull = function (r, o, e, n, a) {
					var i = function () {
						var o;
						if (void 0 === r._rollbarPayloadQueue) {
							var t, e, n, i;
							for (o = new Error("rollbar.js did not load"); t = r._rollbarShimQueue.shift();)for (n = t.args, i = 0; i < n.length; ++i)if (e = n[i], "function" == typeof e) {
								e(o);
								break
							}
						}
						"function" == typeof a && a(o)
					}, l = !1, s = o.createElement("script"), u = o.getElementsByTagName("script")[0], p = u.parentNode;
					s.crossOrigin = "", s.src = n.rollbarJsUrl, s.async = !e, s.onload = s.onreadystatechange = t(function () {
						if (!(l || this.readyState && "loaded" !== this.readyState && "complete" !== this.readyState)) {
							s.onload = s.onreadystatechange = null;
							try {
								p.removeChild(s)
							} catch (r) {
							}
							l = !0, i()
						}
					}), p.insertBefore(s, u)
				}, a.prototype.wrap = function (r, o) {
					try {
						var t;
						if (t = "function" == typeof o ? o : function () {
								return o || {}
							}, "function" != typeof r)return r;
						if (r._isWrap)return r;
						if (!r._wrapped) {
							r._wrapped = function () {
								try {
									return r.apply(this, arguments)
								} catch (o) {
									throw o._rollbarContext = t() || {}, o._rollbarContext._wrappedSource = r.toString(), window._rollbarWrappedError = o, o
								}
							}, r._wrapped._isWrap = !0;
							for (var e in r)r.hasOwnProperty(e) && (r._wrapped[e] = r[e])
						}
						return r._wrapped
					} catch (n) {
						return r
					}
				};
				for (var u = "log,debug,info,warn,warning,error,critical,global,configure,scope,uncaughtError".split(","), p = 0; p < u.length; ++p)a.prototype[u[p]] = i(u[p]);
				r.exports = {Rollbar: a, _rollbarWindowOnError: e}
			}, function (r, o) {
				"use strict";
				r.exports = function (r, o) {
					return function (t) {
						if (!t && !window._rollbarInitialized) {
							var e = window.RollbarNotifier, n = o || {}, a = n.globalAlias || "Rollbar", i = window.Rollbar.init(n, r);
							i._processShimQueue(window._rollbarShimQueue || []), window[a] = i, window._rollbarInitialized = !0, e.processPayloads()
						}
					}
				}
			}]);
			// End Rollbar Snippet

			// Rollbar jQuery Snippet
			!function (r) {
				function t(o) {
					if (e[o])return e[o].exports;
					var n = e[o] = {exports: {}, id: o, loaded: !1};
					return r[o].call(n.exports, n, n.exports, t), n.loaded = !0, n.exports
				}

				var e = {};
				return t.m = r, t.c = e, t.p = "", t(0)
			}([function (r, t, e) {
				"use strict";
				!function (r, t, e) {
					var o = t.Rollbar;
					if (o) {
						var n = "0.0.8";
						o.configure({notifier: {plugins: {jquery: {version: n}}}});
						var a = function (r) {
							if (o.error(r), t.console) {
								var e = "[reported to Rollbar]";
								o.options && !o.options.enabled && (e = "[Rollbar not enabled]"), t.console.log(r.message + " " + e)
							}
						};
						r(e).ajaxError(function (r, t, e, n) {
							var a = t.status, i = e.url, l = e.type;
							if (a) {
								var u = {
									status: a,
									url: i,
									type: l,
									isAjax: !0,
									data: e.data,
									jqXHR_responseText: t.responseText,
									jqXHR_statusText: t.statusText
								}, s = n ? n : "jQuery ajax error for " + l;
								o.warning(s, u)
							}
						});
						var i = r.fn.ready;
						r.fn.ready = function (r) {
							return i.call(this, function (t) {
								try {
									r(t)
								} catch (e) {
									a(e)
								}
							})
						};
						var l = r.event.add;
						r.event.add = function (t, e, o, n, i) {
							var u, s = function (r) {
								return function () {
									try {
										return r.apply(this, arguments)
									} catch (t) {
										a(t)
									}
								}
							};
							return o.handler ? (u = o.handler, o.handler = s(o.handler)) : (u = o, o = s(o)), u.guid ? o.guid = u.guid : o.guid = u.guid = r.guid++, l.call(this, t, e, o, n, i)
						}
					}
				}(jQuery, window, document)
			}]);
			// End Rollbar jQuery Snippet

			return $window.Rollbar;
		}];
	});


	module.factory('$exceptionHandler', ["$log", "$window", "$rollbar", "CurrentUser", function ($log, $window, $rollbar, CurrentUser) {
		return function errorCatcherHandler(exception, cause) {
			// Rollbar
			$rollbar.error(exception, {
				cause: cause,
				category: 'AngularJS $exceptionHandler',
				name: exception.name,
				message: exception.message,
				stack: exception.stack,
				info: {
					fileName: exception.fileName,
					lineNumber: exception.lineNumber,
					columnNumber: exception.columnNumber
				},
				user: CurrentUser.id ? CurrentUser.toObject() : null
			});

			// Default
			$log.error.apply($log, arguments);
		};
	}]);

	//errorHttpInterceptor
	//.factory('errorHttpInterceptor', function ($q) {
	//	return {
	//		responseError: function responseError(rejection) {
	//			//Raven.captureException(new Error('HTTP response error'), {
	//			//	extra: {
	//			//		config: rejection.config,
	//			//		status: rejection.status
	//			//	}
	//			//});
	//			return $q.reject(rejection);
	//		}
	//	};
	//})

	module.config(["$rollbarProvider", "SETTINGS", function ($rollbarProvider, SETTINGS) {
		//$httpProvider.interceptors.push('errorHttpInterceptor');

		angular.merge($rollbarProvider.config, {
			enabled: SETTINGS.environment === 'stage',
			accessToken: 'be5891ae1a9d4c759d83994789fa97de',
			captureUncaught: true,
			payload: {
				environment: _.get(SETTINGS, 'environment', $rollbarProvider.config.payload.environment),
				client: {
					javascript: {
						code_version: SETTINGS.version
					}
				}
			}
		});
	}]);
})();
