NgProject.directive('imagesList', ["$parse", function ($parse) {
	'use strict';
	var defaults = {
		loadMore: true,
		template: 'widgets.images.images-list-item',
		teaserOptions: {}
	};

	function link(scope, element, attrs, controller) {
		var options = angular.extend({}, angular.copy(defaults), attrs.options ? $parse(attrs.options)(scope) : {});
		scope.$imagesList = {options: options};
		if (options.loadMore) {
			scope.$imagesList.load = function () {
				var page = controller.getSearchParams().page || 1;
				controller.loadByPage(page + 1);
			};
			scope.$imagesList.disabled = function () {
				var data = scope.$images;
				return data.isLoading || data.endOfList;
			};
		}
	}

	return {
		require: '^^images',
		templateUrl: 'widgets.images.images-list',
		restrict: 'E',
		link: link
	};
}]);
