NgProject.directive('search', function () {
	'use strict';

	controller.$inject = ["$scope", "$stateParams", "$location"];
	function controller($scope, $stateParams, $location) {
		var that = this;
		var sections = {};

		function updateIsLoaded() {
			var loaded_counter = _.sum(sections, function (section) {
				return section.isLoaded;
			});
			$scope.$search.data.isLoaded = _.size(sections) === loaded_counter;
		}

		function resetSections() {
			_.forEach(sections, function (section, type) {
				sections[type] = {count: '', isLoaded: false};
			});
		}

		var query = '';
		if ($stateParams.hasOwnProperty('query') && _.isString($stateParams.query)) {
			query = $stateParams.query;
		}

		$scope.$search = {
			model: query,
			data: {
				count: '',
				isLoaded: false
			},
			controls: {
				clearSections: function () {
					sections = {};
				},
				onLoadHandler: function(count, type) {
					sections[type] = angular.extend(sections[type] || {}, {
						count: count,
						isLoaded: true
					});
					updateIsLoaded();
					if ($scope.$search.data.isLoaded) {
						$scope.$search.data.count = _.sum(sections, function (section) {
							return section.count;
						});
					}
				}
			}
		};

		$scope.$watch('$search.model', function (value, oldValue) {
			if (value === oldValue) return;
			$scope.$search.data.count = '';
			$scope.$search.data.isLoaded = false;
			resetSections();
			$scope.$broadcast('searchReloadEvent', that.getQueryValue());
			$location.search('query', value);
		});

		$scope.$on('$locationChangeSuccess', function () {
			if ($scope.$search.model !== $stateParams.query) {
				$scope.$search.model = $stateParams.query;
			}
		});

		this.setSection = function (name) {
			sections[name] = {count: '', isLoaded: false};
			updateIsLoaded();
		};

		this.unsetSection = function (name) {
			delete sections[name];
			updateIsLoaded();
		};

		this.getQueryValue = function () {
			return !!$scope.$search.model ? $scope.$search.model.toString() : '';
		};
	}

	return {
		restrict: 'E',
		scope: true,
		controller: controller
	};
});

