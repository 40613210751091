'use strict';
NgProject.controller('PageImagesAlbumForm', ["$scope", "$state", "$q", "Deferred", "ImagesAlbumsService", "ImagesService", "TermsService", "cfpLoadingBar", function Controller($scope, $state, $q, Deferred, ImagesAlbumsService, ImagesService, TermsService, cfpLoadingBar) {
	var owner = $state.current.data.owner;

	var imageMethods = {
		isAlbumCover: function () {
			return _.get($scope.$album, 'cover.id', null) === this.id;
		},
		setAlbumCover: function () {
			$scope.$album.updateCover(this.id);
			$scope.$album.cover = {id: this.id, file: this.file};
		},
		revertChanges: function() {
			this.description = this.old_model.description;
			this.album = this.old_model.album;
			this.show_change_album = false;
			this.tags = angular.copy(this.old_model.tags);
			this.form.$setDirty(false);
			this.form.$setPristine(true);
		},
		events: {
			onBeforeRequest: function() {
				cfpLoadingBar.start();
			},
			onAfterRequest: function() {
				cfpLoadingBar.complete();
			},
			onAfterUpdate: function(data) {
				if (data.album.id !== this.old_model.album.id) {
					this.album_changed = true;
				}
				angular.extend(this, data);
				this.old_model = angular.copy(data);
				this.show_change_album = false;
				this.form.$setDirty(false);
				this.form.$setPristine(true);
			},
			onAfterDelete: function() {
				this.is_deleted = true;
			}
		}
	};

	var albumCallbacks = {
		onBeforeRequest: function() {
			cfpLoadingBar.start();
		},
		onAfterRequest: function() {
			cfpLoadingBar.complete();
		},
		onAfterUpdate: function(data) {
			angular.extend(this, data);
			$scope.$album.form.$setDirty(false);
			$scope.$album.form.$setPristine(true);
		},
		onAfterDelete: function() {
			$state.go('^');
		},
		onAfterUpdateCover: function(data) {
			$scope.$album.cover = data.cover;
		}
	};

	$scope.$imagesWidgetOptions = {
		getPromise: function (params) {
			switch (owner) {
				case 'self':
					return ImagesService.findAllByCurrentUserInAlbum($state.params.albumId, params);
				case 'user':
					return ImagesService.findAllByUserInAlbum($state.params.userId, $state.params.albumId, params);
				case 'group':
					return ImagesService.findAllByGroupInAlbum($state.params.groupId, $state.params.albumId, params);
			}
		},
		listOptions: {
			template: 'widgets.images-albums.images-list-item-update'
		},
		onLoadCallback: function (items) {
			_.forEach(items, function (image, index) {
				angular.extend(items[index], imageMethods);
				if (!image.tags) items[index].tags = [];
				image.old_model = angular.copy(image);
			});
		}
	};

	$scope.$controls = {
		isReady: false
	};

	$scope.$data = {
		tags: [],
		albums: []
	};

	$scope.$tags = {
		search: function (query) {
			query = $.trim(query);
			$scope.$data.tags = [];
			if (query.length > 1) {
				Deferred.handlePromise(TermsService.tags(query, 10), function (response) {
					if (response.success) $scope.$data.tags = _.isEmpty(response.items) ? [{name: query}] : response.items;
				});
			}
		},

		exist: function ($model, tag) {
			var params = tag.host_id ? {id: tag.id} : {name: tag.name};
			return _.findIndex($model.tags, params) !== -1;
		}
	};

	function init() {
		var deferred = $q.defer();
		var requests = {album: false, albums: false};
		initAlbumData().then(function () {
			requests.album = true;
			deferred.notify();
		});
		initAlbumsData().then(function () {
			requests.albums = true;
			deferred.notify();
		});
		return deferred.promise.then(angular.noop, angular.noop, function () {
			if (_.every(requests)) deferred.resolve();
		});
	}

	function initAlbumData() {
		var deferred = $q.defer();
		Deferred.handlePromise(getAlbumPromise(), function (response) {
			if (response.success) {
				$scope.$album = response.album;
				angular.extend($scope.$album.events, albumCallbacks);
				deferred.resolve();
			}
		});

		return deferred.promise;
	}

	function initAlbumsData() {
		var deferred = $q.defer();
		Deferred.handlePromise(getAlbumsPromise(), function (response) {
			if (response.success) {
				$scope.$data.albums = response.items;
				deferred.resolve();
			}
		});

		return deferred.promise;
	}

	function getAlbumPromise() {
		switch (owner) {
			case 'self':
				return ImagesAlbumsService.findOneByCurrentUser($state.params.albumId);
			case 'user':
				return ImagesAlbumsService.findOneByUser($state.params.userId, $state.params.albumId);
			case 'group':
				return ImagesAlbumsService.findOneByGroup($state.params.groupId, $state.params.albumId);
		}
	}

	function getAlbumsPromise() {
		switch (owner) {
			case 'self':
				return ImagesAlbumsService.findAllByCurrentUser($state.params.albumId);
			case 'user':
				return ImagesAlbumsService.findAllByUser($state.params.userId, $state.params.albumId);
			case 'group':
				return ImagesAlbumsService.findAllByGroup($state.params.groupId, $state.params.albumId);
		}
	}

	init().then(function () {
		$scope.$controls.isReady = true;
	});
}]);
