'use strict';
(function ($) {
	var plugin = 'redactor_elements_zindex';

	$.Redactor.prototype[plugin] = function () {
		return {
			init: function () {
				// dropdown
				this.core.element().on('dropdownShow.callback.redactor', $.proxy(function () {
					this.processElementZIndex(this.dropdown.active);
				}, this));

				// term tooltip
				this.core.element().on('tooltipShow.callback.redactor', $.proxy(function (e, data) {
					this.processElementZIndex(data[0].tooltip);
				}, this));

				// redactor modal
				this.core.element().on('modalOpened.callback.redactor', $.proxy(function () {
					this.processElementZIndex(this.$modalOverlay, 10);
					this.processElementZIndex(this.$modalBox, 20);
				}, this));

				// redactor links tooltip
				var showTooltipFn = this.observe.showTooltip;
				this.observe.showTooltip = $.proxy(function(e) {
					showTooltipFn(e);
					this.processElementZIndex($('.redactor-link-tooltip'));
				}, this);

			}
		};
	};
})(jQuery);
