'use strict';
NgProject.factory('Image', ["$rootScope", "$resource", "$q", "$filter", "SETTINGS", "CurrentUser", "SharingOptions", "CommonData", function ($rootScope, $resource, $q, $filter, SETTINGS, CurrentUser, SharingOptions, CommonData) {
	var ImageModel = function (data) {
		if (data) this.setData(data);
		this.events = {
			onBeforeRequest: null,
			onAfterRequest: null,
			onAfterUpdate: null,
			onAfterDelete: null,
			onAfterUpdateCover: null,
			onAfterDeleteOverall: null
		};
	};

	var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/images/:id/:command', {id: '@id', command: '@command'}, {
		share: {method: 'POST', params: {command: 'share'}},
		add: {method: 'POST', params: {command: 'add'}},
		update: {method: 'PUT'}
	});

	var resourceProfile = $resource(SETTINGS.apiBaseUrl + 'api/v1/profile/images/own/:id', {id: '@id'});
	var resourceGroups = $resource(SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/images/:id', {
		id: '@id',
		groupId: '@groupId'
	});

	var resourceBookmarks = $resource(SETTINGS['apiBaseUrl'] + 'api/v1/profile/images/bookmarks/:id', {id: '@id'});

	function applyEvent(name, data) {
		if (_.isFunction(this.events[name])) this.events[name].call(this, data);
	}

	function getModelData() {
		var model = this;
		var data = {
			description: model.description,
			tags: model.tags.length ? getTagsValues(model.tags) : [false]
		};
		if (model.album) data.album_id = model.album.id;
		if (CurrentUser.is_admin) data.maker = {type: model.maker.type, id: model.maker.data.id};
		return data;
	}

	function getTagsValues(items) {
		return _.map(items, function (item) {
			return _.isEmpty(item.host_type) ? item.name : item.id;
		});
	}

	function deleteRequest(params) {
		var deferred = $q.defer();
		var model = this;
		var owner = null;
		if (params.overall) owner = resource;
		else if (params.groupId) owner = resourceGroups;
		else owner = resourceProfile;
		params = angular.extend({id: model.id}, params || {});
		owner.delete(params, deferred.resolve, deferred.reject);
		return deferred.promise;
	}

	ImageModel.prototype.setOwner = function (ownerType, ownerId) {
		this.owner = {
			id: ownerId,
			type: ownerType
		};
	};

	ImageModel.prototype.setData = function (data) {
		angular.extend(this, data);
		this.access = {
			'delete': this.checkAccess('delete'),
			'update': this.checkAccess('update'),
			'deleteOverall': CurrentUser.is_admin
		};
	};

	ImageModel.prototype.checkAccess = function (task) {
		switch (task) {
			case 'delete':
				return this.allow_update;
			case 'update':
				return this.allow_update;
		}
		return false;
	};

	ImageModel.prototype.delete = function () {
		var model = this;
		return deleteRequest.call(model);
	};

	ImageModel.prototype.deleteByOwner = function () {
		var model = this;
		var params = _.get(model.owner, 'type', false) ? {groupId: model.owner.id} : {};
		applyEvent.call(model, 'onBeforeRequest');
		return deleteRequest.call(model, params).then(function () {
			applyEvent.call(model, 'onAfterDelete');
			applyEvent.call(model, 'onAfterRequest');
			$rootScope.$broadcast('images-list-reload');
		});
	};

	ImageModel.prototype.deleteOverall = function () {
		var model = this;
		return deleteRequest.call(model, {overall: true}).then(function () {
			$rootScope.$broadcast('images-list-reload');
			applyEvent.call(model, 'onAfterDeleteOverall');
			applyEvent.call(model, 'onAfterRequest');
		});
	};

	ImageModel.prototype.share = function (message, owner) {
		var deferred = $q.defer();
		var model = this;
		var data = angular.extend({id: model.id}, SharingOptions.getRequestData(message, owner));
		model.shared = true;
		resource.share(data, deferred.resolve, deferred.reject);
		return deferred.promise.then(angular.noop, function (response) {
			model.shared = _.get(response, 'success', false);
		});
	};

	ImageModel.prototype.bookmark = function () {
		var model = this;
		var defer = $q.defer();
		if (_.get(model.flags, 'bookmark', false)) {
			resourceBookmarks.delete({id: model.id}, defer.resolve, defer.reject);
		} else {
			resourceBookmarks.update({image_id: model.id}, defer.resolve, defer.reject);
		}
		model.flags['bookmark'] = !model.flags['bookmark'];
		return defer.promise.then(function (response) {
			if (response.success) {
				$rootScope.$broadcast('InterfaceCounters:update');
				$rootScope.$broadcast('listBookmarks:addedImage');
			}
		});
	};

	ImageModel.prototype.update = function () {
		var deferred = $q.defer();
		var model = this;
		applyEvent.call(model, 'onBeforeRequest');
		resource.update({id: model.id, image: getModelData.call(model)}, deferred.resolve, deferred.reject);
		return deferred.promise.then(function (response) {
			if (response.success) applyEvent.call(model, 'onAfterUpdate', response.image);
			applyEvent.call(model, 'onAfterRequest');
			if (response.success) return response.image;
			else deferred.reject(response.errors);
		});
	};

	return {
		create: function (data) {
			return new ImageModel(data);
		}
	};
}]);
