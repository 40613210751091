NgProject.directive('searchSection', ["$parse", function ($parse) {
	'use strict';
	function link(scope, element, attrs, controller) {
		var section = $parse(attrs.searchSection)(scope);
		controller.setSection(section);
		scope.$watch('$search.data.isLoaded', function (value) {
			element.toggle(value);
		});
		scope.$on('$destroy', function () {
			controller.unsetSection(section);
		});
	}

	return {
		require: '^^search',
		restrict: 'A',
		link: link
	};
}]);

