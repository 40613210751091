NgProject.directive('termsFilters', ["Deferred", "TermsService", function (Deferred, TermsService) {
	'use strict';

	return {
		require: '^^terms',
		templateUrl: 'widgets.terms.terms-filters',
		restrict: 'E',
		link: link
	};

	function link(scope, e, a, controller) {
		scope.filtersDefaults = {
			char: null
		};
		scope.$filtersControls = {
			isDisabled: true,
			reset: function () {
				scope.$filters = angular.copy(scope.filtersDefaults);
				$('.b-tokens_content .l-token.active').removeClass('active');
				scope.$filtersControls.isDisabled = true;
				applyFilters();
			}
		};
		scope.$filters = angular.copy(scope.filtersDefaults);
		controller.registerOnSearchCallback(function(params) {
			if(!params.hasOwnProperty('char') || params.char === null){
				loadChars(params);
			}
		});

		loadChars();

		function loadChars(params) {
			Deferred.handlePromise(TermsService.chars(params), function (response) {
				var string = response.chars.join('');
				processTokens(string);
			});
		}

		function applyFilters () {
			controller.search({ char: scope.$filters.char });
		}

		function processTokens(string) {
			var tokensList = [
				{ title: 'А-Я', content: 'АБВГДЕЖЗИКЛМНОПРСТУФХЧЦШЩЪЫЬЭЮЯ'.split(''), open: true },
				{ title: 'A-Z', content: 'ABCDEFGHIEKLMNOPQRSTUVWXYZ'.split(''), open: false },
				{ title: '123', content: '1234567890'.split(''), open: false }
			];
			var bind = {disable: '', tags: '', tokenTags: '', tokenHead: '', tokenContent: '', tokensWrap: '', lToken: ''}, countDisable = 0;
			var tokens = {
				wrap: '<div class="b-tokens{{active}}">{{tokensTags}}</div>',
				head: '<div class="b-tokens_head"><a class="l-token head{{disable}}">{{tokenHead}}</a></div>',
				content: '<div class="b-tokens_content">{{lTokenContent}}</div>',
				lContent: '<a class="l-token{{disable}}" data-token="{{token}}"><span>{{token}}</span></a>'
			};

			angular.forEach(tokensList, function (items) {
				bind.lToken = '';
				countDisable = 0;
				angular.forEach(items.content, function (item) {
					if(string.indexOf(item) === -1) {
						bind.disable = tokens.lContent.replace(/\{\{disable\}\}/, ' disable');
						countDisable++;
					} else {
						bind.disable = tokens.lContent.replace(/\{\{disable\}\}/, '');
					}
					bind.lToken += bind.disable.replace(/\{\{token\}\}/g, item);
				});
				bind.tags = items.open ?
					tokens.wrap.replace(/\{\{active\}\}/, ' active') :
					tokens.wrap.replace(/\{\{active\}\}/, ' ');
				if(countDisable === items.content.length) {
					bind.tokenHead = tokens.head.replace(/\{\{tokenHead\}\}/, items.title).replace(/\{\{disable\}\}/, ' disable');
				} else {
					bind.tokenHead = tokens.head.replace(/\{\{tokenHead\}\}/, items.title).replace(/\{\{disable\}\}/, '');
				}
				bind.tokenContent = tokens.content.replace(/\{\{lTokenContent\}\}/, bind.lToken);
				bind.tokensWrap += bind.tags.replace(/\{\{tokensTags\}\}/, bind.tokenHead + bind.tokenContent);
			});

			$('.b-alphabet').html(bind.tokensWrap);
			$('.b-tokens_head .l-token.head').on('click', function() {
				if($(this).hasClass('disable')) return false;
				$('.b-tokens.active').removeClass('active');
				$(this).closest('.b-tokens').addClass('active');
			});

			$('.b-tokens_content .l-token').on('click', function() {
				if($(this).hasClass('active') || $(this).hasClass('disable')) return false;
				$('.b-tokens_content .l-token.active').removeClass('active');
				$(this).addClass('active');
				scope.$filtersControls.isDisabled = false;
				scope.$filters.char = $(this).attr('data-token');
				applyFilters();
			});
		}
	}
}]);
