'use strict';
NgProject.directive('clickableArea', ["$parse", "$filter", "$location", function ($parse, $filter, $location) {
	var clickableSelectors = ['a', '[ng-click]', 'button'];

	function link(scope, element, attrs) {
		element.css('cursor', 'pointer');
		element.on('click', function (event) {
			console.log('click');
			var target = angular.element(event.target);
			var clicked_other_element = false;
			var article = $parse(attrs['clickableArea'])(scope);

			clickableSelectors.some(function (selector) {
				clicked_other_element = target.is(selector) || element.find(selector).has(target).length > 0;
				return clicked_other_element;
			});

			if (clicked_other_element === false) {
				event.stopPropagation();
				event.preventDefault();
				scope.$apply(function() {
					$location.url($filter('pageUrl')(article, 'article'));
				});
				if (article.subscribe) scope.$subscribes.read(article);
			}
		});
	}

	return {
		restrict: 'A',
		link: link
	};
}]);
