NgProject.directive('albums', ["$parse", "Deferred", function ($parse, Deferred) {
	'use strict';
	var defaults = {
		showActions: true,
		events: {onReloadList: 'albums-list-reload'}
	};

	function link(scope, element, attrs) {
		var data = scope.$albums = getData();
		var loadFn = $parse(attrs.load);
		var options = angular.extend({}, angular.copy(defaults), attrs.options ? $parse(attrs.options)(scope) : {});

		scope.$albumsListOptions = options;

		function reset() {
			data = scope.$albums = getData();
		}

		function loadRemoteData() {
			data.isLoading = true;
			Deferred.handlePromise(loadFn(scope), function (response) {
				if (response.success) {
					angular.extend(data, getData(response.count, response.items), {isLoading: false});
				}
			});
		}

		function reloadList() {
			reset();
			loadRemoteData();
		}

		if (options.events.onReloadList) scope.$on(options.events.onReloadList, reloadList);

		loadRemoteData();
	}

	function getData(count, items) {
		return {
			count: count || 0,
			items: _.isArray(items) ? items : [],
			isLoading: true
		};
	}

	return {
		restrict: 'E',
		scope: true,
		link: link
	};
}]);
