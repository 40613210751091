NgProject.directive('groups', ["$parse", "Deferred", "SETTINGS", function ($parse, Deferred, SETTINGS) {
	'use strict';
	Ctrl.$inject = ["$scope", "$attrs"];
	var defaults = {
		requestParams: {
			countryId: null,
			cityId: null,
			directionId: null,
			orderType: SETTINGS.groups.filters.order[0].key
		},
		onLoadCallback: null,
		template: 'widgets.groups.groups'
	};

	function Ctrl($scope, $attrs) {
		var $groups = $scope.$groups = getData();
		var loadFn = $parse($attrs.load);
		var options = angular.extend({}, angular.copy(defaults), $attrs.options ? $parse($attrs.options)($scope) : {});
		var globalRequestId = 0;

		if ($attrs.onLoadCallback) {
			options.onLoadCallback = $parse($attrs.onLoadCallback);
		}

		function processItem(group) {
			group.private = (group.access === 'closed');
		}

		function callOnLoadCallback() {
			if (options.onLoadCallback) {
				options.onLoadCallback($scope, {
					count: $groups.count,
					items: $groups.items
				});
			}
		}

		function loadRemoteData() {
			var params = options.requestParams;
			var localRequestId = ++globalRequestId;
			$groups.isLoading = true;
			Deferred.handlePromise(loadFn($scope, {params: params}), function (response) {
				if (localRequestId !== globalRequestId) return;
				if (response.success) {
					var items = response.items;
					_.map(items, processItem);
					items = $groups.items.concat(items);
					angular.extend($groups, getData(response.count, items), {
						isLoading: false,
						endOfList: response.items.length === 0 || response.count === $groups.items.length
					});
				}
				callOnLoadCallback();
			});
		}

		this.search = function (params) {
			angular.extend(options.requestParams, params);
			delete options.requestParams.page;
			$groups = $scope.$groups = getData();
			loadRemoteData();
		};

		this.loadByPage = function (page) {
			if (page && page > 1)
				options.requestParams.page = page;
			else
				delete options.requestParams.page;
			loadRemoteData();
		};

		this.getSearchParams = function () {
			return options.requestParams;
		};

		this.getDefaultSearchParams = function() {
			return defaults.requestParams;
		};

		this.getPromiseFn = function () {
			return loadFn;
		};

		$scope.search = this.search;
	}

	function link(scope, element, attrs, controller) {
		var params = {};
		if (_.isObject(controller)) {
			var query = controller.getQueryValue();
			if (query.length) {
				params.q = query;
			}
			scope.$on('searchReloadEvent', function (event, query) {
				scope.search({q: query});
			});
		}
		scope.search(params);
	}

	function getData(count, items) {
		return {
			count: count || 0,
			items: _.isArray(items) ? items : [],
			isLoading: true,
			endOfList: false
		};
	}

	return {
		require: '?^^search',
		restrict: 'E',
		scope: true,
		controller: Ctrl,
		link: link
	};
}]);

