NgProject.directive('bSearchQueryPane', ["$timeout", function ($timeout) {
	'use strict';
	var defaults = {
		queryChangeTimeout: 300
	};

	function link(scope, element, attrs, controller) {
		element = null;
		var timeoutPromise;
		scope.$bSearch = controller.$bSearch;
		scope.$bSearchQuery = {
			model: '',
			placeholder: attrs['placeholder'] + '...',
			apply: applySearch
		};
		if (_.has(controller.$bSearch, 'query')) {
			scope.$bSearchQuery.model = controller.$bSearch.query;
		}
		scope.$watch('$bSearchQuery.model', function (value, oldValue) {
			if (value === oldValue) return;
			if (timeoutPromise) $timeout.cancel(timeoutPromise);
			timeoutPromise = $timeout(applySearch, defaults.queryChangeTimeout);
		});

		function applySearch() {
			controller.search({q: scope.$bSearchQuery.model});
		}
	}

	return {
		require: '^^bSearch',
		templateUrl: 'widgets.b-search.b-search-query-pane',
		restrict: 'E',
		link: link,
		scope: {
			countItems: '=count'
		}
	};
}]);
