// Patch for CustomEvent on old iOS, Android, IE
(function () {
	'use strict';
	try {
		new CustomEvent('IE has CustomEvent, but doesn\'t support constructor');
	} catch (e) {
		var CustomEventModel = function (type, params) {
			params = params || {bubbles: false, cancelable: false, detail: undefined};
			var event = document.createEvent('CustomEvent');
			event.initCustomEvent(type, params.bubbles, params.cancelable, params.detail);
			return event;
		};

		CustomEventModel.prototype = Object.create(window.Event.prototype);
		window.CustomEvent = CustomEventModel;
	}
})();
