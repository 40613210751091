(function (ng, app) {
	'use strict';
	app.directive('eventCounter', ["$parse", "$interval", "moment", "SETTINGS", function ($parse, $interval, moment, SETTINGS) {
		var options = {
			length: 2
		};

		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				var dates = {
					start: $parse(attrs['eventCounter'])(scope),
					finish: $parse(attrs['eventCounterEnd'])(scope)
				};

				if (!dates.finish) {
					dates.finish = dates.start + SETTINGS.events.filters.younger_older_correction * 1000;
				}

				dates.start = moment(dates.start);
				dates.finish = moment(dates.finish);

				function tick() {
					var now = moment();
					if (dates.finish.diff(now) < 0) {
						scope.$counter.status = 'past';
						scope.$counter.value = null;
						return;
					}
					if (dates.start.diff(now) < 0) {
						scope.$counter.status = 'present';
						scope.$counter.value = dates.finish.preciseDiff(now, false, options.length);
						return;
					}
					scope.$counter.status = 'future';
					scope.$counter.value = dates.start.preciseDiff(now, false, options.length);
				}

				var timer = {
					instance: null,
					start: function () {
						var count = Math.ceil(dates.finish.diff(moment()) / 1000) + 1;
						if (count > 0) timer.instance = $interval(tick, 1000, count);
					},
					cancel: function() {
						if (timer.instance) $interval.cancel(timer.instance);
					}
				};

				scope.$counter = {status: null, value: null};
				scope.$on('$destroy', timer.cancel);

				tick();
				timer.start();
			}
		};
	}]);
})(angular, NgProject);
