(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "Deferred", "EventsQuestionsService", "CurrentUser", "_"];
	function Controller($scope, Deferred, EventsQuestionsService, CurrentUser, _) {
		function broadcastContentUpdated() {
			$scope.$broadcast('modal:contentUpdated');
		}

		function processQuestion(question) {
			if ($scope.data.manageMode) {
				question.mode = 'view';
				question.newValue = question.value;
			}

			question.created *= 1000;
			if (question.maker) {
				question.maker.isCurrentUser = question.maker.id === CurrentUser.id;
			}
		}

		function applyRemoteData(questions) {
			_.map(questions, processQuestion);
			$scope.questions = questions;
			$scope.$$postDigest(broadcastContentUpdated);
		}

		function addQuestions(question) {
			processQuestion(question);
			$scope.questions.push(question);
			$scope.$$postDigest(broadcastContentUpdated);
		}

		function loadRemoteData(eventId) {
			var promises = [
				EventsQuestionsService.list(eventId)
			];

			if (CurrentUser.id) {
				promises.push(EventsQuestionsService.userUnpublishedQuestions(eventId));
			}

			Deferred.handleAllPromises(promises, function (questionsResponse, userQuestionsResponse) {
				var items = [];

				if (questionsResponse.success) {
					if (!$scope.data.manageMode) {
						questionsResponse.items = _.withProperty(questionsResponse.items, 'state', 'published');
					}
					items = questionsResponse.items;
				}

				if (!$scope.data.manageMode && userQuestionsResponse && userQuestionsResponse.success) {
					items = items.concat(userQuestionsResponse.items);
				}

				applyRemoteData(items);
			});
		}

		$scope.questions = [];
		$scope.model = {
			question: null,
			questionCreated: false
		};
		$scope.data = {
			isLoading: false,
			newQuestion: null,
			manageMode: false,
			msButtons: {
				do: {
					content: 'Опубликовать',
					class: 'btn4-green'
				},
				status: {
					content: 'Опубликован',
					class: 'btn4-white'
				},
				undo: {
					content: 'Скрыть',
					class: 'btn4-red'
				}
			}
		};
		$scope.controls = {
			switchMode: function () {
				if ($scope.event.allow_update) {
					$scope.data.manageMode = !$scope.data.manageMode;
					$scope.questions = [];
					loadRemoteData($scope.event.id);
					$scope.model.questionCreated = false;
				}
			},
			create: function () {
				Deferred.handlePromise(EventsQuestionsService.create($scope.event.id, $scope.model.question), function (response) {
					if (response.success) {
						$scope.model.question = null;
						addQuestions(response.question);
						$scope.model.questionCreated = true;
					}
				});
			},
			publish: function (question) {
				Deferred.handlePromise(EventsQuestionsService.publish($scope.event.id, question.id), function (response) {
					if (response.success) {
						question.state = response.question.state;
					}
				});
			},
			toggleEdit: function (question) {
				question.mode = (question.mode === 'edit') ? 'view' : 'edit';
			},
			update: function (question) {
				if (question.value !== question.newValue) {
					Deferred.handlePromise(EventsQuestionsService.update($scope.event.id, question.id, question.newValue), function (response) {
						if (response.success) {
							question.newValue = question.value = response.question.value;
							question.mode = 'view';
						}
					});
				}
			},
			remove: function (question) {
				Deferred.handlePromise(EventsQuestionsService.remove($scope.event.id, question.id), function () {
					var index = _.indexOfWithProperty($scope.questions, 'id', question.id);
					$scope.questions.splice(index, 1);
				});
			}
		};

		$scope.$watch('model.question', function (value, oldValue) {
			if (value && value !== oldValue && $scope.model.questionCreated) {
				$scope.model.questionCreated = false;
			}
		});

		$scope.$onParentStateLoaded('event.id', function() {
			loadRemoteData($scope.event.id);
		});
	}

	app.controller('ModalEventQuestionsCtrl', Controller);
})(angular, NgProject);
