NgProject.factory('ImagesSearchParams', function () {
	'use strict';
	return {
		process: function (params) {
			var options = {
				per_page: 40
			};

			if (_.isEmpty(params))
				return options;

			if (!_.isEmpty(params.q))
				options.q = params.q;

			if (params.page && params.page > 1)
				options.page = params.page;

			if (params.limit)
				options.per_page = params.limit;

			if (params.maker)
				options.maker = params.maker;

			if (params.orderType)
				options.order = params.orderType;

			return options;
		}
	};
});
