NgProject.controller('NewsListCtrl', ["$scope", "NewsService", "$state", function ($scope, NewsService, $state) {
	'use strict';

	$scope.options = {
		promise: NewsService.allNews
	};

	if ($state.params.page) {
		$scope.options.params = {
			page: parseInt($state.params.page)
		};
	}
}]);
