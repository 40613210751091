NgProject.controller('EventsListPrivateCtrl', ["$scope", "EventsService", function ($scope, EventsService) {
	'use strict';
	$scope.events = {
		promise: EventsService.findAllByCurrentUser,
		options: {
			requestParams: {
				younger_than: new Date().getTime()
			}
		},
		action: {show: true, sref: 'root.create-event'}
	};
}]);
