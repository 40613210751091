(function (ng, app) {
	'use strict';
	app.factory('Deferred', ["$q", "_", function ($q, _) {
		function Deferred() {
			var deferred = $q.defer();
			var promise = deferred.promise;
			var core = {
				resolve: deferred.resolve,
				reject: deferred.reject,
				then: promise.then
			};
			var isResolved = false;
			var isRejected = false;
			var updateCallbacks = [];
			var mistakeCallbacks = [];
			deferred.resolve = function () {
				if (isResolved && !isRejected) {
					for (var i = 0, length = updateCallbacks.length; i < length; i++) {
						updateCallbacks[i].apply({}, arguments);
					}
				}
				else {
					isResolved = true;
					core.resolve.apply(deferred, arguments);
				}
			};
			deferred.reject = function () {
				if (isResolved) {
					for (var i = 0, length = mistakeCallbacks.length; i < length; i++) {
						mistakeCallbacks[i].apply({}, arguments);
					}
				}
				else {
					isRejected = true;
					core.reject.apply(deferred, arguments);
				}
			};
			promise.then = function () {
				var newPromise = core.then.apply(promise, arguments);
				newPromise.update = promise.update;
				newPromise.mistake = promise.mistake;
				return ( newPromise );
			};
			promise.update = function (callback) {
				updateCallbacks.push(callback);
				return ( promise );
			};
			promise.mistake = function (callback) {
				mistakeCallbacks.push(callback);
				return ( promise );
			};
			return ( deferred );
		}

		Deferred.handleAllPromises = function (promises, successCallback, errorCallback, runOnce) {
			var handleSuccess = function (index, response) {
				if (hasError) {
					return;
				}
				results[index] = response;
				if (_.contains(results, null)) {
					return;
				}
				successCallback.apply(null, results);
			};
			var handleError = function (index, response) {
				if (hasError) {
					return;
				}
				hasError = true;
				errorCallback.call(null, response);
			};
			var results = [];
			for (var i = 0, length = promises.length; i < length; i++) {
				results.push(null);
			}
			var hasError = false;
			successCallback = ( successCallback || ng.noop );
			errorCallback = ( errorCallback || ng.noop );
			_.forEach(
				promises,
				function (promise, index) {
					Deferred.handlePromise(
						promise,
						function (response) {
							handleSuccess(index, response);
						},
						function (response) {
							handleError(index, response);
						},
						runOnce
					);
				}
			);
		};
		Deferred.handlePromise = function (promise, successCallback, errorCallback, runOnce) {
			var handleSuccess = function (response) {
				if (hasRun && runOnce) {
					return;
				}
				hasRun = true;
				successCallback(response);
			};
			var hasRun = false;
			successCallback = ( successCallback || ng.noop );
			errorCallback = ( errorCallback || ng.noop );
			promise.then(handleSuccess, errorCallback);
			promise.update(handleSuccess);
			promise.mistake(errorCallback);
			return ( promise );
		};
		return ( Deferred );
	}]);
})(angular, NgProject);
