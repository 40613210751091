NgProject.controller('PageSettingsProfileCtrl', ["$scope", "$state", "$filter", "$q", "SETTINGS", "Deferred", "UsersService", "CurrentUser", "TermsService", "TimezonesService", "FileUploader", "moment", "cfpLoadingBar", "ContactsService", "modalWindow", "UploadAvatar", function Controller($scope, $state, $filter, $q, SETTINGS, Deferred, 
                                                                    UsersService, CurrentUser, TermsService, TimezonesService, FileUploader,
                                                                    moment, cfpLoadingBar, ContactsService, modalWindow, UploadAvatar) {
	'use strict';
	var old_model = {};

	$scope.data = {
		isLoading: false,
		// base lists
		genders: SETTINGS.users.filters.gender,
		timezones: [],
		// avatar fields
		isNewAvatar: false,
		imageAccept: SETTINGS.validation.image.types,
		uploader: {},
		deleted: false,
	};

	$scope.user = {
		image: null,
		nickname: null,
		fullname: null,
		birthday: null,
		gender: null,
		country: null,
		city: null,
		timezone: null,
		bio: null,
		contacts: [],
		courses: []
	};

	var errors = {
		data: {},
		add: function (field, message) {
			if (!errors.data[field])
				errors.data[field] = [];
			errors.data[field].push(message);
		},
		list: function (field) {
			return errors.data[field];
		},
		remove: function (field) {
			delete errors.data[field];
		},
		has: function (field) {
			return errors.data[field] && errors.data[field].length;
		},
		isEmpty: function () {
			return _.isEmpty(errors.data);
		}
	};

	//******************************************************************************************

	var methods = {
		loadData: function (user_id) {
			var promises = [
				UsersService.about(user_id ? user_id : 'self'),
				TermsService.directions(),
				TermsService.roles(),
				TimezonesService.list()
			];

			Deferred.handleAllPromises(promises, function (user_response, directions_response, roles_response, timezones_response) {
				if (user_response.success) {
					methods.initUser(user_response.user);
				}
				if (directions_response.success) {
					$scope.$courses.directions = $filter('orderBy')(directions_response.items, 'title');
				}
				if (roles_response.success) {
					$scope.$courses.roles = $filter('orderBy')(roles_response.items, 'title');
				}
				if (timezones_response.success) {
					methods.initTimezones(timezones_response.timezones);
				}

				$scope.data.isLoading = _.every([user_response.success, directions_response.success, roles_response.success, timezones_response.success]);

				if (user_id && CurrentUser.is_admin) {
					$scope.adminMenu.init(user_response.user);
				}
			});

		},

		initUser: function (user) {
			if (user.birthday) {
				user.birthday = $filter('birthday')(user.birthday);
			}
			if (user.time_zone) {
				user.timezone = user.time_zone;
				delete user.time_zone;
			}
			if (user.object_contacts && user.object_contacts.length) {
				_.forEach(user.object_contacts, function (contact) {
					$scope.$contacts.add(contact.key, contact.value);
				});
				delete user.object_contacts;
			}

			$.extend($scope.user, user);
			old_model = angular.copy($scope.user);
		},
		initTimezones: function (items) {
			var timezones = [];
			_.forEach(items, function (item) {
				timezones.push({key: item[0], label: item[1]});
			});
			$scope.data.timezones = timezones;
		},

		initFileUploader: function () {
			$scope.data.uploader = new FileUploader({
				alias: 'image[file]',
				filters: [
					{
						name: 'clearErrors',
						fn: function () {
							errors.remove('image');
							return true;
						}
					},
					{
						name: 'imageFilter',
						fn: function (item) {
							var isError = SETTINGS.validation.image.types.indexOf(item.type) === -1;
							if (isError) {
								errors.add('image', SETTINGS.validation.image.errors.type);
								$scope.$digest();
							}
							return true;
						}
					}
				]
			});
		},

		initFileUploaderUrl: function () {
			var url = UsersService.getUploadImageUrl($scope.user.id);
			$scope.data.uploader.url = url;
			_.setProperty($scope.data.uploader.queue, 'url', url);
		},

		updateImage: function () {
			var deferred = $q.defer();
			if ($scope.data.isNewAvatar) {
				if (_.has($scope.data.uploader.queue[0], '_file')) {
					$scope.data.uploader.onSuccessItem = function (fileItem, response) {
						deferred.resolve(response);
					};
					$scope.data.uploader.onErrorItem = function (fileItem, response) {
						response = _.trim(response);
						if (!response) response = {
							success: false,
							errors: {image: [SETTINGS.validation.image.errors.undefined]}
						};
						deferred.resolve(response);
					};

					methods.initFileUploaderUrl();
					$scope.data.uploader.uploadItem(0);
				} else {
					UsersService.changeAvatar(_.get($state.params, 'userId', CurrentUser.id), UploadAvatar.data.id).then(function (response) {
						deferred.resolve(response);
					});
				}
			} else {
				deferred.resolve({success: true});
			}

			return deferred.promise;
		},

		validateContacts: function () {
			var deferred = $q.defer(), response = {success: true};
			if ($scope.user.contacts.length) {
				_.each($scope.user.contacts, function (item) {
					if (item.error) response = {
						success: false,
						errors: {contacts: [SETTINGS['contactsValidate'].error]}
					};
				});
			}
			deferred.resolve(response);
			return deferred.promise;
		},

		getSubmitData: function () {
			var data = {
				nickname: $scope.user.nickname,
				fullname: $scope.user.fullname,
				bio: $scope.user.bio,
				birthday: $scope.user.birthday || null,
				gender: $scope.user.gender,
				country_id: _.get($scope.user, 'country.id', null),
				city_id: _.get($scope.user, 'city.id', null),
				object_contacts: [],
				courses: [],
				deleted: $scope.user.deleted
			};

			if ($scope.user.timezone) {
				data.time_zone = $scope.user.timezone;
			}
			if (_.isDate(data.birthday) || Math.abs(data.birthday).toString().length > 9) {
				data.birthday = $filter('birthday')(data.birthday, 'stamp');
			}
			if ($scope.user.contacts.length) {
				angular.forEach($scope.user.contacts, function (contact) {
					$scope.$contacts.validate(contact);
					if (contact.value && contact.value.length && !contact.error)
						data.object_contacts.push({key: contact.key, value: contact.value});
				});
			}
			if (!data.object_contacts.length) {
				data.object_contacts = [false];
			}
			if ($scope.user.courses.length) {
				angular.forEach($scope.user.courses, function (course) {
					var item = {direction_id: course.direction.id};
					if (course.role)
						item.role_id = course.role.id;
					data.courses.push(item);
				});
			}
			if (!data.courses.length) {
				data.courses = [false];
			}

			return data;
		},

		processResponses: function (response, avatar_response, contacts_response) {
			var result = response.success;
			errors.data = response.errors || {};
			if (avatar_response && !avatar_response.success) {
				errors.remove('image');
				errors.add('image', avatar_response.errors.avatar);
				result = false;
			}
			if (contacts_response && !contacts_response.success) {
				errors.remove('contacts');
				errors.add('contacts', contacts_response.errors.contacts);
				result = false;
			}
			return result && errors.isEmpty();
		}
	};

	//******************************************************************************************

	$scope.$contacts = {
		model: null,
		items: $filter('orderBy')(SETTINGS.contactsValues, 'label'),
		add: function (key, value) {
			$scope.user.contacts.push({
				id: $scope.user.contacts.length + 1,
				key: key,
				value: value || null
			});
			this.model = null;
		},
		remove: function (contact) {
			_.remove($scope.user.contacts, contact);
		},
		validate: function (item) {
			ContactsService.validate(!ContactsService.is[item.key](item.value, item.key), item);
		}
	};

	$scope.$courses = {
		direction: null,
		role: null,
		directions: [],
		roles: [],
		add: function () {
			var item = {direction: angular.copy(this.direction), role: angular.copy(this.role)};
			if (_.findIndex($scope.user.courses, item) === -1) $scope.user.courses.push(item);
			this.direction = null;
			this.role = null;
		},
		remove: function (item) {
			_.remove($scope.user.courses, item);
		}
	};

	$scope.controls = {
		hasError: errors.has,
		getErrors: errors.list,
		errorsIsEmpty: errors.isEmpty,

		cancel: function () {
			$state.go('root.user.main', {userId: $scope.user.id});
			if (cfpLoadingBar.status() !== 1) cfpLoadingBar.complete();
		},

		submit: function () {
			var deferred = $q.defer();
			var data = {
				user: null,
				image: null,
				contacts: null
			};
			cfpLoadingBar.start();
			Deferred.handlePromise(UsersService.update($scope.user.id, methods.getSubmitData()), function (response) {
				data.user = response;
				if(data.user.user.deleted) {
					$state.go('root.users');
					return;
				}
				if (data.image) deferred.resolve();
			});

			methods.updateImage().then(function (response) {
				data.image = response;
				if (data.user) deferred.resolve();
			});

			methods.validateContacts().then(function (response) {
				data.contacts = response;
				if (data.user) deferred.resolve();
			});

			deferred.promise.then(function () {
				if (methods.processResponses(data.user, data.image, data.contacts)) {
					if (CurrentUser.id === $scope.user.id) {
						CurrentUser.create(data.user.user);
						if (data.image && data.image.image) {
							CurrentUser.avatar = data.image.image;
						}
					}
					$state.go('root.user.main', {userId: $scope.user.id});
				}
				cfpLoadingBar.complete();
			});
		},

		birthday: {
			render: function ($view, $dates) {
				var now = moment().startOf($view);
				now = now.subtract(now.utcOffset(), 'minutes').valueOf();
				_.map($dates, function (date) {
					if (date.utcDateValue > now)
						date.selectable = false;
				});
			}
		},

		hasImage: function () {
			return ($scope.user && $scope.user.avatar) || ($scope.data.uploader.queue && $scope.data.uploader.queue.length);
		},

		changeAvatar: function () {
			modalWindow.open('change-avatar', 'image[file]');
		}
	};

	//************************************ Watchers *******************************************

	$scope.$watch('user', function (data, oldData) {
		_.forEach(data, function (value, field) {
			if (value !== oldData[field] && errors.has(field)) {
				errors.remove(field);
			}
		});
	}, true);

	var updateDataAvatar = function () {
		$scope.data.isNewAvatar = true;
		$scope.data.uploader.queue[0] = UploadAvatar.data;
	};

	UploadAvatar.reset();
	UploadAvatar.registerCallback(updateDataAvatar);

	//************************************ Init ************************************************

	methods.loadData((CurrentUser.is_admin && $state.is('root.user-settings.profile')) ? $state.params.userId : null);
	methods.initFileUploader();
}]);
