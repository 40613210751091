(function (ng, app) {
	'use strict';
	Service.$inject = ["$filter"];
	function Service($filter) {
		this.create = function (data) {
			this.id = data.id;
			this.fullname = data.fullname;
			this.nickname = data.nickname;
			this.city = data.city;
			this.country = data.country;
			this.avatar = $filter('avatarFilter')(data.avatar, 'user');
			this.is_admin = data.is_admin;
			this.is_main_admin = this.is_admin && this.id === 28;
		};
		this.destroy = function () {
			this.id = undefined;
			this.avatar = undefined;
			this.fullname = undefined;
			this.nickname = undefined;
			this.city = undefined;
			this.country = undefined;
			delete this.is_admin;
			delete this.is_main_admin;
		};
		this.toObject = function () {
			var data = {};
			for (var key in this) {
				if (!this.hasOwnProperty(key) || typeof this[key] === 'function' || this[key] === undefined) continue;
				data[key] = this[key];
			}
			return data;
		};
		return this;
	}

	app.service('CurrentUser', Service);
})(angular, NgProject);
