NgProject.controller('GroupSettingsRemoveCtrl', ["$scope", "$state", "CurrentUser", "Deferred", "GroupsService", "cfpLoadingBar", function ($scope, $state, CurrentUser, Deferred, GroupsService, cfpLoadingBar) {
	'use strict';
	$scope.$form = {
		submit: function() {
			var promise = GroupsService[CurrentUser.is_admin ? 'remove' : 'markAsRemoved']($scope.$group.id);
			cfpLoadingBar.start();
			Deferred.handlePromise(promise, function () {
				cfpLoadingBar.complete();
				$state.go('root.groups.private');
			});
		}
	};
}]);
