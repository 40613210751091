NgProject.directive('newsForm', ["$parse", "Deferred", "NewsService", "cfpLoadingBar", function ($parse, Deferred, NewsService, cfpLoadingBar) {
	'use strict';
	function link(scope, element, attrs, controller) {
		var promise = attrs.hasOwnProperty('promise') ? $parse(attrs.promise)(scope) : NewsService.create;

		scope.$controls = {
			message: null,
			submit: function() {
				cfpLoadingBar.start();
				Deferred.handlePromise(promise(scope.$controls.message), function (response) {
					cfpLoadingBar.complete();
					if (response.success) {
						controller.addPost(response.blog_post || response.news);
						scope.$controls.message = '';
					}
				});
			}
		};
	}

	return {
		require: '^^news',
		templateUrl: 'widgets.news.news-form',
		restrict: 'E',
		scope: true,
		link: link
	};
}]);
