var ProjectSettings = {
	"fayeAuchTocken": "0872oihalkjng;zsoirp2u3o4iuy3tiawgldkgjyp8791",
	"youtubeApiKey": "AIzaSyDZ-svQaYYZ3vuDdGTe_1uKf6tejxu7-jw",
	"adminId": 28,
	"contactsValidate": {
		"error": "Некоторые контакты указанны неверно",
		"all": {
			"empty": "Поле не должно быть пустым.",
			"long": "Вы ввели слишком длинное значение.",
			"space": "Не должно быть пробелов.",
			"little": "Вы ввели слишком короткое значение."
		},
		"skype": "Неккоректное имя пользователя.",
		"website": "Некоректный адрес ссылки. Необходимо ввести что-то вроде <code>http://example.com/</code>.",
		"phone": "Некорректный номер телефона. Необходимо ввести что-то вроде <code>+7 (923) 123-45-67</code>.",
		"email": "Некорректный E-mail адрес. Необходимо ввести что-то вроде <code>name@email.com</code>.",
		"social": {
			"all": "Необходимо ввести либо ссылку на страницу, либо id, либо nickname.",
			"facebook": "Например <code>http://facebook.com/name</code> или <code>name</code>.",
			"twitter": "Например <code>http://twitter.com/name</code> или <code>name</code>.",
			"ok": "Например <code>http://ok.ru/name</code> или <code>name</code>.",
			"vk": "Например <code>http://vk.com/name</code> или <code>name</code>."
		}
	},
	"contactsValues": [
		{
			"key": "website",
			"label": "Веб-сайт"
		},
		{
			"key": "skype",
			"label": "Skype"
		},
		{
			"key": "email",
			"label": "Email"
		},
		{
			"key": "phone",
			"label": "Телефон",
			"type": "tel"
		},
		{
			"key": "facebook",
			"label": "Facebook"
		},
		{
			"key": "vk",
			"label": "Вконтакте"
		},
		{
			"key": "ok",
			"label": "Одноклассники"
		},
		{
			"key": "twitter",
			"label": "Twitter"
		}
	],
	"articles": {
		"filters": {
			"order": [
				{
					"key": "added",
					"label": "По дате создания"
				},
				{
					"key": "name",
					"label": "По имени"
				},
				{
					"key": "owners",
					"label": "По количеству добавлений себе"
				},
				{
					"key": "added_date_up",
					"label": "По дате добавления себе или создания у себя вверх"
				},
				{
					"key": "added_date",
					"label": "По дате добавления себе или создания у себя вниз"
				},
				{
					"key": "created_at",
					"label": "По дате создания вниз"
				},
				{
					"key": "created_at_up",
					"label": "По дате создания вверх"
				},
				{
					"key": "views",
					"label": "По популярности"
				}
			]
		}
	},
	"events": {
		"filters": {
			"younger_older_correction": 10800
		}
	},
	"groups": {
		"access": [
			{
				"key": "admin",
				"label": "Админ"
			},
			{
				"key": "member",
				"label": "Участник"
			},
			{
				"key": "moderator",
				"label": "Редактор"
			}
		],
		"filters": {
			"order": [
				{
					"key": "name",
					"label": "Названию"
				},
				{
					"key": "members_count",
					"label": "Кол-ву подписчиков"
				}
			]
		}
	},
	"audios": {
		"filters": {
			"order": [
				{
					"key": "created_at",
					"label": "По дате создания"
				},
				{
					"key": "name",
					"label": "По алфавиту"
				}
			]
		}
	},
	"images": {
		"filters": {}
	},
	"videos": {
		"filters": {
			"order": [
				{
					"key": "created_at",
					"label": "По дате создания"
				},
				{
					"key": "name",
					"label": "По алфавиту"
				}
			]
		}
	},
	"users": {
		"filters": {
			"gender": [
				{
					"key": "male",
					"label": "Мужской"
				},
				{
					"key": "female",
					"label": "Женский"
				}
			]
		}
	},
	"notifications": {
		"frequency": [
			{
				"value": 1,
				"label": "1 раз в день"
			},
			{
				"value": 2,
				"label": "1 раз в неделю"
			}
		],
		"labels": {
			"events": "Напоминания о ближайших мероприятиях",
			"messages": "Новые сообщения",
			"subscribes": "Обновления подписок"
		}
	},
	"placeholders": {
		"user": {
			"big": {
				"url": "images/icons/user/user-big.png"
			},
			"medium": {
				"url": "images/icons/user/user-medium.png"
			},
			"small": {
				"url": "images/icons/user/user-small.png"
			}
		},
		"group": {
			"large": {
				"url": "images/icons/group/group-big.png"
			},
			"medium": {
				"url": "images/icons/group/group-medium.png"
			},
			"small": {
				"url": "images/icons/group/group-small.png"
			}
		},
		"article": {
			"large": {
				"url": "images/icons/article/article-large.png"
			}
		},
		"event": {
			"middle": {
				"url": "images/icons/event/event-middle.png"
			},
			"small": {
				"url": "images/icons/event/event-small.png"
			}
		}
	},
	"wiki": {
		"defaultTermId": 240,
		"filters": {
			"order": [
				{
					"key": "added",
					"label": "По дате создания"
				}
			]
		}
	},
	"validation": {
		"image": {
			"types": "image/jpeg,image/jpg,image/png,image/gif,image/pjpeg,image/pjpg,image/x-png,image/pipeg",
			"errors": {
				"undefined": "Неизвестная ошибка, возникшая при загрузке изображения.",
				"type": "Данный формат файлов не поддерживается. изображение должно быть в формате jpg, gif или png."
			}
		}
	},
	"environment": "stage",
	"apiBaseUrl": "/",
	"assetsBaseUrl": "",
	"fayeClient": "//absolutera.ru/faye",
	"cookieDomain": ".absolutera.ru"
};