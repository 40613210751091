NgProject.filter('birthday', ["$filter", "moment", function ($filter, moment) {
	'use strict';
	return function (value, format) {
		if (_.isUndefined(value)) return null;
		if (!_.isNumber(value)) value = value.getTime();
		var length = Math.abs(value).toString().length;
		switch (format) {
			case 'stamp':
				value = length > 9 ? value / 1E3 : value;
				break;
			case 'micro':
				value = length < 12 ? value * 1E3 : value;
				break;
			default:
				value = setTime(length < 12 ? value * 1E3 : value);
				break;
		}
		return value;

		function setTime(value) {
			var date = new Date(value);
			var time = moment(date).utc();
			var timezone = date.getTimezoneOffset();
			return time + (Math.abs(timezone) * 1E4);
		}
	};
}]);
