NgProject.controller('ChangeAvatarModalCtrl', ["$scope", "ImagesService", "SETTINGS", "CurrentUser", "FileUploader", "UploadAvatar", function ($scope, ImagesService, SETTINGS, CurrentUser, FileUploader, UploadAvatar) {
	'use strict';

	$scope.dataImages = {
		currentTab: 'one'
	};

	$scope.data = {
		hasAvatar: false,
		imageAccept: SETTINGS.validation.image.types,
		uploader: {}
	};

	var errors = {
		data: {},
		add: function (field, message) {
			if (!errors.data[field])
				errors.data[field] = [];
			errors.data[field].push(message);
		},
		remove: function (field) {
			delete errors.data[field];
		}
	};

	var methods = {
		initFileUploader: function (alias) {
			var uploader = $scope.data.uploader = new FileUploader({
				//alias: 'image[file]',
				alias: alias, //'avatar[file]'
				filters: [{
					name: 'clearErrors',
					fn: function () {
						errors.remove('image');
						return true;
					}
				}, {
					name: 'imageFilter',
					fn: function (item) {
						var isError = SETTINGS.validation.image.types.indexOf(item.type) === -1;
						if (isError) {
							errors.add('image', SETTINGS.validation.image.errors.type);
							$scope.$digest();
						}
						return true;
					}
				}]
			});

			// CALLBACKS
			uploader.onAfterAddingAll = function () {
				var queueCount = uploader.queue.length;
				_.forEach(uploader.queue, function (fileItem, index) {
					if (index < queueCount - 1) {
						fileItem.remove();
					}
				});
				UploadAvatar.setData(uploader.queue[0]);
				UploadAvatar.notifyObservers();
				$scope.$close();
			};
		}
	};

	$scope.controls = {
		selected: null,
		showImages: false,
		select: function ($image) {
			$image.selected = true;
			$scope.controls.selected = $image;
		},
		changeTab: function (tab) {
			$scope.dataImages.currentTab = tab;
			$scope.controls.selected = null;
			$scope.$broadcast('images-list-reload');
		},
		activeTab: function (request) {
			return request === $scope.dataImages.currentTab;
		},
		submit: function () {
			UploadAvatar.setData($scope.controls.selected);
			UploadAvatar.notifyObservers();
			$scope.$close();
		}
	};


	function getPromise(params) {
		var promise = null;
		switch ($scope.dataImages.currentTab) {
			case 'one':
			case 'two':
				promise = ImagesService.findAllByCurrentUser(params);
				break;
			case 'three':
				promise = ImagesService.findAll(params);
				break;
		}
		return promise;
	}

	$scope.images = {
		promise: getPromise,
		options: {
			requestParams: {limit: 24}
		},
		optionsList: {
			template: 'widgets.images.image-selector-modal',
			infiniteScrollContainer: '.modal-body'
		},
		searchOptions: {
			enableFilters: false
		}
	};

	methods.initFileUploader($scope.$modalWindow.data(0) || 'avatar[file]');
}]);
