'use strict';
NgProject.filter('pageUrl', ["$state", function ($state) {
	return function (object, type) {
		switch (type) {
			case 'article':
				return $state.href('modal.article-view', {articleId: object.id, code: object.code});
		}
		return '';
	};
}]);
