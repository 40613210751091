'use strict';
NgProject.factory('ImageAlbum', ["$rootScope", "$resource", "$q", "$filter", "SETTINGS", "CurrentUser", function ($rootScope, $resource, $q, $filter, SETTINGS, CurrentUser) {
	var ImageAlbumModel = function (data) {
		if (data) {
			this.setData(data);
			this.events = {
				onBeforeRequest: null,
				onAfterRequest: null,
				onAfterUpdate: null,
				onAfterDelete: null,
				onAfterUpdateCover: null
			};
		}
	};

	var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/images/albums/:id', {id: '@id'}, {
		update: {method: 'PUT'}
	});

	function applyEvent(name, data) {
		if (_.isFunction(this.events[name])) this.events[name].call(this, data);
	}

	function getModelData() {
		var model = this;
		return {name: model.name};
	}

	ImageAlbumModel.prototype.setData = function (data) {
		angular.extend(this, data);
		this.access = {
			'delete': this.checkAccess('delete'),
			'update': this.checkAccess('update'),
			'deleteOverall': CurrentUser.is_admin
		};
	};

	ImageAlbumModel.prototype.checkAccess = function (task) {
		switch (task) {
			case 'delete':
				return this.allow_update || this.is_owner;
			case 'update':
				return this.allow_update;
		}
		return false;
	};

	ImageAlbumModel.prototype.setOwner = function(owner_type, owner_id) {
		this.owner_signature = $filter('ownerSignature')(owner_type, owner_id);
	};

	ImageAlbumModel.prototype.delete = function () {
		var deferred = $q.defer();
		var model = this;
		applyEvent.call(model, 'onBeforeRequest');
		resource.delete({id: model.id}, deferred.resolve, deferred.reject);
		return deferred.promise.then(function () {
			applyEvent.call(model, 'onAfterDelete');
			applyEvent.call(model, 'onAfterRequest');
		});
	};

	ImageAlbumModel.prototype.update = function () {
		var deferred = $q.defer();
		var model = this;
		applyEvent.call(model, 'onBeforeRequest');
		resource.update({id: model.id, album: getModelData.call(model)}, deferred.resolve, deferred.reject);
		return deferred.promise.then(function (response) {
			if (response.success) applyEvent.call(model, 'onAfterUpdate', response.album);
			applyEvent.call(model, 'onAfterRequest');
		});
	};

	ImageAlbumModel.prototype.updateCover = function (image_id) {
		var deferred = $q.defer();
		var model = this;
		applyEvent.call(model, 'onBeforeRequest');
		resource.update({id: model.id, album: {cover_id: image_id}}, deferred.resolve, deferred.reject);
		return deferred.promise.then(function (response) {
			if (response.success) applyEvent.call(model, 'onAfterUpdateCover', response.album);
			applyEvent.call(model, 'onAfterRequest');
		});
	};

	return {
		create: function (data) {
			return new ImageAlbumModel(data);
		}
	};
}]);
