NgProject.controller('GroupSettingsManagersSelectModalCtrl', ["$rootScope", "$scope", "Deferred", "GroupsService", "cfpLoadingBar", function ($rootScope, $scope, Deferred, GroupsService, cfpLoadingBar) {
	'use strict';
	var groupId = $scope.$modalWindow.data(0);

	$scope.$members = {
		promise: function (params) {
			return GroupsService.members.list(groupId, params);
		},
		mainOptions: {
			followButton: false
		},
		listOptions: {
			template: 'pages.groups.forms.global.managers.modal-select-manager-list-item'
		},

		changeRole: function (user) {
			cfpLoadingBar.start();
			Deferred.handlePromise(GroupsService.managers.add(groupId, user.id, 'admin'), function (response) {
				if (response.success) {
					user.group_role = response.user.group_role;
				}
				cfpLoadingBar.complete();
				$scope.$close();
				$rootScope.$broadcast('group-add-manager');
			});
		}
	};
}]);
