NgProject.directive('comments', ["$parse", "$filter", "Deferred", "NewsService", "ArticlesService", "CurrentUser", "cfpLoadingBar", function ($parse, $filter, Deferred, NewsService, ArticlesService, CurrentUser, cfpLoadingBar) {
	'use strict';
	controller.$inject = ["$scope", "$attrs"];
	var defaults = {
		update: false,
		countOfVisibleComments: 2
	};

	function controller($scope, $attrs) {
		var data = $parse($attrs['comments'])($scope);
		var post = _.has($attrs, 'commentsPost') ? $parse($attrs['commentsPost'])($scope) : null;
		var options = angular.extend({}, angular.copy(defaults), $attrs.options ? $parse($attrs.options)($scope) : {});
		var hasType = _.has(post, 'type');
		var service = hasType ? NewsService : ArticlesService; // has post.type in news && walls else articles

		if (!_.isArray(data)) {
			data = [];
		}

		$scope.$comments = {
			data: processComments(data),
			count: data.length,
			show: data.length > 0,
			showMoreButton: false,
			countOfVisibleComments: options.countOfVisibleComments
		};
		$scope.$comments.showMoreButton = $scope.$comments.count > $scope.$comments.countOfVisibleComments;
		$scope.$comments.controls = {
			show: function () {
				$scope.$comments.show = true;
			},
			showAllComments: function () {
				$scope.$comments.showMoreButton = !$scope.$comments.showMoreButton;
				if ($scope.$comments.showMoreButton) {
					$scope.$comments.countOfVisibleComments = options.countOfVisibleComments;
				} else {
					$scope.$comments.countOfVisibleComments = null;
				}
			},
			limit: function () {
				return $scope.$comments.countOfVisibleComments >= 0 ? $scope.$comments.countOfVisibleComments : null;
			},
			begin: function () {
				if (!$scope.$comments.countOfVisibleComments) return 0;
				else return hasType ? 0 : $scope.$comments.count - $scope.$comments.countOfVisibleComments;
			},
			remove: function (comment) {
				cfpLoadingBar.start();
				Deferred.handlePromise(service.comments.remove(post.id, comment.id, post.type)).then(function () {
					cfpLoadingBar.complete();
					_.remove($scope.$comments.data, comment);
					$scope.$comments.count--;
					if ($scope.$comments.countOfVisibleComments !== null && $scope.$comments.countOfVisibleComments > options.countOfVisibleComments) {
						$scope.$comments.countOfVisibleComments--;
					}
					updateComments();
				});
			}
		};

		this.addComment = function (message) {
			cfpLoadingBar.start();
			Deferred.handlePromise(service.comments.create(post.id, message, post.type)).then(function (response) {
				cfpLoadingBar.complete();
				updateComments();
				if (response.success) {
					if (hasType) {
						$scope.$comments.data.unshift(response.comment);
					} else {
						$scope.$comments.data.push(response.comment);
					}
					$scope.$comments.count++;
					if ($scope.$comments.countOfVisibleComments !== null) {
						$scope.$comments.countOfVisibleComments++;
					}
				}
			});
		};

		function updateComments() {
			Deferred.handlePromise(service.comments.list(post.id, post.type)).then(function (response) {
				if (response.success) {
					$scope.$comments.data = processComments(response.items);
					$scope.$comments.count = response.count;
					if (!$scope.$comments.showMoreButton && $scope.$comments.countOfVisibleComments >= $scope.$comments.count) {
						$scope.$comments.showMoreButton = true;
					}
					if ($scope.$comments.show && !$scope.$comments.count) {
						$scope.$comments.show = false;
					}
				}
			});
		}

		function processComments(data) {
			_.map(data, function (item) {
				item.allow_update = options.update || CurrentUser.is_admin || item.maker.data.id === CurrentUser.id;
			});
			data = _.sortBy(data, 'created_at');
			if (!hasType) data = data.reverse();
			return data;
		}
	}

	return {
		restrict: 'A',
		scope: true,
		controller: controller
	};
}]);

