NgProject.directive('audioPlayer', ["AudioPlayer", function (AudioPlayer) {
	'use strict';

	controller.$inject = ["$scope", "$window", "AudiosService"];
	return {
		templateUrl: 'widgets.audio-player.audio-player',
		restrict: 'E',
		scope: true,
		controller: controller,
		link: link
	};

	function controller($scope, $window, AudiosService) {
		$scope.player = AudioPlayer;
		$scope.closePlayer = function () {
			$scope.player.destroy();
		};

		$scope.$sliders = {
			position: 0,
			volume: AudioPlayer.volume(),
			volume_hide: _.includes(['Windows Phone', 'Android', 'iOS', 'BlackBerry'], $.pgwBrowser().os.group)
		};

		$scope.download = function () {
			$window.open(AudiosService.download(AudioPlayer.getAudioObject().id), '_blank');
		};
	}

	function link(scope, element) {
		var sliders = {
			position: {
				sliding: false,
				element: element.find('[md-position-slider]')
			},
			volume: {
				sliding: false,
				element: element.find('[md-volume-slider]')
			}
		};

		// Position Slider
		sliders.position.element.on('$md.pressdown $md.dragstart', function () {
			sliders.position.sliding = true;
		});
		sliders.position.element.on('$md.pressup $md.dragend', function () {
			AudioPlayer.moveToPosition(scope.$sliders.position);
			sliders.position.sliding = false;
		});

		scope.$watch('player.position_percent', function (value) {
			if (!sliders.position.sliding) scope.$sliders.position = value;
		});

		// Volume Slider
		if (!scope.$sliders.volume_hide) {
			sliders.volume.element.on('$md.pressup $md.dragend', function () {
				AudioPlayer.volume(scope.$sliders.volume);
			});

			scope.$on('AudioPlayer.volumeTrigger', function (e, volume) {
				scope.$sliders.volume = volume;
			});
		}
	}
}]);
