(function (ng, app) {
	'use strict';
	app.directive('fuThumb', ["$window", "ImagesService", function ($window, ImagesService) {
		var helper = {
			support: !!($window.FileReader && $window.CanvasRenderingContext2D),
			isFile: function (item) {
				return angular.isObject(item) && item instanceof $window.File;
			},
			isImage: function (file) {
				var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
				return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
			}
		};

		return {
			restrict: 'A',
			template: '<canvas/>',
			scope: {
				file: '=fuThumb'
			},
			link: function (scope, element, attributes) {
				if (!helper.support) return;

				var params = scope.$eval(attributes.fuThumbOptions);
				var canvas = element.find('canvas');
				var reader = new FileReader();


				function onLoadImage() {
					var width = params.width || this.width / this.height * params.height;
					var height = params.height || this.height / this.width * params.width;
					canvas.attr({width: width, height: height});
					canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
				}

				function onLoadFile(event) {
					var img = new Image();
					img.onload = onLoadImage;
					img.src = event.target.result;
				}

				function onLoadUrl(id) {
					var url = ImagesService.resizeImage(id, params.width + 'x' + params.height, 'photo');
					var img = new Image();
					img.onload = onLoadImage;
					img.src = url;
				}

				function init() {
					var file = null;
					if (!scope.file) return;

					if (_.has(scope.file, '_file')) {
						file = scope.file._file; // если получаем данные из файла
						if (!helper.isImage(file)) return;
						reader.onload = onLoadFile;
						reader.readAsDataURL(file);
					} else {
						file = scope.file; // или с сервера
						onLoadUrl(file.id);
					}
				}

				scope.$watch('file', init);
			}
		};
	}]);
})(angular, NgProject);
