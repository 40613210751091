NgProject.service('ImCounter', ["$q", "Deferred", "CountersService", "ImService", "CurrentUser", function ($q, Deferred, CountersService, ImService, CurrentUser) {
	'use strict';

	var ImCounter = this;

	var defaults = {
		im: {
			new_messages: 0
		}
	};

	ImCounter.getCounter = getCounter;
	ImCounter.readCounter = readCounter;
	ImCounter.resetCounter = resetCounter;
	ImCounter.resetChatCounter = resetChatCounter;

	init();
	return ImCounter;

	function init() {
		if (!CurrentUser.id) return false;
		angular.extend(ImCounter, angular.copy(defaults));
		getCounter();
	}

	function getCounter() {
		return Deferred.handlePromise(CountersService.load(), function (response) {
			if (response.success) setData(response['counters']);
		});
	}

	function readCounter(chatId) {
		return Deferred.handlePromise(ImService.read(chatId));
	}

	function resetCounter() {
		angular.extend(ImCounter, angular.copy(defaults));
	}

	function resetChatCounter(item) {
		return Deferred.handlePromise(ImService.count(item.id)).then(function (r) {
			if (r.success) item['new_messages_count'] = r['new_messages_count'];
		});
	}

	function setData(data) {
		if (data.hasOwnProperty('im')) {
			angular.extend(ImCounter.im, data.im);
		}
	}
}]);
