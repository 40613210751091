(function (ng, app) {
	'use strict';
	app.filter('srefByType', function () {
		return function (type, id) {
			type = !type ? 'user' : type; // тип по умолчанию, если не определён
			id = !id ? 0 : id; // id по умолчанию, если не определён
			switch (type.toLowerCase()) {
				case 'user':
					return 'root.user.main({userId: ' + id + '})';
				case 'group':
					return 'root.group.main({groupId:' + id + '})';
				case 'termtitle':
					return 'root.wiki.view.term.info({termId:' + id + '})';
			}
			return 'root.home';
		};
	});
})(angular, NgProject);
