NgProject.factory('ArticlesQuestionsSearchManageParams', function () {
	'use strict';
	return {
		process: function (params) {
			var options = {};

			if (params.page && params.page > 1)
				options.page = params.page;

			if (params.limit)
				options.per_page = params.limit;
			
			if (params.tag_status)
				options.tags_status = params.tag_status;

			return options;
		}
	};
});
