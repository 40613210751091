(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "Deferred", "ProfileSettingsService", "cfpLoadingBar"];
	function Controller($scope, Deferred, ProfileSettingsService, cfpLoadingBar) {
		$scope.controls = {
			requestSend: false,
			submit: function() {
				cfpLoadingBar.start();
				Deferred.handlePromise(ProfileSettingsService.account.remove(), function (response) {
					$scope.controls.requestSend = response.success;
					cfpLoadingBar.complete();
				});
			}
		};
	}

	app.controller('PageSettingsRemoveAccountCtrl', Controller);
})(angular, NgProject);
