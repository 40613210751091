NgProject.directive('messageNotify', ["$rootScope", "CurrentUser", "Deferred", "ImService", "AUTH_EVENTS", "notification", function ($rootScope, CurrentUser, Deferred, ImService, AUTH_EVENTS, notification) {
	'use strict';
	controller.$inject = ["$scope", "$state", "$compile"];
	var message = {
		dir1: 'up',
		dir2: 'right',
		push: 'top',
		spacing1: 15,
		spacing2: 15
	};

	var settings = {
		addclass: 'notify-message-wrap',
		animation: 'none',
		closer_hover: true,
		delay: 12000,
		hide: true,
		icon: false,
		sticker: false,
		shadow: false,
		width: '350px',
		history: {
			maxonscreen: 5
		},
		buttons: {
			closer: true,
			sticker: false
		}
	};

	notification.setDefaults(settings).setStack('message', 'stack-bottomleft', message);

	return {
		restrict: 'E',
		controller: controller
	};

	function controller($scope, $state, $compile) {
		$scope.stack = [];
		$scope.timers = null;
		$scope.$watchCollection('imFaye', function (data, oldData) {
			if (!data['id'] || _.eq(data, oldData)) return;
			if (data['message'].sender.data['id'] !== CurrentUser.id && !$state.includes('root.im')) {
				var item = _.find($rootScope.chatsListNotify, {id: data['id']}), message = null;
				if (!item) item = data;
				if (item.hasOwnProperty('left')) {
					if (item.left) return;
				}
				if (item.type === 'group') {
					data['message'].sender.data = item['recepient'];
					data['message'].sender.type = 'group';
				}
				message = notification.message(generateMessage(data), 'message');
				message.get().on('click', '.notify-message', function () {
					$state.go('root.im.chat', {chatId: data['id']});
					message.remove();
				});
			}
		});

		function generateMessage(data) {
			data = data.message;
			var newScope = $scope.$new();
			newScope.$data = data;
			var avatar = '<avatar data="$data.sender.data.avatar" type="' + data.sender.type + '" size="medium" width="45" height="45"></avatar>';
			var cAvatar = $compile(avatar)(newScope);
			var name = data.sender.type === 'user' ? data.sender.data.nickname : data.sender.data.name;
			avatar = '<img src="' + cAvatar.find('img').attr('ng-src') + '" class="image__' + data.sender.type + '" alt="" />';
			return '<div class="notify-message">\
				<div class="notify-message_content">\
					<div class="notify-message_image">' + avatar + '</div>\
					<div class="notify-message_context">\
						<h2>' + name + '</h2>\
						<div class="notify-message_text">' + data.message + '</div>\
					</div>\
				</div>\
			</div>';
		}

		function initNotifications() {
			if (!!CurrentUser.id) {
				$rootScope.chatsListNotify = null;
				Deferred.handlePromise(ImService.chats({per_page: 100, type: 'group'}), function (r) {
					if (r.success) $rootScope.chatsListNotify = r.items;
				});
			}
		}

		function resetNotifications() {
			$rootScope.chatsListNotify = null;
			$('.notify-message-wrap').remove();
		}

		$scope.$on(AUTH_EVENTS.loginSuccess, initNotifications);
		$scope.$on(AUTH_EVENTS.logoutSuccess, resetNotifications);
		$scope.$on(AUTH_EVENTS.sessionTimeout, resetNotifications);

		initNotifications();
	}
}]);
