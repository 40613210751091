NgProject.controller('WikiTermFormCtrl', ["$scope", "$state", "$q", "$filter", "CurrentUser", "Deferred", "TermsService", "cfpLoadingBar", function ($scope, $state, $q, $filter, CurrentUser, Deferred, TermsService, cfpLoadingBar) {
	'use strict';
	$scope.$term = {
		title: '',
		description: '',
		synonyms: [],
		relations: [],
		tags: []
	};
	$scope.$termOptions = {
		participant_title: false,
		participant: false,
		direction: false,
		role: false,
		content_type: false
	};
	$scope.$definition = {
		value: '',
		author: null
	};

	$scope.fData = {
		isReady: false,
		errors: {},
		resetErrors: function () {
			$scope.fData.errors = {};
		},
		hasErrors: function () {
			return !_.isEmpty($scope.fData.errors);
		}
	};

	$scope.$tags = {
		synonyms: [],
		relations: [],
		searchItems: [],
		search: function (query) {
			query = $.trim(query);
			$scope.$tags.searchItems = [];
			if (query.length <= 1) return;
			Deferred.handlePromise(TermsService.tags(query, 10), function (response) {
				if (response.success) $scope.$tags.searchItems = response.items;
			});
		},
		select: function (field) {
			var item = angular.copy($scope.$tags[field][0]);
			if (!this.exist(field, item)) $scope.$term[field].push(item);
			$scope.$tags[field] = [];
		},
		remove: function (field, item) {
			_.remove($scope.$term[field], item);
		},
		exist: function (field, item) {
			return _.findIndex($scope.$term[field], 'host_id', item.host_id) !== -1;
		}
	};

	$scope.controls = {
		save: function () {
			var deferred = $q.defer();
			var data = getSubmitData();
			$scope.fData.resetErrors();
			cfpLoadingBar.start();
			if ($scope.$term.id) {
				var promises = [
					TermsService.update_term($scope.$term.id, data.term),
					TermsService.update_options($scope.$term.id, data.options)
				];
				if ($scope.$definition.id) promises.push(TermsService.update_definition($scope.$term.id, $scope.$definition.id, data.definition));
				else promises.push(TermsService.create_definition($scope.$term.id, data.definition));

				Deferred.handleAllPromises(promises, function (term, options, definition) {
					var isSuccess = _.every([term.success, options.success, definition.success]);
					if (isSuccess) deferred.resolve($scope.$term.id);
					else deferred.reject({
						term: term.errors,
						definition: definition.errors
					});
				});
			} else {
				Deferred.handlePromise(TermsService.create_term(data), function (response) {
					if (response.success) deferred.resolve(response.term.id);
					else deferred.reject(response.errors);
				});
			}

			deferred.promise.then(savingComplete, savingFailed);
			deferred.promise.finally(cfpLoadingBar.complete);
		},
		remove: function () {
			cfpLoadingBar.start();
			Deferred.handlePromise(TermsService.remove_term($scope.$term.id), function () {
				cfpLoadingBar.complete();
				$state.go('root.wiki.view');
			});
		},
		cancel: function () {
			if ($scope.$term.id) $state.go('root.wiki.view.term', {termId: $scope.$term.id});
			else $state.go('root.wiki.view');
		}
	};

	$scope.controls.changeMaker = function () {
		$scope.$modal.open('change-maker', {
			options: {
				title: 'Автор определения',
				allowTextValue: true
			},
			data: angular.copy($scope.$definition.author)
		});
	};

	$scope.$on('maker-changed', function (e, author) {
		$scope.$definition.author = author;
	});


	function savingFailed(errors) {
		$scope.fData.errors.term = _.get(errors, 'term.title', null);
		$scope.fData.errors.definition = _.get(errors, 'definition.value', null);
	}

	function savingComplete(term_id) {
		$state.go('root.wiki.view.term', {termId: term_id});
	}

	function loadData(term_id) {
		var deferred = $q.defer();
		Deferred.handlePromise(TermsService.options(term_id), function (response) {
			if (response.success) {
				setModelData(response);
				deferred.resolve();
			}
		});
		return deferred.promise;
	}

	function initDefinitionAuthor() {
		var deferred = $q.defer();
		Deferred.handlePromise(TermsService.authors({
			host_id: CurrentUser.id,
			host_type: 'user',
			per_page: 1
		}), function (response) {
			if (response.success) {
				deferred.resolve();
				if (!_.isEmpty(response.items[0])) {
					$scope.$definition.author = {id: response.items[0].id, type: 'user', data: CurrentUser.toObject()};
				}
			}
		});
		return deferred.promise;
	}

	function processTags(array) {
		var data = [];
		_.forEach(array, function (item) {
			data.push({name: item.term.name, host_id: item.term.id});
		});
		return _.uniq(data);
	}

	function tagsObjectsToArray(objArray) {
		var tagsArray = [];
		_.forEach(objArray, function(item) {
			tagsArray.push(item.name);
		});
		return _.uniq(tagsArray);
	}

	function setModelData(data) {
		angular.extend($scope.$term, {
			id: data.id,
			title: data.title,
			description: data.description || '',
			synonyms: _.isEmpty(data.synonyms) ? [] : processTags(data.synonyms),
			relations: _.isEmpty(data.relations) ? [] : processTags(data.relations),
			tags: _.isEmpty(data.tags) ? [] : tagsObjectsToArray(data.tags)
		});
		angular.extend($scope.$termOptions, data.options);
		if (!_.isEmpty(data.definition)) {
			angular.extend($scope.$definition, {
				id: data.definition.id,
				value: data.definition.value,
				author: data.definition.author
			});
		}
	}

	function getSubmitData() {
		var data = {
			term: {
				title: _.trim($scope.$term.title),
				description: $scope.$term.description,
				synonyms: $scope.$term.synonyms.length ? _.pluck($scope.$term.synonyms, 'host_id') : [false],
				relations: $scope.$term.relations.length ? _.pluck($scope.$term.relations, 'host_id') : [false],
				tags: $scope.$term.tags
			},
			definition: {
				value: $scope.$definition.value,
				author: $scope.$definition.author.type === 'text' ? $scope.$definition.author.data : $scope.$definition.author.id
			},
			options: angular.copy($scope.$termOptions)
		};
		if ($filter('htmlContentIsEmpty')(data.term.description)) data.term.description = '';
		if ($filter('htmlContentIsEmpty')(data.definition.value)) data.definition.value = '';
		return data;
	}

	// Initialization
	var loadPromise = !$state.params.termId ? initDefinitionAuthor() : loadData($state.params.termId);
	loadPromise.then(function () {
		$scope.fData.isReady = true;
	});
}]);
