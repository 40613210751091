(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state", "CurrentUser", "Deferred", "UsersService", "cfpLoadingBar"];
	function Controller($scope, $state, CurrentUser, Deferred, UsersService, cfpLoadingBar) {
		$scope.menu = {
			show: $state.includes('root.settings')
		};

		$scope.adminMenu = {
			show: $state.includes('root.user-settings') && CurrentUser.is_admin,
			data: {id: null, is_admin: null},
			changeRole: function () {
				var data = $scope.adminMenu.data;
				cfpLoadingBar.start();
				Deferred.handlePromise(UsersService.adminize(data.id, !data.is_admin), function (response) {
					if (response.success)
						$scope.adminMenu.data.is_admin = response.user.is_admin;
					cfpLoadingBar.complete();
				});
			},
			init: function(user) {
				if ($state.includes('root.user-settings') && CurrentUser.is_admin) {
					$scope.adminMenu.data.id = user.id;
					$scope.adminMenu.data.is_admin = user.is_admin;
				}
			}
		};

		$scope.$on('$stateChangeSuccess', function (event, toState) {
			if (toState.name === 'root.settings' || toState.name === 'root.user-settings') {
				$state.go('.profile');
			}
		});
	}

	app.controller('PageSettingsCtrl', Controller);
})(angular, NgProject);
