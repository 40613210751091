(function (ng, app) {
	'use strict';
	function Factory() {
		_.countWithProperty = function (collection, name, value) {
			var count = 0;
			for (var i = 0; i < collection.length; i++) {
				if (collection[i][name] === value) {
					count++;
				}
			}
			return ( count );
		};
		_.extendExistingProperties = function (destination, source) {
			if (!( destination && source )) {
				return ( destination );
			}
			var angularKeyPattern = new RegExp('^\\$+', 'i');
			for (var key in source) {
				if (
					source.hasOwnProperty(key) &&
					destination.hasOwnProperty(key) && !angularKeyPattern.test(key) && !_.isFunction(source[key])
				) {
					destination[key] = source[key];
				}
			}
			return ( destination );
		};
		_.findWithProperty = function (collection, name, value) {
			var result = _.find(
				collection,
				function (item) {
					return ( item[name] === value );
				}
			);
			return ( result );
		};
		_.indexOfWithProperty = function (collection, name, value) {
			for (var i = 0; i < collection.length; i++) {
				if (collection[i][name] === value) {
					return ( i );
				}
			}
			return ( -1 );
		};
		_.notContainsProperty = function (collection, name, value) {
			var filteredCollection = this.filter(
				collection,
				function (item) {
					return ( !_.contains(item[name].toLowerCase(), value.toLowerCase()) );
				}
			);
			return ( filteredCollection );
		};
		_.maxProperty = function (collection, name) {
			if (!collection.length) {
				return;
			}
			return (
				_.max(
					_.pluck(collection, name)
				)
			);
		};
		_.minProperty = function (collection, name) {
			return (
				_.min(
					_.pluck(collection, name)
				)
			);
		};
		_.rejectWithProperty = function (collection, name, value) {
			var filteredCollection = _.reject(
				collection,
				function (item) {
					return ( item[name] === value );
				}
			);
			return ( filteredCollection );
		};
		_.setProperty = function (collection, name, value) {
			this.forEach(
				collection,
				function (item) {
					item[name] = value;
				}
			);
			return ( collection );
		};
		_.sortOnProperty = function (collection, name, direction) {
			direction = ( direction || 'asc' );
			var sortIndicator = ( direction === 'asc' ? -1 : 1 );
			collection.sort(
				function (a, b) {
					return ( a[name] < b[name] ? sortIndicator : -sortIndicator );
				}
			);
			return ( collection );
		};
		_.sumProperty = function (collection, name) {
			var sum = 0;
			for (var i = 0; i < collection.length; i++) {
				sum += collection[i][name];
			}
			return ( sum );
		};
		_.withoutProperty = function (collection, name, value) {
			var filteredCollection = this.filter(
				collection,
				function (item) {
					return ( item[name] !== value );
				}
			);
			return ( filteredCollection );
		};
		_.withoutPropertyRange = function (collection, name, valueRange) {
			var filteredCollection = this.filter(
				collection,
				function (item) {
					return ( _.indexOf(valueRange, item[name]) === -1 );
				}
			);
			return ( filteredCollection );
		};
		_.withProperty = function (collection, name, value) {
			var filteredCollection = this.filter(
				collection,
				function (item) {
					return ( item[name] === value );
				}
			);
			return ( filteredCollection );
		};
		_.withPropertyRange = function (collection, name, valueRange) {
			var filteredCollection = this.filter(
				collection,
				function (item) {
					return ( _.indexOf(valueRange, item[name]) !== -1 );
				}
			);
			return ( filteredCollection );
		};
		_.firstToUpperCase = function (str) {
			return str[0].toUpperCase() + str.slice(1);
		};
		_.getText = function (item) {
			return /<(?!\s)[^<>]+>/ig.test(item) ? $(item).text() : item;
		};

		_.exchange = function (array, a, b) {
			if (array[a] && array[b]) {
				var item = array[a];
				array[a] = array[b];
				array[b] = item;
			}
			return array;
		};

		return ( _ );
	}

	app.factory('_', Factory);
})(angular, NgProject);


