NgProject.directive('usersListBriefly', ["$parse", "$compile", "$templateCache", function ($parse, $compile, $templateCache) {
	'use strict';
	controller.$inject = ["$scope", "$attrs"];
	var templateId = 'widgets.users.users-list-briefly';

	function compile(tElement) {
		var template = $templateCache.get(templateId);
		var content = tElement.html();
		if (content.length) {
			content = angular.element(content);
			if (content[0].localName.toLowerCase() === 'transclude-badges') {
				template = angular.element(template);
				template.find('.user').first().append(content[0]);
			}
		}
		tElement.html(template);
	}

	function controller($scope, $attrs) {
		var limit = $attrs.limit ? $attrs.limit : 100;
		var items = $parse($attrs.items)($scope);
		$scope.$users = {
			items: items.slice(0, limit),
			count: $parse($attrs.count)($scope),
			state: $attrs.state
		};
	}

	return {
		restrict: 'E',
		scope: true,
		compile: compile,
		controller: controller
	};
}]);
