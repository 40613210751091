NgProject.controller('GroupMaterialsCtrl', ["$scope", "$state", "VideosService", "AudiosService", function ($scope, $state, VideosService, AudiosService) {
	'use strict';
	var listOptions = {
		teaserOptions: {
			buttons: {deleteByOwner: $scope.group.access.manage}
		}
	};

	$scope.videos = {
		promise: function (params) {
			return VideosService.findAllByGroup($scope.group.id, params);
		},
		listOptions: listOptions
	};

	$scope.audios = {
		promise: function (params) {
			return AudiosService.findAllByGroup($scope.group.id, params);
		},
		listOptions: listOptions
	};

	$scope.imagesControls = {
		isAlbumPage: function() {
			return $state.current.name === 'root.group.materials.images.album';
		},
		openImagesFrom: function() {
			var params = {
				maker: {type: 'group', id: $scope.group.id}
			};
			if ($scope.imagesControls.isAlbumPage()) params.album_id = $state.params.albumId;
			$scope.$modal.open('materials-images-form', params);
		}
	};

	function updateStateSection() {
		$scope.materialsSection = $state.current.data.section;
	}

	$scope.$on('$stateChangeSuccess', updateStateSection);
	updateStateSection();
}]);
