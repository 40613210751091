'use strict';
NgProject.controller('ProfileMaterialsCtrl', ["$scope", "$state", "VideosService", "AudiosService", function ($scope, $state, VideosService, AudiosService) {
	$scope.videos = {
		promise: function (params) {
			return VideosService.findAllByUser($scope.user.id, params);
		}
	};

	$scope.audios = {
		promise: function (params) {
			return AudiosService.findAllByUser($scope.user.id, params);
		}
	};

	$scope.imagesControls = {
		isAlbumPage: function() {
			return $state.current.name === 'root.user.materials.images.album';
		},
		openImagesFrom: function() {
			var params = {
				maker: {type: 'user', id: $scope.user.id}
			};
			if ($scope.imagesControls.isAlbumPage()) params.album_id = $state.params.albumId;
			$scope.$modal.open('materials-images-form', params);
		}
	};

	function updateStateSection() {
		$scope.materialsSection = $state.current.data.section;
	}

	$scope.$on('$stateChangeSuccess', updateStateSection);
	updateStateSection();
}]);
