(function (ng, app) {
	'use strict';
	app.service('ArticlesService', ["$resource", "serviceHelper", "SETTINGS", "Article", "ArticlesSearchParams", "CurrentUser", function ($resource, serviceHelper, SETTINGS, Article, ArticlesSearchParams, CurrentUser) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/articles/:articleId/:command', {
			articleId: '@articleId',
			command: '@command',
			termId: '@termId'
		}, {
			allSimilar: {params: {command: 'similar'}},
			allByCurrentUser: {url: SETTINGS.apiBaseUrl + 'api/v1/profile/articles/own'},
			allByUser: {url: SETTINGS.apiBaseUrl + 'api/v1/users/:userId/articles'},
			allByGroup: {url: SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/articles'},
			allByTerm: {url: SETTINGS.apiBaseUrl + 'api/v1/terms/:termId/articles'},
			allTransmitted: {url: SETTINGS.apiBaseUrl + 'api/v1/transmitted/articles'},
			authorsList: {url: SETTINGS.apiBaseUrl + 'api/v1/authors'},
			changeAvatar: {method: 'PUT', params: {command: 'avatar'}},
			pinByTerm: {
				url: SETTINGS.apiBaseUrl + 'api/v1/terms/:termId/articles',
				method: 'PUT'
			}
		});

		var resourceComments = $resource(SETTINGS.apiBaseUrl + 'api/v1/articles/:articleId/comments/:commentId', {
			commentId: '@commentId',
			articleId: '@articleId'
		});

		var resourceMetaTags = $resource(SETTINGS.apiBaseUrl + 'api/v1/articles/:articleId/meta_tags', {
			articleId: '@articleId'
		});

		var resourceSeries = $resource(SETTINGS.apiBaseUrl + 'api/v1/series/:seriesId/:commands/:commandEval', {
			seriesId: '@seriesId',
			commands: '@commands',
			commandEval: '@commandEval',
			userId: '@userId',
			article_id: '@article_id'
		}, {
			byUser: {url: SETTINGS.apiBaseUrl + 'api/v1/profile/series'},
			byUsers: {url: SETTINGS.apiBaseUrl + 'api/v1/users/:userId/series'},
			list: {url: SETTINGS.apiBaseUrl + 'api/v1/series'},
			create: {url: SETTINGS.apiBaseUrl + 'api/v1/series', method: 'POST'},
			update: {url: SETTINGS.apiBaseUrl + 'api/v1/series/:seriesId', method: 'PUT'},
			remove: {url: SETTINGS.apiBaseUrl + 'api/v1/series/:seriesId', method: 'DELETE'},
			articles: {url: SETTINGS.apiBaseUrl + 'api/v1/series/:seriesId/articles'},
			addArticle: {url: SETTINGS.apiBaseUrl + 'api/v1/series/:seriesId/articles/add', method: 'PUT'},
			orderArticle: {url: SETTINGS.apiBaseUrl + 'api/v1/series/:seriesId/articles/order', method: 'PUT'},
			deleteArticle: {url: SETTINGS.apiBaseUrl + 'api/v1/series/:seriesId/articles/:article_id', method: 'DELETE'}
		});

		var resourceBookmarks = $resource(SETTINGS.apiBaseUrl + 'api/v1/profile/articles/bookmarks/:articleId', {articleId: '@articleId'});

		var resourceReadingList = $resource(SETTINGS.apiBaseUrl + 'api/v1/profile/articles/reading/:articleId',
			{userId: '@articleId'}, {
				accept: {method: 'PUT', url: SETTINGS.apiBaseUrl + 'api/v1/profile/articles/reading'},
				rejectAll: {method: 'DELETE', url: SETTINGS.apiBaseUrl + 'api/v1/profile/articles/subscribes'}
			});


		return {
			create: function (data) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'save',
					parameters: {article: data}
				});
			},

			update: function (id, data) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'update',
					parameters: {articleId: id, article: data}
				});
			},

			findOne: function (id) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: {articleId: id}
				});
				promise.then(function (response) {
					if (response.success) response.article = Article.create(response.article);
				});
				return promise;
			},

			findAll: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: ArticlesSearchParams.process(params)
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllByCurrentUser: function (params) {
				params = angular.extend({status: 'published'}, params || {});
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByCurrentUser',
					parameters: ArticlesSearchParams.process(params)
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllByUser: function (userId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByUser',
					parameters: angular.extend(ArticlesSearchParams.process(params), {userId: userId})
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllByGroup: function (groupId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByGroup',
					parameters: angular.extend(ArticlesSearchParams.process(params), {groupId: groupId})
				});
				promise.then(function (response) {
					processResponseCollection(response, {type: 'group', id: groupId});
				});
				return promise;
			},

			findAllByTerm: function (termId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByTerm',
					parameters: angular.extend(ArticlesSearchParams.process(params), {termId: termId})
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllDraftsByCurrentUser: function (params) {
				params = angular.extend({status: 'draft'}, params || {});
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByCurrentUser',
					parameters: ArticlesSearchParams.process(params)
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllTransmitted: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allTransmitted',
					parameters: ArticlesSearchParams.process(params)
				});
				promise.then(function (response) {
					response.items = _.map(response.transmissions, function (item) {
						return item.material;
					});
					processResponseCollection(response);
				});
				return promise;
			},

			findAllSimilar: function (id, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allSimilar',
					parameters: angular.extend(ArticlesSearchParams.process(params), {articleId: id})
				});
				promise.then(processResponseCollection);
				return promise;
			},

			listAuthors: function (params) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'authorsList',
					parameters: ArticlesSearchParams.processAuthorsList(params)
				});
			},

			getUploadImageUrl: function (id) {
				return SETTINGS.apiBaseUrl + 'api/v1/articles/' + parseInt(id).toString() + '/avatar';
			},

			changeAvatar: function (articleId, imageId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'changeAvatar',
					parameters: {articleId: articleId, image_id: imageId}
				});

				return (promise);
			},

			pinByTerm: function (id, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'pinByTerm',
					parameters: angular.extend(params, {termId: id})
				});
				promise.then(processResponseCollection);
				return promise;
			},

			comments: {
				list: function (articleId) {
					return serviceHelper.executeRequest({
						resource: resourceComments,
						name: 'get',
						parameters: {articleId: articleId}
					});
				},
				create: function (articleId, message) {
					var data = {
						articleId: articleId,
						comment: {message: message}
					};
					return serviceHelper.executeRequest({
						resource: resourceComments,
						name: 'save',
						parameters: data
					});
				},
				remove: function (articleId, commentId) {
					return serviceHelper.executeRequest({
						resource: resourceComments,
						name: 'remove',
						parameters: {articleId: articleId, commentId: commentId}
					});
				}
			},

			meta: {
				load: function (id) {
					return serviceHelper.executeRequest({
						resource: resourceMetaTags,
						name: 'get',
						parameters: {articleId: id}
					});
				},
				update: function (id, data) {
					return serviceHelper.executeRequest({
						resource: resourceMetaTags,
						name: 'update',
						parameters: angular.extend({articleId: id}, data)
					});
				}
			},

			series: {
				byUser: function () {
					return serviceHelper.executeRequest({
						resource: resourceSeries,
						name: 'byUser'
					});
				},
				byUsers: function (userId) {
					return serviceHelper.executeRequest({
						resource: resourceSeries,
						name: 'byUsers',
						parameters: {userId: userId}
					});
				},
				list: function () {
					return serviceHelper.executeRequest({
						resource: resourceSeries,
						name: 'list'
					});
				},
				create: function (seriesName) {
					return serviceHelper.executeRequest({
						resource: resourceSeries,
						name: 'create',
						parameters: {title: seriesName}
					});
				},
				update: function (id, seriesName) {
					return serviceHelper.executeRequest({
						resource: resourceSeries,
						name: 'update',
						parameters: {seriesId: id, title: seriesName}
					});
				},
				remove: function (seriesId) {
					return serviceHelper.executeRequest({
						resource: resourceSeries,
						name: 'remove',
						parameters: {seriesId: seriesId}
					});
				},
				articlesList: function (params) {
					var promise = serviceHelper.executeRequest({
						resource: resourceSeries,
						name: 'articles',
						parameters: ArticlesSearchParams.process(params)
					});
					promise.then(processResponseCollection);
					return promise;
				},
				addArticle: function (seriesId, articleId) {
					return serviceHelper.executeRequest({
						resource: resourceSeries,
						name: 'addArticle',
						parameters: {seriesId: seriesId, article_id: articleId}
					});
				},
				orderArticle: function (seriesId, articleId, order) {
					return serviceHelper.executeRequest({
						resource: resourceSeries,
						name: 'orderArticle',
						parameters: {seriesId: seriesId, article_id: articleId, order: order}
					});
				},
				deleteArticle: function (seriesId, articlesId) {
					return serviceHelper.executeRequest({
						resource: resourceSeries,
						name: 'deleteArticle',
						parameters: {seriesId: seriesId, article_id: articlesId}
					});
				}
			},

			bookmarks: {
				all: function (params) {
					var promise = serviceHelper.executeRequest({
						resource: resourceBookmarks,
						name: 'get',
						parameters: params
					});
					promise.then(processResponseCollection);
					return (promise);
				}
			},

			reading: {
				all: function (params) {
					var promise = serviceHelper.executeRequest({
						resource: resourceReadingList,
						name: 'get',
						parameters: params
					});
					promise.then(processResponseCollection);
					return (promise);
				},

				accept: function (articleId) {
					var promise = serviceHelper.executeRequest({
						resource: resourceReadingList,
						name: 'accept',
						parameters: {article_id: articleId}
					});
					return (promise);
				}
			}
		};

		function processResponseCollection(response, owner) {
			if (response.success) {
				response.items = response.items.map(function (item) {
					var model = Article.create(item);
					if (owner) model.setOwner(owner.type, owner.id);
					return model;
				});
			}
		}
	}]);
})(angular, NgProject);
