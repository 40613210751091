(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state", "Deferred", "AuthService"];
	function Controller($scope, $state, Deferred, AuthService) {
		$scope.isLoading = true;
		$scope.isConfirmed = false;
		$scope.errors = [];

		function sendToken(token) {
			Deferred.handlePromise(AuthService.registrationConfirm(token),
				function (response) {
					if (response.success === false) {
						$scope.errors = response.errors.confirmation_token;
					}
					$scope.isConfirmed = response.success;
					$scope.isLoading = false;
				}
			);
		}

		sendToken($state.params.token);
	}

	app.controller('PageChangeEmailConfirmationCtrl', Controller);
})(angular, NgProject);
