'use strict';
NgProject.directive('audioPlayerStabilizer', ["ContentStabilizer", "$timeout", function (ContentStabilizer, $timeout) {
	return {
		restrict: 'A',
		link: link
	};

	function link(scope, element) {
		var audioCallback = function () {
			var data = ContentStabilizer.getData();
			if (_.has(data, 'instance')) {
				if (_.get(data.instance.config, 'name') === 'article') {
					element.css('margin-left', '');
					$timeout(function () {
						var modal = $('#modal-article');
						var zIndex = modal.css('z-index') + '1';
						// var size = parseInt(element.css('margin-left')) - Math.ceil(data.scrollWidth / 2);
						element.css({
							position: 'relative',
							'z-index': zIndex
							// 'margin-left': size + 'px',
							// width: '1082px',
							// padding: '6px 31px'
						});
						modal.addClass('show-player');
					}, 30);
				} else {
					element.css({'-webkit-filter': 'blur(8px)', filter: 'blur(8px)'});
				}
			} else {
				element.removeAttr('style');
			}
		};
		ContentStabilizer.registerCallback(audioCallback);
		ContentStabilizer.notifyObservers();

		scope.$on('$destroy', function () {
			$('#modal-article').removeClass('show-player');
			ContentStabilizer.resetObservers();
		});
	}
}]);
