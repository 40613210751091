NgProject.controller('ArticlesListReadingCtrl', ["$q", "$scope", "$state", "$timeout", "ArticlesService", "InterfaceCounters", "CommonData", function ($q, $scope, $state, $timeout, ArticlesService, InterfaceCounters, CommonData) {
	'use strict';
	var requests = {listReading: false};

	function processLoadedTriggers() {
		if (requests.listReading) {
			$scope.controls.isReady = true;
		}
	}

	$scope.articlesCount = InterfaceCounters.articles;
	$scope.controls = {
		isReady: false
	};

	$scope.listReading = {
		promise: ArticlesService.reading.all,
		action: {show: true, sref: 'root.create-article'},
		listOptions: {
			teaserOptions: {buttons: {deleteByOwner: false, deleteBookmark: false, deleteReading: true}}
		},
		onLoadCallback: function () {
			if (!requests.listReading) {
				requests.listReading = true;
				processLoadedTriggers();
			}
		}
	};

	if (!InterfaceCounters.articles.reading) $timeout(function () {
		$state.go('^');
	}, 10);

	$scope.$watch('articlesCount', function (a, b) {
		if (_.eq(a, b)) return;
		if (!a['reading']) $state.go('^');
	}, true);

	$scope.$on('listReading:remove', function (e, articleId) {
		_.remove(CommonData.data.items, {id: articleId});
		CommonData.data.count--;
	});

	$scope.$on('listReading:added', function () {
		$scope.$broadcast('articles-list-reload');
	});
}]);
