NgProject.directive('articles', ["$parse", function ($parse) {
	'use strict';
	Ctrl.$inject = ["$scope", "$attrs", "CommonData"];
	var defaults = {
		requestParams: {},
		onLoadCallback: null,
		events: {
			onReloadList: 'articles-list-reload',
			onRemoveItem: false
		}
	};

	function Ctrl($scope, $attrs, CommonData) {
		var $articles = $scope.$articles = getData();
		var options = angular.extend({}, angular.copy(defaults), $attrs.options ? $parse($attrs.options)($scope) : {});
		var loadFn;
		var globalRequestId = 0;
		var that = this;
		var edited = $attrs['edited'] || false;

		if ($attrs.items) {
			var items = $parse($attrs.items)($scope);
			var count = $attrs.count ? $parse($attrs.count)($scope) : items.length;
			angular.extend($articles, getData(count, items), {
				isLoading: false,
				endOfList: true
			});
		}

		if ($attrs.load) {
			loadFn = $parse($attrs.load);
			if ($attrs.onLoadCallback) {
				options.onLoadCallback = $parse($attrs.onLoadCallback);
			}
		}

		function callOnLoadCallback() {
			if (options.onLoadCallback) {
				options.onLoadCallback($scope, {count: $articles.count});
			}
			if (edited) CommonData.notifyObservers();
		}

		function reset() {
			delete options.requestParams.page;
			$articles = $scope.$articles = getData();
		}

		function loadRemoteData(append) {
			var params = options.requestParams;
			var localRequestId = ++globalRequestId;
			$articles.isLoading = true;
			loadFn($scope, {params: params}).then(function (response) {
				if (localRequestId !== globalRequestId) return;
				if (response.success) {
					var items = response.items;
					if (append) {
						items = $articles.items.concat(items);
					}
					angular.extend($articles, getData(response.count, _.uniq(items, 'id')), {
						isLoading: false,
						endOfList: response.items.length === 0 || response.count === $articles.items.length
					});
				}
				callOnLoadCallback();
				that.emit('receivedRemoteData', response);
			});
		}

		function reloadList() {
			reset();
			loadRemoteData(false);
		}

		if (options.events.onReloadList) $scope.$on(options.events.onReloadList, reloadList);

		this.search = function (params) {
			angular.extend(options.requestParams, params);
			delete options.requestParams.page;
			$articles = $scope.$articles = getData();
			loadRemoteData();
		};


		this.loadByPage = function (page) {
			if (page && page > 1)
				options.requestParams.page = page;
			else
				delete options.requestParams.page;
			loadRemoteData(true);
		};

		this.loadNextPage = function () {
			if (!$articles.endOfList) {
				if (_.isNumber(options.requestParams.page) && options.requestParams.page > 0) options.requestParams.page++;
				else options.requestParams.page = 2;
				loadRemoteData(true);
			}
		};

		this.getSearchParams = function () {
			return options.requestParams;
		};

		this.getPromiseFn = function () {
			return loadFn;
		};

		function initEvents() {
			if (options.events.onReloadList) {
				$scope.$on(options.events.onReloadList, function () {
					delete options.requestParams.page;
					loadRemoteData();
				});
			}
			if (options.events.onRemoveItem) {
				$scope.$on(options.events.onRemoveItem, function (event, item) {
					var itemIndex = _.findIndex($articles.items, item);
					if (itemIndex !== -1) {
						$articles.items.splice(itemIndex, 1);
						$articles.count--;
					}
				});
			}
		}

		function edition() {
			if (!edited) return;
			CommonData.reset();
			CommonData.registerCallback(function () {
				CommonData.setData($articles);
			});
		}

		$scope.search = this.search;
		initEvents();
		edition();
	}

	Ctrl.prototype = EventEmitter2.prototype;

	function link(scope, element, attrs, controller) {
		var params = {};
		if (attrs.hasOwnProperty('load')) {
			if (_.isObject(controller)) {
				var query = controller.getQueryValue();
				if (query.length) {
					params.q = query;
				}
				scope.$on('searchReloadEvent', function (event, query) {
					scope.search({q: query});
				});
			}
			scope.search(params);
		}
	}

	function getData(count, items) {
		return {
			count: count || 0,
			items: _.isArray(items) ? items : [],
			isLoading: true,
			endOfList: false
		};
	}

	return {
		restrict: 'EA',
		require: '?^^search',
		scope: true,
		controller: Ctrl,
		link: link
	};
}]);

