NgProject.directive('news', ["$q", "$parse", "Deferred", "NewsService", "CurrentUser", function ($q, $parse, Deferred, NewsService, CurrentUser) {
	'use strict';
	controller.$inject = ["$scope", "$attrs"];
	var defaults = {
		update: false,
		comments: {}
	};
	function controller($scope, $attrs) {
		var data = $scope.$news = getData();
		var promise = $parse($attrs.load)($scope);
		var removePromise = $attrs.hasOwnProperty('removePromise') ? $parse($attrs.removePromise)($scope) : NewsService.remove;
		var requestParams = angular.extend({}, $attrs.params ? $parse($attrs.params)($scope) : {});
		var options = angular.extend({}, angular.copy(defaults), $attrs.options ? $parse($attrs.options)($scope) : {});
		var that = this;

		function processItem(item) {
			item.trackId = item.type + item.id;
			item.allow_update = options.update || CurrentUser.is_admin || (item.owner.type === 'user' && item.owner.id === CurrentUser.id);
		}

		function loadRemoteData() {
			var deferred = $q.defer();
			Deferred.handlePromise(promise(requestParams), function (response) {
				if (response.success) {
					var items = response.items;
					_.map(items, processItem);
					deferred.resolve({items: items, count: response.count});
				}
				else {
					deferred.reject();
				}
			});

			return deferred.promise;
		}

		function receiveData() {
			data.isLoading = true;
			loadRemoteData().then(function (response) {
				var items = data.items.concat(response.items);
				angular.extend(data, getData(items, response.count), {
					isLoading: false,
					endOfList: response.items.length === 0 || response.count === data.items.length
				});
			});
		}

		this.loadNextPage = function () {
			requestParams.page = requestParams.page ? requestParams.page + 1 : 2;
			receiveData();
		};

		this.addPost = function (post) {
			processItem(post);
			$scope.$news.items.unshift(post);
			$scope.$news.count++;
			delete requestParams.page;
			loadRemoteData().then(function (response) {
				angular.extend(data, getData(response.items, response.count), {
					isLoading: false,
					endOfList: response.items.length === 0 || response.count === data.items.length
				});
			});
		};

		this.removePost = function (item) {
			Deferred.handlePromise(removePromise(item.id), function () {
				_.remove(data.items, item);
			});
		};

		$scope.$controls = {
			options: options,
			remove: function(item) {
				if (item.allow_update) {
					that.removePost(item);
				}
			}
		};

		receiveData();
	}

	function getData(items, count) {
		return {
			items: _.isArray(items) ? items : [],
			count: count || 0,
			isLoading: true,
			endOfList: false
		};
	}

	return {
		restrict: 'E',
		scope: true,
		controller: controller
	};
}]);

