NgProject.directive('navSearch', ["$state", "$timeout", function ($state, $timeout) {
	'use strict';
	Ctrl.$inject = ["$scope"];
	function Ctrl($scope) {
		$scope.$navSearch = {
			active: false,
			showClearButton: false,
			model: '',
			clearModel: function () {
				$scope.$navSearch.model = '';
				$('.nav-search-input').focus();
			},
			submit: function () {
				$state.go('root.search.all', {query: $scope.$navSearch.model});
				$scope.$navSearch.active = false;
			},
			blurFocus: function () {
				if (!$scope.$navSearch.model.length) {
					$timeout(function () {
						$scope.$navSearch.active = false;
					}, 100);
				}
			}
		};

		this.toggleBar = function () {
			$scope.$navSearch.active = !$scope.$navSearch.active;
		};

		this.clearModel = function () {
			$scope.$navSearch.model = '';
		};
	}

	return {
		restrict: 'A',
		controller: Ctrl,
		scope: true
	};
}]);
