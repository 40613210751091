'use strict';
NgProject.controller('ArticleViewModalCtrl', ["$scope", "$filter", "$state", "ModalState", "$timeout", "$q", "Deferred", "ArticlesService", "CurrentUser", "UsersService", "GroupsService", function ($scope, $filter, $state, ModalState, $timeout, $q, Deferred, ArticlesService, CurrentUser, UsersService, GroupsService) {
	var _timeoutPromise = null;
	var article_id = ModalState.getStateParams('articleId');
	$scope.article = {};

	$scope.controls = {
		showActions: false,
		showAddBtn: false,
		showShareBtn: false,
		showEditBtn: false,
		options: {
			comments: {
				countOfVisibleComments: 1
			}
		},
		isCurrentUser: false,
		isAdmin: CurrentUser.is_admin
	};

	$scope.maker = {
		isReady: false,
		following: false,
		subscribe: function (maker, follow) {
			var promise = null;
			if (maker.type == 'user') {
				promise =  UsersService.relationship[follow ? 'unfollow' : 'follow'];
			}
			else {
				promise = GroupsService[follow ? 'leave' : 'join'];
			}
			promise(maker.data.id).then(function (response) {
				if (response.success) $scope.maker.following = !$scope.maker.following;
			});
		}
	};

	function initControls(article) {
		angular.extend($scope.controls, {
			showActions: $scope.isAuthenticated(),
			showAddBtn: /*!_.get(article.flags, 'maker', false) && !article.is_owner &&*/ article.status !== 'draft',
			showShareBtn: article.is_published,
			showEditBtn: article.allow_update
		});
	}

	function readySubscribe(maker) {
		if (!CurrentUser.id || $scope.controls.isCurrentUser) return;
		if (maker.type == 'user') {
			UsersService.about(maker.data.id).then(function (response) {
				if (response.success) {
					$scope.maker.following = response.user.following;
					$scope.maker.isReady = true;
				}
			})
		} else {
			GroupsService.main(maker.data.id).then(function (response) {
				if (response.success) {
					$scope.maker.following = response.group.user_status === 'member';
					$scope.maker.isReady = response.group.user_status !== 'administrator';
				}
			});
		}
	}

	function loadAllRemoteData(id) {
		var deferred = $q.defer();
		var promises = [];
		ArticlesService.findOne(id).then(function (resolve) {
			if (resolve.success) {
				$scope.controls.isAdmin = CurrentUser.is_admin || CurrentUser.is_main_admin;
				if (resolve.article.maker.type === 'user' && resolve.article.maker.data.id)
					$scope.controls.isCurrentUser = CurrentUser.id === resolve.article.maker.data.id;
				promises.push(resolve.article);
				if (!resolve.article.disable_comments) {
					ArticlesService.comments.list(id).then(function (resolve) {
						if (resolve.success) {
							promises.push(resolve.items);
							deferred.resolve(promises);
						}
						else deferred.reject(resolve);
					});
				} else deferred.resolve(promises);
				readySubscribe(resolve.article.maker);
			} else deferred.reject(resolve);
		});
		return deferred.promise;
	}

	// Initialization
	loadAllRemoteData(article_id).then(function (data) {
		initControls(data[0]);
		$scope.article = data[0];
		if (data[0].is_published) {
			// Start Article View Timeout
			_timeoutPromise = $timeout(function () {
				$scope.article.sendViewAction();
			}, 5000);
		}
		if (!data[0].disable_comments) $scope.article.comments = data[1];
	}).catch(function (error) {
		if (DEBUG) console.log('Не удалось получить данные. Причина:', error);
	});

	$scope.similar = {
		enabled: article_id !== 2540,
		sliderOptions: {margin: 15, items: 4, slideBy: 4},
		series: {
			sliderOptions: {margin: 15, items: 4, slideBy: 4},
			options: {
				dynamicPagination: false,
				startFromItemId: article_id,
				highlightCurrent: true
			},
			promise: function (params) {
				params.seriesId = _.get($scope.article.series, 'serie.id');
				return ArticlesService.series.articlesList(params);
			}
		},
		common: {
			promise: function (params) {
				return ArticlesService.findAllSimilar(article_id, angular.extend({limit: 8}, params || {}));
			}
		},
		maker: {
			promise: function (params) {
				params.limit = 8;
				params.order = 'created_at';
				params.watching = article_id;
				params.maker = $filter('ownerSignature')($scope.article.maker.type, $scope.article.maker.data.id);
				return ArticlesService.findAll(params);
			}
		}
	};

	$scope.$on('$destroy', function () {
		$timeout.cancel(_timeoutPromise);
	});
}]);
