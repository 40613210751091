'use strict';
NgProject.factory('httpErrorsInterceptor', ["$q", "PageError", function ($q, PageError) {
	return {
		'responseError': function (error) {
			switch (error.status) {
				case 404:
					PageError.trigger(PageError.API_NOT_FOUND_ERROR, error);
					break;
				case 401:
					PageError.trigger(PageError.API_NOT_ALLOWED_ERROR, error);
					break;
			}

			return $q.reject(error);
		}
	};
}]);
