(function (ng, app) {
	'use strict';
	app.service('VideosService', ["$resource", "serviceHelper", "SETTINGS", "VideosSearchParams", "Video", function ($resource, serviceHelper, SETTINGS, VideosSearchParams, Video) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/videos/:videoId', {videoId: '@videoId'}, {
			allByCurrentUser: {url: SETTINGS.apiBaseUrl + 'api/v1/profile/videos'},
			allByUser: {url: SETTINGS.apiBaseUrl + 'api/v1/users/:userId/videos'},
			allByGroup: {url: SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/videos'},
			allByTerm: {url: SETTINGS.apiBaseUrl + 'api/v1/terms/:termId/videos'}
		});

		var resourceBookmarks = $resource(SETTINGS.apiBaseUrl + 'api/v1/profile/videos/bookmarks/:videoId', {videoId: '@videoId'});

		var resourceSubscribes = $resource(SETTINGS.apiBaseUrl + 'api/v1/profile/videos/subscribes/:videoId', {videoId: '@videoId'});

		function processResponseCollection(response, owner) {
			if (response.success) {
				response.items = response.items.map(function (item) {
					var model = Video.create(item);
					if (owner) model.setOwner(owner.type, owner.id);
					return model;
				});
			}
		}

		return {
			get: function (id) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: {videoId: id}
				});
				promise.then(function (response) {
					if (response.success) response.video = Video.create(response.video);
				});
				return promise;
			},

			create: function (data) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'save',
					parameters: {video: data}
				});
			},

			update: function (id, data) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'update',
					parameters: {videoId: id, video: data}
				});
			},

			findAll: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: VideosSearchParams.process(params)
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllByCurrentUser: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByCurrentUser',
					parameters: VideosSearchParams.process(params)
				});
				promise.then(function (response) {
					processResponseCollection(response);
				});
				return promise;
			},

			findAllByUser: function (userId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByUser',
					parameters: angular.extend(VideosSearchParams.process(params), {userId: userId})
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllByGroup: function (groupId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByGroup',
					parameters: angular.extend(VideosSearchParams.process(params), {groupId: groupId})
				});
				promise.then(function (response) {
					processResponseCollection(response, {type: 'group', id: groupId});
				});
				return promise;
			},

			findAllByTerm: function (termId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByTerm',
					parameters: angular.extend(VideosSearchParams.process(params), {termId: termId})
				});
				promise.then(processResponseCollection);
				return promise;
			},

			bookmarks: {
				all: function (params) {
					var promise = serviceHelper.executeRequest({
						resource: resourceBookmarks,
						name: 'get',
						parameters: VideosSearchParams.process(params)
					});
					promise.then(function (response) {
						processResponseCollection(response);
					});
					return (promise);
				},

				accept: function (videoId) {
					var promise = serviceHelper.executeRequest({
						resource: resourceBookmarks,
						name: 'update',
						parameters: {video_id: videoId}
					});
					return (promise);
				}
			},

			subscribes: {
				all: function (params) {
					var promise = serviceHelper.executeRequest({
						resource: resourceSubscribes,
						name: 'get',
						parameters: VideosSearchParams.process(params)
					});
					promise.then(function (response) {
						processResponseCollection(response);
					});
					return promise;
				},
				reject: function (videoId) {
					var promise = serviceHelper.executeRequest({
						resource: resourceSubscribes,
						name: 'remove',
						parameters: {videoId: videoId}
					});
					return (promise);
				},
				rejectAll: function () {
					var promise = serviceHelper.executeRequest({
						resource: resourceSubscribes,
						name: 'remove'
					});
					return (promise);
				}
			}
		};
	}]);
})(angular, NgProject);
