NgProject.directive('share', ["$parse", "modalWindow", function ($parse, modalWindow) {
	'use strict';
	var defaults = {
		method: 'share',
		modal: 'sharing-options'
	};

	function link(scope, element, attrs) {
		element.on('click', function () {
			var attachment = $parse(attrs['share'])(scope);
			modalWindow.open(defaults.modal, attachment);
		});

		scope.$on('$destroy', function () {
			element.off('click');
		});
	}

	return {
		restrict: 'A',
		link: link
	};
}]);
