(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "CurrentUser", "cfpLoadingBar"];
	function Controller($scope, CurrentUser, cfpLoadingBar) {
		$scope.roomsMatrix = {
			options: {
				owner: {type: 'user', id: CurrentUser.id}
			}
		};

		$scope.controls = {
			hasErrors: false,
			submit: function () {
				cfpLoadingBar.start();
				$scope.$broadcast('inputRoomsMatrixSave');
			}
		};

		$scope.$on('onInputRoomsMatrixBeforeDelete', function () {
			cfpLoadingBar.start();
		});
		$scope.$on('onInputRoomsMatrixAfterDelete', function () {
			cfpLoadingBar.complete();
		});
		$scope.$on('onInputRoomsMatrixSaveFinished', function (e, isSuccess) {
			cfpLoadingBar.complete();
			$scope.controls.hasErrors = !isSuccess;
		});
	}

	app.controller('PageSettingsEventsRoomsCtrl', Controller);
})(angular, NgProject);
