'use strict';
NgProject.controller('PageMaterialsCtrl', ["$scope", "$state", "InterfaceCounters", function Controller($scope, $state, InterfaceCounters) {
	$scope.$counter = InterfaceCounters;

	function updateStateSection() {
		$scope.materialsSection = $state.current.data.section;
	}

	$scope.$on('$stateChangeStart', function(e, toState, toParams, fromState) {
		// запоминание открытого подраздела при переключении между разделами
		if (toState.data.tab && fromState.data.tab && fromState.data.tab !== toState.data.tab) {
			if (toState.data.section !== fromState.data.section) {
				e.preventDefault();
				$state.go(toState.data.tab + '.' + fromState.data.section);
			}
		}
	});

	$scope.$on('$stateChangeSuccess', updateStateSection);
}]);
