(function (ng, app) {
	'use strict';
	app.directive('multistateButton', function () {
		return {
			restrict: 'A',
			scope: {
				options: '=multistateButton',
				state: '=',
				actionDo: '&',
				actionUndo: '&'
			},
			link: function (scope, element) {
				var buttons = scope.options;
				var stateIsDo = scope.state;
				var oldStateIsDo = false;
				var isMouseOn = false;

				var toggleClasses = {
					do: {
						remove: buttons.undo.class + ' ' + buttons.status.class,
						add: buttons.do.class
					},
					status: {
						remove: buttons.do.class + ' ' + buttons.undo.class,
						add: buttons.status.class
					},
					undo: {
						remove: buttons.do.class + ' ' + buttons.status.class,
						add: buttons.undo.class
					}
				};

				function initButton() {
					if (stateIsDo) {
						if (element.data('state') === 'do') {
							return;
						}

						element
							.removeClass(toggleClasses.do.remove)
							.addClass(toggleClasses.do.add)
							.html(buttons.do.content)
							.data('state', 'do');

						if (buttons.do.title)
							element.attr('title', buttons.do.title);
					}
					else {
						if (isMouseOn && !oldStateIsDo) {
							if (element.data('state') === 'undo') {
								return;
							}
							element
								.removeClass(toggleClasses.undo.remove)
								.addClass(toggleClasses.undo.add)
								.html(buttons.undo.content)
								.data('state', 'undo');

							if (buttons.do.title)
								element.attr('title', buttons.undo.title);
						}
						else {
							if (element.data('state') === 'status') {
								return;
							}
							element
								.removeClass(toggleClasses.status.remove)
								.addClass(toggleClasses.status.add)
								.html(buttons.status.content)
								.data('state', 'status');

							if (buttons.status.title)
								element.attr('title', buttons.status.title);
						}
					}
				}

				initButton();

				element.on('mouseenter', function () {
					isMouseOn = true;
					initButton();
				});

				element.on('mouseleave', function () {
					isMouseOn = false;
					if (oldStateIsDo) {
						oldStateIsDo = false;
					}
					initButton();
				});

				element.on('click', function () {
					if (stateIsDo) {
						scope.actionDo();
					}
					else {
						if (!oldStateIsDo) {
							scope.actionUndo();
						}
					}
				});

				scope.$watch('state', function (newValue, oldValue) {
					if (newValue !== oldValue) {
						oldStateIsDo = oldValue;
						stateIsDo = newValue;
						initButton();
					}
				});
			}
		};
	});
})(angular, NgProject);
