NgProject.directive('events', ["$parse", "Deferred", function ($parse, Deferred) {
	'use strict';
	Ctrl.$inject = ["$scope", "$attrs"];
	var defaults = {
		requestParams: {},
		onLoadCallback: null,
		events: {
			onRemoveItem: false
		}
	};

	function Ctrl($scope, $attrs) {
		var that = this;
		var $events = $scope.$events = getData();
		var options = angular.extend({}, angular.copy(defaults), $attrs.options ? $parse($attrs.options)($scope) : {});
		var loadFn;
		var globalRequestId = 0;

		if($attrs.load) {
			loadFn = $parse($attrs.load);
			if ($attrs.onLoadCallback) {
				options.onLoadCallback = $parse($attrs.onLoadCallback);
			}
		}
		else {
			var items = $parse($attrs.items)($scope);
			angular.extend($events, getData(items.length, items), {
				isLoading: false,
				endOfList: true
			});
		}

		function callOnLoadCallback() {
			if (options.onLoadCallback) {
				options.onLoadCallback($scope, {
					count: $events.count
				});
			}
		}

		function loadRemoteData(append) {
			var params = options.requestParams;
			var localRequestId = ++globalRequestId;
			$events.isLoading = true;
			Deferred.handlePromise(loadFn($scope, {params: params}), function (response) {
				if (localRequestId !== globalRequestId) return;
				if (response.success) {
					var items = response.items;
					if (append) {
						items = $events.items.concat(items);
					}
					angular.extend($events, getData(response.count, items), {
						isLoading: false,
						endOfList: response.items.length === 0 || response.count === $events.items.length
					});
				}
				callOnLoadCallback();
				that.emit('receivedRemoteData', response);
			});
		}

		this.search = function(params) {
			angular.extend(options.requestParams, params);
			delete options.requestParams.page;
			$scope.$broadcast('$onChangeSearchParams');
			$events = $scope.$events = getData();
			loadRemoteData();
		};

		this.loadByPage = function (page) {
			if (page && page > 1)
				options.requestParams.page = page;
			else
				delete options.requestParams.page;
			loadRemoteData(true);
		};

		this.loadNextPage = function () {
			if (!$events.endOfList) {
				if (_.isNumber(options.requestParams.page) && options.requestParams.page > 0) options.requestParams.page++;
				else options.requestParams.page = 2;
				loadRemoteData(true);
			}
		};

		this.getSearchParams = function() {
			return options.requestParams;
		};

		this.getPromiseFn = function() {
			return loadFn;
		};

		function initEvents() {
			if (options.events.onRemoveItem) {
				$scope.$on(options.events.onRemoveItem, function (event, item) {
					var itemIndex = _.findIndex($events.items, item);
					if (itemIndex !== -1) {
						$events.items.splice(itemIndex, 1);
						$events.count--;
					}
				});
			}
		}

		$scope.search = this.search;
		initEvents();
	}

	Ctrl.prototype = EventEmitter2.prototype;

	function link(scope, element, attrs, controller) {
		var params = {};
		if (attrs.hasOwnProperty('load')){
			if (_.isObject(controller)) {
				var query = controller.getQueryValue();
				if (query.length) {
					params.q = query;
				}
				scope.$on('searchReloadEvent', function (event, query) {
					scope.search({q: query});
				});
			}
			scope.search(params);
		}
	}

	function getData(count, items) {
		return {
			count: count || 0,
			items: _.isArray(items) ? items : [],
			isLoading: true,
			endOfList: false
		};
	}

	return {
		require: '?^^search',
		restrict: 'E',
		scope: true,
		controller: Ctrl,
		link: link
	};
}]);
