NgProject.controller('modal:event-registration.ctrl', ["$scope", "$state", "$localStorage", "$timeout", "CurrentUser", "Deferred", "moment", "EventContent", "AuthService", "ProfileSettingsService", "UsersService", "EventsService", function Controller($scope, $state, $localStorage, $timeout, CurrentUser, Deferred, moment, EventContent,
                                                                          AuthService, ProfileSettingsService, UsersService, EventsService) {
	'use strict';
	var pattern = {
		fullname: null,
		email: null,
		phone: null
	};

	$scope.fData = {
		allowStore: false,
		registrationSuccess: false,
		data: {
			headman: _.extend(_.clone(pattern), {utc_offset: -1 * new Date().getTimezoneOffset() || ''}),
			reservations: []
		}
	};

	$scope.event = EventContent.data;
	var $data = $scope.fData.data;

	$scope.fErrors = {
		headman: {},
		reservations: []
	};

	$scope.controls = {
		isAuth: false,
		addPart: function () {
			$data.reservations.push(_.extend(_.clone(pattern), {id: Math.ceil(Math.random() * 1E6)}));
		},
		submitForm: function () {
			var sendData = prepareRequestData($scope.fData.data);
			var data = {
				alreadyRegistered: true,
				data: sendData
			};
			$scope.fErrors = [];
			Deferred.handlePromise(EventsService.join($scope.event.id, sendData), function (response) {
				if (response.success) {
					if ($scope.fData.allowStore) {
						setStorageData(data);
					} else {
						$localStorage.$reset();
					}
					$scope.fData.registrationSuccess = true;
					$scope.registered = response.members;
				} else {
					$scope.fErrors = response.errors;
				}
			});
		},
		removeReservation: function (item) {
			_.remove($data.reservations, item);
			if (!!$scope.fErrors.length) $scope.fErrors = [];
		}
	};

	if (!!CurrentUser.id) getAuthData();
	if ($localStorage.alreadyRegistered) getStorageData();

	EventContent.registerCallback(function () {
		$scope.event = EventContent.data;
	});

	function prepareRequestData(data) {
		var _data = {headman: data.headman};
		if (!!data.reservations && !!data.reservations.length) {
			_data.reservations = [];
			_.each(data.reservations, function (item) {
				item = _.clone(item);
				_data.reservations.push(cleanEmptyItems(item));
			});
		}
		return _data;
	}

	function cleanEmptyItems(data) {
		for (var item in data) {
			if (data.hasOwnProperty(item) && _.isEmpty(data[item])) delete data[item];
		}
		return data;
	}

	function getAuthData() {
		$scope.controls.isAuth = true;
		$data.headman.user_id = CurrentUser.id;
		Deferred.handleAllPromises([ProfileSettingsService.email.get(), UsersService.about('self')], function (settings, profile) {
			var phone = null;
			if (settings.success) {
				$data.headman.fullname = !!CurrentUser.fullname ? CurrentUser.fullname : '';
				$data.headman.email = settings.email;
			}
			if (profile.success) {
				phone = _.find(_.get(profile, 'user.object_contacts', null), {key: 'phone'});
				if (!!phone) {
					$data.headman.phone = phone.value;
				}
			}
		});
	}

	function setStorageData(data) {
		if (!$scope.controls.isAuth && !$localStorage.alreadyRegistered) {
			$localStorage.$default(data);
		} else {
			$localStorage.$reset(data);
		}
	}

	function getStorageData() {
		if (_.has($localStorage.data, 'headman')) {
			$scope.fData.data['headman'] = $localStorage.data['headman'];
		}
		if (_.has($localStorage.data, 'reservations') && $localStorage.data['reservations'].length > 0) {
			_.forEach($localStorage.data['reservations'], function (item) {
				$scope.fData.data['reservations'].push(item);
			});
		}
	}
}]);
