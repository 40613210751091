NgProject.directive('termInfo', ["$parse", function ($parse) {
	'use strict';
	function link(scope, element, attrs) {
		scope.item = $parse(attrs['termInfo'])(scope);
	}

	return {
		restrict: 'A',
		link: link,
		scope: true
	};
}]);
