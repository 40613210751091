'use strict';
NgProject.directive('complexButton', function () {
	controller.$inject = ["$scope", "$element"];
	function controller($scope, $element) {
		var that = this;
		this.state = Boolean($scope.state);
		this.active = false;
		this.callbacks = [];

		this.registerCallback = function(callback) {
			this.callbacks.push(callback);
		};

		function applyCallbacks() {
			that.callbacks.forEach(function(fn) {
				fn();
			});
		}

		$element.on('mouseenter', function () {
			that.active = true;
			applyCallbacks();
		});

		$element.on('mouseleave', function () {
			that.active = false;
			applyCallbacks();
		});

		$scope.$watch('state', function () {
			that.state = Boolean($scope.state);
			applyCallbacks();
		});
	}

	return {
		restrict: 'A',
		controller: controller,
		scope: {
			state: '='
		}
	};
});

NgProject.directive('complexButtonStateFalse', function () {
	function link(scope, element, attrs, controller) {
		element.toggle(!controller.state);
		controller.registerCallback(function() {
			element.toggle(!controller.state);
		});
	}

	return {
		restrict: 'A',
		require: '^^complexButton',
		link: link
	};
});

NgProject.directive('complexButtonStateTrue', function () {
	function link(scope, element, attrs, controller) {
		element.toggle(controller.state);
		controller.registerCallback(function() {
			element.toggle(controller.state && controller.active);
		});
	}

	return {
		restrict: 'A',
		require: '^^complexButton',
		link: link
	};
});

NgProject.directive('complexButtonStatus', function () {
	function link(scope, element, attrs, controller) {
		element.toggle(controller.state);
		controller.registerCallback(function() {
			element.toggle(controller.state && !controller.active);
		});
	}

	return {
		restrict: 'A',
		require: '^^complexButton',
		link: link
	};
});
