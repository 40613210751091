NgProject.controller('ImNewMessageModalCtrl', ["$scope", "$state", "Deferred", "ImService", function Controller($scope, $state, Deferred, ImService) {
	'use strict';
	var chat = {};
	$scope.user = $scope.$modalWindow.data(0);

	$scope.controls = {
		isSent: false,
		message: null,
		submit: function () {
			var that = this;
			var data = {
				id: chat.id,
				message: _.trim(that.message)
			};

			if (_.isEmpty(data.message)) return;

			that.isSent = true;
			Deferred.handlePromise(ImService.message(data), function (response) {
				that.isSent = _.get(response, 'success', false);
			});
		},
		openChat: function () {
			$state.go('root.im.chat', {chatId: chat.id});
		}
	};

	function loadChat() {
		var promise = Deferred.handlePromise(ImService.users($scope.user.id));
		promise.then(function (response) {
			if (response.success) chat = response.chat;
		});
		return promise;
	}

	loadChat();
}]);
