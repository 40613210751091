NgProject.directive('lightboxVideo', ["$parse", "modalWindow", function ($parse, modalWindow) {
	'use strict';
	function link(scope, element, attrs) {
		element.on('click', function () {
			var object = $parse(attrs.lightboxVideo)(scope);
			modalWindow.open('video', object);
		});
	}

	return {
		restrict: 'A',
		link: link
	};
}]);
