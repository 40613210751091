NgProject.factory('ImagesSearchManageParams', ["ImagesSearchParams", function (ImagesSearchParams) {
	'use strict';
	return {
		process: function (params) {
			var options = ImagesSearchParams.process(params);

			if (params.moderation_status)
				options.moderation_status = params.moderation_status;

			if (params.visibility)
				options.visibility = params.visibility;

			if (params.tag_status)
				options.tags_status = params.tag_status;
			
			return options;
		}
	};
}]);
