NgProject.factory('UsersSearchParams', function () {
	'use strict';
	return {
		process: function (params) {
			if (_.isEmpty(params)) return {};
			var options = {};

			if (!_.isEmpty(params.q))
				options.q = params.q;

			if (params.page && params.page > 1)
				options.page = params.page;

			if (params.limit)
				options.per_page = params.limit;

			if (params.countryId) {
				options.country_id = params.countryId;
				if (params.cityId)
					options.city_id = params.cityId;
			}

			if (params.directionId)
				options.direction_id = params.directionId;

			if (params.roleId)
				options.role_id = params.roleId;

			if (params.gender)
				options.gender = params.gender;

			return options;
		}
	};
});
