'use strict';
NgProject.directive('eventsCarousel', ["$parse", function ($parse) {
	controller.$inject = ["$scope", "$attrs"];
	var defaults = {
		load: null,
		options: {
			emptyText: '',
			dynamicPagination: true,
			startFromItemId: false
		},
		carouselOptions: {
			nav: true,
			navText: ['', ''],
			dots: false,
			smartSpeed: 200
		}
	};

	function controller($scope, $attrs) {
		$scope.$options = {
			viewMode: 'static'
		};
		$scope.$options.load = $parse($attrs['load'])($scope);
		$scope.$options.options = angular.extend({}, defaults.options, $parse($attrs['options'])($scope));
		$scope.$options.carouselOptions = angular.extend({}, defaults.carouselOptions, $parse($attrs['carouselOptions'])($scope));

		this.getOptions = function (key) {
			return $scope.$options[key];
		};
	}

	return {
		restrict: 'E',
		scope: true,
		templateUrl: 'widgets.events-carousel.template',
		controller: controller
	};
}]);

NgProject.directive('eventsCarouselInner', function () {
	function link(scope, element, attrs, controllers) {
		var eventsCarouselCtrl = controllers[0];
		var eventsCtrl = controllers[1];
		var carouselOptions = eventsCarouselCtrl.getOptions('carouselOptions');

		eventsCtrl.once('receivedRemoteData', function (response) {
			if (response.count === 0) {
				scope.$options.viewMode = 'empty';
			}
			else if (response.count === 1) {
				scope.$options.viewMode = 'single';
			}
			else if (response.count > carouselOptions.items) {
				scope.$options.viewMode = 'slider';
			}
		});
	}

	return {
		restrict: 'A',
		require: ['^^eventsCarousel', '^^events'],
		link: link
	};
});

NgProject.directive('eventsCarouselSlider', ["$compile", function ($compile) {
	var itemTemplate = null;

	function compile(tElement) {
		itemTemplate = tElement.html();
		tElement.html('');
		return link;
	}

	function link(scope, element, attrs, controllers) {
		var eventsCarouselCtrl = controllers[0];
		var eventsCtrl = controllers[1];
		var owl = null;

		var options = angular.copy(eventsCarouselCtrl.getOptions('options'));
		var carouselOptions = eventsCarouselCtrl.getOptions('carouselOptions');

		function addItems(items) {
			var startPosition = null;
			angular.forEach(items, function (item, index) {
				var newScope = scope.$new();
				newScope.$event = item;
				newScope.$index = index;
				owl.trigger('add.owl.carousel', [$compile(itemTemplate)(newScope)]);

				if (options.startFromItemId && item.id === options.startFromItemId) {
					startPosition = newScope.$index;
				}
			});

			owl.trigger('refresh.owl.carousel');

			if (options.startFromItemId && startPosition) {
				owl.trigger('to.owl.carousel', [startPosition, 0]);
			}
		}

		if (options.dynamicPagination) {
			carouselOptions.onTranslate = function (event) {
				if ((event.item.count - event.item.index - event.page.size) < event.page.size * 2) {
					eventsCtrl.loadNextPage();
				}
			};

			eventsCtrl.on('receivedRemoteData', function (response) {
				if (response.count <= carouselOptions.items) return;
				addItems(response.items);
			});
		}

		owl = $(element).owlCarousel(carouselOptions);

		addItems(scope.$events.items);
	}

	return {
		restrict: 'A',
		require: ['^^eventsCarousel', '^^events'],
		compile: compile
	};
}]);

