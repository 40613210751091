(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state", "Deferred", "AuthService"];
	function Controller($scope, $state, Deferred, AuthService) {
		var token = $state.params.token;

		$scope.isLoading = true;
		$scope.isConfirmed = false;

		$scope.isFormSubmitted = false;

		$scope.errors = [];
		$scope.credentials = {
			password: null,
			password_confirm: null
		};

		function checkToken() {
			Deferred.handlePromise(AuthService.checkPasswordsChangeToken(token),
				function (response) {
					$scope.isConfirmed = response.success;
					$scope.isLoading = false;
				}
			);
		}

		$scope.submitForm = function () {
			if (!$scope.isConfirmed) {
				return;
			}
			Deferred.handlePromise(AuthService.changePassword(token, $scope.credentials.password, $scope.credentials.password_confirm), function (response) {
				if (response.success === false) {
					$scope.errors = response.errors;
				}
				$scope.isFormSubmitted = response.success;
			});
		};

		checkToken();
	}

	app.controller('PageChangePasswordCtrl', Controller);
})(angular, NgProject);
