(function (ng, app) {
	'use strict';
	app.filter('imageUrl', ["SETTINGS", function (SETTINGS) {
		return function (image_url, withoutHost) {
			if (withoutHost) {
				return image_url;
			}
			return SETTINGS.assetsBaseUrl + image_url;
		};
	}]);
})(angular, NgProject);
