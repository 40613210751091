NgProject.filter('parseLink', ["$sanitize", function ($sanitize) {
	'use strict';
	var rlink = {
		url: /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/,
		http: /^https?:\/\//i,
		site: /[\S]*\.[\S]*/i,
		path: /\/([^/]+)\/?(.*)?$/i
	};

	var sites = {
		twitter: 'https://twitter.com/',
		facebook: 'https://facebook.com/',
		vk: 'https://vk.com/',
		ok: 'https://ok.ru/'
	};

	return function (text, target) {
		var result, ref = text;
		if (!text) return text;

		switch (target.key) {
			case 'website':
				parseWebsite();
				break;
			case 'phone':
				result = '<a href="tel:+' + ref.match(/\d+/g).join('') + '">' + ref + '</a>';
				break;
			case 'skype':
				result = '<a href="skype:' + ref + '?chat">' + ref + '</a>';
				break;
			case 'email':
				result = '<a href="mailto:' + ref + '">' + ref + '</a>';
				break;
			default:
				parseSocial();
		}

		return $sanitize(result);

		function parseWebsite() {
			if (rlink.site.test(ref)) {
				var url = rlink.http.test(ref) ? ref : '//' + ref;
				result = '<a href="' + url + '" target="_blank">' + ref + '</a>';
			} else {
				result = ref;
			}
		}

		function parseSocial() {
			if (rlink.url.test(ref)) {
				var parser = document.createElement('a'), text = '';
				parser.href = ref;
				if (target.key === 'facebook') {
					if (parser.search && /id/.test(parser.search)) {
						text = parser.search.match(/id=(\d+)/)[1];
					} else {
						text = parser.pathname.match(/\/people\/([^\/]*)/)[1];
					}
				} else if (target.key === 'ok') {
					text = parser.pathname.match(/\/profile\/([^\/]*)/)[1];
				} else {
					text = parser.pathname.match(rlink.path)[1];
				}
				result = '<a href="' + ref + '" target="_blank">' + text + '</a>';
			} else {
				ref = rlink.path.test(ref) ? ref.match(rlink.path)[1] : ref;
				result = '<a href="' + sites[target.key] + ref + '" target="_blank">' + ref + '</a>';
			}
		}
	};
}]);
