(function (ng, app) {
	'use strict';
	app.service('MakersService', ["$resource", "serviceHelper", "SETTINGS", function ($resource, serviceHelper, SETTINGS) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/makers?type=:type', {type: '@type'});

		return {
			videos: function () {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: {type: 'video'}
				});
			},
			audios: function () {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: {type: 'audio'}
				});
			},
			images: function () {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: {type: 'photo'}
				});
			},
			articles: function () {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: {type: 'article'}
				});
			}
		};
	}]);
})(angular, NgProject);
