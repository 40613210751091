NgProject.directive('facebookGroupWidget', ["$window", function ($window) {
	'use strict';

	function initFacebookSdk() {
		var s = 'script', id = 'facebook-jssdk';
		var js, fjs = document.getElementsByTagName(s)[0];
		if (document.getElementById(id)) return;
		js = document.createElement(s);
		js.id = id;
		js.src = '//connect.facebook.net/ru_RU/sdk.js';
		fjs.parentNode.insertBefore(js, fjs);
	}

	function initFacebookSettings() {
		$window.FB.init({
			appId: '243795729296700',
			xfbml: true,
			status: true,
			version: 'v2.7'
		});
	}

	return {
		restrict: 'A',
		link: function () {
			if (!$window.FB) {
				angular.element('body').append('<div id="fb-root"></div>');
				$window.fbAsyncInit = initFacebookSettings;
				initFacebookSdk();
			}
			else {
				$window.FB.XFBML.parse();
			}
		}
	};
}]);
