NgProject.controller('PageManageImagesCtrl', ["$scope", "$state", "$stateParams", "ImagesManageService", function ($scope, $state, $stateParams, ImagesManageService) {
	'use strict';
	$scope.images = {
		promise: function(params) {
			params = angular.extend(params || {}, $scope.$filter.model);
			return ImagesManageService.findAll(params);
		}
	};

	$scope.$filter = {
		model: {
			moderation_status: $stateParams.moderation_status,
			visibility: $stateParams.visibility
		},
		data: {
			moderation_status: [
				{key: 'awaiting_moderation', title: 'Ожидают модерации'},
				{key: 'moderated', title: 'Промодерированные'}
			],
			visibility: [
				{key: 'public', title: 'Публичные'},
				{key: 'private', title: 'Приватные'}
			]
		},

		reset: function() {
			angular.extend($scope.$filter.model, {
				moderation_status: null,
				visibility: null
			});
		}
	};

	$scope.setVisibility = function($item, visibility) {
		ImagesManageService.setVisibility($item.id, visibility).then(function(response) {
			if (response.success) {
				$item.content_options = response.image.content_options;
			}
		});
	};

	$scope.$watch('$filter.model', function(value, oldValue) {
		if (value !== oldValue) {
			$state.go($state.current.name, value);
		}
	}, true);
}]);
