'use strict';
(function ($) {
	var plugin = 'redactor_questions';

	$.Redactor.prototype[plugin] = function () {
		return {
			$service: null,
			langs: {
				ru: {'redactor-questions': 'Вопрос & Ответ'}
			},

			init: function () {
				if (!_.get(this, 'opts.data.questions', false)) return false;
				if (!_.get(this, 'opts.data.articleId', false)) return false;
				this[plugin].createService();
				this[plugin].createButton();
			},

			createService: function () {
				var $redactor = this;
				var $plugin = this[plugin];
				$plugin.$service = this.$injector.invoke(['RedactorQuestionsBox', function (RedactorQuestionsBox) {
					return new RedactorQuestionsBox($redactor, {
						articleId: $redactor.opts.data.articleId
					});
				}]);

				this.core.editor().find('span[redactor-question]').each(function () {
					var $anchor = $(this);
					$plugin.$service.addAnchor($anchor, parseInt($anchor.attr('redactor-question')));
				});
			},

			createButton: function () {
				var button = this.button.add('questions', this.lang.get('redactor-questions'));
				this.button.addCallback(button, this[plugin].buttonCallback);
			},

			buttonCallback: function () {
				this[plugin].openCreateForm();
			},

			openCreateForm: function () {
				var data = {answer: _.trim(this.selection.html())};
				this.selection.save();
				this[plugin].openModalForm(data);
			},

			openUpdateForm: function (data) {
				this.selection.save();
				this[plugin].openModalForm(data);
			},

			openModalForm: function (data) {
				this.selection.save();
				this.openModal('redactor-question', {
					data: data,
					articleId: this.opts.data.articleId,
					callbacks: {
						onCreate: $.proxy(this[plugin].insert, this),
						onUpdate: $.proxy(this[plugin].update, this),
						onRemove: $.proxy(this[plugin].remove, this)
					}
				});
			},

			insert: function (data) {
				this.selection.restore();
				var $block = this.selection.block();

				this[plugin].$service.addItem(data);

				if (!$block) {
					this[plugin].$service.draw();
					return;
				}

				var $anchor = $('<span>').attr('redactor-question', data.id);
				$($block).prepend($anchor);
				this[plugin].$service.addAnchor($anchor, data.id);

				this.code.sync();
			},

			update: function () {
				this[plugin].$service.reload();
			},

			remove: function (id) {
				this[plugin].$service.reload();
				var $anchors = this.core.editor().find('span[redactor-question="' + id + '"]');
				if ($anchors.length) {
					$anchors.remove();
					this.code.sync();
				}
			}
		};
	};
})(jQuery);
