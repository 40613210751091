NgProject.controller('GroupSettingsManagersCtrl', ["$scope", "Deferred", "GroupsService", "cfpLoadingBar", function ($scope, Deferred, GroupsService, cfpLoadingBar) {
	'use strict';
	$scope.$managers = {
		promise: function (params) {
			return GroupsService.managers.list($scope.$group.id, params);
		},
		mainOptions: {
			followButton: false,
			events: {
				onReloadList: 'update managers list'
			}
		},
		listOptions: {
			template: 'pages.groups.forms.global.managers.managers-list-item'
		},
		excludeUser: function(user) {
			cfpLoadingBar.start();
			Deferred.handlePromise(GroupsService.managers.remove($scope.$group.id, user.id), function () {
				cfpLoadingBar.complete();
				updateManagersList();
			});
		}
	};

	$scope.$on('group-add-manager', updateManagersList);

	function updateManagersList() {
		$scope.$broadcast($scope.$managers.mainOptions.events.onReloadList);
	}
}]);
