NgProject.factory('DefinitionHelper', function () {
	'use strict';

	return {
		clean: clean,
		set: set,
		to: _toLowerCase
	};

	/*
	 [/(\s)(<\/[^>]+>)/ig, '$2'],
	 [/(<[^\/>][^>]*>)(\s)/ig, '$1'],
	 [/([А-я]|[0-9]|[A-Za-z]|\.,)(<span\sclass)/ig, '$1 $2'],
	 [/(<\/[^>]+>)([\W])/ig, '$1 $2'],
	 [/\s\./ig, '.'], [/\s,/ig, ','],
	 */
	function clean(item) {
		var replaces = [
			[/\&nbsp;/ig, ' '],
			[/<br[^>]*?\/?>/ig, ' '],
			[/\s+/ig, ' '],
			[/[\n\r\t↵]/g, ' ']
		];
		item = _.trim(item ? item.toString() : '');
		for (var i = 0; i < replaces.length; i++) {
			item = item.replace(replaces[i][0], replaces[i][1]);
		}
		return item;
	}

	function set(title, definition, isLink) {
		var titleStr = isLink ?
			'<a class="name" href="/wiki/-id-" target="_blank">-title-</a> &mdash; '.replace(/-title-/, title.title).replace(/-id-/, title.id):
			'<strong>-title-</strong> &mdash; '.replace(/-title-/, title.title);

		definition = clean(definition);

		if (/^<p[^>]*>/.test(definition)) {
			definition = definition.replace(/^(<p[^>]*>)(.*)/, function () {
				return arguments[1] + titleStr + _toLowerCase(arguments[2]);
			});
		} else {
			// todo: remove if not matches
			definition = definition.replace(/(.*)/, function () {
				return titleStr + _toLowerCase(arguments[1]);
			});
		}
		return definition;
	}

	function _toLowerCase(str) {
		return str.replace(/^\s*(<\w+[^>]*>\s*)*([A-ZА-Я])/, function () {
			return arguments[2].toLowerCase();
		});
	}
});

