(function (ng, app) {
	'use strict';
	app.service('ArticlesManageService', ["$resource", "serviceHelper", "SETTINGS", "Article", "ArticlesSearchManageParams", function ($resource, serviceHelper, SETTINGS, Article, ArticlesSearchManageParams) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/administration/content/articles', null, {
			setVisibility: {
				url: SETTINGS.apiBaseUrl + 'api/v1/administration/content/articles/:id',
				params: {id: '@id'},
				method: 'PUT'
			},
			setTagVisibility: {
				url: SETTINGS.apiBaseUrl + 'api/v1/administration/content/articles/:id/tags/:tag_id/visibility',
				params: {id: '@id', tag_id: '@tag_id'},
				method: 'PUT'
			}
		});

		function processResponseCollection(response, owner) {
			if (response.success) {
				response.items = response.items.map(function (item) {
					var model = Article.create(item);
					if (owner) model.setOwner(owner.type, owner.id);
					return model;
				});
			}
		}

		return {
			setVisibility: function (id, visibility) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'setVisibility',
					parameters: {id: id, visibility: visibility}
				});
			},

			setTagVisibility: function (article_id, tag_id, visibility) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'setTagVisibility',
					parameters: {
						id: article_id,
						tag_id: tag_id,
						visibility: visibility
					}
				});
			},

			findAll: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: ArticlesSearchManageParams.process(params)
				});
				promise.then(processResponseCollection);
				return promise;
			}
		};
	}]);
})(angular, NgProject);
