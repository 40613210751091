(function (ng, app) {
	'use strict';
	app.service('AudiosService', ["$resource", "serviceHelper", "SETTINGS", "AudiosSearchParams", "Audio", function ($resource, serviceHelper, SETTINGS, AudiosSearchParams, Audio) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/audios/:audioId', {audioId: '@audioId'}, {
			allByCurrentUser: {url: SETTINGS.apiBaseUrl + 'api/v1/profile/audios'},
			allByUser: {url: SETTINGS.apiBaseUrl + 'api/v1/users/:userId/audios'},
			allByGroup: {url: SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/audios'},
			allByTerm: {url: SETTINGS.apiBaseUrl + 'api/v1/terms/:termId/audios'},
			allTransmitted: {url: SETTINGS.apiBaseUrl + 'api/v1/transmitted/audios'},
			download: {url: SETTINGS.apiBaseUrl + 'api/v1/audios/:audioId/download'}
		});

		var resourceBookmarks = $resource(SETTINGS.apiBaseUrl + 'api/v1/profile/audios/bookmarks/:audioId', {audioId: '@audioId'});

		var resourceSubscribes = $resource(SETTINGS.apiBaseUrl + 'api/v1/profile/audios/subscribes/:audioId', {audioId: '@audioId'});

		function processResponseCollection(response, owner) {
			if (response.success) {
				response.items = response.items.map(function (item) {
					var model = Audio.create(item);
					if (owner) model.setOwner(owner.type, owner.id);
					return model;
				});
			}
		}

		return {
			get: function (id) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: {audioId: id}
				});
				promise.then(function (response) {
					if (response.success) response.audio = Audio.create(response.audio);
				});
				return promise;
			},

			create: function (data) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'save',
					parameters: {audio: data}
				});
			},

			update: function (id, data) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'update',
					parameters: {audioId: id, audio: data}
				});
			},

			findAll: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: AudiosSearchParams.process(params)
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllByCurrentUser: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByCurrentUser',
					parameters: AudiosSearchParams.process(params)
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllByUser: function (userId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByUser',
					parameters: angular.extend(AudiosSearchParams.process(params), {userId: userId})
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllByGroup: function (groupId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByGroup',
					parameters: angular.extend(AudiosSearchParams.process(params), {groupId: groupId})
				});
				promise.then(function (response) {
					processResponseCollection(response, {type: 'group', id: groupId});
				});
				return promise;
			},

			findAllByTerm: function (termId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByTerm',
					parameters: angular.extend(AudiosSearchParams.process(params), {termId: termId})
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllSubscribesByCurrentUser: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allSubscribesByCurrentUser',
					parameters: AudiosSearchParams.process(params)
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllTransmitted: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allTransmitted',
					parameters: AudiosSearchParams.process(params)
				});
				promise.then(function (response) {
					response.items = _.map(response.transmissions, function (item) {
						return item.material;
					});
					processResponseCollection(response);
				});
				return promise;
			},

			getUploadUrl: function () {
				return SETTINGS.apiBaseUrl + 'api/v1/audios/upload';
			},

			download: function (audioId) {
				return SETTINGS.apiBaseUrl + 'download/audios/' + audioId;
			},

			bookmarks: {
				all: function (params) {
					var promise = serviceHelper.executeRequest({
						resource: resourceBookmarks,
						name: 'get',
						parameters: AudiosSearchParams.process(params)
					});
					promise.then(processResponseCollection);
					return (promise);
				},
				accept: function (audioId) {
					var promise = serviceHelper.executeRequest({
						resource: resourceBookmarks,
						name: 'update',
						parameters: {audio_id: audioId}
					});
					return (promise);
				}
			},

			subscribes: {
				all: function (params) {
					var promise = serviceHelper.executeRequest({
						resource: resourceSubscribes,
						name: 'get',
						parameters: AudiosSearchParams.process(params)
					});
					promise.then(processResponseCollection);
					return promise;
				},
				reject: function (audioId) {
					var promise = serviceHelper.executeRequest({
						resource: resourceSubscribes,
						name: 'remove',
						parameters: {audioId: audioId}
					});
					return (promise);
				},
				rejectAll: function () {
					var promise = serviceHelper.executeRequest({
						resource: resourceSubscribes,
						name: 'remove'
					});
					return (promise);
				}
			}
		};
	}]);

})(angular, NgProject);
