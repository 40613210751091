(function () {
	'use strict';
	angular.module('project.Redactor').directive('redactor', ["$parse", function ($parse) {
		function link(scope, element, attrs, ngModel) {
			var options = {
				callbacks: {
					change: function (value) {
						scope.$$postDigest(function() {
							scope.$apply(function () {
								ngModel.$setViewValue(value);
							});
						});
					}
				},
				data: {
					$scope: scope
				}
			};

			var additionalOptions = attrs.redactor ? scope.$eval(attrs.redactor) : {};

			if (attrs['redactorPreset']) {
				angular.merge(additionalOptions, getPresetOptions(attrs['redactorPreset']));
			}

			if (attrs['redactorData']) {
				angular.extend(options.data, $parse(attrs['redactorData'])(scope))
			}

			angular.merge(options, additionalOptions);

			ngModel.$render = function () {
				element.val(ngModel.$viewValue);
				element.redactor(options);
			};
		}

		function getPresetOptions(preset) {
			preset = _.trim(preset);
			if (_.isEmpty(preset)) return null;
			if (!_.has($.Redactor.settings.presets, preset)) return null;

			return $.Redactor.settings.presets[preset];
		}

		return {
			restrict: 'A',
			require: 'ngModel',
			link: link
		}
	}]);
})();



