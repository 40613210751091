NgProject.controller('PageManagePopularArticlesCtrl', ["$scope", "ArticlesPopularManageService", function($scope, ArticlesPopularManageService){

    initController();

    function initController() {
        $scope.refreshPeriod = 0;
        $scope.saved = false;
        $scope.error = {
            exists: false,
            message: ''
        }
        $scope.refreshPeriod = null;

        ArticlesPopularManageService.getRefreshPeriod().then(function(response){
            if(response.success){
                $scope.refreshPeriod = response.number_of_days;
            }
        })
    }

    $scope.savePeriod = function () {
        var refreshPeriod = $scope.refreshPeriod;
        var isValid = isValidPeriod(refreshPeriod).result;
        var message = isValidPeriod(refreshPeriod).message;

        if(isValid) {
            $scope.saved = true;
            ArticlesPopularManageService.saveRefreshPeriod(refreshPeriod);
        } else {
            $scope.error = { exists: true, message: message}
        }
    }

    function isValidPeriod(period) {
        if(period < 0) return { result: false, message: '*Период должен быть меньше 1'};
        if(typeof(period) === 'string') return { result: false , message: '*Период должен быть числом'};
        if(period === null) return { result: false, message: '*Период не может быть пустым'};

        return { result: true, message: "Ok"};
    }

    $scope.change = function () {
        $scope.saved = false;
        $scope.error = { exists: false, message: ''}
    }

}]);