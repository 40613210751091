(function (ng, app) {
	'use strict';
	app.service('NewsService', ["$resource", "serviceHelper", "SETTINGS", function ($resource, serviceHelper, SETTINGS) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/wall/:command', {command: '@command'}, {
			all_news: {params: {command: 'all_news'}},
			my_news: {params: {command: 'my_news'}}
		});

		var resourceComments = $resource(SETTINGS.apiBaseUrl + 'api/v1/wall/:postId/comments/:commentId?parent_type=:postType', {
			commentId: '@commentId',
			postId: '@postId',
			postType: '@postType'
		});

		return {
			allNews: function (params) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'all_news',
					parameters: params
				});
			},

			myNews: function (params) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'my_news',
					parameters: params
				});
			},

			create: function (message) {
				var data = {
					blog_post: {message: message}
				};
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'save',
					parameters: data
				});
			},

			remove: function (postId) {
				var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/wall/:postId', {postId: '@postId'});
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'remove',
					parameters: {postId: postId}
				});
			},

			comments: {
				list: function (postId, postType) {
					return serviceHelper.executeRequest({
						resource: resourceComments,
						name: 'get',
						parameters: {postType: postType, postId: postId}
					});
				},
				create: function (postId, message, postType) {
					var data = {
						postType: postType,
						postId: postId,
						comment: {message: message}
					};
					return serviceHelper.executeRequest({
						resource: resourceComments,
						name: 'save',
						parameters: data
					});
				},
				remove: function(postId, commentId, postType) {
					return serviceHelper.executeRequest({
						resource: resourceComments,
						name: 'remove',
						parameters: {postType: postType, postId: postId, commentId: commentId}
					});
				}
			}
		};
	}]);
})(angular, NgProject);
