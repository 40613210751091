NgProject.directive('postMessage', function () {
	'use strict';
	return {
		restrict: 'E',
		link: link,
		templateUrl: 'widgets.post.post-message',
		scope: {
			post: '='
		}
	};

	function link(scope) {
		var lengthMessage = 40;
		var data = _division(scope.post.message, lengthMessage);
		scope.message = {
			show: data.length === 2,
			data: data
		};

		scope.controls = {
			showMore: function () {
				scope.message.show = !scope.message.show;
			}
		};

		function _division(string, length) {
			var subStr = [];
			length = length || lengthMessage;
			string = string.split(' ');
			if (length >= string.length || Math.abs(length - string.length) < 5) return [string.join(' ')];

			for (var i = 0; i < length; i++) subStr.push(string[i]);
			var item = subStr[subStr.length - 1];
			subStr[subStr.length - 1] = /\.$/.test(item) ? item + '..' : item + '...';
			return [string.join(' '), subStr.join(' ')];
		}
	}
});
