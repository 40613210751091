NgProject.directive('eventsFiltersCalendar', ["moment", "DateHelper", "Deferred", function (moment, DateHelper, Deferred) {
	'use strict';
	var modelsCount = 3;

	/************************************************
	 * Get Start Date Function
	 */
	function getStartDate() {
		var subtractMonths = Math.floor(modelsCount / 2);
		return moment().subtract(subtractMonths, 'months').startOf('month').valueOf();
	}

	/************************************************
	 * Get Models Function
	 */
	function getModels(startTime) {
		var values = [];
		var date = moment(startTime).startOf('day');
		for (var i = 0; i < modelsCount; i++) {
			values.push({id: i, value: date.valueOf()});
			date.add(1, 'months');
		}
		return values;
	}

	/************************************************
	 * Tick Models
	 */
	function tickModels(models) {
		_.map(models, function (model) {
			var value = _.isDate(model.value) ? model.value.getTime() : model.value;
			value += value % 10 ? -1 : 1;
			model.value = value;
		});
	}

	function getStartOfDayTimestamsWithoutOffset(time) {
		var date = moment(time).startOf('day');
		// заменил subtract на add - потому что не правильно вычислялось
		return date.add(date.utcOffset(), 'minutes').valueOf();
	}

	/************************************************
	 * Link Function
	 */
	function link(scope, element, attrs, eventsCtrl) {
		var selected = null;
		var selectedTime = null;
		var eventsDates = [];
		var eventsDatesIsReady = false;
		var onChangeSearchParams;
		var promiseFn = eventsCtrl.getPromiseFn();
		var methods = {
			init: function (startTime, notBuildModels) {
				eventsDates = [];
				eventsDatesIsReady = false;
				if (!notBuildModels) {
					scope.dtPicker.models = getModels(startTime);
				}
				this.loadRemoteData(startTime, function (dates) {
					eventsDates = dates;
					eventsDatesIsReady = true;
					tickModels(scope.dtPicker.models);
				});
			},

			loadRemoteData: function (startTime, loadCallback) {
				var fromTime = moment(startTime).startOf('month');
				var toTime = fromTime.clone().add(modelsCount - 1, 'months').endOf('month');
				var params = angular.extend({}, eventsCtrl.getSearchParams(), {
					limit: 1000,
					between: [fromTime.valueOf(), toTime.valueOf()],
					page: 1,
					full_list: true,
					younger_than: undefined,
					older_than: undefined
				});

				Deferred.handlePromise(promiseFn(scope, {params: params}), function (response) {
					var dates = [];
					if (response.success) {
						_.forEach(response.items, function (event) {
							dates.push(getStartOfDayTimestamsWithoutOffset(event.added));
						});
						dates = _.uniq(dates);
					}
					loadCallback(dates);
				});
			},

			dtpBeforeRender: function (model, $view, $dates) {
				var activeDateValue = selectedTime ? selectedTime : getStartOfDayTimestamsWithoutOffset();
				$dates.map(function (date) {
					if (date.past || date.future) {
						date.selectable = false;
						date.active = false;
					}
					else {
						date.active = date.utcDateValue === activeDateValue;
						date.selectable = eventsDatesIsReady && eventsDates.indexOf(date.utcDateValue) !== -1;
						if (date.active) selected = date;
					}
				});
			},

			dtpOnSetTime: function (date) {
				if (selected) {
					selected.active = false;
					selected = null;
				}
				selectedTime = DateHelper.getUTCTime(date);
				applyFilter(date);
			},

			slideLeft: function () {
				var model = _.first(scope.dtPicker.models);
				var startDate = moment(model.value).subtract(3, 'months');
				methods.init(startDate.valueOf());
			},

			slideRight: function () {
				var model = _.last(scope.dtPicker.models);
				var startDate = moment(model.value).add(1, 'months');
				methods.init(startDate.valueOf());
			}
		};

		function getRequestParams(date) {
			var fromTime = moment(date).startOf('day');
			return {
				younger_than: fromTime.valueOf(),
				younger_older_correction: 0
			};
		}

		function applyFilter(date) {
			disableOnChangeSearchParams();
			eventsCtrl.search(getRequestParams(date));
			enableOnChangeSearchParams();
		}

		function enableOnChangeSearchParams() {
			onChangeSearchParams = scope.$on('$onChangeSearchParams', function () {
				methods.init(_.first(scope.dtPicker.models).value, true);
			});
		}

		function disableOnChangeSearchParams() {
			if (onChangeSearchParams) onChangeSearchParams();
		}

		scope.dtPicker = {
			models: [],
			options: {
				startView: 'day',
				minView: 'day'
			},
			beforeRender: methods.dtpBeforeRender,
			onSetTime: methods.dtpOnSetTime,
			left: methods.slideLeft,
			right: methods.slideRight
		};

		methods.init(getStartDate());
		enableOnChangeSearchParams();
	}

	return {
		require: '^^events',
		templateUrl: 'widgets.events.events-filters-calendar',
		restrict: 'E',
		scope: true,
		link: link
	};
}]);
