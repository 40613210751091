(function (ng, app) {
	'use strict';
	var types = {user: 'U', group: 'G'};
	app.filter('ownerSignature', function () {
		return function (type, id) {
			var prefix = types[type.toLowerCase()];
			if (!prefix) return null;
			return prefix + id.toString();
		};
	});
})(angular, NgProject);
