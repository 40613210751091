NgProject.directive('inputCountry', function () {
	'use strict';

	controller.$inject = ["$scope", "$filter", "LocationService"];
	function controller($scope, $filter, LocationService) {
		$scope.$data = {
			model: [],
			items: []
		};

		if (!_.isEmpty($scope.model)) {
			$scope.$data.model.push($scope.model);
		}

		$scope.$controls = {
			query: null,

			search: function () {
				var query = _.trim($scope.$controls.query);
				if (query.length)
					return $filter('filter')($scope.$data.items, {name: query});
				return $scope.$data.items;
			},

			isSelected: function () {
				return Boolean($scope.$data.model.length);
			},

			isDisabled: function () {
				return !$scope.$data.items.length;
			}
		};

		function loadItems() {
			LocationService.countriesList().then(function (response) {
				if (response.success) $scope.$data.items = response.items;
			});
		}

		loadItems();
	}

	function link(scope, element, attrs, ngModel) {
		scope.$watchCollection('$data.model', function (selected) {
			ngModel.$setViewValue(_.get(selected, '[0]', null));
		});
	}

	return {
		require: 'ngModel',
		scope: {
			model: '=ngModel'
		},
		restrict: 'E',
		templateUrl: 'widgets.input-country.template',
		link: link,
		controller: controller
	};
});
