NgProject.filter('dateTimeFilter', ["$filter", "moment", function ($filter, moment) {
	'use strict';
	return function (value, format) {
		if (_.isUndefined(value)) {
			return null;
		}

		if (_.isNumber(value) && value < 1000000000000) {
			value = value * 1000;
		}

		var date = new Date(value);
		var showYear = date.getYear() !== (new Date()).getYear();
		var today = moment().diff(value, 'day');

		format = format || 'short';
		switch (format) {
			case 'short':
				format = showYear ? 'd MMM yyyy' : 'd MMM';
				break;
			case 'medium':
				format = showYear ? 'd MMM yyyy H:mm' : 'd MMM H:mm';
				break;
			case 'chat':
				format = today ? 'HH:mm dd.MM.yy':'HH:mm';
				break;
			case 'chats':
				format = today ? 'dd.MM.yy':'HH:mm';
		}

		return $filter('date')(date, format);
	};
}]);
