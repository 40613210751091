'use strict';
(function () {
	if (typeof DEBUG === 'undefined') window.DEBUG = true;
})();

var NgProject = angular.module('project', [
	'project.Constants',
	// 'project.ErrorCatcher',
	'project.ResizeEnd',
	'project.PointerEvents',
	'project.Templates',
	'project.Analytics',
	'project.Redactor',
	'ngAnimate',
	'ngResource',
	'ngSanitize',
	'ngMessages',
	'ngCookies',
	'ngAria',
	'ngMaterial',
	'ngStorage',
	'ui.router',
	'ui.select',
	'infinite-scroll',
	'mgcrea.ngStrap.modal',
	'mgcrea.ngStrap.popover',
	'angularMoment',
	'monospaced.elastic',
	'youtube-embed',
	'ui.bootstrap.datetimepicker',
	'ngSocial',
	'angularFileUpload',
	'cfp.loadingBar',
	'angular-sortable-view'
]);

/**
 *
 * **************** Bootstrap Application *****************
 *
 **/

angular.element(document).ready(function () {
	angular.bootstrap(document, ['project'], {
		strictDi: !DEBUG
	});
});


/**
 *
 * **************** Application Config Section *****************
 *
 **/

NgProject.config(["$compileProvider", "$provide", "$httpProvider", "$locationProvider", "$stateProvider", "$urlRouterProvider", "$urlMatcherFactoryProvider", "moment", "uiSelectConfig", "modalWindowProvider", "YoutubeApiProvider", "SETTINGS", "$animateProvider", "$resourceProvider", "ModalStateProvider", "$mdInkRippleProvider", "$mdThemingProvider", function ($compileProvider, $provide, $httpProvider, $locationProvider, $stateProvider, $urlRouterProvider,
									$urlMatcherFactoryProvider, moment, uiSelectConfig, modalWindowProvider, YoutubeApiProvider, SETTINGS,
									$animateProvider, $resourceProvider, ModalStateProvider,
									$mdInkRippleProvider, $mdThemingProvider) {
	var routes = {};
	routes.states = {
		/**
		 * **************** Base Layouts *****************
		 **/

		/** Root Layout */
		'root': {
			abstract: true,
			data: {bodyClass: 'body-l-root'},
			views: {
				'': {
					templateUrl: 'layouts.root'
				},
				'navigation@': {
					templateUrl: 'layouts.blocks.navigation',
					controller: 'BlockTopNavigationCtrl'
				},
				'userbar@': {
					templateUrl: 'layouts.blocks.userbar'
				}
			}
		},
		'root.error': {
			url: '/error',
			template: '<div page-error></div>',
			params: {
				type: null,
				location: null
			}
		},

		/** System Layout */
		'system': {
			abstract: true,
			templateUrl: 'layouts.system',
			controller: 'SystemLayoutCtrl',
			data: {bodyClass: 'body-l-system'}
		},
		'system.signup-confirm': {
			url: '/signup-confirm/:token',
			templateUrl: 'pages.system.signup-confirm',
			controller: 'PageSignUpConfirmationCtrl'
		},
		'system.reset-password': {
			url: '/reset-password/:token',
			templateUrl: 'pages.system.change-password',
			controller: 'PageChangePasswordCtrl'
		},
		'system.login': {
			url: '/login-with-new-password',
			templateUrl: 'pages.system.login.system-login',
			controller: 'ModalLoginCtrl',
			params: {newPasswordLayout: false}
		},
		'system.change-email-confirm': {
			url: '/change-email/:token',
			templateUrl: 'pages.system.change-email-confirmation',
			controller: 'PageChangeEmailConfirmationCtrl'
		},
		'system.delete-my-account': {
			url: '/delete-my-account?:token',
			templateUrl: 'pages.system.delete-account-confirmation',
			controller: 'PageDeleteAccountConfirmationCtrl'
		},

		/**
		 * **************** Section Home Page *****************
		 **/

		'root.home': {
			url: '/',
			templateUrl: 'pages.home.home',
			controller: 'PageHomeCtrl',
			// data: {bodyClass: 'body-l-root state-homepage'}
		},

		'root.news': {
			url: '/news?page',
			templateUrl: 'pages.news.feed',
			controller: 'NewsListCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},


		'root.manage': {
			url: '/manage',
			templateUrl: 'pages.manage.layout',
			controller: 'PageManageCtrl',
			defaultSubstate: 'articles',
			data: {
				access: {
					authorization: true
				}
			}
		},
		'root.manage.articles': {
			url: '/articles?moderation_status&visibility',
			templateUrl: 'pages.manage.articles.articles',
			controller: 'PageManageArticlesCtrl'
		},
		'root.manage.popular': {
			url: '/popular',
			templateUrl: 'pages.manage.articles.popular',
			controller: 'PageManagePopularArticlesCtrl'
		},
		'root.manage.images': {
			url: '/images?moderation_status&visibility',
			templateUrl: 'pages.manage.images.images',
			controller: 'PageManageImagesCtrl'
		},
		'root.manage.videos': {
			url: '/videos?moderation_status&visibility',
			templateUrl: 'pages.manage.videos.videos',
			controller: 'PageManageVideosCtrl'
		},
		'root.manage.audios': {
			url: '/audios?moderation_status&visibility',
			templateUrl: 'pages.manage.audios.audios',
			controller: 'PageManageAudiosCtrl'
		},
		'root.manage.tags': {
			url: '/tags',
			templateUrl: 'pages.manage.tags.layout',
			defaultSubstate: 'articles'
		},
		'root.manage.tags.articles': {
			url: '/articles?tag_status',
			templateUrl: 'pages.manage.tags.articles',
			controller: 'PageManageTagsArticlesCtrl'
		},
		'root.manage.tags.questions': {
			url: '/questions?tag_status',
			templateUrl: 'pages.manage.tags.questions',
			controller: 'PageManageTagsQuestionsCtrl'
		},
		'root.manage.tags.images': {
			url: '/images?tag_status',
			templateUrl: 'pages.manage.tags.images',
			controller: 'PageManageTagsImagesCtrl'
		},
		'root.manage.tags.videos': {
			url: '/videos?tag_status',
			templateUrl: 'pages.manage.tags.videos',
			controller: 'PageManageTagsVideosCtrl'
		},
		'root.manage.tags.audios': {
			url: '/audios?tag_status',
			templateUrl: 'pages.manage.tags.audios',
			controller: 'PageManageTagsAudiosCtrl'
		},

		/**
		 * **************** Section IM *****************
		 **/

		'root.im': {
			url: '/im',
			templateUrl: 'pages.im.layout',
			controller: 'imPageCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},
		'root.im.users': {
			url: '/users',
			modal: {
				templateUrl: 'pages.im.modal.users-list',
				controller: 'imUsersListModalCtrl'
			}
		},
		'root.im.chat': {
			url: '/:chatId',
			templateUrl: 'pages.im.chat',
			controller: 'imChatCtrl'
		},


		/**
		 * **************** Section Profile *****************
		 **/

		'root.user': {
			abstract: true,
			url: '/user{userId:int}',
			templateUrl: 'pages.profile.layout',
			controller: 'PageProfileCtrl',
			defaultSubstate: 'main'
		},
		'root.user.main': {
			url: '',
			templateUrl: 'pages.profile.main'
		},
		'root.user.groups': {url: '/groups', templateUrl: 'pages.profile.groups'},
		'root.user.events': {url: '/events', templateUrl: 'pages.profile.events'},
		'root.user.articles': {url: '/articles', templateUrl: 'pages.profile.articles'},
		'root.user.materials': {
			url: '/materials',
			templateUrl: 'pages.profile.materials',
			controller: 'ProfileMaterialsCtrl',
			defaultSubstate: 'videos'
		},
		'root.user.materials.videos': {
			url: '/videos',
			templateUrl: 'pages.materials.blocks.videos',
			data: {section: 'videos'}
		},
		'root.user.materials.audios': {
			url: '/audios',
			templateUrl: 'pages.materials.blocks.audios',
			data: {section: 'audios'}
		},
		'root.user.materials.images': {
			similar: 'images_parent',
			extend: {
				data: {owner: 'user'}
			}
		},
		'root.user.materials.images.all': {similar: 'images_summary'},
		'root.user.materials.images.album': {similar: 'images_album'},
		'root.user.materials.images-album-update': {
			similar: 'images_album_update',
			extend: {
				data: {owner: 'user'}
			}
		},

		/**
		 * ************* User Settings For Admin ***************
		 **/

		'root.user-settings': {
			url: '/user{userId:int}/settings',
			templateUrl: 'pages.profile.settings.layout',
			controller: 'PageSettingsCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},
		'root.user-settings.profile': {
			url: '/profile',
			templateUrl: 'pages.profile.settings.profile',
			controller: 'PageSettingsProfileCtrl'
		},
		'root.user-settings.global': {
			url: '/global',
			templateUrl: 'pages.profile.settings.global',
			controller: 'PageSettingsGlobalCtrl'
		},

		/**
		 * **************** User Settings *****************
		 **/

		'root.settings': {
			url: '/settings',
			templateUrl: 'pages.profile.settings.layout',
			controller: 'PageSettingsCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},
		'root.settings.profile': {
			url: '^/profile/edit',
			templateUrl: 'pages.profile.settings.profile',
			controller: 'PageSettingsProfileCtrl'
		},
		'root.settings.global': {
			templateUrl: 'pages.profile.settings.global',
			controller: 'PageSettingsGlobalCtrl'
		},
		'root.settings.global.notifications': {
			url: '/notifications',
			templateUrl: 'pages.profile.settings.global.notifications',
			controller: 'PageSettingsNotificationsCtrl'
		},
		'root.settings.global.email': {
			url: '/email',
			templateUrl: 'pages.profile.settings.global.email',
			controller: 'PageSettingsEmailCtrl'
		},
		'root.settings.global.password': {
			url: '/password',
			templateUrl: 'pages.profile.settings.global.password',
			controller: 'PageSettingsPasswordCtrl'
		},
		'root.settings.global.remove-account': {
			url: '/remove-account',
			templateUrl: 'pages.profile.settings.global.remove-account',
			controller: 'PageSettingsRemoveAccountCtrl'
		},
		'root.settings.global.events-rooms': {
			url: '/events-rooms',
			templateUrl: 'pages.profile.settings.global.events-rooms',
			controller: 'PageSettingsEventsRoomsCtrl'
		},
		'root.settings.subscribes': {
			url: '/subscribes',
			templateUrl: 'pages.profile.settings.subscribes',
			controller: 'PageSettingsSubscribesCtrl'
		},
		'root.settings.subscribes.users': {url: '/users', templateUrl: 'pages.profile.settings.subscribes.users'},
		'root.settings.subscribes.groups': {url: '/groups', templateUrl: 'pages.profile.settings.subscribes.groups'},


		/**
		 * **************** Section Wiki *****************
		 **/

		'root.wiki': {
			url: '/wiki',
			templateUrl: 'pages.wiki.layout',
			abstract: true,
			defaultSubstate: 'view'
		},
		'root.wiki.view': {
			controller: 'WikiViewCtrl',
			templateUrl: 'pages.wiki.view'
		},
		'root.wiki.view.term': {
			url: '/:termId',
			templateUrl: 'pages.wiki.view.term',
			controller: 'WikiViewTermCtrl',
			defaultSubstate: 'info'
		},
		'root.wiki.view.term.info': {
			templateUrl: 'pages.wiki.view.term-view'
		},
		'root.wiki.view.term.articles': {
			url: '/articles',
			templateUrl: 'pages.wiki.view.term-articles',
			controller: 'WikiArticlesListCtrl'
		},
		'root.wiki.view.term.faq': {
			url: '/faq',
			templateUrl: 'pages.wiki.view.term-faq',
			controller: 'WikiFAQListCtrl'
		},
		'root.wiki.view.term.video': {url: '/video', templateUrl: 'pages.wiki.view.term-videos'},
		'root.wiki.view.term.audio': {url: '/audio', templateUrl: 'pages.wiki.view.term-audios'},
		'root.wiki.view.term.images': {url: '/images', templateUrl: 'pages.wiki.view.term-images'},

		'root.wiki.term-create': {
			url: '/create/term',
			templateUrl: 'pages.wiki.form.term',
			controller: 'WikiTermFormCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},

		'root.wiki.term-update': {
			url: '/{termId}/update',
			templateUrl: 'pages.wiki.form.term',
			controller: 'WikiTermFormCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},

		'root.wiki.definition-create': {
			url: '/{termId}/create/definition',
			templateUrl: 'pages.wiki.form.definition',
			controller: 'WikiDefinitionFormCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},

		'root.wiki.definition-update': {
			url: '/{termId}/definitions/{definitionId:[0-9]{1,8}}/update',
			templateUrl: 'pages.wiki.form.definition',
			controller: 'WikiDefinitionFormCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},

		/**
		 * **************** Section Groups *****************
		 **/

		'root.groups': {
			url: '/groups',
			templateUrl: 'pages.groups.lists.layout',
			controller: 'PageGroupsCtrl',
			defaultSubstate: 'common'
		},
		'root.groups.common': {
			url: '',
			templateUrl: 'pages.groups.lists.common',
			controller: 'GroupsListCommonCtrl'
		},
		'root.groups.private': {
			url: '/my',
			templateUrl: 'pages.groups.lists.private',
			controller: 'GroupsListPrivateCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},
		/** Group View */
		'root.group': {
			abstract: true,
			url: '/group{groupId:int}',
			templateUrl: 'pages.groups.view.layout',
			controller: 'PageGroupCtrl',
			defaultSubstate: 'main'
		},
		'root.group.main': {
			url: '',
			templateUrl: 'pages.groups.view.main'
		},
		'root.group.events': {
			url: '/events',
			templateUrl: 'pages.groups.view.events',
			controller: 'GroupEventsListCtrl'
		},
		'root.group.articles': {
			url: '/articles',
			templateUrl: 'pages.groups.view.articles',
			controller: 'GroupArticlesListCtrl'
		},
		'root.group.materials': {
			url: '/materials',
			templateUrl: 'pages.groups.view.materials',
			controller: 'GroupMaterialsCtrl',
			data: {section: null},
			defaultSubstate: 'videos'
		},
		'root.group.materials.videos': {
			url: '/videos',
			templateUrl: 'pages.materials.blocks.videos',
			data: {section: 'videos'}
		},
		'root.group.materials.audios': {
			url: '/audios',
			templateUrl: 'pages.materials.blocks.audios',
			data: {section: 'audios'}
		},
		'root.group.materials.images': {
			similar: 'images_parent',
			extend: {
				data: {owner: 'group'}
			}
		},
		'root.group.materials.images.all': {similar: 'images_summary'},
		'root.group.materials.images.album': {similar: 'images_album'},
		'root.group.materials.images-album-update': {
			similar: 'images_album_update',
			extend: {
				data: {owner: 'group'}
			}
		},


		'root.group.members': {
			url: '/members',
			modal: {
				templateUrl: 'pages.groups.modal.members',
				controller: 'GroupMembersListModalCtrl'
			}
		},

		/** Group Settings */
		'root.group-manager': {
			abstract: true,
			url: '/group',
			controller: 'GroupSettingsCtrl',
			template: '<ui-view ng-if="$controls.isReady"></ui-view>',
			data: {
				access: {
					authorization: true
				}
			}
		},
		'root.group-manager.create': {
			url: '/new',
			templateUrl: 'pages.groups.forms.profile',
			controller: 'GroupSettingsProfileCtrl'
		},
		'root.group-manager.update': {
			abstract: true,
			url: '{groupId:int}',
			templateUrl: 'pages.groups.forms.layout'
		},
		'root.group-manager.update.profile': {
			url: '/update',
			templateUrl: 'pages.groups.forms.profile',
			controller: 'GroupSettingsProfileCtrl'
		},
		'root.group-manager.update.global': {
			url: '/settings',
			templateUrl: 'pages.groups.forms.global',
			defaultSubstate: 'type'
		},
		'root.group-manager.update.global.type': {
			url: '/type',
			templateUrl: 'pages.groups.forms.global.type',
			controller: 'GroupSettingsTypeCtrl'
		},
		'root.group-manager.update.global.events-rooms': {
			url: '/events-rooms',
			templateUrl: 'pages.groups.forms.global.events-rooms',
			controller: 'GroupSettingsEventsRoomsCtrl'
		},
		'root.group-manager.update.global.remove': {
			url: '/remove',
			templateUrl: 'pages.groups.forms.global.remove-group',
			controller: 'GroupSettingsRemoveCtrl'
		},
		'root.group-manager.update.global.admins': {
			url: '/admins',
			templateUrl: 'pages.groups.forms.global.managers',
			controller: 'GroupSettingsManagersCtrl'
		},


		/**
		 * **************** Section Events *****************
		 **/

		'root.events': {
			url: '/events',
			templateUrl: 'pages.events.lists.layout',
			controller: 'PageEventsCtrl',
			defaultSubstate: 'common'
		},
		'root.events.common': {
			url: '',
			templateUrl: 'pages.events.lists.common',
			controller: 'EventsListCommonCtrl'
		},
		'root.events.private': {
			url: '/my',
			templateUrl: 'pages.events.lists.private',
			controller: 'EventsListPrivateCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},

		/** Event View */
		'root.event': {
			url: '/event{eventId:int}',
			templateUrl: 'pages.events.view.main',
			controller: 'PageEventCtrl'
		},
		'root.event.registration-confirm': {
			url: '/confirm/:token'
		},
		'root.event.registration': {
			url: '/registration',
			modal: {
				templateUrl: 'modal.event-registration',
				controller: 'modal:event-registration.ctrl'
			}
		},
		'root.event.questions': {
			url: '/questions',
			modal: {
				templateUrl: 'pages.events.modal.questions',
				controller: 'ModalEventQuestionsCtrl'
			}
		},
		'root.event.members': {
			url: '/members',
			modal: {
				templateUrl: 'pages.events.modal.members',
				controller: 'ModalEventMembersCtrl'
			}
		},


		/** Event Create */
		'root.create-event': {
			url: '/event/create?maker_type&maker_id',
			templateUrl: 'pages.events.forms.form',
			controller: 'PageEventFormCtrl',
			params: {
				maker_type: null,
				maker_id: null
			},
			data: {
				access: {
					authorization: true
				}
			}
		},

		/** Event Update */
		'root.event-update': {
			url: '/event{eventId:int}/update',
			templateUrl: 'pages.events.forms.form',
			controller: 'PageEventFormCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},

		/**
		 * **************** Section Articles *****************
		 **/

		'root.articles': {
			templateUrl: 'pages.articles.lists.layout',
			controller: 'PageArticlesCtrl',
			url: '/articles',
			defaultSubstate: 'common'
		},
		'root.articles.common': {
			url: '',
			templateUrl: 'pages.articles.lists.common',
			controller: 'ArticlesListCommonCtrl'
		},
		'root.articles.private': {
			url: '/own',
			templateUrl: 'pages.articles.lists.private',
			controller: 'ArticlesListPrivateCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},
		'root.articles.reading': {
			url: '/reading',
			templateUrl: 'pages.articles.lists.reading',
			controller: 'ArticlesListReadingCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},
		'root.articles.bookmarks': {
			url: '/bookmarks',
			templateUrl: 'pages.articles.lists.bookmarks',
			controller: 'ArticlesListBookmarksCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},

		/** Article Create */
		'root.create-article': {
			url: '/create/article?maker_type&maker_id',
			templateUrl: 'pages.articles.form.layout',
			controller: 'PageArticleFormLayoutCtrl',
			params: {
				maker_type: null,
				maker_id: null
			},
			data: {
				access: {
					authorization: true
				}
			}
		},

		/** Article Update */
		'root.article-update': {
			url: '/article{articleId:int}/update',
			templateUrl: 'pages.articles.form.layout',
			controller: 'PageArticleFormLayoutCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},


		/**
		 * **************** Section Users *****************
		 **/

		'root.users': {
			url: '/users',
			templateUrl: 'pages.users.lists.layout',
			controller: 'PageUsersCtrl',
			data: {
				access: {
					authorization: true
				}
			}
		},
		'root.users.common': {
			url: '/all',
			templateUrl: 'pages.users.lists.list'
		},
		'root.users.followers': {
			url: '/followers',
			templateUrl: 'pages.users.lists.list'
		},
		'root.users.follows': {
			url: '/follows',
			templateUrl: 'pages.users.lists.list'
		},


		/**
		 * **************** Section Search *****************
		 **/

		'root.search': {
			url: '/search?query',
			templateUrl: 'pages.search.layout',
			controller: 'PageSearchCtrl',
			abstract: true
		},
		'root.search.all': {
			url: '',
			templateUrl: 'pages.search.all',
			controller: 'SearchAllCtrl',
			reloadOnSearch: false
		},
		'root.search.users': {
			url: '/users',
			templateUrl: 'pages.search.one-section',
			controller: 'SearchUsersCtrl',
			reloadOnSearch: false
		},
		'root.search.groups': {
			url: '/groups',
			templateUrl: 'pages.search.one-section',
			controller: 'SearchGroupsCtrl',
			reloadOnSearch: false
		},
		'root.search.events': {
			url: '/events',
			templateUrl: 'pages.search.one-section',
			controller: 'SearchEventsCtrl',
			reloadOnSearch: false
		},
		'root.search.articles': {
			url: '/articles',
			templateUrl: 'pages.search.one-section',
			controller: 'SearchArticlesCtrl',
			reloadOnSearch: false
		},
		'root.search.terms': {
			url: '/terms',
			templateUrl: 'pages.search.one-section',
			controller: 'SearchTermsCtrl',
			reloadOnSearch: false
		},
		'root.search.materials': {
			url: '/materials',
			templateUrl: 'pages.search.materials',
			abstract: true
		},
		'root.search.materials.all': {
			url: '',
			templateUrl: 'pages.search.materials-all',
			controller: 'SearchMaterialsCtrl',
			reloadOnSearch: false
		},
		'root.search.materials.audios': {
			url: '/audios',
			templateUrl: 'pages.search.one-section',
			controller: 'SearchMaterialsAudiosCtrl',
			reloadOnSearch: false
		},
		'root.search.materials.images': {
			url: '/images',
			templateUrl: 'pages.search.one-section',
			controller: 'SearchMaterialsImagesCtrl',
			reloadOnSearch: false
		},
		'root.search.materials.videos': {
			url: '/videos',
			templateUrl: 'pages.search.one-section',
			controller: 'SearchMaterialsVideosCtrl',
			reloadOnSearch: false
		},

		/**
		 * **************** Section Materials *****************
		 **/

		'root.materials': {
			url: '/materials',
			templateUrl: 'pages.materials.layout',
			controller: 'PageMaterialsCtrl',
			data: {tab: null, section: null},
			defaultSubstate: 'common.videos'
		},
		'root.materials.common': {
			url: '^/materials',
			templateUrl: 'pages.materials.layout-common',
			data: {tab: 'root.materials.common'},
			defaultSubstate: 'videos'
		},
		'root.materials.private': {
			url: '/own',
			templateUrl: 'pages.materials.layout-private',
			controller: 'MaterialsPrivateCtrl',
			data: {
				tab: 'root.materials.private',
				access: {
					authorization: true
				}
			},
			defaultSubstate: 'videos'
		},
		'root.materials.bookmarks': {
			url: '/bookmarks',
			templateUrl: 'pages.materials.layout-bookmarks',
			controller: 'MaterialsBookmarksCtrl',
			data: {
				tab: 'root.materials.bookmarks',
				access: {
					authorization: true
				}
			},
			defaultSubstate: 'videos'
		},
		'root.materials.common.images': {
			url: '/images',
			templateUrl: 'pages.materials.common.images',
			controller: 'ImagesListCommonCtrl',
			data: {section: 'images'}
		},
		'root.materials.common.videos': {
			url: '/videos',
			templateUrl: 'pages.materials.common.videos',
			controller: 'VideosListCommonCtrl',
			data: {section: 'videos'}
		},
		'root.materials.common.audios': {
			url: '/audios',
			templateUrl: 'pages.materials.common.audios',
			controller: 'AudiosListCommonCtrl',
			data: {section: 'audios'}
		},
		'root.materials.private.videos': {
			url: '/videos',
			templateUrl: 'pages.materials.blocks.videos',
			data: {section: 'videos'}
		},
		'root.materials.private.audios': {
			url: '/audios',
			templateUrl: 'pages.materials.blocks.audios',
			data: {section: 'audios'}
		},
		'root.materials.private.images': {
			similar: 'images_parent',
			extend: {
				data: {owner: 'self'}
			}
		},
		'root.materials.bookmarks.videos': {
			url: '/videos',
			templateUrl: 'pages.materials.blocks.videos',
			data: {section: 'videos'}
		},
		'root.materials.bookmarks.audios': {
			url: '/audios',
			templateUrl: 'pages.materials.blocks.audios',
			data: {section: 'audios'}
		},
		'root.materials.bookmarks.images': {
			url: '/images',
			templateUrl: 'pages.materials.blocks.images',
			data: {section: 'images'}
		},
		'root.materials.private.images.all': {similar: 'images_summary'},
		'root.materials.private.images.album': {similar: 'images_album'},
		'root.materials.private.images-album-update': {
			similar: 'images_album_update',
			extend: {
				data: {owner: 'self'}
			}
		}
	};


	/**
	 * **************** Similar States *****************
	 **/

	routes.similar = {
		images_parent: {
			url: '/images',
			controller: 'ImagesParentCtrl',
			data: {section: 'images', owner: null},
			defaultSubstate: 'all',
			template: '<ui-view></ui-view>'
		},

		images_summary: {
			url: '/all',
			controller: 'ImagesSummaryCtrl',
			templateUrl: 'pages.materials.blocks.albums.summary'
		},

		images_album: {
			url: '/albums/{albumId:int}',
			controller: 'ImagesAlbumCtrl',
			templateUrl: 'pages.materials.blocks.albums.album-view'
		},

		images_album_update: {
			url: '/images/albums/{albumId:int}/update',
			templateUrl: 'pages.materials.blocks.albums.album-form',
			controller: 'PageImagesAlbumForm',
			data: {
				section: 'images',
				owner: null,
				access: {
					authorization: true
				}
			}
		}
	};


	/**
	 * **************** Modals Windows *****************
	 **/

	routes.modals = {
		'modal': {
			abstract: true,
			views: {'modal@': {template: null}}
		},
		'modal.login': {
			url: '/login',
			modal: {
				templateUrl: 'pages.system.login.modal',
				controller: 'ModalLoginCtrl'
			},
			params: {
				returnUrl: null
			}
		},
		'modal.registration': {
			url: '/registration',
			modal: {
				templateUrl: 'modal.registration',
				controller: 'modal:registration.ctrl'
			},
			params: {
				credentials: null
			}
		},
		'modal.reset-password': {
			url: '/reset-password-request',
			modal: {
				templateUrl: 'modal.reset-password',
				controller: 'modal:reset-password.ctrl'
			}
		},
		'modal.article-view': {
			url: '/article{articleId:int}{code:seoUrlCode}',
			modal: {
				name: 'article',
				templateUrl: 'pages.articles.modal.view',
				controller: 'ArticleViewModalCtrl'
			}
		}
	};


	var initialization = {
		run: function () {
			this.$compileProvider();
			this.$locationProvider();
			this.$urlMatcherFactoryProvider();
			this.$animateProvider();
			this.$resourceProvider();
			this.modalStateProvider();
			this.modalWindowProvider();
			this['ui-router']();
			this.$httpProvider();
			this.moment();
			this['ui-select']();
			this['angular-file-uploader']();
			this['youtube-api']();
			this.angularMaterial();
		},

		/**
		 * **************** UI Router *****************
		 **/

		'ui-router': function () {
			$urlRouterProvider.when('/materials', '/materials/videos');
			$urlRouterProvider.when('/my/materials', '/my/materials/videos');
			$urlRouterProvider.when('/my/materials/images', '/my/materials/images/all');
			$urlRouterProvider.when('/user{userId}/materials', '/user{userId}/materials/videos');
			$urlRouterProvider.when('/user{userId}/materials/images', '/user{userId}/materials/images/all');
			$urlRouterProvider.when('/group{groupId}/materials', '/group{groupId}/materials/videos');
			$urlRouterProvider.when('/group{groupId}/materials/images', '/group{groupId}/materials/images/all');
			$urlRouterProvider.when('/wiki', '/wiki/' + SETTINGS.wiki.defaultTermId);
			$urlRouterProvider.when('/manage', '/manage/articles');
			$urlRouterProvider.otherwise('/');
			$urlRouterProvider.deferIntercept();

			// Register states
			angular.forEach(routes.states, function (config, state) {
				if (config.similar) {
					config = angular.merge({}, routes.similar[config.similar], config.extend || {});
				}

				if (config.modal) {
					config.modal.relative = true;
					config = ModalStateProvider.createState(state, config);
				}

				$stateProvider.state(state, config);
			});

			angular.forEach(routes.modals, function (config, state) {
				if (state !== 'modal') {
					config.params = config.params || {};
					config.modal.defaultSubstate = 'root.home';
					config = ModalStateProvider.createState(state, config);
				}
				$stateProvider.state(state, config);
			});
		},


		/**
		 * **************** $compileProvider *****************
		 **/

		'$compileProvider': function () {
			$compileProvider.debugInfoEnabled(DEBUG);
		},


		/**
		 * **************** $httpProvider *****************
		 **/

		'$httpProvider': function () {
			$httpProvider.interceptors.push('httpErrorsInterceptor');
			$httpProvider.defaults.headers.common['Accept'] = 'application/json';
			$httpProvider.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';

			if (DEBUG) {
				//Remove the header used to identify ajax call that would prevent CORS from working
				delete $httpProvider.defaults.headers.common['X-Requested-With'];
				//Enable cross domain calls
				$httpProvider.defaults.useXDomain = true;
				$httpProvider.defaults.withCredentials = true;
			}
		},


		/**
		 * **************** $locationProvider *****************
		 **/

		'$locationProvider': function () {
			// use the HTML5 History API
			$locationProvider.html5Mode(true).hashPrefix('!');
		},


		/**
		 * **************** $urlMatcherFactoryProvider *****************
		 **/

		'$urlMatcherFactoryProvider': function () {
			$urlMatcherFactoryProvider.type('seoUrlCode', {
				decode: function (val) {
					return _.isEmpty(val) === false && val.indexOf('-') === 0 ? val.substr(1) : null;
				},
				encode: function (val) {
					return _.isEmpty(val) ? null : '-' + val;
				},
				equals: function (a, b) {
					return this.is(a) && a === b;
				},
				is: function (val) {
					return _.isEmpty(val) || _.isString(val);
				},
				pattern: /[^\/]*/
			});
		},


		/**
		 * **************** $animateProvider *****************
		 **/

		'$animateProvider': function () {
			$animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
		},


		/**
		 * **************** $resourceProvider *****************
		 **/

		'$resourceProvider': function () {
			angular.extend($resourceProvider.defaults.actions, {
				'update': {method: 'PUT'}
			});
		},

		/**
		 * **************** ModalStateProvider *****************
		 **/

		'modalStateProvider': function () {
			angular.extend(ModalStateProvider.config, {
				defaultSubstateProperty: 'defaultSubstate'
			});
		},

		/**
		 * **************** modalWindowProvider *****************
		 **/

		'modalWindowProvider': function () {
			angular.extend(modalWindowProvider.config, {
				container: 'body',
				animation: false,
				backdropAnimation: false
			});
			angular.extend(modalWindowProvider.modals, {
				'materials-videos-form': {
					templateUrl: 'modal.materials.video.video-form',
					controller: 'ModalVideoFormCtrl'
				},
				'materials-audios-form': {
					templateUrl: 'modal.materials.audio.audio-form',
					controller: 'ModalAudioFormCtrl'
				},
				'materials-images-form': {
					templateUrl: 'modal.materials.image.images-form',
					controller: 'ModalImagesFormCtrl'
				},
				'materials-images-albums-form': {
					templateUrl: 'modal.materials.image.album-form',
					controller: 'ModalAlbumFormCtrl'
				},

				'video': {
					templateUrl: 'modal.materials.video.video-view',
					controller: 'ModalVideoViewCtrl'
				},
				'image': {
					templateUrl: 'modal.materials.image.image-view',
					controller: 'ModalImageViewCtrl'
				},

				'change-maker': {
					templateUrl: 'modal.change-maker',
					controller: 'ModalChangeMakerCtrl'
				},
				'new-message': {
					templateUrl: 'modal.new-message',
					controller: 'ImNewMessageModalCtrl'
				},
				'sharing-options': {
					templateUrl: 'modal.sharing-options',
					controller: 'ModalSharingOptionsCtrl'
				},
				'group-settings-select-manager': {
					templateUrl: 'pages.groups.forms.global.managers.modal-select-manager',
					controller: 'GroupSettingsManagersSelectModalCtrl'
				},
				'group-add-material-options': {
					templateUrl: 'pages.groups.modal.add-material-options',
					controller: 'GroupAddMaterialOptionsCtrl'
				},
				'group-add-article': {
					templateUrl: 'pages.groups.modal.add-article',
					controller: 'GroupAddArticleCtrl'
				},
				'select-header-bg': {
					templateUrl: 'pages.groups.forms.modal-select-header-bg',
					controller: 'modal:select-header-bg.ctrl'
				},
				'event-voting-question-form': {
					templateUrl: 'pages.events.modal.voting-question-form',
					controller: 'ModalEventVotingQuestionsCtrl'
				},
				'redactor-images': {
					templateUrl: 'module.Redactor.modals.images',
					controller: 'RedactorImagesModalCtrl'
				},
				'redactor-videos': {
					templateUrl: 'module.Redactor.modals.videos',
					controller: 'RedactorVideosModalCtrl'
				},
				'redactor-audios': {
					templateUrl: 'module.Redactor.modals.audios',
					controller: 'RedactorAudiosModalCtrl'
				},
				'redactor-terms': {
					templateUrl: 'module.Redactor.modals.terms',
					controller: 'RedactorTermsModalCtrl'
				},
				'redactor-validate': {
					templateUrl: 'module.Redactor.modals.validate',
					controller: 'RedactorValidateModalCtrl'
				},
				'redactor-question': {
					templateUrl: 'module.Redactor.modals.question',
					controller: 'RedactorQuestionsModalCtrl'
				},
				'wiki-faq': {
					templateUrl: 'pages.wiki.modal.faq',
					controller: 'WikiFAQModalCtrl'
				},
				'change-avatar': {
					templateUrl: 'modal.change-avatar',
					controller: 'ChangeAvatarModalCtrl'
				},
				'article-series': {
					templateUrl: 'pages.articles.modal.series',
					controller: 'PageArticleSeriesCtrl'
				}
			});
		},


		/**
		 * **************** Moment *****************
		 **/

		'moment': function () {
			// Config moment relative seconds
			var localeData = moment.localeData('ru');
			localeData._relativeTime.s = '%d сек';
			localeData._relativeTime.mm = '%d мин';
		},


		/**
		 * **************** UI Select *****************
		 **/

		'ui-select': function () {
			angular.extend(uiSelectConfig, {
				theme: 'select2',
				searchEnabled: false,
				refreshDelay: 200
			});
		},


		/**
		 * **************** Angular File Uploader *****************
		 **/

		'angular-file-uploader': function () {
			$provide.decorator('fileUploaderOptions', ["$delegate", function ($delegate) {
				angular.extend($delegate, {
					withCredentials: $httpProvider.defaults.withCredentials,
					headers: {'Accept': '*/*'}
				});
				return $delegate;
			}]);
		},


		/**
		 * **************** Youtube API *****************
		 **/

		'youtube-api': function () {
			YoutubeApiProvider.setApiKey(SETTINGS.youtubeApiKey);
		},

		/**
		 * **************** Angular Material *****************
		 **/

		'angularMaterial': function () {
			$mdInkRippleProvider.disableInkRipple();
			$mdThemingProvider.definePalette('greenAbs', $mdThemingProvider.extendPalette('green', {
				'A200': '#57AE60',
				'contrastDefaultColor': 'light'
			}));
			$mdThemingProvider.theme('default').accentPalette('greenAbs');
		}
	};

	initialization.run();
}]);


/**
 *
 * **************** Application Run Section *****************
 *
 **/

NgProject.run(["$rootScope", "$urlRouter", "$state", "$location", "AuthService", "$q", "$window", "ModalState", "PageError", "CurrentUser", "AUTH_EVENTS", function ($rootScope, $urlRouter, $state, $location, AuthService, $q, $window, ModalState, PageError, CurrentUser, AUTH_EVENTS) {
	$rootScope.$one = function (name, listener) {
		var deregistration = this.$on(name, function () {
			deregistration();
			deregistration = null;
			listener.apply(null, arguments);
		});
		return deregistration;
	};

	// Аунтефикация пользователя до открытия первой страницы.
	$rootScope.$one('$locationChangeSuccess', function (e) {
		// Prevent $urlRouter's default handler from firing
		e.preventDefault();
		// Сначала посылает запрос аутентификации пользователя и приостанавливает выполнение до получения ответа сервера.
		$q.all([AuthService.authenticate(), ModalState.processInitialState($location.url())]).then(function () {
			// Once the user has logged in, sync the current URL to the router:
			$urlRouter.sync();
		});
	});

	$urlRouter.listen();

	$rootScope.$on('$stateChangeStart', function (e, toState, toParams) {
		// Проверка прав доступа на страницу
		if (_.get(toState, 'data.access.authorization', false) === true && !CurrentUser.id) {
			e.preventDefault();
			PageError.trigger(PageError.PAGE_ACCESS_REQUIRE_AUTHORIZATION, {
				location: $state.href(toState.name, toParams)
			});
		}

		// Изменение класса body в зависимости от состояния.
		if (!toState.modal && $rootScope.bodyLayoutClass !== toState.data.bodyClass) {
			$rootScope.bodyLayoutClass = toState.data.bodyClass;
		}

		if (toState.defaultSubstate) {
			e.preventDefault();
			$state.go(toState.name + '.' + toState.defaultSubstate, toParams);
		}
	});

	$rootScope.$on(AUTH_EVENTS.logoutSuccess, function () {
		if (_.get($state.current, 'data.access.authorization', false) === true) {
			PageError.trigger(PageError.PAGE_ACCESS_REQUIRE_AUTHORIZATION);
		}
	});

	PageError
		.onNotFoundError(function (event) {
			$state.go('root.error', {type: event.type}, {
				location: false
			});
		})
		.onPageRequireAuthorization(function (event) {
			var params = {
				type: event.type,
				location: _.get(event.detail, 'location', $location.url())
			};

			if (params.location !== $location.url()) {
				$location.url(params.location);
			}

			$state.transitionTo('root.error', params, {
				location: false
			});
		});
}]);
