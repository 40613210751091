(function (ng, app) {
	'use strict';
	app.service('EventsRoomsService', ["$resource", "serviceHelper", "SETTINGS", function ($resource, serviceHelper, SETTINGS) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/:section/:id/rooms/:roomId', {
			section: '@section',
			id: '@id',
			roomId: '@roomId'
		}, {
			get: {
				method: 'GET'
			},
			create: {
				method: 'POST',
				url: SETTINGS.apiBaseUrl + 'api/v1/rooms'
			},
			update: {
				method: 'PUT',
				url: SETTINGS.apiBaseUrl + 'api/v1/rooms/:roomId'
			},
			remove: {
				method: 'DELETE',
				url: SETTINGS.apiBaseUrl + 'api/v1/rooms/:roomId'
			}
		});

		return {
			list: function (maker_type, maker_id) {
				if (_.includes(['user', 'group'], maker_type)) maker_type = maker_type + 's';
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: {
						per_page: 1000,
						section: maker_type,
						id: maker_id
					}
				});
				return (promise);
			},

			get: function(section, id, roomId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: {
						section: section,
						id: id,
						roomId: roomId
					}
				});
				return (promise);
			},

			create: function (data) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'create',
					parameters: {
						room: data
					}
				});
				return (promise);
			},

			update: function (roomId, data) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'update',
					parameters: {
						roomId: roomId,
						room: data
					}
				});
				return (promise);
			},

			remove: function (roomId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'remove',
					parameters: {
						roomId: roomId
					}
				});
				return (promise);
			}
		};
	}]);
})(angular, NgProject);
