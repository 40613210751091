NgProject.directive('articleTeaser', ["$parse", "$templateCache", "Article", function ($parse, $templateCache, Article) {
	'use strict';
	controller.$inject = ["$scope", "$attrs"];
	var templateId = 'widgets.articles.article-teaser';
	var types = ['default', 'compact', 'minimal', 'edited'];
	var options = {
		buttons: {
			'deleteByOwner': false,
			'deleteReading': false,
			'deleteBookmark': false
		},
		showButtons: function () {
			return this.buttons.deleteByOwner || this.buttons.deleteReading || this.buttons.deleteBookmark;
		}
	};

	function compile(tElement, tAttrs) {
		var type = tAttrs.hasOwnProperty('type') ? tAttrs.type : 'default';
		var template = getTemplate(type, tElement);
		tElement.html(template);
	}

	function controller($scope, $attrs) {
		$scope.$teaser = {options: angular.copy(options)};
		if ($attrs.hasOwnProperty('object')) {
			$scope.$article = $parse($attrs.object)($scope);
		}

		if (_.isPlainObject($scope.$article)) {
			$scope.$article = Article.create($scope.$article);
		}

		if ($attrs.hasOwnProperty('options')) {
			angular.merge($scope.$teaser.options, $parse($attrs.options)($scope));
		}
	}

	function getTemplate(type, tElement) {
		var template = $templateCache.get(getTemplateIdByType(type));
		if (!template) {
			template = _.contains(types, type) ? createTemplate(type) : transcludeBlocks(tElement);
			if (type !== 'edited') template.find('.edit_article').remove();
			$templateCache.put(getTemplateIdByType(type), template.prop('outerHTML'));
		}
		return template;
	}

	function getTemplateIdByType(type) {
		return templateId.concat('-', type);
	}

	function getBaseTemplate() {
		return angular.element($templateCache.get(templateId));
	}

	function transcludeBlocks(tElement) {
		var template = getBaseTemplate();
		var content = tElement.html();
		if (content.length) {
			var blocks = angular.element(content);
			_.forEach(blocks, function (block) {
				switch (block.localName.toLowerCase()) {
					case 'transclude-footer':
						template.find('.footer-container').html(block.innerHTML);
						break;
				}
			});
		}
		return template;
	}

	function createTemplate(type) {
		var template = getBaseTemplate();
		switch (type) {
			case 'compact':
				template.prepend(template.find('.title-container'));
				break;
			case 'minimal':
				template.find('.subtitle-container').remove();
				template.find('.footer-container').remove();
				template.find('.created').remove();
				break;
			case 'edited':
				template.find('.reading_time').remove();
				template.find('.row-container.description-container').remove();
				break;
		}

		template.addClass('type-' + type);
		return template;
	}

	return {
		restrict: 'E',
		compile: compile,
		controller: controller
	};
}]);
