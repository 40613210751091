NgProject.directive('imagesFilters', ["Deferred", "MakersService", function (Deferred, MakersService) {
	'use strict';
	function link(scope, element, attrs, controller) {

		function loadMakers(callback) {
			Deferred.handlePromise(MakersService.images(), function (response) {
				callback(response.success ? response.items : []);
			});
		}

		function init() {
			scope.$filters = angular.copy(controller.getSearchParams());
			scope.$filtersData = {
				makers: []
			};
			scope.$filtersControls = {
				reset: function () {
					scope.$filters = angular.copy(controller.getDefaultSearchParams());
					applyFilters();
				}
			};
			loadMakers(function (data) {
				scope.$filtersData.makers = data;
			});
		}

		function getRequestParams() {
			return {
				maker: scope.$filters.maker
			};
		}

		function applyFilters() {
			controller.search(getRequestParams());
		}

		scope.$watch('$filters.maker', function (value, oldValue) {
			if (value !== null && value !== oldValue) applyFilters();
		});

		init();
	}

	return {
		require: '^^images',
		templateUrl: 'widgets.images.images-filters',
		restrict: 'E',
		scope: true,
		link: link
	};
}]);

