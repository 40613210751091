NgProject.provider('ModalState', function () {
	'use strict';
	var provider = this;
	var states = [];
	var modalSubstate = {state: null, params: null};
	this.config = {
		defaultSubstateProperty: 'defaultSubstate'
	};

	// Define the constructor function.
	function ModalState(name, options) {
		this.id = name;
		this.url = options.url;
		this.params = options.params;
		this.views = {
			'modal@': {template: null}
		};
		this.modal = angular.extend({}, options.modal, {
			disableStateChangeHandler: true
		});
	}

	ModalState.prototype.onEnter = ['$state', 'modalWindow', '$rootScope', '$q', function ($state, modalWindow, $rootScope, $q) {
		var that = this;
		var options = angular.copy(this.modal);

		if (!$state.current.modal) {
			modalSubstate.save($state.current.name, $state.params);
		}

		function initModalScope() {
			var deferred = $q.defer();
			if (options.relative) {
				// Получаем scope родительского элемента
				var parentModalState = $state.get('^', that.id);
				var parentModalView = '@' + $state.get('^', parentModalState.name).name;
				var path = _.findWhere($state.$current.path, {self: {name: parentModalState.name}});

				// $$postDigest нужен для первоначальной открытии страцы, когда родительский $scope еще не успел инициализироваться
				$rootScope.$$postDigest(function () {
					if (path && path.locals[parentModalView].hasOwnProperty('$scope')) {
						options.scope = path.locals[parentModalView].$scope;
					}
					deferred.resolve();
				});
			}
			else deferred.resolve();

			return deferred.promise;
		}

		initModalScope().then(function () {
			var modal = modalWindow.open(options);
			var $scope = modal.modal.$scope;
			var events = {
				afterCloseRedirect: true,
				stateChangeStartHandler: true
			};

			modal.promise.finally(function () {
				if (events.afterCloseRedirect) {
					// простое закрытие модальных окон
					events.stateChangeStartHandler = false;
					$state.transitionTo(modalSubstate.state, modalSubstate.params, {
						location: true,
						inherit: false,
						notify: false
					});
				}
				modalSubstate.reset();
			});

			$scope.$one('$stateChangeStart', function (e, toState) {
				if (!events.stateChangeStartHandler) return;
				events.afterCloseRedirect = false;
				// если идет переключение с одного модально окна - на другие
				if (toState.modal) modal.modal.destroy();
				// или переход из модального окна на обычную страницу
				else modal.modal.hide();
			});

			$scope.$one('$modalStateChangeStart', function () {
				if (!events.stateChangeStartHandler) return;
				events.afterCloseRedirect = false;
				// если идет переключение с одного модально окна - на другие
				$scope.$$postDigest(modal.modal.destroy);
			});
		});
	}];

	modalSubstate.save = function (state, params) {
		this.state = state;
		this.params = params || null;
	};

	modalSubstate.reset = function () {
		this.state = null;
		this.params = null;
	};

	this.createState = function (name, options) {
		var state = new ModalState(name, options);
		states.push(state.id);
		return state;
	};

	this.$get = ["$state", "$q", "$urlMatcherFactory", "$location", "$injector", "$rootScope", function ($state, $q, $urlMatcherFactory, $location, $injector, $rootScope) {
		var $$state = {
			current: null,
			params: {},
			transitionTo: $state.transitionTo
		};

		$state.transitionTo = function transitionTo(toState, toParams, options) {
			var state = _.isString(toState) ? $state.get(toState, _.get(options, 'relative.self', null)) : toState;

			if (_.get(state, 'modal', false)) {
				$$state.current = state;
				$$state.params = angular.copy(toParams);

				if (_.get(toParams, 'resetSubstate', false)) {
					delete toParams['resetSubstate'];
					return $$state.transitionTo(state.modal.defaultSubstate, null, {location: false}).then(function() {
						$rootScope.$broadcast('$modalStateChangeStart');
						return $$state.transitionTo(state, toParams, {location: true, notify: false, reload: false});
					});
				}

				if ($state.href(state, toParams) === $location.url()) {
					$rootScope.$broadcast('$modalStateChangeStart');
					$injector.invoke(state.onEnter, state);
				}
				else {
					$rootScope.$broadcast('$modalStateChangeStart');
					angular.extend(options, {location: true, notify: false, reload: false});
					return $$state.transitionTo(state, toParams, options);
				}
			}
			else {
				$$state.current = null;
				$$state.params = {};
				return $$state.transitionTo(state, toParams, options);
			}
		};

		function getStateUrlPattern(state_name) {
			var sections = state_name.split('.');
			var url = '';
			_.forEach(sections, function (section, index) {
				var stateId = sections.slice(0, index + 1).join('.');
				var state = $state.get(stateId);
				if (state.url) {
					if (state.url.charAt(0) === '^') {
						url = state.url;
					}
					else {
						url = url.concat(state.url);
					}
				}
			});

			return url;
		}


		function processInitialState(url) {
			var state = false;
			var params = false;

			states.some(function (name) {
				var matcher = $urlMatcherFactory.compile(getStateUrlPattern(name));
				state = $state.get(name);
				if (!state.url) return false;
				params = matcher.exec(url);
				return Boolean(params);
			});

			if (params) {
				var substate = state.modal.defaultSubstate;
				if (!substate) {
					var parent = $state.get('^', state.name);
					if (parent.abstract && parent.hasOwnProperty(provider.config.defaultSubstateProperty))
						substate = $state.get('.' + parent[provider.config.defaultSubstateProperty], parent.name).name;
					else
						substate = parent.name;
				}

				return openSubstate(substate, params);
			}

			return $q.resolve();
		}

		function openSubstate(name, params, options) {
			return $$state.transitionTo(name, params, angular.extend(options || {}, {
				location: false,
				inherit: true,
				relative: $state.$current
			}));
		}

		function getStateParams(key) {
			//var url = $location.url();
			//var params = null;
			//states.some(function (name) {
			//	var matcher = $urlMatcherFactory.compile(getStateUrlPattern(name));
			//	var args = matcher.exec(url);
			//	if (args) params = args;
			//	return Boolean(args);
			//});
			//return key ? _.get(params, key, undefined) : params || undefined;

			return key ? _.get($$state.params, key, undefined) : $$state.params;
		}

		return {
			// Если первоначальная страница модалка - то сначала загрузить страницу по умолчанию, а потом отобразить модалку.
			processInitialState: processInitialState,
			openSubstate: openSubstate,
			getStateParams: getStateParams
		};
	}];

	return (this);
});
