NgProject.directive('avatar', ["$parse", "$filter", "$compile", function ($parse, $filter, $compile) {
	'use strict';
	function link(scope, element, attrs) {
		var type = attrs.type.toLowerCase();
		var data = $filter('avatarFilter')($parse(attrs.avatar || attrs.data)(scope), type);
		var size = [Math.ceil(attrs.width), Math.ceil(attrs.height)].join('x');


		var img = angular.element('<img />', {
			'ng-src': $filter('imageResize')([(data.url ? data.url : data[attrs.size].url), size], data.default),
			'ng-src-retina': true,
			'class': 'image__' + type + (data.default ? ' is-default' : '')
		}).attr('width', attrs.width).attr('height', attrs.height);
		element.prepend($compile(img)(scope));
	}

	return {
		restrict: 'EA',
		link: link
	};
}]);
