NgProject.directive('eventsList', ["$parse", function ($parse) {
	'use strict';
	var defaults = {
		loadMore: true,
		teaserOptions: {}
	};

	function link(scope, element, attrs, controller) {
		var options = angular.extend({}, angular.copy(defaults), attrs.options ? $parse(attrs.options)(scope) : {});
		scope.$eventsList = {options: options};
		if (options.loadMore) {
			scope.$eventsList.load = function () {
				var page = controller.getSearchParams().page || 1;
				controller.loadByPage(page + 1);
			};
			scope.$eventsList.disabled = function () {
				var data = scope.$events;
				return data.isLoading || data.endOfList;
			};
		}
	}

	return {
		require: '^^events',
		templateUrl: 'widgets.events.events-list',
		restrict: 'E',
		link: link
	};
}]);
