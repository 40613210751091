(function (ng, app) {
	'use strict';
	app.service('ProfileSubscribesSettingsService', ["$resource", "serviceHelper", "SETTINGS", "UsersSearchParams", "GroupsSearchParams", function ($resource, serviceHelper, SETTINGS, UsersSearchParams, GroupsSearchParams) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/subscribes/:command', {command: '@command'}, {
			users: {params: {command: 'users'}},
			groups: {params: {command: 'groups'}}
		});

		return {
			users: function(params) {
				params = UsersSearchParams.process(params);
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'users',
					parameters: params
				});
				return (promise);
			},

			groups: function(params) {
				params = GroupsSearchParams.process(params);
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'groups',
					parameters: params
				});
				return (promise);
			},

			update: function(id, data) {
				var promise = serviceHelper.executeRequest({
					resource: $resource(SETTINGS.apiBaseUrl + 'api/v1/settings/subscribes/:id', {id: '@id'}, {update: {method: 'PUT'}}),
					name: 'update',
					parameters: {id: id, subscribe: data}
				});
				return (promise);
			}
		};
	}]);
})(angular, NgProject);
