NgProject.controller('RedactorValidateModalCtrl', ["$scope", function ($scope) {
	'use strict';
	var errorCodes = $scope.$modalWindow.data(0);
	var errors = {
		'UAC_SYM': 'Определение термина содержит недопустимые знаки препинания',
		'LONG_DEF': 'Слишком длинное определение термина',
		'UNDF_DEF': 'Необходимо выделить текст, который будет отображаться как термин'
	};

	$scope.validates = [];
	_.forEach(errorCodes, function(code) {
		$scope.validates.push(errors[code]);
	});
}]);
