NgProject.directive('uiSelectOpenOnFocus', ["$parse", "$timeout", function ($parse, $timeout) {
	'use strict';
	function link(scope, element, attrs) {
		var $select = $parse(attrs['uiSelectOpenOnFocus'])(scope);
		var event_name = $parse(attrs['uiSelectOpenOnFocusEvent'])(scope);

		if (!_.isEmpty(event_name)) {
			scope.$on(event_name, function() {
				// В данном случае scope.$$postDigest вызывает ошибку. Надо использовать $timeout
				$timeout(function() {
					if (!$select.isActive()) $select.activate();
				});
			});
		}
	}

	return {
		restrict: 'A',
		link: link
	};
}]);
