'use strict';
NgProject.filter('convertHostTagToParticipantTag', function () {
	return function (tag) {
		var data = {};
		var host_type = _.get(tag, 'host_type') || 'text';

		switch (host_type.toLowerCase()) {
			case 'user':
				data = {
					tag_id: tag.id,
					tag_type: 'user',
					data: {id: tag.host_id, nickname: tag.name}
				};
				break;
			case 'termtitle':
				data = {
					tag_id: tag.id,
					tag_type: 'term',
					data: {id: tag.host_id, title: tag.name}
				};
				break;
			case 'text':
				data = {tag_type: 'text', data: tag.name};
				break;
		}

		return data;
	};
});
