'use strict';
NgProject.controller('PageUsersCtrl', ["$scope", "$state", "UsersService", function Controller($scope, $state, UsersService) {

	$scope.$on('$stateChangeSuccess', function (event, toState) {
		if (toState.name === 'root.users' && $scope.isAuthenticated()) {
			$state.go('.follows');
		}
	});

	$scope.$config = {
		promise: statePromise
	};

	function statePromise(params) {
		var promise = null;
		var state = _.last($state.current.name.split('.'));
		switch (state) {
			case 'common':
				promise = UsersService.list(params);
				break;
			case 'followers':
				promise = UsersService.followers('self', params);
				break;
			case 'follows':
				promise = UsersService.follows('self', params);
				break;
		}

		return promise;
	}
}]);
