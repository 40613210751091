NgProject.service('AuthService', ["$resource", "$rootScope", "CurrentUser", "serviceHelper", "AUTH_EVENTS", "SETTINGS", function Service($resource, $rootScope, CurrentUser, serviceHelper, AUTH_EVENTS, SETTINGS) {
	'use strict';
	var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/users/:command', {command: '@command'}, {
		sign_in: {
			method: 'POST',
			params: {command: 'sign_in'}
		},
		sign_up: {
			method: 'POST',
			params: {command: 'sign_up'}
		},
		sign_up_confirmation: {
			params: {command: 'confirmation'}
		},
		check_passwords_reset_token: {
			params: {command: 'check_passwords_reset_token'}
		},
		reset_password: {
			method: 'POST',
			params: {command: 'password'}
		},
		change_password: {
			method: 'PUT',
			params: {command: 'password'}
		},
		sign_out: {
			method: 'DELETE',
			params: {command: 'sign_out'}
		},
		authenticate: {
			params: {command: 'authenticate'}
		},
		authenticate_by_token: {
			method: 'POST',
			params: {command: 'social_signin'}
		}
	});

	return {
		authenticate: function () {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'authenticate',
				successCallback: function (response) {
					if (response.success) {
						CurrentUser.create(response.user);
						$rootScope.$broadcast(AUTH_EVENTS.authenticationSuccess);
					}
					else {
						$rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
					}
				}
			});
			return ( promise );
		},

		authenticateByToken: function(token) {
			return serviceHelper.executeRequest({
				resource: resource,
				name: 'authenticate_by_token',
				parameters: {
					token: token,
					remember_me: true
				},
				successCallback: function (response) {
					if (response.success) {
						CurrentUser.create(response.user);
						$rootScope.$broadcast(AUTH_EVENTS.authenticationSuccess);
					}
					else {
						$rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
					}
				}
			});
		},

		login: function (credentials) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'sign_in',
				parameters: {
					email: credentials.email,
					password: credentials.password,
					remember_me: credentials.remember_me
				},
				successCallback: function (response) {
					if (response.success) {
						CurrentUser.create(response.user);
						$rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
					}
					else {
						$rootScope.$broadcast(AUTH_EVENTS.loginFailed);
					}
				}
			});

			return (promise);
		},

		registration: function (credentials) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'sign_up',
				parameters: {
					fullname: credentials.fullname,
					nickname: credentials.nickname,
					email: credentials.email,
					password: credentials.password,
					password_confirm: credentials.password_confirm,
					utc_offset: credentials.utc_offset
				}
			});
			return ( promise );
		},

		registrationConfirm: function (token) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'sign_up_confirmation',
				parameters: {
					confirmation_token: token
				}
			});
			return ( promise );
		},

		changePassword: function (token, password, password_confirmation) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'change_password',
				parameters: {
					reset_password_token: token,
					password: password,
					password_confirmation: password_confirmation
				}
			});
			return ( promise );
		},

		checkPasswordsChangeToken: function (token) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'check_passwords_reset_token',
				parameters: {token: token}
			});
			return ( promise );
		},

		logout: function () {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'sign_out',
				successCallback: function () {
					CurrentUser.destroy();
					$rootScope.$broadcast(AUTH_EVENTS.logoutSuccess);
				}
			});
			return ( promise );
		},

		resetPassword: function (email) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'reset_password',
				parameters: {
					email: email
				}
			});
			return ( promise );
		},

		isAuthenticated: function () {
			return !!CurrentUser.id;
		}
	};
}]);
