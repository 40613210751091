NgProject.controller('ImCounterCtrl', ["$rootScope", "$scope", "$state", "ImCounter", function ($rootScope, $scope, $state, ImCounter) {
	'use strict';

	$scope.imCounter = ImCounter;
	$scope.imCounterValidate = function (count) {
		return count > 99 ? '99+' : count;
	};
	ImCounter.getCounter();
	$scope.$watchCollection('imFaye', function () {
		if (!$state.includes('root.im')) ImCounter.getCounter();
	});

	$scope.controls = {
		getCounter: function (that) {
			if (that.imCounter.im.new_messages) {
				ImCounter.getCounter();
			}
		}
	};

	$scope.$on('$destroy', function () {
		ImCounter.resetCounter();
	});
}]);
