NgProject.directive('message', ["$compile", "$filter", function ($compile, $filter) {
	'use strict';

	var template = '<div class="message">\
		<div class="message__cell image"><a href="-href-" class="message__image-link" target="_blank">-avatar-</a></div>\
		<div class="message__cell">\
			<div class="message__item name">\
				<a href="-href-" class="message__name" target="_blank">-sender-</a>\
				<span class="message__date">-created-</span>\
			</div>\
			<div class="message__item content">-message-</div>\
		</div></div>';
	var avatarTemplate = '<avatar data="-avatar-" type="-type-" size="medium" width="45" height="45" class="message__image"></avatar>';

	return {
		link: link,
		restrict: 'E'
	};

	function link(scope, element) {
		var data = scope.item;
		var avatar = createAvatar(avatarTemplate, data);
		element.html($compile(createMessage(template, avatar, data))(scope));
	}

	function createMessage(template, avatar, data) {
		var sender = data.sender.type === 'group' ? data.sender.data.name : data.sender.data.nickname;
		if (_.has(data, 'sync')) {
			template = template.replace(/(<div[^>]*)(>)/, '$1 message-sync="' + data.sync + '"$2');
		}
		return template.replace(/-sender-/, sender)
			.replace(/-created-/, $filter('dateTimeFilter')(data.created, 'chat'))
			//.replace(/-created-/, moment(data.created).fromNow())
			.replace(/-href-/g, '/' + data.sender.type + data.sender.data.id.toString())
			.replace(/-message-/, data.message)
			.replace(/-avatar-/, avatar);
	}

	function createAvatar(avatar, data) {
		return avatar.replace(/-avatar-/, 'item.sender.data.avatar')
			.replace(/-type-/, data.sender.type);
	}
}]);
