NgProject.directive('navSearchButton', function () {
	'use strict';
	function link(scope, element, attrs, controller) {
		element.on('click', function () {
			if(scope.$navSearch.active && scope.$navSearch.model.length > 0) {
				scope.$navSearch.active = false;
				scope.$navSearch.submit();
			} else {
				controller.toggleBar();
			}
			controller.clearModel();
			scope.$digest();
		});
	}

	return {
		require: '^^navSearch',
		restrict: 'A',
		link: link
	};
});
