'use strict';
(function ($) {
	var plugin = 'redactor_videos';

	$.Redactor.prototype[plugin] = function () {
		return {
			langs: {
				ru: {
					'redactor-videos': 'Видео'
				}
			},

			init: function () {
				this.opts.blockTags.push('content-video');
				this[plugin].createButton();
				this[plugin].attachEvents();
				this[plugin].compile();
			},

			createButton: function () {
				var button = this.button.add('videos', this.lang.get('redactor-videos'));
				this.button.addCallback(button, this[plugin].buttonCallback);
			},

			buttonCallback: function () {
				this.selection.save();
				this.openModal('redactor-videos', this);
			},

			attachEvents: function () {
				var redactor = this;
				this.core.editor().on('click', 'figure[data-type-video] button[remove-video]', function () {
					$(this).closest('figure[data-type-video]').remove();
					redactor.placeholder.enable();
				});
				this.core.editor().on('click', 'figure[data-type-video] span.content_video-button', function () {
					var button = this;
					redactor.$injector.invoke(['$compile', function ($compile) {
						var videoId = $(button).closest('content-video').attr('video-id');
						$(button).closest('content-video')
							.append($compile('<youtube-video video-id="\'' + videoId + '\'" player-vars="{autoplay: true}"></youtube-video>')(redactor.opts.data.$scope));
						$(button).closest('.content_video-button').remove();
					}]);
				});
			},

			insert: function (videoId) {
				var $box = $('<figure data-type-video class="video-container" contenteditable="false">');
				var $element = $('<content-video>').attr('video-id', videoId);
				$box.html($element);

				this.selection.restore();

				var insert = this.utils.isEmpty() ? this.insert.set : this.insert.html;
				this.buffer.set();
				insert($('<div>').html($box).html());
				this[plugin].compile();
			},

			compile: function () {
				var redactor = this;
				var $videos = this.core.editor().find('content-video').filter(function () {
					return !$(this).data('compiled');
				});

				if (!$videos.length) return;

				this.$injector.invoke(['$compile', function ($compile) {
					$videos.each(function () {
						var $video = $compile($(this).clone())(redactor.opts.data.$scope);
						$video.append("<button remove-video type='button' title='Удалить видео'></button>");
						$(this).html($video.html());
						$(this).data('compiled', true);
					});
				}]);
			}
		};
	};
})(jQuery);
