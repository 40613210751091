NgProject.factory('ImSearchParams', function () {
	'use strict';
	return {
		list: function (params) {
			var options = {};
			if (_.isEmpty(params)) return options;
			if (params.page && params.page > 1) options.page = params.page;
			if (params.per_page) options.per_page = params.per_page;
			if (params.id) options.id = params.id;
			if (params.type) options.type = params.type;

			return options;
		}
	};
});
