NgProject.service('ImData', ["$rootScope", "$state", "AUTH_EVENTS", "CurrentUser", function ($rootScope, $state, AUTH_EVENTS, CurrentUser) {
	'use strict';

	var ImData = this;
	var defaults = {
		chatId: null,
		left: false,
		isAdmin: false,
		recipient: null,
		groupSender: false
	};

	ImData.data = angular.copy(defaults);
	ImData.setData = setData;
	ImData.resetData = resetData;

	$rootScope.$on(AUTH_EVENTS.logoutSuccess, reset);
	$rootScope.$on(AUTH_EVENTS.sessionTimeout, reset);

	return ImData;

	function setData(data) {
		_.extend(ImData.data, {
			chatId: data.id,
			left: data.left,
			isAdmin: !_.isEmpty(_.find(data['administrators'], {id: CurrentUser.id})),
			recipient: data['recepient'],
			groupSender: false
		});
	}

	function resetData() {
		if (DEBUG) console.log('resetData im chat');
		_.extend(ImData.data, angular.copy(defaults));
	}

	function reset() {
		resetData();
		$state.go('root.home');
	}
}]);
