NgProject.factory('EventsSearchParams', ["SETTINGS", function (SETTINGS) {
	'use strict';
	return {
		process: function (params) {
			if (_.isEmpty(params)) return {};
			var options = {};

			if (!_.isEmpty(params.q))
				options.q = params.q;

			if (params.page && params.page > 1)
				options.page = params.page;

			if (params.limit)
				options.per_page = params.limit;

			if (params.typeId)
				options.event_type = params.typeId;

			if (params.onlyOnline)
				options.online_events = true;

			if (!params.onlyOnline && params.countryId) {
				options.country_id = params.countryId;
				if (params.cityId)
					options.city_id = params.cityId;
			}

			if (params.between && params.between.length === 2) {
				options.date_from = parseInt(params.between[0] / 1000);
				options.date_to = parseInt(params.between[1] / 1000);
			}

			if (params.younger_than)
				options.younger_than = parseInt(params.younger_than / 1000);
			if (params.older_than)
				options.older_than = parseInt(params.older_than / 1000);
			if (options.younger_than || options.older_than) {
				if (params.hasOwnProperty('younger_older_correction') && _.isNumber(params.younger_older_correction))
					options.younger_older_correction = params.younger_older_correction;
				else
					options.younger_older_correction = SETTINGS.events.filters.younger_older_correction;
			}

			if (params.full_list)
				options.full_list = true;

			return options;
		}
	};
}]);
