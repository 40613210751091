NgProject.controller('PageManageTagsQuestionsCtrl', ["$scope", "$state", "$stateParams", "ArticlesQuestionsManageService", function ($scope, $state, $stateParams, ArticlesQuestionsManageService) {
	'use strict';
	$scope.$config = {
		promise: function (params) {
			params = angular.extend(params || {}, $scope.$filter.model);
			return ArticlesQuestionsManageService.findAll(params);
		}
	};

	$scope.$filter = {
		model: {
			tag_status: $stateParams.tag_status
		},
		data: {
			tag_status: [
				{key: 'awaiting_moderation', title: 'Ожидают модерации'},
				{key: 'moderated', title: 'Промодерированные'}
			]
		},

		reset: function () {
			angular.extend($scope.$filter.model, {tag_status: null});
		}
	};

	$scope.$watch('$filter.model', function (value, oldValue) {
		if (value !== oldValue) {
			$state.go($state.current.name, value);
		}
	}, true);
}]);


NgProject.controller('PageManageTagsQuestionsListCtrl', ["$scope", "modalWindow", "DefinitionHelper", "ArticlesQuestionsManageService", function ($scope, modalWindow, DefinitionHelper, ArticlesQuestionsManageService) {
	'use strict';
	var requestOptions = {
		page: 1,
		limit: 20
	};

	var $questionList = $scope.$questionList = getData();

	$scope.controls = {
		more: function (item) {
			modalWindow.open('wiki-faq', item);
		},
		openInfoModal: function (index) {
			$scope.$modal.open('wiki-faq-info', index);
		},
		load: function () {
			var page = requestOptions.page;
			loadByPage(page + 1);
		},
		disabled: function () {
			var data = $scope.$questionList;
			return data.isLoading || data.endOfList;
		}
	};


	$scope.setTagVisibility = function ($item, $tag, visibility) {
		ArticlesQuestionsManageService.setTagVisibility($item.id, $tag.id, visibility).then(function (response) {
			if (response.success) {
				$item.tag_options = response.question.tag_options;
				$scope.$questionList.count_am = response['awaiting_moderation_count'];
			}
		});
	};

	function loadByPage(page) {
		if (page && page > 1) requestOptions.page = page;
		loadData(true);
	}

	function loadData(append) {
		$questionList.isLoading = true;

		$scope.$config.promise(requestOptions).then(function (response) {
			if (response.success) {
				prepareQuestion(response.items);
				if (append) {
					response.items = $questionList.items.concat(response.items);
				}
				angular.extend($questionList, getData(response.items, response.count, response['awaiting_moderation_count']), {
					isLoading: false,
					endOfList: response.items.length === 0 || response.count === response.items.length
				});
			}
		});
	}

	function getData(items, count, count_am) {
		return {
			items: _.isArray(items) ? items : [],
			count: count,
			count_am: count_am,
			isLoading: true,
			endOfList: false
		};
	}

	function prepareQuestion(items) {
		_.map(items, function (item) {
			item.answer = DefinitionHelper.clean(item.answer);
			item.value = _.getText(item.value);
		});
	}

	loadData();
}]);
