NgProject.controller('ArticlesListPrivateCtrl', ["$scope", "$state", "$timeout", "ArticlesService", "CommonData", "InterfaceCounters", function ($scope, $state, $timeout, ArticlesService, CommonData, InterfaceCounters) {
	'use strict';
	var requests = {articles: false, drafts: false};

	function processLoadedTriggers() {
		if (requests.articles && _.get(requests, 'drafts', true)) {
			$scope.controls.isReady = true;
			$scope.controls.showHeader = Boolean(requests.drafts);
		}
	}

	$scope.articlesCount = InterfaceCounters.articles;
	$scope.controls = {
		isReady: false,
		showHeader: false
	};

	$scope.articles = {
		promise: ArticlesService.findAllByCurrentUser,
		action: {show: true, sref: 'root.create-article'},
		listOptions: {
			teaserOptions: {
				buttons: {deleteByOwner: true}
			}
		},
		onLoadCallback: function () {
			if (!requests.articles) {
				requests.articles = true;
				processLoadedTriggers();
			}
		}
	};

	$scope.drafts = {
		enabled: true,
		promise: ArticlesService.findAllDraftsByCurrentUser,
		options: {
			requestParams: {limit: 1000}
		},
		listOptions: {
			loadMore: false
		},
		onLoadCallback: function (count) {
			if (!requests.drafts) {
				if (count) {
					requests.drafts = true;
				}
				else {
					delete requests.drafts;
					$scope.drafts.enabled = false;
				}
				processLoadedTriggers();
			}
		}
	};

	if (!InterfaceCounters.articles.private) $timeout(function () {
		$state.go('^');
	}, 10);

	$scope.$watch('articlesCount', function (a, b) {
		if (_.eq(a, b)) return;
		if (!a['private']) $state.go('^');
	}, true);
}]);
