(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state"];
	function Controller($scope, $state) {


		$scope.$on('$stateChangeSuccess', function (event, toState) {
			if (toState.name === 'root.settings.global') {
				$state.go('.notifications');
			}
		});
	}

	app.controller('PageSettingsGlobalCtrl', Controller);
})(angular, NgProject);
