'use strict';
NgProject.directive('contentShowMore', function () {

	controller.$inject = ["$scope", "$element"];
	function controller($scope, $element) {
		$element.css({visibility: 'hidden', height: 0});

		$scope.$contentShowMore = {
			truncated: false,
			isFullView: true
		};

		this.compiled = function () {
			$element.css({visibility: '', height: ''});
			if ($scope.$contentShowMore.truncated) {
				$element.addClass('content-show-more');
			}
		};

		this.toggle = function () {
			$scope.$contentShowMore.isFullView = !$scope.$contentShowMore.isFullView;
			$element[$scope.$contentShowMore.isFullView ? 'addClass' : 'removeClass']('content-show-more-active');
		};
	}

	return {
		restrict: 'A',
		controller: controller
	};
});

NgProject.directive('contentShowMoreTruncate', ["$q", "$timeout", "$compile", function ($q, $timeout, $compile) {
	function link(scope, element, attrs, controller) {
		var deferred = $q.defer();
		var options = scope.$eval(attrs['contentShowMoreTruncate']) || {};
		var data = {
			truncated: false,
			contents: {}
		};

		$timeout(function () {
			element.dotdotdot(options);
		});

		options.callback = function (isTruncated, orgContent) {
			data.truncated = isTruncated;

			if (isTruncated) {
				data.contents = {
					original: orgContent,
					truncated: $compile(element.clone().contents())(scope)
				};
			}

			element.trigger('destroy.dot');
			deferred.resolve();
		};

		deferred.promise.then(function () {
			scope.$contentShowMore.truncated = data.truncated;
			scope.$contentShowMore.isFullView = !data.truncated;
			if (data.truncated) element.html(data.contents.truncated);
			controller.compiled();
		});
	}

	return {
		require: '^^contentShowMore',
		restrict: 'A',
		link: link
	};
}]);


NgProject.directive('contentShowMoreToggle', function () {
	function link(scope, element, attrs, controller) {
		element.on('click', function () {
			controller.toggle();
		});
	}

	return {
		require: '^^contentShowMore',
		restrict: 'A',
		link: link
	};
});
