NgProject.directive('modalDialogCenter', ["$window", function ($window) {
	'use strict';
	function link(scope, element) {
		function processPosition() {
			var marginTop = getOriginalMarginTop(element);
			if ($window.innerHeight > element.outerHeight(true)) {
				marginTop = parseInt(($window.innerHeight - element.outerHeight()) * 0.4);
			}
			element.css('marginTop', marginTop);
		}

		function getOriginalMarginTop() {
			var value = $.data(element, 'originalMarginTop');
			if (!value) {
				value = parseInt(element.css('marginTop'));
				$.data(element, 'originalMarginTop', value);
			}
			return value;
		}

		scope.$on('resize:end', processPosition);
		scope.$on('modal:contentUpdated', processPosition);
		element.on('resize', processPosition);
		scope.$on('$destroy', function () {
			element.off('resize');
		});

		element.hide();
		scope.$$postDigest(function () {
			processPosition();
			element.show();
		});
	}

	return {
		restrict: 'A',
		link: link
	};
}]);
