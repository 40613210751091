NgProject.directive('animationFadeDelay', ["$timeout", function ($timeout) {
	'use strict';
	return {
		restrict: 'A',
		link: function (scope, element, attrs) {
			var el = attrs.hasOwnProperty('animationElement') ? element.closest(attrs['animationElement']) : element;
			$timeout(function() {
				el.fadeOut(500, function() {
					el.remove();
					scope.$destroy();
				});
			}, 2000);
		}
	};
}]);
