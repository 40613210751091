(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state", "$filter", "Deferred", "GroupsService", "GroupRequestsService", "AUTH_EVENTS", "CurrentUser", "ImService"];
	var defaults = {
		buttons: [
			// Открытая группа
			{do: 'Вступить в группу', status: 'Вы состоите в группе', undo: 'Выйти из группы'},
			// Закрытая группа - пользователь в ней не состоит
			{do: 'Вступить в группу', status: 'Запрос в группу отправлен', undo: 'Отменить запрос'},
			// Закрытая группа - пользователь в ней состоит
			{do: 'Вступить в группу', status: 'Вы состоите в группе', undo: 'Выйти из группы'}
		]
	};

	function Controller($scope, $state, $filter, Deferred, GroupsService, GroupRequestsService, AUTH_EVENTS, CurrentUser, ImService) {
		$scope.group = {};
		$scope.contents = {
			audios: false,
			videos: false,
			images: false,
			articles: false,
			events: false,
			materials: false
		};
		$scope.msBtnOptions = {
			state: true,
			buttons: {
				do: {class: 'btn3-green', content: '<i class="icon icon-plus"></i>'},
				status: {class: 'btn3-transparent action-ms-hover', content: '<i class="icon icon-checkmark"></i>'},
				undo: {class: 'btn3-transparent action-ms-hover', content: '<i class="icon icon-close"></i>'}
			}
		};

		$scope.$members = {
			items: [],
			count: 0
		};

		$scope.$wall = {
			promise: function (params) {
				return GroupsService.wall.list($scope.group.id, params);
			},
			formPromise: function (message) {
				return GroupsService.wall.create($scope.group.id, message);
			},
			removePromise: function (postId) {
				return GroupsService.wall.remove($scope.group.id, postId);
			},
			options: {
				update: false,
				comments: {update: false}
			}
		};

		$scope.$controls = {
			isReady: false,
			join: function () {
				var promise = $scope.group.private ? GroupRequestsService.create($scope.group.id) : GroupsService.join($scope.group.id);
				Deferred.handlePromise(promise, function (response) {
					if (response.success) {
						$scope.msBtnOptions.state = false;
						if (!$scope.group.private) $scope.group.access.chat = true;
					}
				});
			},
			leave: function () {
				Deferred.handlePromise(GroupsService.leave($scope.group.id), function (response) {
					if (response.success) {
						$scope.msBtnOptions.state = true;
						$scope.group.access.chat = false;
						if ($scope.group.private) reloadState();
					}
				});
			},
			goToChat: function () {
				Deferred.handlePromise(ImService.groups($scope.group.id)).then(function (response) {
					if (response.success) Deferred.handlePromise(ImService.join($scope.group.id)).then(function (r) {
						if (r.success) $state.go('root.im.chat', {chatId: response.id});
					});
				});
			}
		};

		function loadRemoteData(groupId) {
			var promises = [GroupsService.main(groupId), GroupsService.members.list(groupId, {limit: 12})];
			Deferred.handleAllPromises(promises, function (group, members) {
				if (group.success) {
					setModelData(group.group);
					setContentsFlags(group.contents);
					setWallOptions(group.contents);
					setMultistateButtonOptions();
					loadCountOfRequests();
				}

				if (members.success) {
					$scope.$members.items = members.items;
					$scope.$members.count = members.count;
				}

				$scope.$controls.isReady = true;
			});
		}

		function loadCountOfRequests() {
			if ($scope.group.private && $scope.group.access.manage) {
				Deferred.handlePromise(GroupRequestsService.count($scope.group.id), function (response) {
					if (response.success) $scope.group.requests_count = response.count;
				});
			}
		}

		function setWallOptions() {
			$scope.$wall.options.update = $scope.group.access.manage;
			$scope.$wall.options.comments.update = $scope.group.access.manage;
		}

		function setModelData(data) {
			var is_closed = data.access === 'closed';
			var user_access = {
				member: _.contains(['member'], data.user_status),
				manager: _.contains(['administrator', 'editor'], data.user_status) || Boolean(CurrentUser.is_admin)
			};

			$scope.group = {
				id: data.id,
				name: data.name,
				avatar: data.avatar,
				background: data.background_image_number ? $filter('headerBgSrc')(data.background_image_number) : null,
				description: data.description || data.short_description,
				short_description: data.short_description,
				courses: data.courses,
				contacts: data.contacts,
				location_name: $filter('locationName')(data),
				private: is_closed,
				role: _.contains(['administrator', 'editor', 'member'], data.user_status) ? data.user_status : false,
				user_is_maker: _.get(data, 'user_is_maker', false),
				access: {
					view: is_closed ? (user_access.member || user_access.manager) : true,
					manage: user_access.manager,
					chat: !!data.user_status
				}
			};

			if ($scope.group.private && !$scope.group.role) {
				$scope.group.request_status = data.user_status === 'request_sent';
			}
		}

		function setContentsFlags(flags) {
			_.map($scope.contents, function (value, key) {
				$scope.contents[key] = flags[key] || $scope.group.access.manage;
			});
			$scope.contents.materials = _.some([flags.images, flags.videos, flags.audios]) || $scope.group.access.manage;
		}

		function setMultistateButtonOptions() {
			// Если группа открытая
			var index = 0;
			$scope.msBtnOptions.state = !$scope.group.role;
			if ($scope.group.private) {
				// Закрытая группа - пользователь в ней не состоит
				// Закрытая группа - пользователь в ней состоит
				index = !$scope.group.role ? 1 : 2;
				$scope.msBtnOptions.state = !$scope.group.role ? !$scope.group.request_status : false;
			}
			$scope.msBtnOptions.buttons.do.content += defaults.buttons[index].do;
			$scope.msBtnOptions.buttons.status.content += defaults.buttons[index].status;
			$scope.msBtnOptions.buttons.undo.content += defaults.buttons[index].undo;
		}

		function reloadState() {
			$state.transitionTo('root.group.main', {groupId: $scope.group.id}, {
				reload: true, inherit: false, notify: true
			});
		}

		loadRemoteData($state.params.groupId);

		$scope.$on(AUTH_EVENTS.loginSuccess, reloadState);
		$scope.$on(AUTH_EVENTS.logoutSuccess, reloadState);
		$scope.$on(AUTH_EVENTS.sessionTimeout, reloadState);
	}

	app.controller('PageGroupCtrl', Controller);
})(angular, NgProject);
