NgProject.controller('GroupAddArticleCtrl', ["$rootScope", "$scope", "ArticlesService", "GroupsService", function ($rootScope, $scope, ArticlesService, GroupsService) {
	'use strict';
	var group_id = $scope.$modalWindow.data(0);

	$scope.articles = {
		promise: ArticlesService.findAllByCurrentUser,
		optionsList: {
			infiniteScrollContainer: '.modal'
		}
	};

	$scope.$controls = {
		select: function (item) {
			GroupsService.addArticle(group_id, item.id).then(function (response) {
				if (response.success) {
					$rootScope.$broadcast('group-reload-articles-list');
					item.add_to_group_success = true;
				} else {
					item.add_to_group_error = true;
				}
			}, function() {
				item.add_to_group_error = true;
			});
		}
	};
}]);
