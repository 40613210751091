NgProject.directive('groupsSearch', ["$parse", function ($parse) {
	'use strict';
	controller.$inject = ["$scope", "$attrs"];
	var defaults = {
		action: {label: 'Создать группу'}
	};

	function controller($scope, $attrs) {
		$scope.bSearchOptions = angular.copy(defaults);
		if ($attrs.hasOwnProperty('showQueryPanel'))
			$scope.bSearchOptions.enableQueryPane = $parse($attrs.showQueryPanel)($scope);
		if ($attrs.hasOwnProperty('showFiltersPanel'))
			$scope.bSearchOptions.showFilters = $scope.bSearchOptions.openFilters = $parse($attrs.showFiltersPanel)($scope);
		if ($attrs.hasOwnProperty('action'))
			angular.extend($scope.bSearchOptions.action, $parse($attrs.action)($scope));
	}

	return {
		templateUrl: 'widgets.groups.groups-search',
		restrict: 'E',
		controller: controller
	};
}]);
