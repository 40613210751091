NgProject.controller('RedactorQuestionsModalCtrl', ["$scope", "$q", "ArticlesQuestionsService", "TermsService", "cfpLoadingBar", function ($scope, $q, ArticlesQuestionsService, TermsService, cfpLoadingBar) {
	'use strict';
	var options = $scope.$modalWindow.data(0);

	$scope.$model = {
		id: _.get(options, 'data.id', null),
		value: _.get(options, 'data.value', null),
		answer: _.get(options, 'data.answer', null),
		tags: _.get(options, 'data.tags', [])
	};

	$scope.$tags = {
		search: function (query) {
			query = $.trim(query);
			var defer = $q.defer();
			if (query.length) TermsService.tags(query, 30).then(function (response) {
				if (response.success) defer.resolve(response.items);
			});
			else defer.resolve([]);
			return defer.promise;
		},
		transform: function (item) {
			return _.isObject(item) ? item : {name: item.toString()};
		}
	};

	$scope.$ctrl = {
		ready: true
	};

	$scope.$ctrl.submit = function () {
		var method = _.get(options, 'data.id', false) ? 'update' : 'create';
		$scope.$ctrl[method]();
	};

	$scope.$ctrl.create = function () {
		cfpLoadingBar.start();
		ArticlesQuestionsService.create(options.articleId, getSubmitData())
			.then(function (response) {
				if (response.success) {
					options.callbacks.onCreate(response.question);
					cfpLoadingBar.complete();
					$scope.$close();
				}
			});
	};

	$scope.$ctrl.update = function () {
		cfpLoadingBar.start();
		ArticlesQuestionsService.update(options.articleId, options.data.id, getSubmitData())
			.then(function (response) {
				if (response.success) {
					options.callbacks.onUpdate();
					cfpLoadingBar.complete();
					$scope.$close();
				}
			});
	};

	$scope.$ctrl.remove = function () {
		cfpLoadingBar.start();
		ArticlesQuestionsService.remove(options.articleId, options.data.id).finally(function () {
			options.callbacks.onRemove(options.data.id);
			cfpLoadingBar.complete();
			$scope.$close();
		});
	};

	function getSubmitData() {
		var data = {
			value: $scope.$model.value,
			answer: $scope.$model.answer,
			tags: []
		};

		_.forEach($scope.$model.tags, function (tag) {
			data.tags.push(_.isEmpty(tag.host_type) ? tag.name : tag.id);
		});

		return data;
	}
}]);
