(function (ng, app) {
	'use strict';
	app.service('GroupRequestsService', ["$resource", "serviceHelper", "UsersSearchParams", "SETTINGS", function ($resource, serviceHelper, UsersSearchParams, SETTINGS) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/requests/:requestId/:command', {
			groupId: '@groupId',
			requestId: '@requestId',
			command: '@command'
		}, {
			list: {
				method: 'GET'
			},
			create: {
				method: 'POST'
			},
			accept: {
				method: 'POST',
				params: {command: 'accept'}
			},
			decline: {
				method: 'POST',
				params: {command: 'decline'}
			}
		});

		return {
			list: function (groupId, params) {
				params = UsersSearchParams.process(params);
				params.groupId = groupId;
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'list',
					parameters: params
				});
				return (promise);
			},

			count: function (groupId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'list',
					parameters: {
						groupId: groupId,
						per_page: 0
					}
				});
				return (promise);
			},

			create: function (groupId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'create',
					parameters: {groupId: groupId}
				});
				return (promise);
			},

			accept: function (groupId, requestId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'accept',
					parameters: {
						groupId: groupId,
						requestId: requestId
					}
				});
				return (promise);
			},

			decline: function (groupId, requestId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'decline',
					parameters: {
						groupId: groupId,
						requestId: requestId
					}
				});
				return (promise);
			}
		};
	}]);
})(angular, NgProject);
