'use strict';
NgProject.controller('PageHomeCtrl', ["$scope", "ArticlesService", "EventsService", function ($scope, ArticlesService, EventsService) {
	var date = new Date();

	$scope.data = {
		currentYear: date.getFullYear()
	};

	$scope.articles = {
		popular: {
			carouselOptions: {margin: 25, items: 3, slideBy: 3},
			promise: function (params) {
				params.limit = 6;
				params.order = 'views';
				params.views_count_for_period = true;
				return ArticlesService.findAll(params);
			}
		}
	};

	$scope.events = {
		carouselOptions: {margin: 20, items: 4, slideBy: 4},
		promise: function (params) {
			params.limit = 8;
			params.younger_than = date.getTime();
			return EventsService.findAll(params);
		}
	};

	$scope.$latestArticle = [];

	ArticlesService.findAll({limit: 6}).then(function (response) {
		if (response.success) {
			$scope.$latestArticle = response.items;
		}
	});
}]);
