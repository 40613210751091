NgProject.directive('linkArticleShow', ["$parse", "$filter", function ($parse, $filter) {
	'use strict';
	return {
		restrict: 'A',
		link: function (scope, element, attrs) {
			var model = $parse(attrs['linkArticleShow'])(scope);
			element.attr('href', $filter('pageUrl')(model, 'article'));
		}
	};
}]);
