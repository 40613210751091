NgProject.directive('fieldErrors', function () {
	'use strict';
	return {
		templateUrl: 'widgets.field-errors.field-errors',
		restrict: 'E',
		scope: {
			type: '@position',
			errors: '='
		},
		link: function (scope) {
			scope.popoverClass = (scope.type === 'input') ? 'popover-input' : scope.type;
		}
	};
});
