'use strict';
NgProject.directive('youtubeThumbnail', ["$parse", function ($parse) {
	var sizes = {
		'default': [120, 90],
		'mqdefault': [320, 180],
		'hqdefault': [480, 360],
		'sddefault': [640, 480]
	};

	function link(scope, element, attrs) {
		var videoId = $parse(attrs.videoId)(scope);
		var size = attrs.size || 'default';
		var img = angular.element('<img />', {src: '//img.youtube.com/vi/' + videoId + '/' + size + '.jpg'})
			.attr('width', sizes[size][0])
			.attr('height', sizes[size][1]);
		element.replaceWith(img);
	}

	return {
		restrict: 'E',
		link: link
	};
}]);


NgProject.directive('textDotdotdotDynamicByParent', ["$timeout", function ($timeout) {
	function link(scope, element, attrs) {
		$timeout(function() {
			var $block = element.closest(attrs['textDotdotdotDynamicByParent']);
			element.dotdotdot({
				height: $block.height() + parseInt($block.css('padding-top')) - element[0].offsetTop
			});
		});
	}

	return {
		restrict: 'A',
		link: link
	};
}]);


NgProject.directive('textDotdotdotMaxLines', ["$timeout", function ($timeout) {
	function link(scope, element, attrs) {
		$timeout(function() {
			element.dotdotdot({
				height: parseInt(attrs['textDotdotdotMaxLines']) * parseFloat(element.css('line-height'))
			});
		});
	}

	return {
		restrict: 'A',
		link: link
	};
}]);
