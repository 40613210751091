NgProject.directive('users', ["$location", "$stateParams", "$parse", "$filter", "Deferred", "CurrentUser", "UsersService", "EventContent", function ($location, $stateParams, $parse, $filter, Deferred, CurrentUser, UsersService, EventContent) {
	'use strict';
	Ctrl.$inject = ["$scope", "$attrs"];
	var defaults = {
		followButton: true,
		membersControls: false,
		requestParams: {
			countryId: null,
			cityId: null,
			directionId: null,
			roleId: null,
			gender: null
		},
		onLoadCallback: null,
		events: {
			onReloadList: false,
			onRemoveItem: false,
			onRemoveAllItems: false
		}
	};

	function Ctrl($scope, $attrs) {
		var $users = $scope.$users = getData();
		var options = angular.extend({}, angular.copy(defaults), $attrs.options ? $parse($attrs.options)($scope) : {});
		var loadFn;
		var globalRequestId = 0;

		if ($attrs.items) {
			var items = $parse($attrs.items)($scope);
			var count = $attrs.count ? $parse($attrs.count)($scope) : items.length;
			_.map(items, processItem);
			angular.extend($users, getData(count, items), {
				isLoading: false,
				endOfList: true
			});
		}

		if ($attrs.load) {
			loadFn = $parse($attrs.load);
			if ($attrs.onLoadCallback) {
				options.onLoadCallback = $parse($attrs.onLoadCallback);
			}
		}

		if (options.followButton)     initFollowButtonControls();
		if (options.membersControls)  initMembersControls();

		function processItem(user) {
			user.location_name = $filter('locationName')(user);
			user.isCurrentUser = CurrentUser.id === user.id;

			if (options.followButton && !user.isCurrentUser) {
				user.followButton = {
					state: !user.following,
					buttons: {
						do: {
							content: '<i class="icon icon-add"></i>',
							class: 'btn6-green',
							title: 'Подписаться'
						},
						status: {
							content: '<i class="icon icon-checkmark"></i>',
							class: 'btn6-white bordered without-hover-bg action-ms-hover'
						},
						undo: {
							content: '<i class="icon icon-clear"></i>',
							class: 'btn6-white bordered without-hover-bg action-ms-hover',
							title: 'Отписаться от пользователя'
						}
					}
				};
			}
		}

		function callOnLoadCallback() {
			if (options.onLoadCallback) {
				options.onLoadCallback($scope, {
					count: $users.count,
					items: $users.items
				});
			}
		}

		function loadRemoteData(append) {
			var params = options.requestParams;
			var localRequestId = ++globalRequestId;
			$users.isLoading = true;
			Deferred.handlePromise(loadFn($scope, {params: params}), function (response) {
				if (localRequestId !== globalRequestId) return;
				if (response.success) {
					var items = response.items;
					_.map(items, processItem);
					if (append) {
						items = $users.items.concat(items);
					}
					angular.extend($users, getData(response.count, items), {
						isLoading: false,
						endOfList: response.items.length === 0 || response.count === $users.items.length
					});
				}
				callOnLoadCallback();
			});
		}

		this.search = function (params) {
			angular.extend(options.requestParams, params);
			delete options.requestParams.page;
			$users = $scope.$users = getData();
			loadRemoteData();
		};

		this.loadByPage = function (page) {
			if (page && page > 1)
				options.requestParams.page = page;
			else
				delete options.requestParams.page;
			loadRemoteData(true);
		};

		this.getSearchParams = function () {
			return options.requestParams;
		};

		this.getDefaultSearchParams = function () {
			return defaults.requestParams;
		};

		this.getPromiseFn = function () {
			return loadFn;
		};

		function initEvents() {
			if (options.events.onReloadList) {
				$scope.$on(options.events.onReloadList, function () {
					delete options.requestParams.page;
					loadRemoteData();
				});
			}
			if (options.events.onRemoveItem) {
				$scope.$on(options.events.onRemoveItem, function (event, item) {
					var itemIndex = _.findIndex($users.items, item);
					if (itemIndex !== -1) {
						$users.items.splice(itemIndex, 1);
						$users.count--;
					}
				});
			}
			if (options.events.onRemoveAllItems) {
				$scope.$on(options.events.onRemoveAllItems, function () {
					$users.items = [];
					$users.count = 0;
				});
			}
		}

		function initFollowButtonControls() {
			$scope.$usersFollowControls = {
				follow: function (user) {
					UsersService.relationship.follow(user.id).then(function (response) {
						if (response.success) {
							user.following = true;
							user.followButton.state = false;
						}
					});
				},
				unfollow: function (user) {
					UsersService.relationship.unfollow(user.id).then(function (response) {
						if (response.success) {
							user.following = false;
							user.followButton.state = true;
						}
					});
				}
			};
		}

		function initMembersControls() {
			$scope.$usersMembersControls = {
				allow_view: EventContent.data.is_maker || EventContent.data.allow_update
			};
		}

		$scope.search = this.search;
		initEvents();
	}

	function link(scope, element, attrs, controller) {
		var params = {};
		if (attrs.hasOwnProperty('load')) {
			if (_.isObject(controller)) {
				var query = controller.getQueryValue();
				if (query.length) {
					params.q = query;
				}
				scope.$on('searchReloadEvent', function (event, query) {
					scope.search({q: query});
				});
			}
			scope.search(params);
		}
	}

	function getData(count, items) {
		return {
			count: count || 0,
			items: _.isArray(items) ? items : [],
			isLoading: true,
			endOfList: false
		};
	}

	return {
		require: '?^^search',
		restrict: 'E',
		scope: true,
		controller: Ctrl,
		link: link
	};
}]);

