NgProject.service('AudioPlayer', ["$rootScope", "SETTINGS", function ($rootScope, SETTINGS) {
	'use strict';
	var _volume = 1;
	var currentAudio = null;
	var params = {
		swf_path: 'scripts/vendor/audio5js/audio5js.swf',
		throw_errors: true,
		format_time: false
	};
	var defaults = {
		loaded: false,
		playing: false,
		position: null,
		duration: null,
		load_percent: null,
		position_percent: null,
		setAudioObject: setAudioObject,
		getAudioObject: getAudioObject,
		play: play,
		playPause: playPause,
		moveToPosition: moveToPosition,
		volume: volume,
		muteToggle: muteToggle,
		destroy: destroy,
		data: {
			artist: null,
			title: null
		}
	};

	var audio5 = new Audio5js(params);
	var player = angular.copy(defaults);

	function setAudioObject($audio) {
		_setTrack(SETTINGS.assetsBaseUrl + $audio.file.url, {artist: $audio.author_name, title: $audio.title});
		if (currentAudio) {
			currentAudio.playing = false;
		}
		currentAudio = $audio;
	}

	function getAudioObject() {
		return currentAudio;
	}

	function play() {
		if (!player.loaded) {
			_setCanPlayHandler();
		}
		else {
			_startPlaying();
		}
	}

	function playPause() {
		if (!player.loaded) {
			_setCanPlayHandler();
		}
		else if (audio5.playing) {
			_pause();
		}
		else {
			_startPlaying();
		}
	}

	function moveToPosition(position) {
		var seconds = (player.duration / 100) * position;
		audio5.seek(seconds);
	}

	function volume(volume) {
		if (volume) {
			_volume = volume;
			audio5.volume(_volume);
			_broadcastVolumeTrigger();
		}
		else {
			return audio5.volume();
		}
	}

	function muteToggle() {
		if (audio5.volume() > 0) {
			audio5.volume(0);
		}
		else {
			audio5.volume(_volume);
		}
		_broadcastVolumeTrigger();
	}

	function destroy() {
		audio5.pause();
		currentAudio.playing = false;
		currentAudio = null;
		player = angular.copy(defaults);
		_broadcastDestroy();
	}

	/*
	 * Private functions
	 */

	function _setTrack(source, data) {
		if (audio5.playing) {
			audio5.pause();
		}

		player.loaded = false;
		player.playing = false;
		player.position = null;
		player.duration = null;
		player.load_percent = null;
		player.position_percent = null;
		player.trackName = name;
		angular.extend(player.data, data);

		audio5.load(source);
		audio5.volume(_volume);
		_broadcastLoad();
	}

	function _setCanPlayHandler() {
		currentAudio.playing = true;
		audio5.one('canplay', function () {
			player.loaded = true;
			_startPlaying();
		});
	}

	function _startPlaying() {
		audio5.play();
		player.playing = true;
		currentAudio.playing = true;
		_broadcastPlayTrigger();
	}

	function _pause() {
		audio5.pause();
		player.playing = false;
		currentAudio.playing = false;
		_broadcastPlayTrigger();
	}

	function _updateCounters(position, duration) {
		player.position = position;
		player.position_percent = (position / (duration / 100));
	}

	function _setDuration(duration) {
		player.duration = duration;
	}

	function _updateLoadPercent() {
		player.load_percent = audio5.load_percent;
	}

	function _broadcastDestroy() {
		$rootScope.$broadcast('AudioPlayer.destroy');
	}

	function _broadcastLoad() {
		$rootScope.$broadcast('AudioPlayer.load');
	}

	function _broadcastPlayTrigger() {
		$rootScope.$broadcast('AudioPlayer.playTrigger', player.playing);
	}

	function _broadcastVolumeTrigger() {
		$rootScope.$broadcast('AudioPlayer.volumeTrigger', audio5.volume());
	}


	/*
	 * Audio5 events handlers
	 */

	audio5.on('timeupdate', function (time, duration) {
		if (!player.duration) {
			$rootScope.$apply(_setDuration(duration));
		}

		$rootScope.$apply(function () {
			_updateCounters(time, duration);
			_updateLoadPercent();
		});
	});

	audio5.on('ended', function () {
		$rootScope.$apply(function () {
			player.playing = false;
			currentAudio.playing = false;
			_broadcastPlayTrigger();
		});
	});

	return player;
}]);
