(function (ng, app) {
	'use strict';
	app.service('EventsService', ["$resource", "serviceHelper", "SETTINGS", "EventsSearchParams", "UsersSearchParams", function ($resource, serviceHelper, SETTINGS, EventsSearchParams, UsersSearchParams) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/events/:eventId/:command', {
			eventId: '@eventId',
			command: '@command'
		}, {
			allByGroup: {url: SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/events'},
			allByUser: {url: SETTINGS.apiBaseUrl + 'api/v1/users/:userId/events'},
			allByCurrentUser: {url: SETTINGS.apiBaseUrl + 'api/v1/profile/events'},
			allSubscribesByCurrentUser: {url: SETTINGS.apiBaseUrl + 'api/v1/profile/subscribed_events'},
			join: {
				method: 'POST',
				params: {command: 'members'}
			},
			un_join: {
				method: 'DELETE',
				params: {command: 'un_join'}
			},
			members: {
				params: {command: 'members'}
			},
			changeAvatar: {
				method: 'PUT',
				params: {command: 'avatar'}
			},
			event_confirmation: {
				method: 'POST',
				url: SETTINGS.apiBaseUrl + 'api/v1/event_members/confirm'
			}
		});

		var resourceSubscribes = $resource(SETTINGS['apiBaseUrl'] + 'api/v1/profile/events/subscribes/:id', {id: '@id'});

		var votingQuestionsResource = $resource(SETTINGS.apiBaseUrl + 'api/v1/events/:eventId/voting_questions/:questionId/:command', {
			eventId: '@eventId',
			questionId: '@questionId',
			command: '@command'
		});

		function processResponseCollection(response) {
			if (response.success) {
				response.items.forEach(function (item) {
					item.added *= 1000;
					item.finish *= 1000;
				});
			}
		}

		return {
			findAll: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: EventsSearchParams.process(params)
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllByGroup: function (id, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByGroup',
					parameters: angular.extend(EventsSearchParams.process(params), {groupId: id})
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllByUser: function (id, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByUser',
					parameters: angular.extend(EventsSearchParams.process(params), {userId: id})
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllByCurrentUser: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByCurrentUser',
					parameters: EventsSearchParams.process(params)
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllSubscribesByCurrentUser: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allSubscribesByCurrentUser',
					parameters: EventsSearchParams.process(params)
				});
				promise.then(processResponseCollection);
				return promise;
			},

			view: function (id) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: {eventId: id}
				});
			},

			join: function (id, data) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'join',
					parameters: _.extend({eventId: id}, data)
				});
			},

			leave: function (id) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'un_join',
					parameters: {eventId: id}
				});
			},

			confirm: function (token) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'event_confirmation',
					parameters: {
						confirmation_token: token
					}
				});
				return ( promise );
			},

			members: function (id, params) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'members',
					parameters: angular.extend(UsersSearchParams.process(params), {eventId: id})
				});
			},

			create: function (data) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'save',
					parameters: {event: data}
				});
			},

			update: function (id, data) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'update',
					parameters: {eventId: id, event: data}
				});
			},

			remove: function (id) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'remove',
					parameters: {eventId: id}
				});
			},

			getUploadImageUrl: function (id) {
				return SETTINGS.apiBaseUrl + 'api/v1/events/' + parseInt(id).toString() + '/avatar';
			},

			changeAvatar: function (eventId, imageId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'changeAvatar',
					parameters: {eventId: eventId, image_id: imageId}
				});

				return (promise);
			},

			votingQuestions: {
				findAll: function (event_id) {
					return serviceHelper.executeRequest({
						resource: votingQuestionsResource,
						name: 'get',
						parameters: {eventId: event_id}
					});
				},

				create: function (event_id, text) {
					return serviceHelper.executeRequest({
						resource: votingQuestionsResource,
						name: 'save',
						parameters: {eventId: event_id, text: text}
					});
				},

				update: function (event_id, question_id, text) {
					return serviceHelper.executeRequest({
						resource: votingQuestionsResource,
						name: 'update',
						parameters: {eventId: event_id, questionId: question_id, text: text}
					});
				},

				vote: function (event_id, question_id) {
					return serviceHelper.executeRequest({
						resource: votingQuestionsResource,
						name: 'save',
						parameters: {
							command: 'vote',
							eventId: event_id,
							questionId: question_id
						}
					});
				},

				remove: function (event_id, question_id) {
					return serviceHelper.executeRequest({
						resource: votingQuestionsResource,
						name: 'remove',
						parameters: {eventId: event_id, questionId: question_id}
					});
				}
			},

			subscribes: {
				all: function (params) {
					var promise = serviceHelper.executeRequest({
						resource: resourceSubscribes,
						name: 'get',
						parameters: EventsSearchParams.process(params)
					});
					promise.then(processResponseCollection);
					return promise;
				},
				reject: function (eventId) {
					var promise = serviceHelper.executeRequest({
						resource: resourceSubscribes,
						name: 'delete',
						parameters: EventsSearchParams.process({id: eventId})
					});
					return (promise);
				},
				rejectAll: function () {
					var promise = serviceHelper.executeRequest({
						resource: resourceSubscribes,
						name: 'delete',
						parameters: EventsSearchParams.process()
					});
					return (promise);
				}
			}
		};
	}]);
})(angular, NgProject);
