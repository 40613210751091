NgProject.controller('MaterialsBookmarksCtrl', ["$scope", "$state", "$q", "VideosService", "AudiosService", "ImagesService", "CommonData", "InterfaceCounters", function ($scope, $state, $q, VideosService, AudiosService, ImagesService, CommonData, InterfaceCounters) {
	'use strict';
	var listOptions = {
		teaserOptions: {
			buttons: {deleteByOwner: false, add: false}
		}
	};

	$scope.materialsCount = InterfaceCounters.materials;

	$scope.videos = {
		promise: VideosService.bookmarks.all,
		listOptions: listOptions
	};

	$scope.audios = {
		promise: AudiosService.bookmarks.all,
		listOptions: listOptions
	};

	$scope.images = {
		promise: ImagesService.bookmarks.all,
		listOptions: listOptions
	};

	$scope.$on('listBookmarks:remove', function (e, materialId) {
		_.remove(CommonData.data.items, {id: materialId});
		CommonData.data.count--;
	});

	$scope.$on('listBookmarks:added', function (e, material) {
		CommonData.data.items.unshift(material);
		CommonData.data.count++;
	});

	$scope.$on('listBookmarks:addedImage', function () {
		$scope.$broadcast('images-list-reload');
	});

	$scope.$watch('materialsCount', function (a, b) {
		if (_.eq(a, b)) return;
		if (a['bookmarks'] === 0) $state.go('^');
	}, true);
}]);
