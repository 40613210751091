(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "Deferred", "AuthService"];
	function Controller($scope, Deferred, AuthService) {
		$scope.isFormSubmitted = false;
		$scope.errors = {};
		$scope.credentials = {
			email: null
		};

		$scope.submitForm = function () {
			$scope.errors = {};
			Deferred.handlePromise(AuthService.resetPassword($scope.credentials.email),
				function (response) {
					if (response.success === false) {
						$scope.errors = response.errors;
					}
					$scope.isFormSubmitted = response.success;
				}
			);
		};
	}

	app.controller('modal:reset-password.ctrl', Controller);
})(angular, NgProject);
