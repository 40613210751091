NgProject.service('EventContent', function () {
	'use strict';

	var Event = this;
	var observerCallbacks = [];

	Event.data = {};
	Event.setData = setData;
	Event.reset = reset;

	Event.registerCallback = registerCallback;
	Event.notifyObservers = notifyObservers;

	return Event;

	function setData(data) {
		Event.data = data;
	}

	function reset() {
		observerCallbacks = [];
		Event.data = {};
	}

	function registerCallback(callback) {
		observerCallbacks.push(callback);
	}

	function notifyObservers() {
		angular.forEach(observerCallbacks, function (callback) {
			callback();
		});
	}
});
