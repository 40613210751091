NgProject.controller('WikiViewCtrl', ["$scope", "TermsService", "CurrentUser", function Controller($scope, TermsService, CurrentUser) {
	'use strict';
	$scope.termsOptions = {
		promise: TermsService.list,
		action: {
			show: !!CurrentUser.is_admin,
			sref: 'root.wiki.term-create'
		}
	};
}]);
