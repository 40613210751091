NgProject.directive('selectOwnerRow', ["$templateCache", "$compile", function ($templateCache, $compile) {
	'use strict';
	function link(scope, element, attrs) {
		var templateName = 'select-owner-row-type-text';
		switch (attrs['type']) {
			case 'group':
				templateName = 'select-owner-row-type-group';
				break;
			case 'user':
				templateName = 'select-owner-row-type-user';
				break;
		}
		element.html($templateCache.get('widgets.select-owner.' + templateName));
		$compile(element.contents())(scope);
	}

	return {
		scope: {$model: '=model'},
		restrict: 'E',
		link: link
	};
}]);
