'use strict';
NgProject.factory('RedactorQuestionsBox', ["$timeout", "$mdPanel", "ArticlesQuestionsService", function ($timeout, $mdPanel, ArticlesQuestionsService) {

	var Model = function ($redactor, opts) {
		this.$panel = $('<div class="redactor-questions-panel">');
		this.$redactor = $redactor;
		this.$redactorPlugin = $redactor['redactor_questions'];
		this.opts = opts;

		this.items = [];
		this.anchors = [];

		this.drawWithoutTimer = false;

		this.createPanel();
		this.loadData();
		this.attachEvents();
	};

	Model.prototype.createPanel = function () {
		this.initPanelOffset();
		this.$redactor.core.box().append(this.$panel);
	};

	Model.prototype.attachEvents = function () {
		var that = this;
		var timer = null;
		this.$redactor.core.element().on('change.callback.redactor', function () {
			if (timer) $timeout.cancel(timer);
			if (that.drawWithoutTimer) {
				that.drawWithoutTimer = false;
				$.proxy(that.draw, that)();
				return;
			}

			timer = $timeout($.proxy(that.draw, that), 400);
		});

		this.$panel
			.on('mouseover', '.redactor-question-popover', function (e) {
				var defaults = {
					controller: 'RedactorQuestionCtrl',
					controllerAs: 'ctrl',
					templateUrl: 'module.Redactor.question-popover',
					panelClass: 'redactor-question-data-popover',
					clickOutsideToClose: true,
					focusOnOpen: false,
					zIndex: 70,
					hasBackdrop: false
				};

				var config = angular.extend({}, defaults, {
					attachTo: angular.element(document.body),
					openFrom: e,
					locals: {
						$redactorPlugin: that.$redactorPlugin,
						item: _.find(that.items, {id: $(e.target).data('item-id')})
					},
					onDomAdded: function () {
						$('.redactor-question-data-popover').on('mouseleave', function (e) {
							$(this).parent().remove();
						});
					}
				});

				config.position = $mdPanel.newPanelPosition()
					.relativeTo(e.target)
					.addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.ALIGN_TOPS);

				$mdPanel.open(config);
			});
	};

	Model.prototype.loadData = function () {
		var that = this;
		ArticlesQuestionsService.list(this.opts.articleId).then(function (response) {
			that.items = _.get(response, 'items', []);
			that.draw.call(that);
		});
	};

	Model.prototype.initPanelOffset = function () {
		var offset = this.getPanelOffset();
		this.$panel.css({
			'margin-top': offset.top,
			'right': offset.right
		});
	};

	Model.prototype.draw = function () {
		var that = this;
		var containers = [];

		this.$panel.find('.panel-container').remove();

		this.initPanelOffset();

		_.forEach(this.items, function (item) {
			var $el = $('<div class="redactor-question-popover">');
			var anchor = _.where(that.anchors, {item_id: item.id})[0];
			var position = anchor ? anchor.$element[0].offsetTop : 0;
			var container = _.where(containers, {position: position})[0];

			if (!container) {
				container = {
					$element: $('<div class="panel-container">'),
					position: position
				};
				containers.push(container);
			}

			$el.attr('title', item.value).data('item-id', item.id);
			container.$element.append($el);
		});

		containers = _.sortBy(containers, 'position');

		var offset = 0;
		_.forEach(containers, function (container) {
			if (container.position > 0 && container.position > offset) {
				container.$element.css('margin-top', container.position - offset);
			}

			that.$panel.append(container.$element);
			offset = container.$element.position().top + container.$element.outerHeight(true);
		});
	};

	Model.prototype.getPanelOffset = function () {
		var $box = this.$redactor.core.box();
		var $editor = this.$redactor.core.editor();
		return {
			top: $editor.position().top + parseInt($editor.css('margin-top')),
			right: $box.outerWidth() - $editor.outerWidth() - $editor.position().left
		}
	};

	Model.prototype.addAnchor = function ($anchor, item_id) {
		this.drawWithoutTimer = true;
		this.anchors.push({
			$element: $anchor,
			item_id: item_id
		});
	};

	Model.prototype.addItem = function (item) {
		this.items.push(item);
	};

	Model.prototype.reload = function () {
		this.loadData();
	};

	return Model;
}]);


NgProject.controller('RedactorQuestionCtrl', function () {
	var that = this;
	this.open = function () {
		that.$redactorPlugin.openUpdateForm(that.item);
		that.mdPanelRef.close();
	};
});
