'use strict';
NgProject.controller('ImagesParentCtrl', ["$scope", "$state", "ImagesService", "ImagesAlbumsService", "CurrentUser", function ($scope, $state, ImagesService, ImagesAlbumsService, CurrentUser) {
	var owner = $state.current.data.owner;

	$scope.$page = {
		owner: getOwner(),
		access: {
			update: allowUpdate()
		},
		promises: {
			images: getImagesPromise,
			albums: getAlbumsPromise,
			album: getAlbumPromise,
			imagesByAlbum: getAlbumImagesPromise
		}
	};

	function getOwner() {
		switch (owner) {
			case 'self': return {};
			case 'user': return {type: 'user', id: $state.params.userId};
			case 'group': return {type: 'group', id: $state.params.groupId};
		}
	}

	function allowUpdate() {
		if (CurrentUser.is_admin || owner === 'self') {
			return true;
		}
		else if (owner === 'group') {
			return $scope.group.access.manage && !$scope.group.is_removed;
		}
	}

	function getImagesPromise(params) {
		switch (owner) {
			case 'self': return ImagesService.findAllByCurrentUser(params);
			case 'user': return ImagesService.findAllByUser($state.params.userId, params);
			case 'group': return ImagesService.findAllByGroup($state.params.groupId, params);
		}
	}

	function getAlbumsPromise(params) {
		switch (owner) {
			case 'self': return ImagesAlbumsService.findAllByCurrentUser(params);
			case 'user': return ImagesAlbumsService.findAllByUser($state.params.userId, params);
			case 'group': return ImagesAlbumsService.findAllByGroup($state.params.groupId, params);
		}
	}

	function getAlbumPromise() {
		switch (owner) {
			case 'self': return ImagesAlbumsService.findOneByCurrentUser($state.params.albumId);
			case 'user': return ImagesAlbumsService.findOneByUser($state.params.userId, $state.params.albumId);
			case 'group': return ImagesAlbumsService.findOneByGroup($state.params.groupId, $state.params.albumId);
		}
	}

	function getAlbumImagesPromise(params) {
		switch (owner) {
			case 'self': return ImagesService.findAllByCurrentUserInAlbum($state.params.albumId, params);
			case 'user': return ImagesService.findAllByUserInAlbum($state.params.userId, $state.params.albumId, params);
			case 'group': return ImagesService.findAllByGroupInAlbum($state.params.groupId, $state.params.albumId, params);
		}
	}
}]);
