NgProject.service('InterfaceCounters', ["$rootScope", "$interval", "AUTH_EVENTS", "CurrentUser", "CountersService", "faye", "SETTINGS", function Service($rootScope, $interval, AUTH_EVENTS, CurrentUser, CountersService, faye, SETTINGS) {
	'use strict';
	var defaults = {
		materials: {subscribes: 0, total: 0},
		audios: {subscribes: 0, total: 0},
		videos: {subscribes: 0, total: 0},
		articles: {subscribes: 0, total: 0},
		events: {subscribes: 0, total: 0},
		im: {new_messages: 0, total: 0}
	};

	var that = this;

	var fayeClient = {
		enabled: false,
		client: faye(SETTINGS.fayeClient),
		subscription: null,
		init: function initPubSub() {
			this.subscription = fayeClient.client.subscribe('/counters/' + CurrentUser.id, function (response) {
				response = angular.fromJson(response);
				setModelData(response['counters']);
				if (DEBUG) console.log('InterfaceCounters PubSub Response', response);
			});
		},
		reset: function () {
			if (this.subscription) this.subscription.cancel();
		}
	};

	var nativeClient = {
		loadData: function () {
			return CountersService.load();
		},
		interval: {
			promise: null,
			start: function (callback) {
				nativeClient.interval.promise = $interval(callback, 20000);
			},
			cancel: function () {
				$interval.cancel(nativeClient.interval.promise);
			}
		}
	};

	function init() {
		if (!CurrentUser.id) return false;
		_.extend(that, angular.copy(defaults));
		nativeClient.loadData().then(function (response) {
			if (response.success) setModelData(response['counters']);
			if (fayeClient.enabled) fayeClient.init();
			else nativeClient.interval.start(update);
		});
	}

	function reset() {
		_.extend(that, angular.copy(defaults));
		fayeClient.reset();
		nativeClient.interval.cancel();
	}

	function update() {
		if (!CurrentUser.id) return false;
		nativeClient.loadData().then(function (response) {
			nativeClient.interval.cancel();
			nativeClient.interval.start(update);
			if (response.success) setModelData(response['counters']);
		});
	}

	function setModelData(data) {
		if (data.hasOwnProperty('articles')) {
			angular.extend(that.articles, data.articles);
			that.articles.total = that.articles.subscribes;
		}
		if (data.hasOwnProperty('audios')) {
			angular.extend(that.audios, data.audios);
			that.audios.total = that.audios.subscribes;
		}
		if (data.hasOwnProperty('videos')) {
			angular.extend(that.videos, data.videos);
			that.videos.total = that.videos.subscribes;
		}
		if (data.hasOwnProperty('im')) {
			angular.extend(that.im, data.im);
			that.im.total = that.im.new_messages;
		}
		if (data.hasOwnProperty('events')) {
			angular.extend(that.events, data.events);
			that.events.total = that.events.subscribes;
		}
		if (data.hasOwnProperty('materials')) {
			angular.extend(that.materials, data.materials);
			that.materials.total = _.sum([that.audios.subscribes, that.videos.subscribes]);
		}
	}

	$rootScope.$on(AUTH_EVENTS.authenticationSuccess, init);
	$rootScope.$on(AUTH_EVENTS.loginSuccess, init);
	$rootScope.$on(AUTH_EVENTS.logoutSuccess, reset);
	$rootScope.$on(AUTH_EVENTS.sessionTimeout, reset);
	$rootScope.$on('InterfaceCounters:update', update);

	init();

	return this;
}]);

