'use strict';
NgProject.service('PageError', function () {

	function Model() {
		this.API_NOT_FOUND_ERROR = 'APINotFoundError';
		this.API_NOT_ALLOWED_ERROR = 'APINotAllowedError';
		this.PAGE_ACCESS_REQUIRE_AUTHORIZATION = 'PageAccessRequireAuthorization';
	}

	Model.prototype = EventEmitter2.prototype;

	Model.prototype.trigger = function (type, data) {
		var event = new CustomEvent(type, {detail: data});
		this.emit(event.type, event);
		return this;
	};

	Model.prototype.onNotFoundError = function (callback) {
		this.on(this.API_NOT_FOUND_ERROR, callback);
		return this;
	};

	Model.prototype.onPageRequireAuthorization = function (callback) {
		this.on(this.API_NOT_ALLOWED_ERROR, callback);
		this.on(this.PAGE_ACCESS_REQUIRE_AUTHORIZATION, callback);
		return this;
	};

	return new Model();
});
