NgProject.directive('articlesFilters', ["Deferred", "TermsService", function (Deferred, TermsService) {
	'use strict';

	function loadTypes(callback) {
		Deferred.handlePromise(TermsService.contentTypes(), function (response) {
			var types = [];
			if (response.success) {
				types = response.items;
			}
			callback(types);
		});
	}

	function link(scope, element, attrs, controller) {
		var filtersDefaults = {
			typeId: null
		};

		function init() {
			loadTypes(function (types) {
				scope.$filtersData.types = types;
			});
		}

		function getRequestParams() {
			return {
				typeId: scope.$filters.typeId
			};
		}

		function applyFilters() {
			controller.search(getRequestParams());
		}

		scope.$filters = angular.copy(filtersDefaults);

		scope.$filtersData = {
			types: []
		};

		scope.$filtersControls = {
			reset: function() {
				scope.$filters = angular.copy(filtersDefaults);
				applyFilters();
			}
		};

		scope.$watch('$filters.typeId', function (value, oldValue) {
			if (value !== null && value !== oldValue)
				applyFilters();
		});

		init();
	}

	return {
		require: '^^articles',
		templateUrl: 'widgets.articles.articles-filters',
		restrict: 'E',
		scope: true,
		link: link
	};
}]);
