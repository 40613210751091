NgProject.directive('newsList', function () {
	'use strict';
	function link(scope, element, attrs, controller) {
		scope.$controls.load = function () {
			controller.loadNextPage();
		};
		scope.$controls.disabled = function () {
			var data = scope.$news;
			return data.isLoading || data.endOfList;
		};
	}

	return {
		require: '^^news',
		templateUrl: 'widgets.news.news-list',
		restrict: 'E',
		link: link
	};
});
