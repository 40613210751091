'use strict';
NgProject.controller('imUsersListModalCtrl', ["$scope", "$state", "UsersService", "ImService", function ($scope, $state, UsersService, ImService) {
	$scope.$ctrl = {
		list: 'follows',
		startChat: function (user) {
			ImService.users(user.id).then(function (response) {
				if (response.success) {
					$state.go('root.im.chat', {chatId: response.chat.id});
				}
			});
		},

		showList: function (name) {
			$scope.$ctrl.list = name;
			$scope.$broadcast($scope.$config.options.events.onReloadList);
		}
	};

	$scope.$config = {
		promise: statePromise,
		options: {
			events: {
				onReloadList: 'reload-users-list'
			}
		},
		listOptions: {
			template: 'pages.im.modal.users-list-item'
		}
	};

	function statePromise(params) {
		var promise = null;
		switch ($scope.$ctrl.list) {
			case 'common':
				promise = UsersService.list(params);
				break;
			case 'followers':
				promise = UsersService.followers('self', params);
				break;
			case 'follows':
				promise = UsersService.follows('self', params);
				break;
		}

		return promise;
	}
}]);
