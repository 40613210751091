NgProject.service('UploadAvatar', function () {
	'use strict';

	var UAData = this;
	var observerCallbacks = [];

	UAData.data = {};
	UAData.setData = setData;
	UAData.reset = reset;

	UAData.registerCallback = registerCallback;
	UAData.notifyObservers = notifyObservers;

	return UAData;

	function setData(data) {
		UAData.data = data;
	}

	function reset() {
		observerCallbacks = [];
		UAData.data = {};
	}

	function registerCallback(callback) {
		observerCallbacks.push(callback);
	}

	function notifyObservers() {
		angular.forEach(observerCallbacks, function (callback) {
			callback();
		});
	}
});
