NgProject.directive('termsList', ["$parse", function ($parse) {
	'use strict';
	var defaults = {
		loadMore: true,
		template: 'widgets.terms.terms-list-items'
	};

	function compile(tElement) {
		var template = '<div ng-if="$terms.count" ng-include="::$termsList.options.template" class="nav-group-wrap"></div>';
		var content = tElement.html();
		if (content.length) {
			template = angular.element(template);
			template.removeAttr('ng-include').html(content);
		}
		tElement.html(template);
		return link;
	}

	function link(scope, element, attrs, controller) {
		var options = angular.extend({}, angular.copy(defaults), attrs.options ? $parse(attrs.options)(scope) : {});
		scope.$termsList = {options: options};

		if (options.loadMore) {
			if (scope.$modalWindow) {
				options.infiniteScrollContainer = '.modal';
			}
			scope.$termsList.load = function () {
				var page = controller.getSearchParams().page || 1;
				controller.loadByPage(page + 1);
			};
			scope.$termsList.disabled = function () {
				var data = scope.$terms;
				return data.isLoading || data.endOfList;
			};
		}
	}

	return {
		require: '^?terms',
		restrict: 'E',
		compile: compile
	};
}]);
