NgProject.controller('WikiViewTermCtrl', ["$scope", "$location", "$state", "Deferred", "TermsService", "VideosService", "AudiosService", "ImagesService", "ArticlesService", "DefinitionHelper", function ($scope, $location, $state, Deferred, TermsService, VideosService, AudiosService, ImagesService, ArticlesService, DefinitionHelper) {
	'use strict';
	var defaultParams = {
		articles: {
			pinned: 0
		}
	};

	$scope.stateTermId = $state.params.termId;
	$scope.$term = {
		isLoading: false
	};
	$scope.$definitions = {
		isLoading: false
	};
	$scope.controls = {
		images: function (params) {
			return ImagesService.findAllByTerm($scope.stateTermId, params);
		},
		openInfoModal: function (index) {
			$scope.$modal.open('wiki-definition-info', index);
		}
	};

	$scope.sections = {
		videos: {
			promise: function (params) {
				return VideosService.findAllByTerm($scope.stateTermId, params);
			}
		},
		images: {
			promise: function (params) {
				return ImagesService.findAllByTerm($scope.stateTermId, params);
			},
			options: {
				requestParams: {limit: 24}
			}
		},
		audios: {
			promise: function (params) {
				return AudiosService.findAllByTerm($scope.stateTermId, params);
			}
		},
		articles: {
			promise: function (params) {
				params = _.extend(defaultParams.articles, params);
				return ArticlesService.findAllByTerm($scope.stateTermId, params);
			},
			listOptions: {itemClass: 'compact'}
		}
	};

	init();

	function init() {
		loadMainDefinition($scope.stateTermId);
	}

	function loadMainDefinition(termId) {
		var promises = [
			TermsService.term(termId),
			TermsService.termDefinitions(termId)
		];
		Deferred.handleAllPromises(promises, function (term, definitions) {
			if (term.success) {
				delete term.success;
				if(term.use_slug_in_url) {
					//Fallback for IE10
					if (!window.location.origin) {
						window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
					}
					window.history.replaceState(null,null, window.location.origin + '/wiki/' + term.term.slug);
				}
				applyTermData(term);
				$scope.$term.isLoading = true;
			}
			if (definitions.success) {
				delete definitions.success;
				if (definitions.items) applyDefinitionsData(term, definitions.items);
				$scope.$definitions.isLoading = true;
			}
		});
	}

	function applyTermData(data) {
		if (!!data.term.definition) {
			if (data.term.definition['created_at'] === data.term.definition['updated_at']) {
				delete data.term.definition['updated_at'];
			} else {
				delete data.term.definition['created_at'];
			}
			data.term.definition.open = false;
			data.term.definition.value = DefinitionHelper.set(data.term, data.term.definition.value);
		}
		$scope.$term = data.term;

		$scope.$tags = data.tagging;
		$scope.$tags.show = (function () {
			var f = false;
			_.map(data.tagging, function (item) {
				if (item === true) {
					f = true;
				}
			});
			return f;
		})();
	}

	function applyDefinitionsData(term, data) {
		var filter = [];
		_.map(data, function (item) {
			if (!item.main) {
				if (item['created_at'] === item['updated_at']) {
					delete item['updated_at'];
				} else {
					delete item['created_at'];
				}
				item.open = false;
				item.value = DefinitionHelper.set(term.term, item.value);
				filter.push(item);
			}
		});
		$scope.$definitions.items = filter;
	}
}]);
