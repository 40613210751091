NgProject.directive('usersList', ["$parse", function ($parse) {
	'use strict';
	var defaults = {
		loadMore: true,
		showBadge: false,
		getBadgeType: null,
		template: 'widgets.users.users-list-item'
	};

	function link(scope, element, attrs, controller) {
		var options = angular.extend({}, angular.copy(defaults), attrs.options ? $parse(attrs.options)(scope) : {});
		scope.$usersList = {options: options};
		if (options.loadMore) {
			if (scope.$modalWindow) {
				options.infiniteScrollContainer = '.modal';
			}
			scope.$usersList.load = function () {
				var page = controller.getSearchParams().page || 1;
				controller.loadByPage(page + 1);
			};
			scope.$usersList.disabled = function () {
				var data = scope.$users;
				return data.isLoading || data.endOfList;
			};
		}
	}

	return {
		require: '^^users',
		templateUrl: 'widgets.users.users-list',
		restrict: 'E',
		scope: true,
		link: link
	};
}]);
