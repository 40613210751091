'use strict';
NgProject.factory('ModalWindowInstance', ["$modal", "$q", "ContentStabilizer", function ($modal, $q, ContentStabilizer) {
	// Define the constructor function.
	function ModalWindowInstance(config, args) {
		this.deferred = $q.defer();
		this.promise = this.deferred.promise;
		this.config = config;
		this.args = args || [];
		this.init();
	}

	function getData(args) {
		return function (index, defaultValue) {
			if (arguments.length === 0) return args;
			if (index > args.length && arguments.length === 2) return defaultValue;
			return args[index];
		};
	}

	ModalWindowInstance.prototype = EventEmitter2.prototype;

	ModalWindowInstance.prototype.initParentWatcher = function () {
		var $scope = this.modal.$scope;
		$scope.$onParentStateLoaded = function (expression, callback) {
			if ($scope.$eval(expression)) {
				callback();
				return;
			}

			var deregistration = $scope.$watch(expression, function (value) {
				if (value) {
					callback();
					deregistration();
				}
			});
		};
	};

	ModalWindowInstance.prototype.init = function () {
		var that = this;
		var instance = that.modal = $modal(that.config);
		var $scope = instance.$scope;
		var originalFunctions = {
			hide: instance.hide,
			destroy: instance.destroy
		};

		$scope.$modalWindow = {data: getData(that.args)};

		if (that.config.relative) {
			that.initParentWatcher();
		}

		instance.hide = function () {
			var event = new CustomEvent('hide', {cancelable: true});
			that.emit(event.type, event);
			if (!event.defaultPrevented) {
				originalFunctions.hide();
				ContentStabilizer.resetData();
				ContentStabilizer.notifyObservers();
			}
		};

		instance.destroy = function () {
			var event = new CustomEvent('destroy', {cancelable: true});
			that.emit(event.type, event);
			if (!event.defaultPrevented) {
				originalFunctions.destroy();
			}
		};

		$scope.$close = function () {
			$scope.$$postDigest(instance.hide);
		};

		$scope.$on('modal.hide', function () {
			instance.destroy();
			that.deferred.resolve();
		});

		if (!that.config.disableStateChangeHandler) {
			// Закрыть модальное окошко если меняется state
			$scope.deregister = $scope.$one('$stateChangeStart', function (e, toState) {
				// если идет переключение с одного модально окна - на другие
				if (toState.modal) instance.destroy();
				// переход из модального окна на обычную страницу
				else instance.hide();
			});
		}
	};

	return (ModalWindowInstance);
}]);
