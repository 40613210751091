NgProject.controller('ModalChangeMakerCtrl', ["$rootScope", "$scope", "$timeout", "Deferred", "TermsService", "UsersService", "GroupsService", "cfpLoadingBar", function ($rootScope, $scope, $timeout, Deferred, TermsService, UsersService, GroupsService, cfpLoadingBar) {
	'use strict';
	var defaults = {
		queryChangeTimeout: 300,
		allowTextValue: false,
		allowEmptyValue: false,
		minQueryLength: 2,
		title: 'Владелец',
		eventName: 'maker-changed'
	};

	var config = $scope.$modalWindow.data(0) || {};
	var options = angular.extend({}, defaults, _.get(config, 'options', {}));

	$scope.$options = options;
	$scope.$users = [];
	$scope.$groups = [];
	$scope.fData = {
		isLoading: false,
		query: '',
		author: _.get(config, 'data', null)
	};

	$scope.$controls = {
		search: loadRemoteData,
		select: function (selected) {
			var maker = {
				type: (options.allowTextValue && !selected) ? 'text' : selected.host_type.toLowerCase()
			};
			if (maker.type !== 'text') {
				cfpLoadingBar.start();
				$scope.fData.isLoading = true;
				$scope.fData.author = null;
				Deferred.handlePromise((maker.type === 'user' ? UsersService.about : GroupsService.main)(selected.host_id), function (response) {
					cfpLoadingBar.complete();
					$scope.fData.isLoading = false;
					if (response.success) {
						angular.extend(maker, {
							id: selected.id,
							object_id: response[maker.type].id,
							data: response[maker.type]
						});
						$scope.fData.author = maker;
					}
				});
			}
			else if (options.allowTextValue) {
				maker.data = $scope.fData.query.toString();
				$scope.fData.author = maker;
			}
		},
		reset: function () {
			if(options.allowEmptyValue) $scope.fData.author = null;
		},
		save: function () {
			$rootScope.$broadcast(options.eventName, $scope.fData.author);
			$scope.$close();
		},
		isAllowRequest: function () {
			return $scope.fData.query.length >= options.minQueryLength;
		}
	};

	var timeoutPromise;
	$scope.$watch('fData.query', function (value, oldValue) {
		if (value === oldValue) return;
		if (timeoutPromise) $timeout.cancel(timeoutPromise);
		$scope.$users = [];
		$scope.$groups = [];
		timeoutPromise = $timeout(loadRemoteData, options.queryChangeTimeout);
	});

	function loadRemoteData() {
		if ($scope.$controls.isAllowRequest()) {
			Deferred.handlePromise(TermsService.authors({q: $scope.fData.query.toString()}), function (response) {
				if (response.success) {
					$scope.$users = _.where(response.items, {host_type: 'User'});
					$scope.$groups = _.where(response.items, {host_type: 'Group'});
				}
			});
		}
	}
}]);
