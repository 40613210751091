NgProject.directive('bSearch', ["$parse", function ($parse) {
	'use strict';
	controller.$inject = ["$scope", "$attrs"];
	var defaults = {
		enableQueryPane: true,
		enableFilters: true,
		showFilters: false,
		action: {
			show: false,
			label: null,
			type: null, // link | button
			sref: null, // if type=link
			callback: null // if type=button
		}
	};

	function controller($scope, $attrs) {
		var options = angular.copy(defaults);
		if ($attrs.hasOwnProperty('options')) {
			angular.extend(options, $parse($attrs.options)($scope));
		}

		options.action.type = _.isEmpty(options.action.sref) ? 'button' : 'link';

		$scope.$bSearch = {
			enableQueryPane: options.enableQueryPane,
			openFilters: options.openFilters,
			showFilters: options.showFilters,
			enableFilters: options.enableFilters,
			action: options.action,
			query: options.query,
			toggleFilters: function () {
				if (!this.openFilters) this.openFilters = true;
				this.showFilters = !this.showFilters;
			}
		};

		this.search = $scope.search;
		this.$bSearch = $scope.$bSearch;
	}

	function link(scope, element) {
		element.addClass('b-search');
	}

	return {
		restrict: 'E',
		link: link,
		controller: controller,
		scope: true
	};
}]);
