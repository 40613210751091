(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "EventsService"];
	function Controller($scope, EventsService) {
		$scope.questions = [];

		$scope.$controls = {
			ready: false,
			user_votes: 10,
			max_user_votes: 10
		};

		$scope.$controls.userVotesPercents = function () {
			return Math.floor((this.max_user_votes - this.user_votes) / this.max_user_votes * 100);
		};

		$scope.$controls.pollVote = function (question) {
			if (question.voted || this.user_votes == 0) return;
			question.votes++;
			question.voted = true;
			this.user_votes--;

			EventsService.votingQuestions.vote($scope.event.id, question.id).then(function(response) {
				console.log(response);
			});
		};

		$scope.$controls.remove = function (question) {
			EventsService.votingQuestions.remove($scope.event.id, question.id).then(function(response) {
				if (response.success) _.remove($scope.questions, question);
			});
		};

		$scope.$controls.update = function (question) {
			$scope.$modal.open('event-voting-question-form', $scope.event, angular.copy(question));
		};

		$scope.$on('createdEventVotingQuestion', function (e, question) {
			$scope.questions.push(question);
		});

		$scope.$on('updatedEventVotingQuestion', function (e, question) {
			var index = _.findIndex($scope.questions, {id: question.id});
			if (index < 0) return;
			$scope.questions[index].text = question.text;
		});

		EventsService.votingQuestions.findAll($scope.event.id).then(function(response) {
			if (response.success) {
				$scope.$controls.user_votes = $scope.$controls.max_user_votes - _.sum(response.items, 'voted');
				$scope.questions = angular.copy(response.items);
				$scope.$controls.ready = true;
			}
		});
	}

	app.controller('PageEventVotingQuestionsCtrl', Controller);
})(angular, NgProject);
