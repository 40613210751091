NgProject.controller('PageArticleSeriesCtrl', ["$scope", "$timeout", "ArticlesService", function Controller($scope, $timeout, ArticlesService) {
	'use strict';

	$scope.newSeries = {
		title: ''
	};

	$scope.series = {
		titleEdit: '',
		items: [],
		addSeries: function () {
			var title = _.trim($scope.newSeries.title);
			if (!_.isEmpty(title)) {
				ArticlesService.series.create(title).then(function (response) {
					if (response.success) {
						delete response.serie.articles;
						$scope.series.items.push(response.serie);
						$scope.newSeries.title = '';
					}
				});
			}
		},
		editSeries: function (item) {
			item.change = true;
			$scope.series.titleEdit = _.clone(item.title);
			$timeout(function () {
				angular.element('#series-edit-' + item.id).focus();
			}, 10);
		},
		updateSeries: function (item) {
			var title = _.trim(item.title);
			if (_.isEmpty(item.title) || _.isEqual($scope.series.titleEdit, title)) {
				item.change = false;
				item.title = $scope.series.titleEdit;
				return;
			}
			ArticlesService.series.update(item.id, title).then(function (response) {
				if (response.success) item.change = false;
			});
		},
		removeSeries: function (item) {
			var confirm = window.confirm('Вы действительно хотите удалить этот цикл?');
			if (confirm) ArticlesService.series.remove(item.id).then(function (response) {
				if (response.success) _.remove($scope.series.items, item);
			});
		}
	};

	getSeriesList();

	function getSeriesList() {
		var seriesItems = $scope.$modalWindow.data(0);
		if (_.isEmpty(seriesItems))  ArticlesService.series.byUser().then(function (response) {
			if (response.success) $scope.series.items = response.items;
		});
		else $scope.series.items = seriesItems;
	}
}]);
