'use strict';
(function ($) {
	var plugin = 'redactor_audios';

	$.Redactor.prototype[plugin] = function () {
		return {
			langs: {
				ru: {'redactor-audios': 'Аудиозаписи'}
			},

			init: function () {
				if (this.$element[0].hasAttribute('redactor-audio')) {
					this[plugin].createButton();
					this.broadcastEvent('$redactorAudioInitialization');
				}
			},

			createButton: function () {
				var button = this.button.add('audios', this.lang.get('redactor-audios'));
				this.button.addCallback(button, this[plugin].buttonCallback);
			},

			buttonCallback: function () {
				this.openModal('redactor-audios', this);
			}
		};
	};
})(jQuery);
