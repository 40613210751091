NgProject.controller('GroupArticlesListCtrl', ["$scope", "ArticlesService", "modalWindow", function ($scope, ArticlesService, modalWindow) {
	'use strict';
	$scope.groupArticles = {
		promise: function (params) {
			return ArticlesService.findAllByGroup($scope.group.id, params);
		},
		action: {
			show: $scope.group.access.manage && !$scope.group.is_removed,
			callback: function() {
				modalWindow.open('group-add-material-options', $scope.group.id);
			}
		},
		listOptions: {
			teaserOptions: {
				buttons: {deleteByOwner: $scope.group.access.manage}
			}
		}
	};

	$scope.$on('group-reload-articles-list', function() {
		$scope.$broadcast('articles-list-reload');
	});
}]);
