NgProject.controller('RedactorTermsModalCtrl', ["$scope", "TermsService", function ($scope, TermsService) {
	'use strict';
	var plugin = $scope.$modalWindow.data(0);
	var data = $scope.$modalWindow.data(1);

	$scope.$ctrl = {
		ready: false,
		model: data.json ? data.json.term + ':' + data.json.definition : null,
		submit: function () {
			var params = $scope.$ctrl.model.split(':');
			plugin.insert({term: params[0], definition: params[1]});
			$scope.$close();
		}
	};

	$scope.$config = {
		promise: TermsService.list,
		options: {
			group: false,
			requestParams: {limit: 30, extended: true}
		},
		searchOptions: {showFiltersPanel: true}
	};

	if (data.json) {
		TermsService.term(data.json.term)
			.then(function (response) {
				setSearchQuery(response.term.title);
				$scope.$ctrl.ready = true;
			});
	}
	else if (data.query) {
		setSearchQuery(data.query);
		$scope.$ctrl.ready = true;
	}

	function setSearchQuery(query) {
		$scope.$config.searchOptions.query = query;
		$scope.$config.options.requestParams.q = query;
	}
}]);
