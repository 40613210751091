(function(ng, app){
    app.service('ArticlesPopularManageService', ["$resource", "serviceHelper", "SETTINGS", function($resource, serviceHelper, SETTINGS){
        var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/administration/settings_popular_articles', null, {
            saveRefreshPeriod: {
                url: SETTINGS.apiBaseUrl + 'api/v1/administration/settings_popular_articles',
                method: 'PUT'
            },

            getRefreshPeriod: {
                url: SETTINGS.apiBaseUrl + 'api/v1/administration/settings_popular_articles',
                method: 'GET'
            }
        } )

        return {
            saveRefreshPeriod: function(refreshPeriodInDays) {
                return serviceHelper.executeRequest({
					resource: resource,
					name: 'saveRefreshPeriod',
					parameters: {number_of_days: refreshPeriodInDays}
				});
            },

            getRefreshPeriod: function() {
                return serviceHelper.executeRequest({
					resource: resource,
					name: 'getRefreshPeriod',
				});
            }
        }
    }]);
})(angular, NgProject);