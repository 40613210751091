'use strict';
(function () {
	var module = angular.module('project.ResizeEnd', []);
	var event_name = 'resize:end';

	module.run(["$rootScope", "$window", function($rootScope, $window) {
		$window.addEventListener(event_name, function() {
			$rootScope.$broadcast(event_name);
		});
	}]);
})();
