NgProject.controller('WikiDefinitionFormCtrl', ["$scope", "$state", "$filter", "$q", "CurrentUser", "Deferred", "TermsService", "cfpLoadingBar", function ($scope, $state, $filter, $q, CurrentUser, Deferred, TermsService, cfpLoadingBar) {
	'use strict';
	$scope.$definition = {
		value: '',
		main: false,
		author: null,
		term: null
	};

	$scope.fData = {
		isReady: false,
		errors: {},
		resetErrors: function () {
			$scope.fData.errors = {};
		},
		hasErrors: function () {
			return !_.isEmpty($scope.fData.errors);
		}
	};

	$scope.controls = {
		save: function () {
			var data = getSubmitData();
			var promise = null;
			if (!$scope.$definition.id) promise = TermsService.create_definition($state.params.termId, data);
			else promise = TermsService.update_definition($state.params.termId, $scope.$definition.id, data);
			$scope.fData.resetErrors();
			cfpLoadingBar.start();
			Deferred.handlePromise(promise, function (response) {
				cfpLoadingBar.complete();
				if (response.success) redirectToViewPage();
				else _savingFailed(response.errors);
			});
		},
		remove: function () {
			cfpLoadingBar.start();
			Deferred.handlePromise(TermsService.remove_definition($state.params.termId, $scope.$definition.id), function (response) {
				cfpLoadingBar.complete();
				if (!!response.success) {
					_savingFailed(response.errors);
					return;
				}
				redirectToViewPage();
			});
		},
		cancel: redirectToViewPage
	};

	$scope.controls.changeAuthor = function () {
		$scope.$modal.open('change-maker', {
			options: {
				title: 'Автор определения',
				allowTextValue: true
			},
			data: angular.copy($scope.$definition.author)
		});
	};
	
	$scope.$on('maker-changed', function (e, author) {
		$scope.$definition.author = author;
	});

	function loadData(term_id, definition_id) {
		var deferred = $q.defer();
		Deferred.handlePromise(TermsService.termDefinition(term_id, definition_id), function (response) {
			if (!_.isEmpty(response.definition)) {
				setModelData(response.definition);
				deferred.resolve();
			}
		});
		return deferred.promise;
	}

	function setModelData(data) {
		angular.extend($scope.$definition, {
			id: data.id,
			main: data.main,
			value: data.value,
			author: data.author,
			term: data.term
		});
	}

	function loadFormData(term_id) {
		var deferred = $q.defer();
		var promises = [
			TermsService.term(term_id),
			TermsService.authors({host_id: CurrentUser.id, host_type: 'user', per_page: 1})
		];
		Deferred.handleAllPromises(promises, function (term, authors) {
			if (term.success) $scope.$definition.term = term.term;
			if (authors.success) $scope.$definition.author = {
				id: authors.items[0].id,
				type: 'user',
				data: CurrentUser.toObject()
			};
			if (_.every([term.success, authors.success])) deferred.resolve();
		});
		return deferred.promise;
	}

	function _savingFailed(errors) {
		$scope.fData.errors.definition = errors.definition.value;
	}

	function redirectToViewPage() {
		$state.go('root.wiki.view.term', {termId: $state.params.termId});
	}

	function getSubmitData() {
		var data = {
			main: $scope.$definition.main,
			value: $scope.$definition.value,
			author: $scope.$definition.author.type === 'text' ? $scope.$definition.author.data : $scope.$definition.author.id
		};
		if ($filter('htmlContentIsEmpty')(data.value)) data.value = '';
		return data;
	}

	// Initialization
	var loadPromise = !$state.params.definitionId ? loadFormData($state.params.termId) : loadData($state.params.termId, $state.params.definitionId);
	loadPromise.then(function () {
		$scope.fData.isReady = true;
	});
}]);
