NgProject.controller('imPageCtrl', ["$rootScope", "$scope", "$state", "$timeout", "SETTINGS", "Deferred", "ImService", "ImData", "ImCounter", "$mdDialog", function Controller($rootScope, $scope, $state, $timeout, SETTINGS, Deferred, ImService, ImData, ImCounter, $mdDialog) {
	'use strict';

	var options = {requestParams: {per_page: 25}};
	var $chats = $scope.$chats = getData();

	$scope.imData = ImData.data;
	$scope.$controls = {
		load: function () {
			var page = options.requestParams.page || 1;
			loadByPage(page + 1);
		},
		disabled: function () {
			var data = $scope.$chats;
			return data.isLoading || data.endOfList;
		},
		leaveConversation: function (chat) {
			if (confirm('Вы действительно хотите покинуть групповой чат?')) {
				Deferred.handlePromise(ImService.leave(chat['recepient'].id), function (response) {
					if (response.success) {
						_.remove($rootScope.chatsListNotify, {'id': chat.id});
						_.remove($chats.items, {'id': chat.id});
						$state.go('root.im');
					}
				});
			}
		},
		isImActive: function () {
			return !$state.includes('root.im.chat');
		}
	};

	loadRemoteData().then(updateChat);

	$scope.$on('$locationChangeSuccess', function () {
		if ($state.is('root.im')) {
			ImData.resetData();
		}
	});

	function loadByPage(page) {
		if (page && page > 1) options.requestParams.page = page;
		loadRemoteData(true);
	}

	function loadRemoteData(append) {
		var params = options.requestParams;
		$chats.isLoading = true;
		return Deferred.handlePromise(ImService.chats(params), function (response) {
			if (response.success) {
				var sort = response.items;
				if (append) $chats = getData(response.count, $chats.items.concat(sort));
				else $chats = getData(response.count, sort);
				angular.extend($chats, {
					isLoading: false,
					endOfList: sort.length === 0 || response.count === $chats.items.length
				});
				$scope.$chats = $chats;
			}
		});
	}

	function getData(count, items) {
		return {
			count: count || 0,
			items: items || [],
			isLoading: true,
			endOfList: true
		};
	}

	function updateChat() {
		$scope.$watchCollection('imFaye', function (data, oldData) {
			if (!data['id'] || _.eq(data, oldData)) return;
			var find = _.find($chats.items, {id: data['id']});
			if (_.isEmpty(find)) // если не найден в списке чатов, запросить у сервера и добавить
				return Deferred.handlePromise(ImService.chats({id: data['id']}), function (response) {
					$chats.items.unshift(response.chat);
				});
			if ($chats.items[0]['id'] === data['id']) _update($chats.items[0], data);  // если первый в списке, обновить
			else { // иначе найти, обновить, сделать первым
				var item = _.remove($chats.items, {id: data['id']});
				$chats.items.unshift(_update(item[0], data));
			}
		});

		function _update(item, data) {
			if (_.isEmpty(item)) return;
			var chatId = parseInt($state.params.chatId);
			item.last_message = data.message;
			if (item.id !== chatId)
				ImCounter.getCounter().then(function () {
					ImCounter.resetChatCounter(item);
				});
			else ImCounter.readCounter(chatId);
			return item;
		}
	}
}]);
