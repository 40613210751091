'use strict';

NgProject.service('MemberData', ["$q", "$filter", "ProfileSettingsService", "UsersService", "EventsService", "CurrentUser", function ($q, $filter, ProfileSettingsService, UsersService, EventsService, CurrentUser) {
	var Member = this;
	var memberSendData = {
		headman: {
			fullname: null,
			email: null,
			utc_offset: new Date().getTimezoneOffset() || ''
		}
	};

	Member.registrationAuthUser = registrationAuthUser;
	Member.getMemberData = getMemberData;

	return Member;

	function registrationAuthUser(eventId) {
		if (!!eventId) return getAuthData().then(function () {
			return EventsService.join(eventId, memberSendData);
		});
	}

	function getAuthData() {
		var deferred = $q.defer();
		var promises = [ProfileSettingsService.email.get(), UsersService.about('self')];
		memberSendData.headman.user_id = CurrentUser.id;
		$q.all(promises).then(function (value) {
			var phone = null;
			if (value[0].success) {
				memberSendData.headman.fullname = $filter('username')(CurrentUser);
				memberSendData.headman.email = value[0].email;
			}
			if (value[1].success) {
				phone = _.find(_.get(value[1], 'user.object_contacts', null), {key: 'phone'});
				if (!!phone) {
					memberSendData.headman.phone = phone.value;
				}
			}
			deferred.resolve();
		});
		return deferred.promise;
	}

	function getMemberData() {
		return memberSendData;
	}
}]);
