(function (ng, app) {
	'use strict';
	app.filter('plural', ["_", function (_) {
		return function (n) {
			var values = _(arguments).toArray(arguments).slice(1).value();
			return n + ' ' + values[plural_index(n)];
		};
	}]);

	app.filter('pluralText', ["_", function (_) {
		return function (n) {
			var values = _(arguments).toArray(arguments).slice(1).value();
			return values[plural_index(n)];
		};
	}]);

	function plural_index(n) {
		// Russian
		return n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2;
	}
})(angular, NgProject);
