'use strict';

$(function () {
	$.Redactor.settings = {
		lang: 'ru',
		script: false,
		shortcuts: false,
		structure: true,
		toolbarFixed: true,
		toolbarFixedTopOffset: 60,
		minHeight: 200,
		pastePlainText: false,
		pasteImages: true,
		pasteLinks: true,
		pasteLinkTarget: '_blank',

		dragImageUpload: false,
		multipleImageUpload: false,
		clipboardImageUpload: false,

		buttons: ['format', 'bold', 'italic', 'lists', 'link', 'horizontalrule'],
		formatting: ['p', 'blockquote', 'h2', 'h3', 'h4'],
		plugins: [
			'alignment',
			'table',
			'redactor_questions',
			'redactor_audios',
			'redactor_videos',
			'redactor_images',
			'redactor_terms',
			'redactor_links',
			'redactor_iconic',
			'redactor_edges',
			'redactor_elements_zindex'
		]
	};

	$.Redactor.settings.presets = {
		simple: {
			pasteImages: false,
			pasteBlockTags: ['ul', 'ol', 'li', 'p'],
			pasteInlineTags: ['br', 'strong', 'ins', 'code', 'del', 'span', 'samp', 'kbd', 'sup', 'sub', 'mark', 'var', 'cite', 'small', 'b', 'u', 'em', 'i'],
			plugins: [
				'alignment',
				'redactor_terms',
				'redactor_links',
				'redactor_iconic',
				'redactor_elements_zindex'
			],
			buttons: ['bold', 'italic', 'lists', 'link']
		}
	};

	$.merge($.Redactor.modules, [
		'redactorBaseModule',
		'redactorImagesModule'
	]);

	$.Redactor.prototype.redactorBaseModule = function () {
		var redactor = this;
		this.$injector = angular.element(document).injector();

		var clean = {
			original: {
				onSet: redactor.clean.onSet,
				onPaste: redactor.clean.onPaste,
				convertTags: redactor.clean.convertTags,
				reconvertTags: redactor.clean.reconvertTags
			},
			onSet: $.proxy(function (html) {
				html = this.core.callback('onBeforeSet', html);
				html = clean.original.onSet(html);
				html = this.core.callback('onAfterSet', html);
				return html;
			}, redactor),
			onPaste: $.proxy(function (html, data, insert) {
				var redactor = this;
				var $box = $('<div>').html(html);

				// replaceTags
				var replacement = this.opts.replaceTags;
				if (replacement) {
					var keys = Object.keys(this.opts.replaceTags);
					$box.find(keys.join(',')).each(function (i, s) {
						redactor.utils.replaceToTag(s, replacement[s.tagName.toLowerCase()]);
					});
				}

				// questions
				$box.find('span[redactor-question]').remove();

				// terms
				$box.find('span[term-definition]').replaceWith(function () {
					return $('<a>').attr({
						'term-definition': this.getAttribute('term-definition'),
						'href': redactor.link.removeSelfHostFromUrl(this.getAttribute('href'))
					}).html(this.innerHTML);
				});

				return clean.original.onPaste($box.html(), data, insert);
			}, redactor),

			convertTags: $.proxy(function (html, data) {
				var $box = $('<div>').html(html);

				// images
				if (data.images && this.opts.pasteImages) {
					$box.find('img').each(function () {
						var $image = $(this);
						var attrs = {
							width: parseInt($image.css('width')),
							src: _.trim($image.attr('src'))
						};

						if (_.isEmpty(attrs.src)) {
							$image.remove();
							return;
						}

						// if src начинается на file:// или что-то подобное - удалять изображения
						// разрешить только форматы: http://   https://   ftp://   /   //
						if (/^(https:|http:|ftp:|\/\/|\/)/gi.test(attrs.src) === false) {
							$image.remove();
							return;
						}

						$.each($image.clone()[0].attributes, function () {
							if (_.contains(['alt', 'title', 'src'], this.name) === false) {
								$image.removeAttr(this.name);
							}
						});

						$image.attr({
							'data-image': '',
							'src': redactor.link.removeSelfHostFromUrl(attrs.src)
						});

						if (attrs.width) $image.css('width', attrs.width);

						if ($image.closest('a').length) {
							$image.unwrap('a')
						}

						var $figure = $image.closest('figure');
						if ($figure.length) {
							$figure.attr('data-type-image', '');
							$figure.html($image);
						}
						else {
							// если нет figure сверху - обернуть
							$image.wrap('<figure data-type-image>');
						}
					});

					$box.find('figure[data-type-image]').each(function () {
						var $figure = $(this);
						var $images = $(this).children('img');

						if (!$images.length) {
							$figure.remove();
							return;
						}

						var position = _.trim(_.get($figure[0].attributes, '[data-position].value', ''));
						var string = '###figure data-type-image=""';
						if (!_.isEmpty(position)) string += ' data-position="' + position + '"';
						string += '###' + $('<div>').html($images[0]).html() + '###/figure###';
						$figure.replaceWith(string);
					});
				}

				return clean.original.convertTags($box.html(), data);
			}, redactor),


			reconvertTags: $.proxy(function (html, data) {
				// terms
				html = html.replace(/###a(.*?)term-definition(.*?)###(.*?)###\/a###/gi, '<span$1term-definition$2>$3</span>');

				// images
				html = html.replace(new RegExp('###/figure###', 'gi'), '</figure>');
				html = html.replace(new RegExp('###figure data-type-image\s?(.*?[^#])###', 'gi'), '<figure data-type-image$1>');

				return clean.original.reconvertTags(html, data);
			}, redactor)
		};

		this.clean.onSet = clean.onSet;
		this.clean.onPaste = clean.onPaste;
		this.clean.convertTags = clean.convertTags;
		this.clean.reconvertTags = clean.reconvertTags;

		// syncBefore
		this.opts.callbacks['syncBefore'] = function (html) {
			var $box = $('<div>').html(html);

			// links
			$box.find('a:not([term-definition])').attr('target', '_blank');

			// terms
			$box.find('span[term-definition]').replaceWith(function () {
				return $('<a>').attr({
					'term-definition': this.getAttribute('term-definition'),
					'href': redactor.link.removeSelfHostFromUrl(this.getAttribute('href'))
				}).html(this.innerHTML);
			});

			// images
			$box.find('figure[data-type-image]').removeAttr('id').find('span').remove();

			// videos
			$box.find('content-video').html('').removeAttr('class');

			return $box.html().replace(/^\r?\n/gm, '');
		};

		// onBeforeSet
		this.opts.callbacks['onBeforeSet'] = function (html) {
			var $box = $('<div>').html(html);

			// terms
			$box.find('a[term-definition]').replaceWith(function () {
				return $('<span>').attr({
					'term-definition': this.getAttribute('term-definition'),
					'href': redactor.link.removeSelfHostFromUrl(this.getAttribute('href'))
				}).html(this.innerHTML);
			});

			return $box.html();
		};

		return false;
	};


	$.extend($.Redactor.opts.langs['ru'], {
		'cancel': 'Отмена',
		'format': 'Стиль',
		'table': 'Таблица',
		'insert-table': 'Вставить таблицу',
		'insert-row-above': 'Вставить строку выше',
		'insert-row-below': 'Вставить строку ниже',
		'insert-column-left': 'Вставить колонку слева',
		'insert-column-right': 'Вставить колонку справа',
		'add-head': 'Добавить заголовок',
		'delete-head': 'Удалить заголовок',
		'delete-column': 'Удалить колонку',
		'delete-row': 'Удалить строку',
		'delete-table': 'Удалить таблицу',
		'align': 'Выравнивание',
		'align-left': 'По левую сторону',
		'align-center': 'По центру',
		'align-right': 'По правую сторону'
	});


	$.Redactor.prototype.broadcastEvent = function (name) {
		var redactor = this;
		this.$injector.invoke(['$rootScope', function ($rootScope) {
			$rootScope.$broadcast(name, redactor);
		}]);
	};

	$.Redactor.prototype.openModal = function () {
		var redactor = this;
		var args = Array.prototype.slice.call(arguments);
		this.observe.closeAllTooltip();
		this.utils.saveScroll();
		this.$injector.invoke(['modalWindow', function (modalWindow) {
			var instance = modalWindow.open.apply(undefined, args);
			instance.once('hide', function () {
				redactor.selection.restore();
				redactor.utils.restoreScroll();
			});
		}]);
	};


	$.Redactor.prototype.processElementZIndex = function ($element, increment) {
		var modal = {
			element: this.core.box().closest('.modal'),
			zIndex: undefined
		};

		if (!modal.element.length) return;

		modal.zIndex = parseInt(modal.element.css('z-index')) || undefined;

		if (!modal.zIndex) return;

		if (!increment) increment = 10;

		var zIndex = parseInt($element.css('z-index')) || undefined;
		if (!zIndex || zIndex < modal.zIndex) {
			$element.css('z-index', modal.zIndex + increment);
		}
	}
});
