NgProject.filter('avatarFilter', ["SETTINGS", function (SETTINGS) {
	'use strict';
	return function (object, type) {
		if (!object && SETTINGS.placeholders[type]) {
			return angular.extend({default: true}, SETTINGS.placeholders[type]);
		}

		return object;
	};
}]);
