NgProject.controller('RedactorVideosModalCtrl', ["$scope", "VideosService", "CurrentUser", function ($scope, VideosService, CurrentUser) {
	'use strict';
	var redactor = $scope.$modalWindow.data(0);
	var plugin = redactor['redactor_videos'];
	var maker = _.get(redactor.opts, 'data.maker', {
		id: CurrentUser.id,
		type: 'user',
		data: CurrentUser
	});

	$scope.$ctrl = {
		model: null,
		submit: function () {
			VideosService.get($scope.$ctrl.model).then(function(respose) {
				plugin.insert(respose.video.service_video_id);
				$scope.$close();
			});
		}
	};

	$scope.$ctrl.tabs = {
		mode: maker.type,
		current: 'one',
		change: function (key) {
			$scope.$ctrl.tabs.current = key;
			$scope.$ctrl.model = null;
			$scope.$broadcast('videos-list-reload');
		},
		isCurrent: function (key) {
			return key === $scope.$ctrl.tabs.current;
		}
	};

	$scope.$config = {
		promise: getPromise,
		options: {
			requestParams: {limit: 10}
		},
		searchOptions: {
			enableFilters: false
		}
	};

	function getPromise(params) {
		var promise = null;
		switch ($scope.$ctrl.tabs.current) {
			case 'one':
				if (maker.type === 'user') {
					promise = VideosService.findAllByUser(maker.data.id, params);
				} else promise = VideosService.findAllByCurrentUser(params);
				break;
			case 'two':
				if (maker.type === 'group') {
					promise = VideosService.findAllByGroup(maker.data.id, params);
				} else promise = VideosService.findAllByCurrentUser(params);
				break;
			case 'three':
				promise = VideosService.findAll(params);
				break;
		}

		return promise;
	}
}]);
