NgProject.controller('WikiFAQListCtrl', ["$scope", "$state", "TermsService", "Deferred", "modalWindow", "CurrentUser", "DefinitionHelper", function ($scope, $state, TermsService, Deferred, modalWindow, CurrentUser, DefinitionHelper) {
	'use strict';

	var requestOptions = {
		termId: $state.params.termId,
		page: 1,
		pinned: 0
	};

	var $questionList = $scope.$questionList = getData();

	var saveStateQuestions = [];

	$scope.fData = {
		isEdited: false,
		isAdmin: CurrentUser.is_admin,
		typeArticle: (this.isEdited) ? 'edited' : 'default'
	};

	$scope.controls = {
		more: function (item) {
			modalWindow.open('wiki-faq', item);
		},
		openInfoModal: function (index) {
			$scope.$modal.open('wiki-faq-info', index);
		},
		load: function () {
			var page = requestOptions.page;
			loadByPage(page + 1);
		},
		disabled: function () {
			var data = $scope.$questionList;
			return data.isLoading || data.endOfList;
		},

		includeEdit: function () {
			$scope.fData.isEdited = true;
			saveStateQuestions = _.clone($scope.$fixedQuestions.items);
		},
		saveEdit: function () {
			var ids = _.pluck($scope.$fixedQuestions.items, 'id');
			var params = {termId: $scope.stateTermId, pin: ids.length ? ids : [false]};
			Deferred.handlePromise(TermsService.pinFaq(params), function (response) {
				if (response.success) {
					$scope.fData.isEdited = false;
				}
			});
		},
		cancelEdit: function () {
			$scope.$fixedQuestions.items = _.clone(saveStateQuestions);
			$scope.fData.isEdited = false;
			saveStateQuestions = [];
		},
		hiddenArticle: function ($article) {
			return !!_.find($scope.$fixedQuestions.items, $article);
		},
		selectFAQ: function ($question) {
			if (_.find($scope.$fixedQuestions.items, $question)) return;
			$scope.$fixedQuestions.items.push($question);
		},
		excludeFAQ: function ($question) {
			if ($questionList.items.length === 0 || !_.find($questionList.items, $question)) {
				$questionList.items.unshift($question);
			}
			_.remove($scope.$fixedQuestions.items, $question);
		}
	};

	$scope.$fixedQuestions = {
		items: []
	};

	function loadByPage(page) {
		if (page && page > 1) requestOptions.page = page;
		loadData(true);
	}

	function loadData(append) {
		$questionList.isLoading = true;
		requestOptions.per_page = 10;
		var promises = [
			TermsService.faq(requestOptions),
			TermsService.faq({termId: $state.params.termId, pinned: 1})
		];
		Deferred.handleAllPromises(promises, function (other, pinned) {
			if (other.success) {
				prepareQuestion(other.items);
				if (append) {
					other.items = $questionList.items.concat(other.items);
				}
				angular.extend($questionList, getData(other.items), {
					isLoading: false,
					endOfList: other.items.length === 0 || other.count === other.items.length
				});
			}

			if (pinned.success) {
				prepareQuestion(pinned.items);
				$scope.$fixedQuestions.items = pinned.items;
			}
		});
	}

	function getData(items) {
		return {
			items: _.isArray(items) ? items : [],
			isLoading: true,
			open: false,
			endOfList: false
		};
	}

	function prepareQuestion(items) {
		_.map(items, function (item) {
			item.answer = DefinitionHelper.clean(item.answer);
		});
	}

	loadData();
}]);
