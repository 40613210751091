NgProject.controller('SearchEventsCtrl', ["$scope", function Controller($scope) {
	'use strict';
	//requestParams: {
	//	younger_than: new Date().getTime(),
	//}
	$scope.$section = angular.copy($scope.getSectionByName('events'));
	$scope.$section.showHeader = false;
	$scope.$section.listOptions.loadMore = true;
	delete $scope.$section.options.requestParams.limit;
	$scope.toggleFilterPanel(true);
}]);
