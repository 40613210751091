(function (ng, app) {
	'use strict';
	Controller.$inject = ["$rootScope", "$scope", "$state", "ImagesService", "cfpLoadingBar", "FileUploader", "SETTINGS"];
	function Controller($rootScope, $scope, $state, ImagesService, cfpLoadingBar, FileUploader, SETTINGS) {
		var options = {
			data: $scope.$modalWindow.data(0) || {},
			params: $scope.$modalWindow.data(1) || {}
		};

		var errors = {
			data: {},
			add: function (field, message) {
				if (!errors.data[field]) errors.data[field] = [];
				errors.data[field].push(message);
			},
			list: function (field) {
				return errors.data[field];
			},
			remove: function (field) {
				delete errors.data[field];
			},
			has: function (field) {
				return errors.data[field] && errors.data[field].length;
			},
			isEmpty: function () {
				return _.isEmpty(errors.data);
			}
		};

		$scope.$errors = {
			get: errors.list,
			has: errors.has,
			isEmpty: errors.isEmpty
		};

		$scope.$data = {
			state: 0
		};

		$scope.$file = {
			uploader: new FileUploader({
				alias: 'image[file]',
				url: ImagesService.getUploadUrl(getUploadParams()),
				autoUpload: true,
				onSuccessItem: function(item, response) {
					if (!response.success) {
						_.forEach(_.get(response, 'errors.image', [SETTINGS.validation.image.errors.undefined]), function(message) {
							errors.add('file', message);
						});
					}
				},
				onCompleteAll: function () {
					$scope.$controls.uploaded();
				},
				onErrorItem: function() {
					errors.add('file', SETTINGS.validation.image.errors.undefined);
				},
				onAfterAddingFile: function() {
					errors.remove('file');
					$scope.$file.validate();
				}
			}),
			attrs: {
				accept: SETTINGS.validation.image.types
			},
			validate: function () {
				_.forEach($scope.$file.uploader.queue, function(item) {
					// validate accept
					if (SETTINGS.validation.image.types.indexOf(item._file.type) === -1) errors.add('file', SETTINGS.validation.image.errors.type);
				});
			}
		};

		$scope.$controls = {
			uploaded: function () {
				$scope.$data.state++;
				$rootScope.$broadcast('images-list-reload');
			},

			save: function () {
				cfpLoadingBar.start();
				cfpLoadingBar.complete();
			},

			close: function () {
				$scope.$close();
			}
		};

		function getUploadParams() {
			var params = {};
			if (options.data.album_id) params.album_id = options.data.album_id;
			if (options.data.maker) params.maker = options.data.maker;
			return params;
		}

		// Initialization
		if (options.params.redirect) {
			$state.go(options.params.redirect);
		}
	}
	app.controller('ModalImagesFormCtrl', Controller);
})(angular, NgProject);
