(function (ng, app) {
	'use strict';
	app.service('TimezonesService', ["$resource", "serviceHelper", "SETTINGS", function ($resource, serviceHelper, SETTINGS) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/timezones');

		return {
			list: function () {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get'
				});
				return (promise);
			}
		};
	}]);
})(angular, NgProject);
