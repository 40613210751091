NgProject.directive('eventsFilters', ["_", "Deferred", "TermsService", "LocationService", function (_, Deferred, TermsService, LocationService) {
	'use strict';

	function loadTypes(callback) {
		Deferred.handlePromise(TermsService.contentTypes(), function (response) {
			var types = [];
			if (response.success) {
				types = response.items;
			}
			callback(types);
		});
	}

	function loadCountries(callback) {
		Deferred.handlePromise(LocationService.countriesList(), function (response) {
			var countries = [];
			if (response.success) {
				countries = response.items;
			}
			callback(countries);
		});
	}

	function loadCities(countryId, callback) {
		Deferred.handlePromise(LocationService.citiesList(countryId), function (response) {
			var cities = [];
			if (response.success) {
				cities = response.items;
			}
			callback(cities);
		});
	}

	function link(scope, element, attrs, eventsCtrl) {
		var filtersDefaults = {
			onlyOnline: false,
			countryId: null,
			cityId: null,
			typeId: null
		};

		function init() {
			loadTypes(function (types) {
				scope.$filtersData.types = types;
			});
			loadCountries(function (countries) {
				scope.$filtersData.countries = countries;
			});
		}

		function getRequestParams() {
			return {
				onlyOnline: scope.$filters.onlyOnline,
				typeId: scope.$filters.typeId,
				countryId: scope.$filters.countryId,
				cityId: scope.$filters.cityId
			};
		}

		function applyFilters() {
			eventsCtrl.search(getRequestParams());
		}

		scope.$filters = angular.copy(filtersDefaults);

		scope.$filtersData = {
			types: [],
			countries: [],
			cities: []
		};

		scope.$filtersControls = {
			reset: function () {
				scope.$filters = angular.copy(filtersDefaults);
				applyFilters();
			}
		};

		scope.$watch('$filters.onlyOnline', function (value, oldValue) {
			if (value !== oldValue) {
				applyFilters();
			}
		});

		scope.$watch('$filters.typeId', function (value, oldValue) {
			if (value !== null && value !== oldValue)
				applyFilters();
		});

		scope.$watch('$filters.countryId', function (value, oldValue) {
			if (value !== null && value !== oldValue) {
				applyFilters();
				if (oldValue) {
					scope.$filtersData.cities = [];
					scope.$filters.cityId = null;
				}
				loadCities(value, function (cities) {
					scope.$filtersData.cities = cities;
				});
			}
		});

		scope.$watch('$filters.cityId', function (value, oldValue) {
			if (value !== null && value !== oldValue)
				applyFilters();
		});

		init();
	}

	return {
		require: '^^events',
		templateUrl: 'widgets.events.events-filters',
		restrict: 'E',
		scope: true,
		link: link
	};
}]);
