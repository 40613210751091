NgProject.directive('groupsList', ["$parse", "$templateCache", function ($parse, $templateCache) {
	'use strict';
	var defaults = {
		loadMore: true,
		teaserOptions: {}
	};


	function compile(tElement) {
		var template = $templateCache.get('widgets.groups.groups-list');
		var content = tElement.html();
		if (content.length) {
			template = angular.element(template);
			template.find('.groups-list-item').html(content);
		}
		tElement.html(template);
		return link;
	}

	function link(scope, element, attrs, controller) {
		var options = angular.extend({}, angular.copy(defaults), attrs.options ? $parse(attrs.options)(scope) : {});
		scope.$groupsList = {options: options};
		if(options.loadMore) {
			scope.$groupsList.load = function () {
				var page = controller.getSearchParams().page || 1;
				controller.loadByPage(page + 1);
			};
			scope.$groupsList.disabled = function () {
				var data = scope.$groups;
				return data.isLoading || data.endOfList;
			};
		}
	}

	return {
		require: '^^groups',
		restrict: 'E',
		link: link,
		compile: compile
	};
}]);
