NgProject.directive('ngSrcRetina', ["$document", function ($document) {
	'use strict';
	/**
	 * documentMode is an IE-only property
	 * http://msdn.microsoft.com/en-us/library/ie/cc196988(v=vs.85).aspx
	 */
	var msie = $document.documentMode;
	
	var config = {
		retinaImageSuffix: '@2x'
	};
	
	function getHighResolutionURL(url) {
		var parts = url.split('?');
		var ext = /(\d+x\d+)\.(\w+)$/;
		if (ext.test(parts[0])) {
			parts[0] = parts[0].replace(ext, function () {
				return _.map(arguments[1].split('x'), function (n) {
						return n * 2;
					}).join('x') + '.' + arguments[2];
			});
		} else
			parts[0] = parts[0].replace(/(\.\w+)$/, config.retinaImageSuffix + '$1');
		return parts.join('?');
	}
	
	return {
		restrict: 'A',
		link: function (scope, element, attrs) {
			function setAttrs(name, value) {
				attrs.$set(name, value);
				// on IE, if "ng:src" directive declaration is used and "src" attribute doesn't exist
				// then calling element.setAttribute('src', 'foo') doesn't do anything, so we need
				// to set the property as well to achieve the desired effect.
				// we use attr[attrName] value since $set can sanitize the url.
				if (msie) element.prop(name, value);
			}
			
			attrs.$observe('src', function (path) {
				if (!path) return;
				var retina_path = getHighResolutionURL(path);
				setAttrs('srcset', retina_path + ' 1.5x, ' + retina_path + ' 2x');
			});
		}
	};
}]);
