NgProject.service('notification', function () {
	'use strict';
	var settings = {
		styling: false
	};

	var stacks = {};
	var defaultStack = false;

	var initHash = function (stackName) {
		var hash = angular.copy(settings);
		if ((stackName || (stackName = defaultStack)) && stackName in stacks) {
			hash.stack = stacks[stackName].stack;
			if (stacks[stackName].addclass) {
				hash.addclass = 'addclass' in hash ?
				hash.addclass + ' ' + stacks[stackName].addclass : stacks[stackName].addclass;
			}
		}
		return hash;
	};

	this.setDefaults = function (defaults) {
		settings = defaults;
		return this;
	};

	this.setStack = function (name, addclass, stack) {
		if (angular.isObject(addclass)) {
			stack = addclass;
			addclass = false;
		}
		stacks[name] = {
			stack: stack,
			addclass: addclass
		};
		return this;
	};

	this.setDefaultStack = function (name) {
		defaultStack = name;
		return this;
	};

	this.getSettings = function () {
		return settings;
	};

	this.message = function (content, stack) {
		var hash = initHash(stack);
		hash.type = 'message';
		hash.text = content;
		return this.notify(hash);
	};

	this.notify = function (hash) {
		return new PNotify(hash);
	};
});
