NgProject.service('ImMessage', ["ImData", "CurrentUser", function (ImData, CurrentUser) {
	'use strict';

	var ImMessage = this;
	var defaults = {
		id: null,
		created: null,
		message: '',
		sender: {
			type: null,
			data: null
		}
	};

	ImMessage.setMessageSync = function (message, sync) {
		return _.extend(angular.copy(defaults), {
			id: sync,
			created: sync,
			message: message,
			sender: {
				type: ImData['data'].groupSender ? 'group' : 'user',
				data: getSenderData()
			}
		});
	};

	ImMessage.setMessageData = function (message, sync) {
		var data = {
			id: ImData['data'].chatId,
			message: message,
			sync: sync
		};
		if (!!ImData['data'].recipient) {
			data['group'] = ImData['data'].groupSender;
		}
		return data;
	};

	return ImMessage;

	function getSenderData() {
		return ImData['data'].groupSender ? ImData['data'].recipient : {
			id: CurrentUser.id,
			type: 'user',
			fullname: CurrentUser.fullname,
			nickname: CurrentUser.nickname,
			avatar: CurrentUser.avatar
		};
	}
}]);
