NgProject.directive('carouselWidget', ["$parse", "$compile", function ($parse, $compile) {
	'use strict';
	var defaults = {
		nav: true,
		navText: ['', ''],
		dots: false,
		margin: 5,
		items: 4,
		slideBy: 4,
		smartSpeed: 200
	};

	var template = null;

	return {
		require: '^^images',
		restrict: 'A',
		compile: compile
	};

	function compile(tElement) {
		template = tElement.html();
		tElement.html('');
		return link;
	}

	function link(scope, element, attrs, controller) {
		var options = angular.extend({}, angular.copy(defaults), attrs.options ? $parse(attrs.options)(scope) : {});
		var owl = null;

		angular.extend(options, {
			onChanged: savePosition
		});

		scope.$watch('$images.isLoading', function (isLoading) {
			if(isLoading === false) processCarousel();
		});

		function loadMore() {
			if (scope.$images.items.length !== scope.$images.count) {
				var page = controller.getSearchParams().page || 1;
				controller.loadByPage(page + 1);
			}
		}

		function savePosition(event) {
			if (event.item.index !== null) {
				options.startPosition = event.item.index;
			}
		}

		function processCarousel() {
			var data = [];

			if (owl) {
				owl.trigger('destroy.owl.carousel');
				owl = null;
			}

			angular.forEach(scope.$images.items, function (item, index) {
				var newScope = scope.$new();
				newScope.$image = item;
				newScope.$index = index;
				data.push($compile(template)(newScope));
			});

			element.html(data);
			if (data.length && owl === null) {
				owl = $(element).owlCarousel(options);
				owl.off('translated.owl.carousel').on('translated.owl.carousel', loadMore);
			}
		}
	}
}]);
