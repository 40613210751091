NgProject.controller('ArticlesListCommonCtrl', ["$scope", "ArticlesService", "CurrentUser", function ($scope, ArticlesService, CurrentUser) {
	'use strict';
	var blocks = {common: false};
	var teaserOptions = {
		buttons: {deleteByOwner: false}
	};

	$scope.controls = {
		isReady: false,
		showHeader: false,
		blocks: {
			init: function (name) {
				blocks[name] = false;
			},
			update: function (name, value) {
				blocks[name] = value;
				processLoadedTriggers();
			},
			active: function (name) {
				return blocks[name];
			},
			remove: function (name) {
				delete blocks[name];
				processLoadedTriggers();
			}
		}
	};

	$scope.articles = {
		promise: ArticlesService.findAll,
		action: {show: !!CurrentUser.id, sref: 'root.create-article'},
		onLoadCallback: function () {
			if (!blocks.common) $scope.controls.blocks.update('common', true);
		},
		listOptions: {
			teaserOptions: Boolean(CurrentUser.id) ? teaserOptions : false
		}
	};

	function processLoadedTriggers() {
		$scope.controls.isReady = _.every(blocks);
		$scope.controls.showHeader = _.size(blocks) > 1;
	}
}]);
