NgProject.factory('Article', ["$rootScope", "$resource", "$q", "$filter", "SETTINGS", "CurrentUser", "SharingOptions", "CommonData", function ($rootScope, $resource, $q, $filter, SETTINGS, CurrentUser, SharingOptions, CommonData) {
	'use strict';
	var ArticleModel = function (data) {
		if (data) this.setData(data);
	};

	var resource = $resource(SETTINGS['apiBaseUrl'] + 'api/v1/articles/:id/:command', {id: '@id', command: '@command'}, {
		share: {method: 'POST', params: {command: 'share'}},
		add: {method: 'PUT', params: {command: 'add'}},
		viewAction: {method: 'PUT', params: {command: 'count_view'}}
	});

	var resourceBookmarks = $resource(SETTINGS['apiBaseUrl'] + 'api/v1/profile/articles/bookmarks/:id', {id: '@id'});

	var resourceReadingList = $resource(SETTINGS['apiBaseUrl'] + 'api/v1/profile/articles/reading/:id', {id: '@id'});

	var resourceProfile = $resource(SETTINGS['apiBaseUrl'] + 'api/v1/profile/articles/own/:id', {id: '@id'});

	var resourceGroups = $resource(SETTINGS['apiBaseUrl'] + 'api/v1/groups/:groupId/articles/:id', {
		id: '@id',
		groupId: '@groupId'
	});

	ArticleModel.prototype.setData = function (data) {
		if (!data.bookmarks_count) data.bookmarks_count = 0;
		if (!data.views_count) data.views_count = 0;
		data.user_is_maker = data.maker.type === 'user' && data.maker.data.id === CurrentUser.id;
		data.is_published = data.allow_update ? data.status === 'published' : true;
		$filter('articleAuthorFilter')(data);
		data.audios = data.audios || [];
		if (data.description) {
			data.description = $filter('contentImagesUrls')(data.description);
		}

		angular.extend(this, data);
		this.access = {
			'delete': this.checkAccess('delete'),
			'update': this.checkAccess('update'),
			'deleteOverall': CurrentUser.is_admin
		};
	};

	ArticleModel.prototype.checkAccess = function (task) {
		switch (task) {
			case 'delete':
				return this.allow_update || this.is_owner;
			case 'update':
				return this.allow_update;
		}
		return false;
	};

	ArticleModel.prototype.setOwner = function (ownerType, ownerId) {
		this.owner = {
			id: ownerId,
			type: ownerType
		};
	};

	function deleteRequest(params) {
		var deferred = $q.defer();
		var model = this;
		var owner = null;
		if (params.overall) owner = resource;
		else if (params.groupId) owner = resourceGroups;
		else owner = resourceProfile;
		params = angular.extend({id: model.id}, params || {});
		owner.delete(params, deferred.resolve, deferred.reject);
		return deferred.promise;
	}

	ArticleModel.prototype.deleteByOwner = function () {
		var model = this;
		var params = _.get(model.owner, 'type', false) ? {groupId: model.owner.id} : {};
		_.remove(CommonData.data.items, {id: model.id});
		CommonData.data.count--;
		return deleteRequest.call(model, params).then(function () {
			$rootScope.$broadcast('InterfaceCounters:update');
			if (!CommonData.data.items.length) $rootScope.$broadcast('articles-list-reload');
		});
	};

	ArticleModel.prototype.deleteOverall = function () {
		return deleteRequest.call(this, {overall: true});
	};

	ArticleModel.prototype.share = function (message, owner) {
		var deferred = $q.defer();
		var model = this;
		var data = angular.extend({id: model.id}, SharingOptions.getRequestData(message, owner));
		model.shared = true;
		resource.share(data, deferred.resolve, deferred.reject);
		return deferred.promise.then(function (response) {
			model.shared = _.get(response, 'success', false);
		});
	};

	ArticleModel.prototype.bookmark = function () {
		var model = this, added = false;
		var defer = $q.defer();
		if (model.flags['bookmark']) {
			resourceBookmarks.delete({id: model.id}, defer.resolve, defer.reject);
			$rootScope.$broadcast('listBookmarks:remove', model.id);
		} else {
			resourceBookmarks.update({article_id: model.id}, defer.resolve, defer.reject);
			added = true;
		}
		model.flags['bookmark'] = !model.flags['bookmark'];
		return defer.promise.then(function (response) {
			$rootScope.$broadcast('InterfaceCounters:update');
			if (response.success) {
				model.bookmarks_count = _.get(response, 'article.bookmarks_count', parseInt(model.bookmarks_count) - 1);
				if (added) $rootScope.$broadcast('listBookmarks:added');
			}
		});
	};

	ArticleModel.prototype.reading = function () {
		var model = this, added = false;
		var defer = $q.defer();
		if (model.flags['reading']) {
			resourceReadingList.delete({id: model.id}, defer.resolve, defer.reject);
			$rootScope.$broadcast('listReading:remove', model.id);
		} else {
			resourceReadingList.update({article_id: model.id}, defer.resolve, defer.reject);
			added = true;
		}
		model.flags['reading'] = !model.flags['reading'];
		return defer.promise.then(function (response) {
			$rootScope.$broadcast('InterfaceCounters:update');
			if (response.success && added) $rootScope.$broadcast('listReading:added');
		});
	};

	ArticleModel.prototype.sendViewAction = function () {
		var deferred = $q.defer();
		var model = this;
		resource.viewAction({id: model.id}, deferred.resolve, deferred.reject);
		return deferred.promise;
	};

	return {
		create: function (data) {
			return new ArticleModel(data);
		}
	};
}]);
