NgProject.directive('contentVideo', ["$compile", function ($compile) {
	'use strict';

	return {
		restrict: 'E',
		compile: function compile(tElement, tAttrs) {
			tElement.html(generateTemplate(tAttrs.videoId));
			return link;
		}
	};

	function link(scope, element, attrs) {
		element.find('.content_video-button').on('click', function () {
			element.find('.content_video-button').remove();
			element.find('.content_video-wrap').append($compile('<youtube-video video-id="\'' + attrs.videoId + '\'" player-vars="{autoplay: true}"></youtube-video>')(scope));
		});
	}

	function generateTemplate(imageId) {
		return '<div class="content_video-wrap">' +
			'<img class="content_video-view" src="http://img.youtube.com/vi/' + imageId + '/maxresdefault.jpg" />' +
			'<span class="content_video-button"><i class="icon-m-play_arrow"></i></span>' +
			'</div>';
	}
}]);
