NgProject.provider('YoutubeApi', function () {
	'use strict';
	Factory.$inject = ["$q"];
	var api_key = null;
	var client = null;
	var initialization = {
		isReady: false,
		defer: null
	};

	function Factory($q) {
		if (!client) {
			initialization.defer = $q.defer();
			initClient();
		}

		function request(command, data) {
			var deferred = $q.defer();
			if (initialization.isReady) executeClientCommand(command, data).then(deferred.resolve, deferred.reject);
			else initialization.defer.promise.then(function () {
				executeClientCommand(command, data).then(deferred.resolve, deferred.reject);
			});
			return deferred.promise;
		}

		return {
			getVideoInfo: function (video_id) {
				return request('videos.list', {part: 'snippet', id: video_id}).then(function (response) {
					return _.get(response, 'result.items[0]', null);
				});
			},
			getVideoIdByUrl: function(url) {
				var pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
				return (url.match(pattern)) ? RegExp.$1 : false;
			}
		};
	}

	function initClient() {
		var template = '<script async src="https://apis.google.com/js/client.js?onload=youtubeAsyncInit"></script>';
		window.youtubeAsyncInit = initYoutubeApi;
		angular.element('body').append(template);
	}

	function initYoutubeApi() {
		window.gapi.client.setApiKey(api_key);
		window.gapi.client.load('youtube', 'v3').then(function () {
			client = _.get(window.gapi.client, 'youtube', null);
			initialization.isReady = true;
			initialization.defer.resolve();
		});
	}

	function executeClientCommand(command, data) {
		return _.get(client, command)(data);
	}

	return {
		setApiKey: function (key) {
			api_key = key;
		},
		$get: Factory
	};
});
