/**
 * Formats seconds into a time string hh:mm:ss.
 * @param {Number} seconds seconds to format as string
 * @return {String} formatted time string
 */
(function (ng, app) {
	'use strict';
	app.filter('playerFormatTime', function () {
		return function (seconds) {
			var hours = parseInt(seconds / 3600, 10) % 24;
			var minutes = parseInt(seconds / 60, 10) % 60;
			var secs = parseInt(seconds % 60, 10);
			var result, fragment = (minutes < 10 ? '0' + minutes : minutes) + ':' + (secs < 10 ? '0' + secs : secs);
			if (hours > 0) {
				result = (hours < 10 ? '0' + hours : hours) + ':' + fragment;
			} else {
				result = fragment;
			}
			return result;
		};
	});
})(angular, NgProject);
