NgProject.service('TermsService', ["$resource", "serviceHelper", "SETTINGS", "TermsSearchParams", function ($resource, serviceHelper, SETTINGS, TermsSearchParams) {
	'use strict';
	var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/:section/:termId/:command/:definitionId', {
		section: '@section',
		termId: '@termId',
		command: '@command',
		definitionId: '@definitionId'
	}, {
		remove_term: {
			method: 'DELETE',
			params: {section: 'terms'}
		},
		create_term: {
			method: 'POST',
			params: {section: 'terms'}
		},
		update_term: {
			method: 'PUT',
			params: {section: 'terms'}
		},
		remove_definition: {
			method: 'DELETE',
			params: {section: 'terms', command: 'definitions'}
		},
		create_definition: {
			method: 'POST',
			params: {section: 'terms', command: 'definitions'}
		},
		update_definition: {
			method: 'PUT',
			params: {section: 'terms', command: 'definitions'}
		},
		update_options: {
			method: 'POST',
			params: {section: 'terms', command: 'options'}
		},
		list: {
			params: {section: 'terms'}
		},
		term: {
			params: {section: 'terms'}
		},
		options: {
			params: {section: 'terms', command: 'options'}
		},
		faq: {
			params: {section: 'terms', command: 'questions_answers'}
		},
		pin_faq: {
			method: 'PUT',
			params: {section: 'terms', command: 'questions_answers'}
		},
		participants: {
			params: {section: 'participants'}
		},
		participantsLabels: {
			params: {section: 'participants_titles'}
		},
		directions: {
			params: {section: 'directions'}
		},
		roles: {
			params: {section: 'roles'}
		},
		contentTypes: {
			params: {section: 'content_types'}
		},
		tags: {
			params: {section: 'tags'}
		},
		authors: {
			params: {section: 'authors'}
		},
		definitions: {
			params: {section: 'definitions'}
		},
		term_definitions: {
			params: {section: 'terms', command: 'definitions'}
		},
		term_definition: {
			params: {section: 'terms', command: 'definitions'}
		},
		chars: {
			params: {section: 'terms', command: 'chars'}
		},
		term_authors: {
			params: {section: 'term_authors'}
		}
	});

	return {
		remove_term: function (termId) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'remove_term',
				parameters: {
					termId: termId
				}
			});
			return (promise);
		},

		create_term: function (data) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'create_term',
				parameters: data
			});
			return (promise);
		},

		update_term: function (termId, data) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'update_term',
				parameters: {termId: termId, term: data}
			});

			return (promise);
		},

		remove_definition: function (termId, definitionId) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'remove_definition',
				parameters: {termId: termId, definitionId: definitionId}
			});
			return (promise);
		},

		create_definition: function (termId, data) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'create_definition',
				parameters: {termId: termId, definition: data}
			});

			return (promise);
		},
		update_definition: function (termId, definitionId, data) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'update_definition',
				parameters: {
					termId: parseInt(termId),
					definitionId: parseInt(definitionId),
					definition: data
				}
			});

			return (promise);
		},
		update_options: function (termId, data) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'update_options',
				parameters: {termId: termId, options: data}
			});

			return (promise);
		},

		list: function (params) {
			params = TermsSearchParams.list(params);
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'list',
				parameters: params
			});
			return (promise);
		},

		term: function (termId) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'term',
				parameters: {termId: termId}
			});
			return (promise);
		},

		options: function (termId) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'options',
				parameters: {termId: termId}
			});
			return (promise);
		},

		faq: function (params) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'faq',
				parameters: TermsSearchParams.faq(params)
			});
			return (promise);
		},

		pinFaq: function (params) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'pin_faq',
				parameters: TermsSearchParams.faq(params)
			});
			return (promise);
		},

		participants: function (query, limit) {
			if (!query || query.length === 0) {
				query = null;
			}
			if (!limit || !_.isNumber(limit)) {
				limit = 10;
			}
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'participants',
				parameters: {
					q: query,
					limit: limit
				}
			});

			return (promise);
		},

		participantsLabels: function () {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'participantsLabels'
			});
			return (promise);
		},

		directions: function (query) {
			if (!query || query.length === 0) {
				query = null;
			}
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'directions',
				parameters: {
					q: query
				}
			});

			return (promise);
		},

		roles: function (query) {
			if (!query || query.length === 0) {
				query = null;
			}
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'roles',
				parameters: {
					q: query
				}
			});

			return (promise);
		},

		contentTypes: function () {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'contentTypes'
			});
			return (promise);
		},

		tags: function (query, limit) {
			if (!query || query.length === 0) {
				query = null;
			}
			if (!limit || !_.isNumber(limit)) {
				limit = 10;
			}
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'tags',
				parameters: {
					q: query,
					limit: limit
				}
			});

			return (promise);
		},

		synonyms: function (query, limit) { // TODO: change to synonym
			if (!query || query.length === 0) {
				query = null;
			}
			if (!limit || !_.isNumber(limit)) {
				limit = 10;
			}
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'tags',
				parameters: {
					q: query,
					limit: limit
				}
			});

			return (promise);
		},

		authors: function (params) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'authors',
				parameters: params
			});

			return (promise);
		},

		definitions: function (ids) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'definitions',
				parameters: {
					ids: ids.join(',')
				}
			});

			return (promise);
		},

		termDefinitions: function (termId) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'term_definitions',
				parameters: {
					termId: termId
				}
			});

			return (promise);
		},

		termDefinition: function (termId, definitionId) {
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'term_definition',
				parameters: {
					termId: termId,
					definitionId: definitionId
				}
			});

			return (promise);
		},

		chars: function (params) {
			return serviceHelper.executeRequest({
				resource: resource,
				name: 'chars',
				parameters: params
			});
		},

		term_authors: function (parameters) {
			if (_.isNull(parameters)) parameters = {};
			var promise = serviceHelper.executeRequest({
				resource: resource,
				name: 'term_authors',
				parameters: parameters
			});

			return (promise);
		}
	};
}]);
