(function (ng, app) {
	'use strict';
	Controller.$inject = ["$rootScope", "$scope", "Deferred", "ImagesAlbumsService", "cfpLoadingBar"];
	function Controller($rootScope, $scope, Deferred, ImagesAlbumsService, cfpLoadingBar) {
		var errors = {
			data: {},
			add: function (field, message) {
				if (!errors.data[field]) errors.data[field] = [];
				errors.data[field].push(message);
			},
			list: function (field) {
				return errors.data[field];
			},
			remove: function (field) {
				delete errors.data[field];
			},
			has: function (field) {
				return errors.data[field] && errors.data[field].length;
			},
			isEmpty: function () {
				return _.isEmpty(errors.data);
			}
		};

		$scope.$errors = {
			get: errors.list,
			has: errors.has,
			isEmpty: errors.isEmpty
		};

		$scope.$model = {
			name: null
		};

		$scope.$data = {
			isReady: false,
			state: 0
		};

		$scope.$controls = {
			save: function () {
				cfpLoadingBar.start();
				Deferred.handlePromise(ImagesAlbumsService.create(requestData()), function (response) {
					cfpLoadingBar.complete();
					if (response.success) {
						$scope.$data.state++;
						$rootScope.$broadcast('albums-list-reload');
					}
					else setFormErrors(response.errors);
				});
			},

			close: function () {
				$scope.$close();
			}
		};

		function getDefaultMaker() {
			var maker = $scope.$modalWindow.data(0);
			if (_.isEmpty(maker)) maker = null;
			return maker;
		}

		function requestData() {
			var data = {
				name: $scope.$model.name
			};
			var maker = getDefaultMaker();
			if (maker) data.maker = maker;
			return data;
		}

		function setFormErrors(data) {
			errors.data = data;
		}

		$scope.$watch('$model.name', function (value, oldValue) {
			if ($scope.$errors.has('name') && value !== oldValue) errors.remove('name');
		});

		// Initialization
		$scope.$data.isReady = true;
	}
	app.controller('ModalAlbumFormCtrl', Controller);
})(angular, NgProject);
