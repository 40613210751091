'use strict';
NgProject.controller('ImagesAlbumCtrl', ["$scope", function ($scope) {
	$scope.$album = null;

	$scope.$config = {
		isReady: false,
		images: {
			promise: $scope.$page.promises.imagesByAlbum,
			listOptions: {
				teaserOptions: {
					buttons: {
						// только если это страница управления картинками пользователя или его группа
						deleteByOwner: $scope.$page.access.update && (_.isEmpty($scope.$page.owner) || $scope.$page.owner.type === 'group')
					}
				}
			}
		}
	};

	// Initialization
	$scope.$page.promises.album().then(function(response) {
		if (response.success) {
			$scope.$album = response.album;
			$scope.$config.isReady = true;
		}
	});

	console.log('albom');
}]);
