(function () {
	'use strict';
	var module = angular.module('project.Analytics', []);

	module.run(["$rootScope", "$location", "$timeout", "GoogleAnalytics", "YandexMetrica", function($rootScope, $location, $timeout, GoogleAnalytics, YandexMetrica) {
		var timer = {
			promise: null,
			delay: 500,
			start: function(fn) {
				this.promise = $timeout(fn, this.delay, false);
			},
			cancel: function() {
				$timeout.cancel(this.promise);
				return this;
			}
		};

		$rootScope.$on('$locationChangeSuccess', function () {
			timer.cancel().start(function() {
				var path =  $location.path();
				GoogleAnalytics.changeLocation(path);
				YandexMetrica.changeLocation(path);
			});
		});
	}]);

	module.factory('GoogleAnalytics', ["$window", function($window) {
		return {
			changeLocation: function(path) {
				if($window.ga) $window.ga('send', 'pageview', {page: path});
			}
		};
	}]);

	module.factory('YandexMetrica', ["$window", function($window) {
		return {
			changeLocation: function(path) {
				if($window.yaCounter) $window.yaCounter.hit(path);
			}
		};
	}]);
})();
