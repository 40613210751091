'use strict';
(function () {
	var module = angular.module('project.PointerEvents', []);
	var defaults = {
		class_name: 'disable-pointer-events',
		timer_delay: 250
	};

	module.run(["$window", function($window) {
		var body = angular.element('body');
		var timer = {
			_instance: null,
			start: function(callback) {
				timer._instance = setTimeout(callback, defaults.timer_delay);
			},
			stop: function() {
				clearTimeout(timer._instance);
			}
		};

		$window.addEventListener('scroll', function() {
			timer.stop();
			if (!body.hasClass(defaults.class_name)) body.addClass(defaults.class_name);
			timer.start(function() {
				body.removeClass(defaults.class_name);
			});
		});
	}]);
})();
