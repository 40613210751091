NgProject.factory('TermsSearchParams', function () {
	'use strict';
	return {
		list: function (params) {
			var options = {
				per_page: 100
			};
			if (_.isEmpty(params)) return options;
			if (params.page && params.page > 1) options.page = params.page;
			if (params.q && params.q.length > 0) options.q = params.q;
			if (params.char) options.char = params.char;
			if (params.author_id) options.author_id = params.author_id;
			if (params.extended) options.extended = params.extended;
			if (params.per_page) options.per_page = params.per_page;
			if (params.limit) options.per_page = params.limit;
			return options;
		},
		faq: function (params) {
			var options = {};
			if (_.isEmpty(params)) return options;
			if (params.page && params.page > 1) options.page = params.page;
			if (params.per_page) options.per_page = params.per_page;
			if (params.termId) options.termId = params.termId;
			if (params.hasOwnProperty('pinned')) options.pinned = params.pinned;
			if (params.pin) options.pin = params.pin;
			return options;
		}
	};
});
