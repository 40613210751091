(function (ng, app) {
	'use strict';
	app.service('ProfileSettingsService', ["$resource", "serviceHelper", "SETTINGS", function ($resource, serviceHelper, SETTINGS) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/settings/:command', {command: '@command'}, {
			notifications_get: {
				params: {command: 'notifications'}
			},
			notifications_update: {
				method: 'PUT',
				params: {command: 'notifications'}
			},
			email_get: {
				params: {command: 'email'}
			},
			email_update: {
				method: 'PUT',
				params: {command: 'email'}
			},
			email_confirmation: {
				method: 'PUT',
				params: {command: 'email_confirmation'}
			},
			password_update: {
				method: 'PUT',
				params: {command: 'password'}
			}
		});

		return {
			notifications: {
				get: function () {
					return serviceHelper.executeRequest({resource: resource, name: 'notifications_get'});
				},
				update: function (data) {
					var promise = serviceHelper.executeRequest({
						resource: resource,
						name: 'notifications_update',
						parameters: data
					});

					return (promise);
				}
			},

			email: {
				get: function() {
					return serviceHelper.executeRequest({resource: resource, name: 'email_get'});
				},
				update: function(email) {
					var promise = serviceHelper.executeRequest({
						resource: resource,
						name: 'email_update',
						parameters: {email: email}
					});

					return (promise);
				},
				confirmation: function(token) {
					var promise = serviceHelper.executeRequest({
						resource: resource,
						name: 'email_confirmation',
						parameters: {token: token}
					});

					return (promise);
				}
			},

			account: {
				remove: function() {
					var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/profile', {}, {remove: {method: 'DELETE'}});
					var promise = serviceHelper.executeRequest({
						resource: resource,
						name: 'remove'
					});

					return (promise);
				},
				removeConfirmation: function(token) {
					var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/delete_confirmation', {}, {confirmation: {method: 'PUT'}});
					var promise = serviceHelper.executeRequest({
						resource: resource,
						name: 'confirmation',
						parameters: {token: token}
					});

					return (promise);
				}
			},

			changePassword: function(data) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'password_update',
					parameters: data
				});
				return (promise);
			}
		};
	}]);
})(angular, NgProject);
