(function (ng, app) {
	'use strict';
	app.service('UsersService', ["$resource", "serviceHelper", "SETTINGS", "UsersSearchParams", "GroupsSearchParams", function ($resource, serviceHelper, SETTINGS, UsersSearchParams, GroupsSearchParams) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/users/:userId/:command', {
			userId: '@userId',
			command: '@command'
		}, {
			followers: {
				params: {command: 'followers'}
			},
			follows: {
				params: {command: 'follows'}
			},
			getAdminGroups: {
				params: {command: 'admin_groups'}
			},
			wall: {
				params: {command: 'user_news'}
			},
			set_role_admin: {
				method: 'PUT',
				params: {command: 'adminize'}
			},
			unset_role_admin: {
				method: 'PUT',
				params: {command: 'free'}
			},
			changeAvatar: {
				method: 'PUT',
				params: {command: 'avatar'}
			},
			relationship: {
				method: 'POST',
				params: {command: 'relationship'}
			}
		});

		return {
			list: function (params) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: UsersSearchParams.process(params)
				});
			},

			about: function (userId) {
				return serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: {userId: userId}
				});
			},

			followers: function (userId, params) {
				params = UsersSearchParams.process(params);
				params.userId = userId;
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'followers',
					parameters: params
				});
				return (promise);
			},

			follows: function (userId, params) {
				params = UsersSearchParams.process(params);
				params.userId = userId;
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'follows',
					parameters: params
				});
				return (promise);
			},

			relationship: {
				follow: function (userId) {
					var promise = serviceHelper.executeRequest({
						resource: resource,
						name: 'relationship',
						parameters: {
							userId: userId,
							relation_action: 'follow'
						}
					});
					return (promise);
				},

				unfollow: function (userId) {
					var promise = serviceHelper.executeRequest({
						resource: resource,
						name: 'relationship',
						parameters: {
							userId: userId,
							relation_action: 'unfollow'
						}
					});
					return (promise);
				}
			},

			adminize: function (userId, admin) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: admin ? 'set_role_admin' : 'unset_role_admin',
					parameters: {userId: userId}
				});
				return (promise);
			},

			getAdminGroups: function (userId, params) {
				params = GroupsSearchParams.process(params);
				params.userId = userId;
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'getAdminGroups',
					parameters: params
				});
				return (promise);
			},

			update: function (userId, data) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'update',
					parameters: {
						userId: userId,
						user: data
					}
				});
				return (promise);
			},

			wall: function (userId, params) {
				var data = params || {};
				data.userId = userId;
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'wall',
					parameters: data
				});
				return (promise);
			},

			getUploadImageUrl: function (userId) {
				return SETTINGS.apiBaseUrl + 'api/v1/users/' + parseInt(userId).toString() + '/avatar';
			},

			changeAvatar: function (userId, imageId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'changeAvatar',
					parameters: {userId: userId, image_id: imageId}
				});

				return (promise);
			}
		};
	}]);
})(angular, NgProject);
