NgProject.directive('audiosSearch', ["$parse", function ($parse) {
	'use strict';
	controller.$inject = ["$scope", "$attrs"];
	var defaults = {
		showFiltersPanel: true,
		enableFilters: true
	};

	function controller($scope, $attrs) {
		$scope.bSearchOptions = angular.copy(defaults);
		if ($attrs.hasOwnProperty('options')) {
			angular.extend($scope.bSearchOptions, $parse($attrs.options)($scope));
		}
		if ($attrs.hasOwnProperty('showQueryPanel'))
			$scope.bSearchOptions.enableQueryPane = $parse($attrs.showQueryPanel)($scope);
		if ($attrs.hasOwnProperty('showFiltersPanel'))
			$scope.bSearchOptions.showFilters = $scope.bSearchOptions.openFilters = $parse($attrs.showFiltersPanel)($scope);
	}

	return {
		templateUrl: 'widgets.audios.audios-search',
		restrict: 'E',
		controller: controller
	};
}]);
