NgProject.factory('faye', ["$q", "$rootScope", function ($q, $rootScope) {
	'use strict';
	return function (url, callback) {
		var scope = $rootScope;
		var client = new Faye.Client(url);
		if (_.isFunction(callback)) callback(client);

		return {
			client: client,
			publish: function (channel, data) {
				return this.client.publish(channel, data);
			},
			subscribe: function (channel, callback) {
				return this.client.subscribe(channel, function (data) {
					return scope.$apply(function () {
						return callback(data);
					});
				});
			},
			get: function (channel) {
				var deferred = $q.defer(),
					sub = this.client.subscribe(channel, function (data) {
						scope.$apply(function () {
							return deferred.resolve(data);
						});
						return sub.cancel();
					});
				return deferred.promise;
			}
		};
	};
}]);
