'use strict';
(function ($) {
	$.Redactor.prototype.redactor_iconic = function () {
		return {
			init: function () {
				var icons = {
					'bold': '<i class="icon-m-format_bold"></i>',
					'italic': '<i class="icon-m-format_italic"></i>',
					'link': '<i class="icon-m-link"></i>',
					'audios': {right: true, icon: '<i class="icon-m-queue_music"></i>'},
					'images': {right: true, icon: '<i class="icon-m-wallpaper"></i>'},
					'videos': {right: true, icon: '<i class="icon-m-ondemand_video"></i>'},
					'terms': {right: true, icon: '<i class="icon-m-chat"></i>'},
					'questions': {right: true, icon: '<i class="icon-m-live_help"></i>'}

					// 'format': '<i class="fa fa-paragraph"></i>',
					// 'lists': '<i class="fa fa-list"></i>',
					// 'horizontalrule': '<i class="fa fa-minus"></i>',
					// 'image': '<i class="fa fa-picture-o"></i>'
					// 'lists': '<i class="icon-m-format_align_left"></i>',
					// 'alignment': '<i class="icon-m-format_align_left"></i>',
				};

				$.each(this.button.all(), $.proxy(function (i, s) {
					var key = $(s).attr('rel');
					// var button = this.button.get(key);

					if (typeof icons[key] !== 'undefined') {
						var icon = icons[key];
						var button = this.button.get(key);
						button.addClass('re-button-icon');

						if (_.isObject(icon)) {
							if (icon.right) button.parent('li').addClass('pull-right');
							this.button.setIcon(button, icon.icon);
						}
						else {
							this.button.setIcon(button, icon);
						}
					}


					// if (button.is('[aria-haspopup]')) {
					// 	button.append('<i class="icon-m-expand_more"></i>');
					// }

				}, this));
			}
		};
	};
})(jQuery);
