'use strict';
NgProject.controller('BlockNearestEventsCtrl', ["$scope", "$interval", "EventsService", function Controller($scope, $interval, EventsService) {
	var interval = {
		promise: null,
		init: function () {
			// проверять каждую минуту
			interval.promise = $interval(deleteStartedEvents, 60000);
		},
		cancel: function () {
			$interval.cancel(interval.promise);
		}
	};

	function init() {
		interval.cancel();
		loadData().then(interval.init);
	}

	function loadData() {
		var date = new Date();
		var params = {
			limit: 3,
			// только мероприятия, которые начнуться не более чем через 6 месяцев
			between: [date.getTime(), date.setMonth(date.getMonth() + 6)]
		};
		return EventsService.findAll(params).then(function (response) {
			if (response.success) $scope.nextEvents = _.get(response, 'items', []);
		});
	}

	// Удаляет мероприятия, если дата начала больше текущей даты
	function deleteStartedEvents() {
		var date = (new Date()).getTime();
		var update = false;
		angular.forEach($scope.nextEvents, function (event) {
			update = update || event.added < date;
		});
		if (update) init();
	}

	// По событию создания мероприятия обновлять список мероприятий
	$scope.$on('events.updates', init);
	// Отменять интервал при уничтожении котроллера
	$scope.$on('$destroy', interval.cancel);

	init();
}]);
