NgProject.directive('bindHtmlCompile', ["$compile", function ($compile) {
	'use strict';

	return {
		restrict: 'A',
		compile: function () {
			return {
				pre: function (scope, element, attrs) {
					var value = scope.$eval(attrs['bindHtmlCompile']);
					element.html(value && value.toString());
					$compile(element.contents())(scope);
				}
			};
		}
	};
}]);
