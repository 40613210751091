(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state", "Deferred", "ProfileSettingsService", "cfpLoadingBar", "CurrentUser"];
	function Controller($scope, $state, Deferred, ProfileSettingsService, cfpLoadingBar, CurrentUser) {
		var errors = {
			data: {},
			add: function (field, message) {
				if (!errors.data[field])
					errors.data[field] = [];
				errors.data[field].push(message);
			},
			list: function (field) {
				return errors.data[field];
			},
			remove: function (field) {
				delete errors.data[field];
			},
			has: function (field) {
				return errors.data[field] && errors.data[field].length;
			},
			isEmpty: function () {
				return _.isEmpty(errors.data);
			}
		};

		function validateNewPassword() {
			if ($scope.model.new_password.length < 6) {
				errors.add('new_password', 'Пароль должен быть не менее 6 символов.');
			}
			else if ($scope.model.new_password !== $scope.model.confirm_password) {
				errors.add('confirm_password', 'Подтверждение пароля не совпадает с новым паролем.');
			}
		}

		function processResponse(response) {
			errors.data = response.errors || {};
		}

		$scope.model = {
			password: '',
			new_password: '',
			confirm_password: ''
		};

		$scope.controls = {
			hasError: errors.has,
			getErrors: errors.list,
			errorsIsEmpty: errors.isEmpty,

			submit: function() {
				var data = {
					current_password: $scope.model.password,
					new_password: $scope.model.new_password
				};

				validateNewPassword();

				if (!errors.isEmpty()) return;

				cfpLoadingBar.start();
				Deferred.handlePromise(ProfileSettingsService.changePassword(data), function (response) {
					if (response.success) {
						CurrentUser.destroy();
						$state.go('system.login', {newPasswordLayout: true});
					}
					else {
						processResponse(response);
					}

					cfpLoadingBar.complete();
				});
			}
		};

		$scope.$watch('model', function (data, oldData) {
			_.forEach(data, function (value, field) {
				if (value !== oldData[field] && errors.has(field)) errors.remove(field);
			});
		}, true);
	}

	app.controller('PageSettingsPasswordCtrl', Controller);
})(angular, NgProject);
