NgProject.directive('vkGroupWidget', ["$window", function ($window) {
	'use strict';
	function initVkGroup() {
		$window.VK.Widgets.Group('vk_groups', {mode: 2, wide: 0, width: 300, height: 400, color2: '434C51', color3: '57AE60'}, 74389188);
	}

	return {
		restrict: 'A',
		link: function () {
			if (!$window.VK) {
				$window.vkAsyncInit = initVkGroup;
				var template = '<script async src="//vk.com/js/api/openapi.js"></script>';
				angular.element('body').append(template);
			}
			else {
				initVkGroup();
			}
		}
	};
}]);
