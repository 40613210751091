'use strict';
NgProject.factory('SharingOptions', function () {
	// Define the constructor function.
	function SharingOptions() {}

	SharingOptions.getRequestData = function(message, owner) {
		var data = {};
		message = _.trim(message || '');

		if (_.get(owner, 'type', false) === 'group') {
			data.owner = {type: 'group', id: owner.id};
			if (!_.isEmpty(message)) data.news = {message: message};
		}
		else {
			if (!_.isEmpty(message)) data.blog_post = {message: message};
		}

		return data;
	};

	return (SharingOptions);
});
