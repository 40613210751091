NgProject.directive('usersSearch', ["$parse", function ($parse) {
	'use strict';
	controller.$inject = ["$scope", "$attrs"];
	function controller($scope, $attrs) {
		$scope.bSearchOptions = {};

		if ($attrs.filtersOptions) {
			$scope.filtersOptions = $parse($attrs.filtersOptions)($scope);
		}

		if ($attrs.queryModel) {
			$scope.queryModel = $parse($attrs.queryModel)($scope);
		}

		if ($attrs.hasOwnProperty('showQueryPanel'))
			$scope.bSearchOptions.enableQueryPane = $parse($attrs.showQueryPanel)($scope);
		if ($attrs.hasOwnProperty('showFiltersPanel'))
			$scope.bSearchOptions.showFilters = $scope.bSearchOptions.openFilters = $parse($attrs.showFiltersPanel)($scope);
	}

	return {
		templateUrl: 'widgets.users.users-search',
		restrict: 'E',
		controller: controller
	};
}]);
