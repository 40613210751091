NgProject.controller('GroupSettingsTypeCtrl', ["$scope", "Deferred", "GroupsService", "cfpLoadingBar", function ($scope, Deferred, GroupsService, cfpLoadingBar) {
	'use strict';
	$scope.$form = {
		setType: function(type) {
			if (type === $scope.$group.access) return false;
			cfpLoadingBar.start();
			Deferred.handlePromise(GroupsService.update($scope.$group.id, {access: type}), function (response) {
				cfpLoadingBar.complete();
				if (response.success) {
					$scope.$group.access = response.group.access;
				}
			});
		}
	};
}]);
