(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state", "$timeout", "$window"];
	function Controller($scope, $state, $timeout, $window) {
		var state = null;

		function scrollToTopAfterChangeState() {
			$timeout(function () {
				$($window).scrollTop(0);
			});
		}

		function redactorToolbarTriggerScroll() {
			$timeout(function () {
				$('.redactor_toolbar').trigger('scroll.redactor');
			});
		}

		$scope.$layout = {
			states: {main: 'main', questions: 'questions'},
			isNewArticle: $state.is('root.create-article'),
			articleId: this.isNewArticle ? null : parseInt($state.params.articleId),

			setState: function (name, scrollToTop) {
				state = $scope.$layout.states[name];
				redactorToolbarTriggerScroll();
				if (scrollToTop) scrollToTopAfterChangeState();
			},

			isState: function (name) {
				return state === $scope.$layout.states[name];
			}
		};
		state = $scope.$layout.states.main;
	}

	app.controller('PageArticleFormLayoutCtrl', Controller);
})(angular, NgProject);
