'use strict';
NgProject.controller('ImagesSummaryCtrl', ["$scope", function ($scope) {
	$scope.$config = {
		images: {
			promise: $scope.$page.promises.images,
			listOptions: {
				teaserOptions: {
					buttons: {
						// только если это страница управления картинками пользователя или его группа
						deleteByOwner: $scope.$page.access.update && (_.isEmpty($scope.$page.owner) || $scope.$page.owner.type === 'group')
					}
				}
			}
		},
		albums: {
			promise: $scope.$page.promises.albums,
			modal_params: $scope.$page.owner
		}
	};
}]);
