(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state", "$filter", "$q", "TermsService", "ArticlesService", "UsersService", "GroupsService", "CurrentUser", "FileUploader", "cfpLoadingBar", "SETTINGS", "UploadAvatar", "modalWindow", "InterfaceCounters"];
	function Controller($scope, $state, $filter, $q, TermsService, ArticlesService, UsersService, GroupsService,
	                    CurrentUser, FileUploader, cfpLoadingBar, SETTINGS, UploadAvatar, modalWindow, InterfaceCounters) {
		var options = $scope.$options = {
			allowChangeMaker: false,
			allowSetMakerAsGroup: false,
			allowViewMaker: false
		};

		var defaultPathName = /uploads\/photo\/file\//;

		$scope.fData = {
			isReady: false,
			isNewAvatar: false,
			imageAccept: SETTINGS.validation.image.types,
			uploader: {},
			types: [],
			participantsLabels: [],
			participants: [],
			tags: [],
			errors: {},

			hasErrors: function () {
				return !_.isEmpty($scope.fData.errors);
			},

			hasError: function (name) {
				return $scope.fData.hasErrors() && $scope.fData.errors[name];
			},

			hasImage: function () {
				return ($scope.article && $scope.article.image) || ($scope.fData.uploader.queue && $scope.fData.uploader.queue.length);
			}
		};

		$scope.series = {
			data: {
				isInclude: false,
				search: '',
				selected: [],
				items: [],
				order: null
			},
			saveData: {},
			selectedItemChange: function () { // callback
				$scope.series.data.order = !!$scope.series.data.selected.length ?
					($scope.series.data.selected[0].id === _.get($scope.series.saveData, 'selected[0].id') ?
						$scope.series.saveData.order : parseInt(_.get($scope.series.data.selected[0], 'count', 0)) + 1)
					: null;
			},
			getSeries: function () {
				if (CurrentUser.is_admin || CurrentUser.is_main_admin) {
					ArticlesService.series.list().then(function (response) {
						if (response.success) $scope.series.data.items = response.items;
					});
				} else {
					ArticlesService.series.byUser().then(function (response) {
						if (response.success) $scope.series.data.items = response.items;
					});
				}
			},
			querySearch: function () {
				var search = _.trim($scope.series.data.search);
				if (search.length)
					return $filter('filter')($scope.series.data.items, {title: search});
				return $scope.series.data.items;
			},
			showWindow: function () {
				modalWindow.open('article-series', $scope.series.data.items);
			},
			recoverData: function () {
				_.extend($scope.series.data, {
					selected: [_.clone($scope.series.saveData.selected[0])],
					order: _.clone($scope.series.saveData.order)
				});
			},
			isRecover: function () {
				return _.get($scope.series.data, 'selected[0].id') === _.get($scope.series.saveData, 'selected[0].id');
			}
		};

		$scope.series.getSeries();

		$scope.$watchCollection('series.data.items', function (ndata, odata) {
			if (_.eq(ndata, odata)) return;
			if (!_.find(ndata, {id: _.get($scope.series.data, 'selected[0].id')})) $scope.series.data.selected = [];
		});

		$scope.article = {
			model: null,
			isDraft: false,
			image: null,
			title: null,
			kindId: null,
			author: null,
			short_description: '',
			description: null,
			audios: [],
			participants: [
				{titleId: null, tags: []},
				{titleId: null, tags: []},
				{titleId: null, tags: []},
				{titleId: null, tags: []}
			],
			tags: [],
			disable_comments: false
		};

		if (CurrentUser.is_admin) {
			$scope.article.meta = {
				description: null,
				keywords: []
			};
		}

		$scope.$participants = {
			models: [],
			search: function (query) {
				query = $.trim(query);
				$scope.fData.participants = [];
				if (query.length) {
					TermsService.participants(query).then(function (response) {
						if (response.success)
							$scope.fData.participants = _.isEmpty(response.items) ? [{
								tag_type: 'text',
								data: query
							}] : response.items;
					});
				}
			},
			select: function ($index, participant) {
				var item = angular.copy(this.models[$index][0]);
				if (_.findIndex(participant.tags, item) === -1) participant.tags.push(item);
				this.models[$index] = [];
			},
			remove: function (participant, item) {
				_.remove(participant.tags, item);
			}
		};

		$scope.$tags = {
			querySearch: function (query) {
				query = $.trim(query);
				var defer = $q.defer();
				if (query.length) TermsService.tags(query, 30).then(function (response) {
					if (response.success) defer.resolve(response.items);
				});
				else defer.resolve([]);
				return defer.promise;
			},
			replaceTag: function (item) {
				if (_.isObject(item)) return;
				return {name: item.toString()};
			},
			controls: {
				left: function (items, item) {
					var index = _.indexOf(items, item);
					if (index == 0) return;
					_.exchange(items, index, --index);
				},
				right: function (items, item) {
					var index = _.indexOf(items, item);
					if (items.length - 1 === index) return;
					_.exchange(items, index, ++index);
				}
			}
		};

		function loadData(article_id) {
			var deferred = $q.defer();
			var requests = {};
			// var maker = null;

			requests.types = null;
			TermsService.contentTypes().then(function (response) {
				deferred.notify(['types', response.success, {data: response.items}]);
			});

			requests.participants = null;
			TermsService.participantsLabels().then(function (response) {
				deferred.notify(['participants', response.success, {data: response.items}]);
			});

			if (article_id) {
				requests.article = null;
				ArticlesService.findOne(article_id).then(function (response) {
					deferred.notify(['article', response.success, {data: response.article}]);
					deferred.notify(['maker', response.success, response.article.maker]);
				});
			} else {
				var maker_params = getDefaultMaker();
				requests.maker = null;
				(maker_params.type === 'user' ? UsersService.about : GroupsService.main)(maker_params.id).then(
					function (response) {
						deferred.notify(['maker', response.success, {
							type: maker_params.type,
							data: response[maker_params.type]
						}]);
					}
				);
			}

			if (article_id && $scope.article.meta) {
				requests.meta = null;
				ArticlesService.meta.load(article_id).then(function (response) {
					deferred.notify(['meta', response.success, {data: response.meta_tags}]);
				});
			}

			return deferred.promise.then(
				// success callback
				function (results) {
					if (results.article) setModelData(results.article.data);
					if (results.meta) setModelMeta(results.meta.data);
					setModelMaker(results.maker.type, results.maker.data);
					setFormTypes(results.types.data);
					setFormParticipantsLabels(results.participants.data);
				},

				// error callback
				angular.noop,

				// notify callback
				function (params) {
					requests[params[0]] = angular.extend({status: params[1]}, params[2]);
					if (_.every(requests, 'status')) deferred.resolve(requests);
				}
			);
		}

		function setFormParticipantsLabels(labels) {
			$scope.fData.participantsLabels = labels;
		}

		function initFileUploader() {
			var uploader = $scope.fData.uploader = new FileUploader({
				alias: 'avatar[file]',
				filters: [
					{
						name: 'clearErrors',
						fn: function () {
							_removeError('image');
							return true;
						}
					},
					{
						name: 'imageFilter',
						fn: function (item) {
							if (SETTINGS.validation.image.types.indexOf(item.type) === -1) {
								_setFormErrors({image: [SETTINGS.validation.image.errors.type]});
								$scope.$digest();
							}
							return true;
						}
					}
				]
			});

			// CALLBACKS

			uploader.onAfterAddingAll = function () {
				var queueCount = uploader.queue.length;
				_.forEach(uploader.queue, function (fileItem, index) {
					if (index < queueCount - 1) {
						fileItem.remove();
					}
				});
				$scope.fData.isNewAvatar = true;
			};
		}

		function setModelSeries(article) {
			var data = {
				isInclude: true,
				order: article.series.order,
				selected: [article.series['serie']]
			};
			_.extend($scope.series.data, data);
			$scope.series.saveData = _.clone(data);
			$scope.series.saveData.selected = [_.clone(data.selected[0])];
		}

		function setModelData(article) {

			if (!!article.series) setModelSeries(article);

			article.description = _replaceImagesUrl(article.description);

			$scope.article.model = angular.copy(article);

			if (_.isObject(article.kind)) {
				$scope.article.kindId = article.kind.id;
				delete article.kind;
			}

			if (_.isObject(article.avatar)) {
				$scope.article.image = ng.copy(article.avatar);
				delete article.avatar;
			}

			if (article.participants && article.participants.length) {
				article.participants = _.map(article.participants, function (participant) {
					return {
						titleId: participant.term_title.id,
						tags: _.map(participant.tags, $filter('convertHostTagToParticipantTag'))
					};
				});
				$.extend(true, $scope.article.participants, article.participants);
				delete article.participants;
			}

			if (article.status) {
				article.isDraft = article.status === 'draft';
				delete article.status;
			}

			if (article.tags) {
				article.tags = _.uniq(article.tags, 'id');
			}

			ng.extend($scope.article, article);
		}

		function _replaceImagesUrl(description) {
			var $description = $(description);
			var images = $description.find('img');
			var url = '';
			if (images.length == 0) return description;
			_.forEach(images, function (image) {
				url = $(image).attr('src');

				if (defaultPathName.test(url))
					description = description.replace(new RegExp(url, 'img'), replaceUrl(url));
			});

			return description;

			function replaceUrl(url) {
				var ext = /(\w+\.)(\d+)\.(jpg|png|gif)$/;
				if (ext.test(url)) {
					url = url.replace(ext, '$1$3');
				}
				return url;
			}
		}

		function setModelMaker(type, data) {
			$scope.article.maker = {id: data.id, type: type, data: data};
		}

		function setModelMeta(data) {
			angular.extend($scope.article.meta, data);
		}

		function setFormTypes(types) {
			$scope.fData.types = types;
			if (!$scope.article.kindId && $scope.fData.types.length) {
				$scope.article.kindId = $scope.fData.types[0].id;
			}
		}

		function updateAvatar() {
			var deferred = $q.defer();
			if (!$scope.fData.isNewAvatar) return true;

			if (_.has($scope.fData.uploader.queue[0], '_file')) {
				$scope.fData.uploader.onSuccessItem = function (fileItem, response) {
					deferred.resolve(response);
				};

				$scope.fData.uploader.onErrorItem = function (fileItem, response) {
					response = $.trim(response);
					if (!response) response = {
						success: false,
						errors: {image: [SETTINGS.validation.image.errors.undefined]}
					};
					deferred.resolve(response);
				};

				$scope.fData.uploader.url = ArticlesService.getUploadImageUrl($scope.article.id);
				$scope.fData.uploader.queue[0].url = $scope.fData.uploader.url;
				$scope.fData.uploader.uploadItem(0);
			} else {
				return ArticlesService.changeAvatar($scope.article.id, UploadAvatar.data.id);
			}

			return deferred.promise;
		}

		function _getSubmitSeriesData() {
			return {
				isInclude: $scope.series.data.isInclude,
				order: $scope.series.data.order,
				selected: $scope.series.data.selected[0]
			};
		}

		function _getSubmitData() {
			var data = {
				draft: $scope.article.isDraft,
				title: $scope.article.title,
				description: $scope.article.description,
				short_description: $scope.article.short_description || '',
				kind_id: $scope.article.kindId || null,
				author: false,
				participants: [], // todo: присвоение participants можно сделать как в видеозаписях, нужны доработки в бэке
				tags: $scope.article.tags.length ? getTagsValues($scope.article.tags) : [false],
				audios: $scope.article.audios.length ? _($scope.article.audios).pluck('id').uniq().value() : [false],
				disable_comments: $scope.article.disable_comments
			};

			if ($filter('htmlContentIsEmpty')(data.description)) data.description = '';

			// разрешает изменять мэйкера только есть это админ
			// или пользователь создает статью (не обновляет) от имени группы
			if (options.allowChangeMaker || (!$scope.article.id && options.allowSetMakerAsGroup)) {
				data.maker = {type: $scope.article.maker.type, id: $scope.article.maker.data.id};
			}

			if ($scope.article.author) {
				data.author = $scope.article.author.type === 'text' ? $scope.article.author.data : $scope.article.author.id;
			}

			if ($scope.article.participants.length) {
				ng.forEach($scope.article.participants, function (role) {
					var performers = getPerformersValues(role.tags);
					if (role.titleId && performers.length) data.participants.push({
						term_title_id: role.titleId,
						tags: performers
					});
				});
			}
			if (!data.participants.length) {
				if ($scope.article.id) data.participants = [false];
				else delete data.participants;
			}

			return data;
		}

		function _removeError(name) {
			delete $scope.fData.errors[name];
		}

		function _setFormErrors(errors) {
			if (!_.isObject(errors)) return;

			var renamedFields = {
				kind: 'kindId',
				avatar: 'image'
			};

			_.forEach(renamedFields, function (evField, resField) {
				if (errors.hasOwnProperty(resField)) {
					errors[evField] = errors[resField];
					delete errors[resField];
				}
			});

			$scope.fData.errors = errors;
		}

		function getPerformersValues(items) {
			return _(items).map(function (item) {
				return _.get(item, 'tag_type', 'text') === 'text' ? item.data : item.tag_id;
			}).uniq().reverse().value();
		}

		function getTagsValues(items) {
			return _.map(items, function (item) {
				return _.isEmpty(item.host_type) ? item.name : item.id;
			});
		}

		function getDefaultMaker() {
			var is_default = !$state.params.maker_type || !$state.params.maker_id;
			return {
				type: is_default ? 'user' : $state.params.maker_type,
				id: is_default ? CurrentUser.id : $state.params.maker_id
			};
		}

		function initUserAccess() {
			var maker = getDefaultMaker();
			options.allowChangeMaker = CurrentUser.is_admin;
			options.allowViewMaker = options.allowChangeMaker || maker.type === 'group';
			options.allowSetMakerAsGroup = options.allowViewMaker && maker.type === 'group';
		}

		function updateMetaTags() {
			if (!$scope.article.meta) return true;
			return ArticlesService.meta.update($scope.article.id, $scope.article.meta);
		}

		var serieArticle = {
			add: function (serieId, articleId, order) {
				return ArticlesService.series.addArticle(serieId, articleId).then(function (response) {
					if (response.success) {
						$scope.series.saveData.selected = [response.serie];
						delete $scope.series.saveData.selected[0].articles;
						$scope.series.saveData.order = order;
						return response;
					} else return $q.reject(response.errors);
				});
			},
			delete: function (serieId, articleId) {
				return ArticlesService.series.deleteArticle(serieId, articleId).then(function (response) {
					if (response.success) {
						_.extend($scope.series.data, {selected: null, order: null, search: ''});
						_.extend($scope.series.saveData, {selected: [], order: null});
					}
					return response;
				});
			},
			order: function (serieId, articleId, order) {
				return ArticlesService.series.orderArticle(serieId, articleId, parseInt(order)).then(function (response) {
					if (response.success) return response;
					else return $q.reject(response.errors);
				});
			}
		};

		function seriesArticleChanger(dataSeries) {
			var serieId = null;
			var oldSerieId = null;
			var articleId = $scope.article.id;

			if (!articleId) return;

			// если статья ещё не была добавлена в серию
			if (dataSeries.isInclude && _.isEmpty($scope.series.saveData) && _.has(dataSeries.selected, 'id')) {
				serieId = dataSeries.selected.id;
				if (DEBUG) console.log('Добавляем новую статью в серию');
				return serieArticle.add(serieId, articleId, dataSeries.order);
			}

			// если статья добавлялась в серию и надо изменить/удалить серию, поменять порядок статьи
			if (dataSeries.isInclude) {
				if (_.has(dataSeries.selected, 'id')) {
					serieId = dataSeries.selected.id;
					if (!!dataSeries.selected.id && dataSeries.selected.id !== $scope.series.saveData.selected[0].id) {
						if (DEBUG) console.log('Меняем серию статьи');
						oldSerieId = $scope.series.saveData.selected[0].id;
						return serieArticle.delete(oldSerieId, articleId).then(function (status) {
							if (status.success) {
								return serieArticle.add(serieId, articleId, dataSeries.order);
							}
						});
					}
					if (!!dataSeries.order && dataSeries.order !== $scope.series.saveData.order) {
						if (DEBUG) console.log('Изменяем позицию статьи');
						return serieArticle.order(serieId, articleId, dataSeries.order);
					}
				} else {
					if (DEBUG) console.log('Error: необходимо выбрать серию');
					//return $q.reject({error: '1234'});
				}
			} else {
				if (!_.isEmpty($scope.series.saveData)) {
					if (DEBUG) console.log('Удаляем статью из серии');
					oldSerieId = $scope.series.saveData.selected[0].id;
					return serieArticle.delete(oldSerieId, articleId);
				}
			}
		}

		function save() {
			var data = _getSubmitData();
			var dataSeries = _getSubmitSeriesData();
			data.description = _resizeImages(data.description);
			var promise = $scope.article.id ? ArticlesService.update($scope.article.id, data) : ArticlesService.create(data);
			cfpLoadingBar.start();

			promise
				.then(function (response) {
					return response.success ? response.article : $q.reject(response.errors);
				})
				.then(function (data) {
					// при создании статьи
					angular.extend($scope.article, {id: data.id, code: data.code});
				})
				.then(function () {
					return seriesArticleChanger(dataSeries);
				})
				.then(function () {
					return $q.all([updateAvatar(), updateMetaTags()]).then(function (responses) {
						var response_with_errors = _(responses).find('errors');
						return response_with_errors ? $q.reject(response_with_errors.errors) : true;
					});
				})
				.then(function () {
					InterfaceCounters.articles.private += 1;
					$state.go('root.articles.private').then(function () {
						$state.go('modal.article-view', {
							articleId: $scope.article.id,
							code: $scope.article.code
						});
					});
				})
				.catch(_setFormErrors)
				.finally(function () {
					cfpLoadingBar.complete();
				});
		}

		function _resizeImages(description) {
			var $description = $(description);
			var images = $description.find('img');
			if (images.length === 0) return description;
			var width = null, newURL = null, regex = null;
			_.forEach(images, function (image) {
				image = $(image);
				if (defaultPathName.test(image.attr('src'))) {
					width = image.data().width;
					if (!!width) {
						newURL = getResizeUrl(image.attr('src'), width);
						regex = new RegExp(image.attr('src'), 'img');
						description = description.replace(regex, newURL);
					}
				}
			});

			return description;

			function getResizeUrl(url, size) {
				url = url.split('/');
				var imageName = url.pop().split('.');
				var ext = imageName.pop();

				imageName.push(Math.ceil(size), ext);
				url.push(imageName.join('.'));
				return url.join('/');
			}
		}

		$scope.controls = {
			publish: function () {
				$scope.article.isDraft = false;
				save();
			},

			saveDraft: function () {
				$scope.article.isDraft = true;
				save();
			},

			save: save,

			remove: function () {
				$scope.article.model[CurrentUser.is_admin ? 'deleteOverall' : 'deleteByOwner']().then(function () {
					$state.go('root.articles.private');
				});
			},

			cancel: function () {
				$state.go('root.articles.private');
				if (cfpLoadingBar.status() !== 1) cfpLoadingBar.complete();
			},

			changeAvatar: function () {
				modalWindow.open('change-avatar');
			}
		};

		$scope.changeAuthor = function () {
			$scope.$modal.open('change-maker', {
				options: {
					title: 'Автор статьи',
					allowTextValue: true,
					allowEmptyValue: true,
					eventName: 'author-changed'
				},
				data: angular.copy($scope.article.author)
			});
		};

		$scope.$watch('article', function (data, oldData) {
			_.forEach(data, function (value, field) {
				if (value !== oldData[field] && $scope.fData.hasError(field)) {
					_removeError(field);
				}
			});
		}, true);

		var updateDataAvatar = function () {
			$scope.fData.isNewAvatar = true;
			$scope.fData.uploader.queue[0] = UploadAvatar.data;
		};

		UploadAvatar.reset();
		UploadAvatar.registerCallback(updateDataAvatar);

		$scope.$on('author-changed', function (e, author) {
			$scope.article.author = author;
		});

		// Initialization
		initUserAccess();
		initFileUploader();
		loadData($scope.$layout.articleId).then(function () {
			$scope.fData.isReady = true;
		});
	}

	app.controller('ArticleFormCtrl', Controller);
})(angular, NgProject);
