NgProject.controller('PageSearchCtrl', ["$scope", "AudiosService", "ImagesService", "VideosService", "UsersService", "ArticlesService", "EventsService", "GroupsService", "TermsService", function Controller($scope, AudiosService, ImagesService, VideosService, UsersService, ArticlesService, EventsService, GroupsService, TermsService) {
	'use strict';
	$scope.$config = [
		{
			name: 'users',
			template: 'pages.search.sections.users',
			promise: UsersService.list,
			showHeader: true,
			options: {
				requestParams: {
					limit: 3
				}
			},
			listOptions: {
				loadMore: false
			}
		},
		{
			name: 'events',
			template: 'pages.search.sections.events',
			promise: EventsService.findAll,
			showHeader: true,
			options: {
				requestParams: {
					limit: 2,
					younger_than: new Date().getTime()
				}
			},
			listOptions: {
				loadMore: false
			}
		},
		{
			name: 'articles',
			template: 'pages.search.sections.articles',
			promise: ArticlesService.findAll,
			showHeader: true,
			options: {
				requestParams: {
					limit: 3
				}
			},
			listOptions: {
				loadMore: false
			}
		},
		{
			name: 'groups',
			template: 'pages.search.sections.groups',
			promise: GroupsService.findAll,
			showHeader: true,
			options: {
				requestParams: {
					limit: 3
				}
			},
			listOptions: {
				loadMore: false
			}
		},
		{
			name: 'videos',
			template: 'pages.search.sections.videos',
			promise: VideosService.findAll,
			showHeader: true,
			options: {
				requestParams: {
					limit: 3
				}
			},
			listOptions: {
				loadMore: false
			}
		},
		{
			name: 'audios',
			template: 'pages.search.sections.audios',
			promise: AudiosService.findAll,
			showHeader: true,
			options: {
				requestParams: {
					limit: 5
				}
			},
			listOptions: {
				loadMore: false
			}
		},
		{
			name: 'images',
			template: 'pages.search.sections.images',
			promise: ImagesService.findAll,
			showHeader: true,
			options: {
				requestParams: {
					limit: 5
				}
			},
			listOptions: {
				loadMore: false
			}
		},
		{
			name: 'terms',
			template: 'pages.search.sections.terms',
			promise: TermsService.list,
			showHeader: true,
			options: {
				requestParams: {
					limit: 4,
					extended: true
				},
				group: false,
				showFiltersPanel: false
			},
			listOptions: {
				template: 'widgets.terms.terms-list-extended',
				loadMore: false
			}
		}
	];

	$scope.getSectionByName = function (name) {
		return _.find($scope.$config, 'name', name);
	};

	$scope.toggleFilterPanel = function (isShown) {
		$scope.showFiltersPanel = isShown;
	};
}]);
