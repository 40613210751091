'use strict';
NgProject.controller('ModalImageViewCtrl', ["$scope", "CurrentUser", "TermsService", "Image", "cfpLoadingBar", function ($scope, CurrentUser, TermsService, Image, cfpLoadingBar) {
	$scope.$lightbox = $scope.$modalWindow.data(0);
	$scope.$buttons = {
		'share': Boolean(CurrentUser.id),
		'add': Boolean(CurrentUser.id)
	};

	$scope.$controls = {
		mode: 'view',
		model: null,

		options: {
			allowChangeMaker: false,
			allowSetMakerAsGroup: false,
			allowViewMaker: false
		},

		tags: {
			data: [],
			search: function (query) {
				query = $.trim(query);
				$scope.$controls.tags.data = [];
				if (query.length > 1) {
					TermsService.tags(query, 10).then(function (response) {
						if (response.success) $scope.$controls.tags.data = _.isEmpty(response.items) ? [{name: query}] : response.items;
					});
				}
			},

			exist: function (tag) {
				var params = tag.host_id ? {id: tag.id} : {name: tag.name};
				return _.findIndex($scope.$controls.model.tags, params) !== -1;
			}
		},

		setViewMode: function (mode) {
			switch (mode) {
				case 'view':
					$scope.$controls.mode = mode;
					$scope.$controls.model = null;
					$scope.$lightbox.slider_is_on = $scope.$lightbox.is_slider;
					break;
				case 'update':
					$scope.$controls.mode = mode;
					$scope.$controls.model = angular.copy($scope.$lightbox.data);
					$scope.$lightbox.slider_is_on = false;
					initUserAccess();
					break;
			}
		},

		save: function () {
			cfpLoadingBar.start();
			$scope.$controls.model.update().then(function (data) {
				angular.extend($scope.$lightbox.data, {
					description: data.description,
					tags: data.tags,
					maker: data.maker
				});
				$scope.$controls.setViewMode('view');
				cfpLoadingBar.complete();
			});
		},

		cancel: function () {
			$scope.$controls.setViewMode('view');
		},

		remove: function () {
			$scope.$lightbox.data[CurrentUser.is_admin ? 'deleteOverall' : 'deleteByOwner']().then($scope.$close);
		}
	};

	function initUserAccess() {
		var maker = $scope.$lightbox.data.maker;
		$scope.$controls.options.allowChangeMaker = CurrentUser.is_admin;
		$scope.$controls.options.allowViewMaker = $scope.$controls.options.allowChangeMaker || maker.type === 'group';
		$scope.$controls.options.allowSetMakerAsGroup = $scope.$controls.options.allowViewMaker && maker.type === 'group';
	}

	$scope.$on('$destroy', $scope.$lightbox.reset);
}]);
