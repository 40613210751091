(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "Deferred", "ProfileSettingsService", "SETTINGS", "cfpLoadingBar"];
	function Controller($scope, Deferred, ProfileSettingsService, SETTINGS, cfpLoadingBar) {
		$scope.model = {
			frequency: null,
			notifications: {}
		};

		$scope.controls = {
			isLoading: false,
			frequency_items: SETTINGS.notifications.frequency,
			optionLabel: function(key) {
				return SETTINGS.notifications.labels[key];
			}
		};

		function saveChanges() {
			var data = {
				notify_frequent: $scope.model.frequency,
				notifications: $scope.model.notifications
			};

			cfpLoadingBar.start();
			ProfileSettingsService.notifications.update(data)
				.then(function (response) {
					if (response.success) {
						$scope.model.frequency = response.frequency;
						angular.extend($scope.model.notifications, response.notifications);
					}
				})
				.finally(function() {
					cfpLoadingBar.complete();
				});
		}

		function applyRemoteData(response) {
			angular.extend($scope.model, {
				frequency: response.frequency,
				notifications: response.notifications
			});
		}

		function enableWatcher() {
			$scope.$watch('model', function(new_value, old_value) {
				if (!angular.equals(new_value, old_value)) saveChanges();
			}, true);
		}

		Deferred.handlePromise(ProfileSettingsService.notifications.get(), function (response) {
			if (response.success) applyRemoteData(response);
			$scope.controls.isLoading = true;
			enableWatcher();
		});
	}

	app.controller('PageSettingsNotificationsCtrl', Controller);
})(angular, NgProject);
