NgProject.controller('modal:registration.ctrl', ["$scope", "$state", "Deferred", "AuthService", "moment", function Controller($scope, $state, Deferred, AuthService, moment) {
	'use strict';
	$scope.submitForm = function () {
		$scope.errors = {};
		if (validate()) {
			Deferred.handlePromise(AuthService.registration($scope.credentials), function (response) {
				if (response.success) {
					$scope.isFormSubmitted = true;
				}
				else {
					$scope.errors = response.errors;
					$scope.credentials.password = $scope.credentials.password_confirm = null;
				}
			});
		}
	};

	$scope.credentials = {
		fullname: null,
		nickname: null,
		email: null,
		password: null,
		password_confirm: null,
		utc_offset: -1 * new Date().getTimezoneOffset() || null
	};

	if (!_.isEmpty($state.params.credentials)) {
		angular.extend($scope.credentials, $state.params.credentials);
	}

	$scope.errors = {};
	$scope.isFormSubmitted = false;

	function validate() {
		if ($scope.credentials.password !== $scope.credentials.password_confirm) {
			$scope.errors = {
				'password': ['Пароль должен быть идентичен']
			};
		}

		return _.isEmpty($scope.errors);
	}
}]);
