(function (ng, app) {
	'use strict';
	app.service('LocationService', ["$resource", "serviceHelper", "SETTINGS", function ($resource, serviceHelper, SETTINGS) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/:section/:id/:command', {
			section: '@section',
			id: '@id',
			command: '@command'
		}, {
			countriesList: {params: {section: 'countries'}},
			citiesList: {params: {section: 'cities'}}
		});

		return {
			countriesList: function () {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'countriesList'
				});
				return (promise);
			},

			citiesList: function (countryId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'citiesList',
					parameters: {country_id: countryId}
				});
				return (promise);
			}
		};
	}]);
})(angular, NgProject);
