(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "$state", "$filter", "AUTH_EVENTS", "UsersService", "CurrentUser", "NewsService", "ArticlesService", "EventsService", "GroupsService"];
	function Controller($scope, $state, $filter, AUTH_EVENTS, UsersService, CurrentUser, NewsService, ArticlesService, EventsService, GroupsService) {
		var userId = parseInt($state.params.userId);
		$scope.user = {};

		$scope.contents = {
			audios: false,
			videos: false,
			images: false,
			articles: false,
			events: false,
			groups: false,
			materials: false
		};

		$scope.data = {
			showInfoSection: false,
			updateButton: {
				show: false,
				href: $state.href('root.settings.profile')
			},
			allowUpdate: true,
			isCurrentUser: false,
			news: {
				promise: NewsService.myNews
			}
		};

		$scope.$ctrl = {
			follow: function () {
				var oldValue = $scope.user.following;
				$scope.user.following = true;
				UsersService.relationship.follow($scope.user.id).then(function (response) {
					$scope.user.following = _.get(response, 'success', false) ? true : oldValue;
				});
			},
			unfollow: function () {
				var oldValue = $scope.user.following;
				$scope.user.following = false;
				UsersService.relationship.unfollow($scope.user.id).then(function (response) {
					$scope.user.following = _.get(response, 'success', false) ? false : oldValue;
				});
			}
		};

		$scope.groups = {
			promise: function (params) {
				return GroupsService.findAllByUser($scope.user.id, params);
			}
		};

		$scope.events = {
			promise: function (params) {
				return EventsService.findAllByUser($scope.user.id, params);
			},
			options: {
				requestParams: {younger_than: new Date().getTime()}
			},
			action: {
				show: CurrentUser.is_admin,
				sref: 'root.create-event({maker_type: "user", maker_id:' + userId + '})'
			}
		};

		$scope.articles = {
			status: 'published',
			promise: function (params) {
				return ArticlesService.findAllByUser($scope.user.id, _.extend(params, {status: $scope.articles.status}));
			},
			options: {
				events: {
					onReloadList: 'reload-user-articles'
				}
			},
			action: {
				show: CurrentUser.is_admin,
				sref: 'root.create-article({maker_type: "user", maker_id:' + userId + '})'
			},
			setFilterStatus: function (status) {
				if ($scope.articles.status !== status) {
					$scope.articles.status = status;
					$scope.$broadcast($scope.articles.options.events.onReloadList);
				}
			}
		};

		function init() {
			$scope.user = {};
			$scope.data.isCurrentUser = CurrentUser.id === userId;

			if ($scope.data.isCurrentUser) {
				$scope.data.news.promise = NewsService.myNews;
			}
			else {
				$scope.data.news.promise = function (params) {
					return UsersService.wall(userId, params);
				};
			}

			loadRemoteData(userId);
		}

		function initMenu() {
			$scope.data.updateButton.show = false;
			if (CurrentUser.id) {
				if ($scope.data.isCurrentUser) {
					$scope.data.updateButton.show = true;
				}
				else if (CurrentUser.is_admin) {
					$scope.data.updateButton.show = true;
					$scope.data.updateButton.href = $state.href('root.user-settings', {userId: userId});
				}
			}
		}

		function loadRemoteData(id) {
			UsersService.about($scope.data.isCurrentUser ? 'self' : id).then(function (response) {
				var data = {};
				if (response.success) {
					data = response.user;
					setContentsFlags(response.contents);
				}

				applyRemoteData(data);
				initMenu();
			});
		}

		function applyRemoteData(user) {
			user.location_name = $filter('locationName')(user);
			$scope.data.showInfoSection = !!(user.object_contacts || user.bio || user.birthday);
			$scope.user = user;
		}

		function setContentsFlags(flags) {
			_.map($scope.contents, function (value, key) {
				$scope.contents[key] = flags[key] || CurrentUser.is_admin;
			});
			$scope.contents.materials = _.some([flags.images, flags.videos, flags.audios]) || CurrentUser.is_admin;
		}

		$scope.$on(AUTH_EVENTS.authenticationSuccess, init);
		$scope.$on(AUTH_EVENTS.loginSuccess, init);
		$scope.$on(AUTH_EVENTS.logoutSuccess, init);
		$scope.$on(AUTH_EVENTS.sessionTimeout, init);
		init();
	}

	app.controller('PageProfileCtrl', Controller);
})(angular, NgProject);
