(function (ng, app) {
	'use strict';
	app.service('EventsQuestionsService', ['$resource', 'serviceHelper', 'SETTINGS', function ($resource, serviceHelper, SETTINGS) {

		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/events/:eventId/:section/:questionId/:command', {
			eventId: '@eventId',
			section: '@section',
			questionId: '@questionId',
			command: '@command'
		}, {
			list: {
				method: 'GET',
				params: {
					section: 'questions'
				}
			},
			create: {
				method: 'POST',
				params: {
					section: 'questions'
				}
			},
			update: {
				method: 'PUT',
				params: {
					section: 'questions'
				}
			},
			remove: {
				method: 'DELETE',
				params: {
					section: 'questions'
				}
			},
			publish: {
				method: 'PUT',
				params: {
					section: 'questions',
					command: 'publish'
				}
			},
			userUnpublishedQuestions: {
				method: 'GET',
				params: {
					section: 'my_unpublished_questions'
				}
			}
		});

		return {
			list: function (eventId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'list',
					parameters: {
						eventId: eventId
					}
				});
				return (promise);
			},

			create: function (eventId, question) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'create',
					parameters: {
						eventId: eventId,
						question: {
							value: question
						}
					}
				});
				return (promise);
			},

			update: function (eventId, questionId, question) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'update',
					parameters: {
						eventId: eventId,
						questionId: questionId,
						question: {
							value: question
						}
					}
				});
				return (promise);
			},

			remove: function (eventId, questionId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'remove',
					parameters: {
						eventId: eventId,
						questionId: questionId
					}
				});
				return (promise);
			},

			publish: function (eventId, questionId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'publish',
					parameters: {
						eventId: eventId,
						questionId: questionId
					}
				});
				return (promise);
			},

			userUnpublishedQuestions: function (eventId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'userUnpublishedQuestions',
					parameters: {
						eventId: eventId
					}
				});
				return (promise);
			}
		};
	}]);

})(angular, NgProject);
