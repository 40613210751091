NgProject.factory('ImagesAlbumsSearchParams', function () {
	'use strict';
	return {
		process: function (params) {
			var options = {};
			if (_.isEmpty(params)) return options;
			if (!_.isEmpty(params.q)) options.q = params.q;
			if (params.page && params.page > 1) options.page = params.page;
			if (params.limit) options.per_page = params.limit;
			return options;
		}
	};
});
