NgProject.service('ImFaye', ["$rootScope", "$q", "$location", "faye", "SETTINGS", "CurrentUser", "AUTH_EVENTS", function ($rootScope, $q, $location, faye, SETTINGS, CurrentUser, AUTH_EVENTS) {
	'use strict';
	var ImFaye = this;
	var fayeClient = faye($location.protocol() + ':' + SETTINGS.fayeClient);
	var imFaye = null, defaults = {
		id: null,
		message: {}
	};

	function init() {
		if (!CurrentUser.id) return false;
		if (DEBUG) console.log('init im faye');
		_.extend(ImFaye, angular.copy(defaults));
		imFaye = fayeClient.subscribe('/chat/' + CurrentUser.id, function (data) {
			data = angular.fromJson(data);
			setModelData(data);
		});
	}

	function setModelData(data) {
		ImFaye.id = data['id'];
		ImFaye.message = data['message'];
	}

	function reset() {
		if (DEBUG) console.log('reset im faye');
		_.extend(ImFaye, angular.copy(defaults));
		if (imFaye) imFaye.cancel();
	}

	$rootScope.$on(AUTH_EVENTS.authenticationSuccess, init);
	$rootScope.$on(AUTH_EVENTS.loginSuccess, init);
	$rootScope.$on(AUTH_EVENTS.logoutSuccess, reset);
	$rootScope.$on(AUTH_EVENTS.sessionTimeout, reset);

	init();

	return ImFaye;
}]);
