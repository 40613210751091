(function (ng, app) {
	'use strict';
	Controller.$inject = ["$rootScope", "$scope", "$q", "$filter", "Deferred", "AudiosService", "TermsService", "GenresService", "CurrentUser", "UsersService", "GroupsService", "cfpLoadingBar", "FileUploader"];
	var fileConfig = {
		rules: {
			maxSize: 200, // 200 MB
			accept: ['audio/mpeg', 'audio/mp3']
		},
		errors: {
			0: 'Неизвестная ошибка, возникшая при загрузке аудиофайла.',
			1: 'Данный формат аудиофайлов не поддерживается. Аудиофайл должен быть в формате mp3',
			2: 'Максимальный размер файла 200 MB'
		}
	};

	function Controller($rootScope, $scope, $q, $filter, Deferred, AudiosService, TermsService, GenresService, CurrentUser, UsersService, GroupsService, cfpLoadingBar, FileUploader) {
		var errors = {
			data: {},
			add: function (field, message) {
				if (!errors.data[field]) errors.data[field] = [];
				errors.data[field].push(message);
			},
			list: function (field) {
				return errors.data[field];
			},
			remove: function (field) {
				delete errors.data[field];
			},
			has: function (field) {
				return errors.data[field] && errors.data[field].length;
			},
			isEmpty: function () {
				return _.isEmpty(errors.data);
			}
		};

		var options = $scope.$options = {
			allowChangeMaker: false,
			allowSetMakerAsGroup: false,
			allowViewMaker: false
		};

		$scope.$errors = {
			get: errors.list,
			has: errors.has,
			isEmpty: errors.isEmpty
		};

		$scope.$model = {
			title: null,
			genre: null,
			performers: [],
			performers_role: null,
			tags: [],
			participants: [{titleId: null}, {titleId: null}]
		};

		$scope.$data = {
			isReady: false,
			action: 'create',
			state: 0,
			genres: [],
			participantsLabels: [],
			participants: [],
			tags: [],
			performers: []
		};

		$scope.$uploader = {
			instance: new FileUploader({
				alias: 'audio[file]',
				url: AudiosService.getUploadUrl(),
				autoUpload: true,
				removeAfterUpload: true,
				queueLimit: 1,
				onSuccessItem: function (item, response) {
					if (response.success) {
						$scope.$controls.uploaded(response.file.id);
					}
					else {
						_.forEach(_.get(response, 'errors.file', [fileConfig.errors[0]]), function (message) {
							errors.add('file', message);
						});
						$scope.$uploader.instance.clearQueue();
					}
				},
				onErrorItem: function () {
					errors.add('file', fileConfig.errors[0]);
					$scope.$uploader.instance.clearQueue();
				},
				onAfterAddingFile: function () {
					errors.remove('file');
					$scope.$uploader.validate();
				}
			}),
			attrs: {
				accept: fileConfig.rules.accept.join(',')
			},
			validate: function () {
				var file = $scope.$uploader.instance.queue[0]._file;
				var error = false;
				// validate accept
				if (fileConfig.rules.accept.indexOf(file.type) === -1) error = fileConfig.errors[1];
				// validate filesize
				else if (file.size > fileConfig.rules.maxSize * 1024 * 1024) error = fileConfig.errors[2];
				if (error) {
					errors.add('file', error);
					$scope.$uploader.instance.clearQueue();
				}
			}
		};

		$scope.$performers = {
			search: function (query) {
				query = $.trim(query);
				$scope.$data.performers = [];
				if (query.length) {
					Deferred.handlePromise(TermsService.participants(query), function (response) {
						if (response.success) {
							$scope.$data.performers = _.isEmpty(response.items) ? [{tag_type: 'text', data: query}] : response.items;
						}
					});
				}
			},
			exist: function (item) {
				var params = item.host_id ? {host_id: item.host_id} : {name: item.name};
				return _.findIndex($scope.$model.performers, params) !== -1;
			}
		};

		$scope.$participants = {
			models: [],
			search: function (query) {
				query = $.trim(query);
				$scope.$data.participants = [];
				if (query.length) {
					Deferred.handlePromise(TermsService.participants(query), function (response) {
						if (response.success) {
							$scope.$data.participants = _.isEmpty(response.items) ? [{tag_type: 'text', data: query}] : response.items;
						}
					});
				}
			},
			exist: function ($index, item) {
				var params = item.host_id ? {host_id: item.host_id} : {name: item.name};
				return _.findIndex(this.models[$index], params) !== -1;
			},
			existRole: function (index, item) {
				var part = _.findIndex($scope.$model.participants, {titleId: item.id});
				return part !== -1 && part !== index;
			}
		};

		$scope.$genres = {
			search: function (query) {
				query = $.trim(query);
				_.remove($scope.$data.genres, 'custom');
				if (query.length) {
					$scope.$data.genres.push({name: query, custom: true});
				}
			}
		};

		$scope.$tags = {
			models: [],
			search: function (query) {
				query = $.trim(query);
				$scope.$data.tags = [];
				if (query.length > 1) {
					Deferred.handlePromise(TermsService.tags(query, 10), function (response) {
						if (response.success) $scope.$data.tags = _.isEmpty(response.items) ? [{name: query}] : response.items;
					});
				}
			},
			select: function () {
				var item = angular.copy(this.models[0]);
				if (!this.exist(item)) $scope.$model.tags.push(item);
				this.models = [];
			},
			remove: function (tag) {
				_.remove($scope.$model.tags, tag);
			},
			exist: function (tag) {
				var params = tag.host_id ? {id: tag.id} : {name: tag.name};
				return _.findIndex($scope.$model.tags, params) !== -1;
			}
		};

		$scope.$model.validate = function () {
			if (!$scope.$model.performers_role)
				errors.add('performers_role', 'Необходимо выбрать роль исполнителя.');
			if (_.isEmpty(getTagsValues($scope.$model.performers)))
				errors.add('performers', 'Необходимо выбрать исполнителя.');
			if (_.isEmpty(_.trim(($scope.$model.title))))
				errors.add('title', 'Необходимо заполнить название аудиозаписи.');
			return errors.isEmpty();
		};

		$scope.$controls = {
			uploaded: function (file_id) {
				$scope.$model.file_id = file_id;
				$scope.$data.state++;
			},

			save: function () {
				if (!$scope.$model.validate()) return;
				var promise = AudiosService[$scope.$model.id ? 'update' : 'create'];
				var data = requestData();
				promise = $scope.$model.id ? promise($scope.$model.id, data) : promise(data);
				cfpLoadingBar.start();
				Deferred.handlePromise(promise, function (response) {
					cfpLoadingBar.complete();
					if (response.success) {
						$scope.$data.state++;
						$rootScope.$broadcast('audios-list-reload');
					}
					else setFormErrors(response.errors);
				});
			},

			remove: function () {
				$scope.$model.audio[CurrentUser.is_admin ? 'deleteOverall' : 'deleteByOwner']().then($scope.$close);
			},

			close: function () {
				$scope.$close();
			}
		};

		function initUserAccess() {
			var maker = getDefaultMaker();
			options.allowChangeMaker = CurrentUser.is_admin;
			options.allowViewMaker = options.allowChangeMaker || maker.type === 'group';
			options.allowSetMakerAsGroup = options.allowViewMaker && maker.type === 'group';
		}

		function loadData(id) {
			var deferred = $q.defer();
			var requests = {participants: null, genres: null};
			Deferred.handleAllPromises([TermsService.participantsLabels(), GenresService.list()], function (participants, genres) {
				deferred.notify(['participants', participants.success, {data: participants.items}]);
				deferred.notify(['genres', genres.success, {data: genres.items}]);
			});

			if (id) {
				requests.audio = null;
				Deferred.handlePromise(AudiosService.get(id), function (response) {
					deferred.notify(['audio', response.success, {data: response.audio}]);
				});
			}
			else {
				var maker = getDefaultMaker();
				requests.maker = null;
				Deferred.handlePromise(maker.type === 'user' ? UsersService.about(maker.id) : GroupsService.main(maker.id), function (response) {
					deferred.notify(['maker', response.success, {type: maker.type, data: response[maker.type]}]);
				});
			}

			return deferred.promise.then(angular.noop, angular.noop, function (request) {
				requests[request[0]] = angular.extend({status: request[1]}, request[2]);
				if (_.every(requests, 'status')) {
					if (requests.audio) {
						setModelData(requests.audio.data);
						setModelMaker(requests.audio.data.maker.type, requests.audio.data.maker.data);
					}
					if (requests.maker) setModelMaker(requests.maker.type, requests.maker.data);
					setFormParticipantsLabels(requests.participants.data);
					setFormGenres(requests.genres.data);
					deferred.resolve();
				}
			});
		}

		function getDefaultMaker() {
			var maker = $scope.$modalWindow.data(1);
			var is_default = !maker;
			return {type: is_default ? 'user' : maker.type, id: is_default ? CurrentUser.id : maker.id};
		}

		function setFormParticipantsLabels(labels) {
			$scope.$data.participantsLabels = labels;
			if (!$scope.$model.performers_role) $scope.$model.performers_role = _.get(labels, '[0].id', null);
		}

		function setFormGenres(genres) {
			$scope.$data.genres = genres;
		}

		function setModelData(data) {
			angular.extend($scope.$model, {
				id: data.id,
				title: _.trim(data.title),
				genre: _.get(data, 'genre.name', null),
				tags: _.uniq(data.tags, 'id'),
				audio: data
			});

			var performer = _.get(data, 'participants[0]', null);
			if (!_.isEmpty(performer)) {
				data.participants = _.rest(data.participants);
				$scope.$model.performers_role = performer.term_title.id;
				$scope.$model.performers = _.map(performer.tags, $filter('convertHostTagToParticipantTag'));
			}

			if (!_.isEmpty(data.participants)) {
				_.forEach(data.participants, function (item, index) {
					$scope.$model.participants[index].titleId = item.term_title.id;
					$scope.$participants.models[index] = _.map(item.tags, $filter('convertHostTagToParticipantTag'));
				});
			}
		}

		function setModelMaker(type, data) {
			$scope.$model.maker = {id: data.id, type: type, data: data};
		}

		function requestData() {
			var data = {
				title: $scope.$model.title,
				genre: $scope.$model.genre,
				tags: $scope.$model.tags.length ? getTagsValues($scope.$model.tags) : [false],
				participants: []
			};

			data.participants.push({
				term_title_id: $scope.$model.performers_role,
				tags: getPerformersValues($scope.$model.performers)
			});

			angular.forEach($scope.$model.participants, function (role, index) {
				var performers = getPerformersValues($scope.$participants.models[index]);
				if (role.titleId && performers.length) data.participants.push({
					term_title_id: role.titleId,
					tags: performers
				});
			});

			if ($scope.$data.action === 'create') {
				data.file_id = $scope.$model.file_id;
				if (options.allowSetMakerAsGroup && $scope.$model.maker.type === 'group') {
					// разрешает задать мэйкера только если пользователь создает материал (не обновляет) от имени группы
					data.maker = {type: 'group', id: $scope.$model.maker.data.id};
				}
			}

			if (!data.maker && options.allowChangeMaker) {
				data.maker = {type: $scope.$model.maker.type, id: $scope.$model.maker.data.id};
			}

			return data;
		}

		function getPerformersValues(items) {
			return _(items).map(function (item) {
				return _.get(item, 'tag_type', 'text') === 'text' ? item.data : item.tag_id;
			}).uniq().reverse().value();
		}

		function getTagsValues(items) {
			return _.map(items, function (item) {
				return _.isEmpty(item.host_type) ? item.name : item.id;
			});
		}

		function setFormErrors(data) {
			errors.data = data;
		}

		function initWatchers() {
			$scope.$watchCollection('$model', function (data, oldData) {
				_.forIn(data, function (value, field) {
					if (errors.has(field) && value !== oldData[field]) errors.remove(field);
				});
			});
		}

		// Initialization
		initUserAccess();
		loadData($scope.$modalWindow.data(0)).then(function () {
			$scope.$data.action = $scope.$model.id ? 'update' : 'create';
			if ($scope.$data.action === 'update') $scope.$data.state++;
			$scope.$data.isReady = true;
			initWatchers();
		});
	}

	app.controller('ModalAudioFormCtrl', Controller);
})(angular, NgProject);
