NgProject.directive('videosFilters', ["Deferred", "GenresService", "MakersService", "SETTINGS", function (Deferred, GenresService, MakersService, SETTINGS) {
	'use strict';

	function loadGenres(callback) {
		Deferred.handlePromise(GenresService.list(), function (response) {
			callback(response.success ? response.items : []);
		});
	}

	function loadMakers(callback) {
		Deferred.handlePromise(MakersService.videos(), function (response) {
			callback(response.success ? response.items : []);
		});
	}

	function link(scope, element, attrs, controller) {
		function init() {
			scope.$filters = angular.copy(controller.getSearchParams());
			scope.$filtersData = {
				orderTypes: SETTINGS.videos.filters.order,
				genres: [],
				makers: []
			};
			scope.$filtersControls = {
				reset: function () {
					scope.$filters = angular.copy(controller.getDefaultSearchParams());
					applyFilters();
				}
			};

			loadGenres(function(data) {
				scope.$filtersData.genres = data;
			});
			loadMakers(function (data) {
				scope.$filtersData.makers = data;
			});
		}

		function getRequestParams() {
			return {
				order: scope.$filters.orderType,
				genre: scope.$filters.genre,
				maker: scope.$filters.maker
			};
		}

		function applyFilters() {
			controller.search(getRequestParams());
		}

		scope.$watch('$filters.orderType', function (value, oldValue) {
			if (value !== null && value !== oldValue) applyFilters();
		});
		scope.$watch('$filters.genre', function (value, oldValue) {
			if (value !== null && value !== oldValue) applyFilters();
		});
		scope.$watch('$filters.maker', function (value, oldValue) {
			if (value !== null && value !== oldValue) applyFilters();
		});

		init();
	}

	return {
		require: '^^videos',
		templateUrl: 'widgets.videos.videos-filters',
		restrict: 'E',
		scope: true,
		link: link
	};
}]);

