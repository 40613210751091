NgProject.controller('PageManageTagsArticlesCtrl', ["$scope", "$state", "$stateParams", "ArticlesManageService", function ($scope, $state, $stateParams, ArticlesManageService) {
	'use strict';

	$scope.articles = {
		promise: function(params) {
			params = angular.extend(params || {}, $scope.$filter.model);
			return ArticlesManageService.findAll(params);
		}
	};

	$scope.$filter = {
		model: {
			tag_status: $stateParams.tag_status
		},
		data: {
			tag_status: [
				{key: 'awaiting_moderation', title: 'Ожидают модерации'},
				{key: 'moderated', title: 'Промодерированные'}
			]
		},

		reset: function() {
			angular.extend($scope.$filter.model, {tag_status: null});
		}
	};

	$scope.setTagVisibility = function($item, $tag, visibility) {
		ArticlesManageService.setTagVisibility($item.id, $tag.id, visibility).then(function(response) {
			if (response.success) {
				$item.tag_options = response.article.tag_options;
			}
		});
	};

	$scope.$watch('$filter.model', function(value, oldValue) {
		if (value !== oldValue) {
			$state.go($state.current.name, value);
		}
	}, true);
}]);
