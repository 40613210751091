(function (ng, app) {
	'use strict';
	app.controller('ModalVideoFormCtrl', ["$rootScope", "$scope", "$q", "$filter", "Deferred", "VideosService", "TermsService", "GenresService", "YoutubeApi", "CurrentUser", "UsersService", "GroupsService", "cfpLoadingBar", function ($rootScope, $scope, $q, $filter, Deferred, VideosService, TermsService, GenresService, YoutubeApi, CurrentUser, UsersService, GroupsService, cfpLoadingBar) {
		var errors = {
			data: {},
			add: function (field, message) {
				if (!errors.data[field]) errors.data[field] = [];
				errors.data[field].push(message);
			},
			list: function (field) {
				return errors.data[field];
			},
			remove: function (field) {
				delete errors.data[field];
			},
			has: function (field) {
				return errors.data[field] && errors.data[field].length;
			},
			isEmpty: function () {
				return _.isEmpty(errors.data);
			}
		};

		var options = $scope.$options = {
			allowChangeMaker: false,
			allowSetMakerAsGroup: false,
			allowViewMaker: false
		};

		$scope.$errors = {
			get: errors.list,
			has: errors.has,
			isEmpty: errors.isEmpty
		};

		$scope.$model = {
			url: null,
			service_type: 'youtube',
			service_video_id: null,
			title: null,
			description: null,
			genre: null,
			tags: [],
			participants: [{titleId: null}, {titleId: null}, {titleId: null}]
		};

		$scope.$data = {
			isReady: false,
			action: 'create',
			state: 0,
			genres: [],
			participantsLabels: [],
			participants: [],
			tags: []
		};

		$scope.$participants = {
			models: [],
			search: function (query) {
				query = $.trim(query);
				$scope.$data.participants = [];
				if (query.length) {
					Deferred.handlePromise(TermsService.participants(query), function (response) {
						if (response.success) {
							$scope.$data.participants = _.isEmpty(response.items) ? [{
								tag_type: 'text',
								data: query
							}] : response.items;
						}
					});
				}
			},
			exist: function ($index, item) {
				var params = item.host_id ? {host_id: item.host_id} : {name: item.name};
				return _.findIndex(this.models[$index], params) !== -1;
			}
		};

		$scope.$genres = {
			search: function (query) {
				query = $.trim(query);
				_.remove($scope.$data.genres, 'custom');
				if (query.length) {
					$scope.$data.genres.push({name: query, custom: true});
				}
			}
		};

		$scope.$tags = {
			models: [],
			search: function (query) {
				query = $.trim(query);
				$scope.$data.tags = [];
				if (query.length > 1) {
					Deferred.handlePromise(TermsService.tags(query, 10), function (response) {
						if (response.success) $scope.$data.tags = _.isEmpty(response.items) ? [{name: query}] : response.items;
					});
				}
			},
			select: function () {
				var item = angular.copy(this.models[0]);
				if (!this.exist(item)) $scope.$model.tags.push(item);
				this.models = [];
			},
			remove: function (tag) {
				_.remove($scope.$model.tags, tag);
			},
			exist: function (tag) {
				var params = tag.host_id ? {id: tag.id} : {name: tag.name};
				return _.findIndex($scope.$model.tags, params) !== -1;
			}
		};

		$scope.$controls = {
			upload: function () {
				var service_video_id = YoutubeApi.getVideoIdByUrl($scope.$model.url);
				$scope.errors = [];
				if (!service_video_id) {
					errors.add('url', 'Указанна неверная ссылка.');
					return;
				}
				cfpLoadingBar.start();
				YoutubeApi.getVideoInfo(service_video_id).then(function (response) {
					cfpLoadingBar.complete();
					if (!response) {
						errors.add('url', 'Указанна неверная ссылка.');
						return;
					}
					angular.extend($scope.$model, {
						service_video_id: response.id,
						title: response.snippet.title,
						description: response.snippet.description
					});
					$scope.$data.state++;
				});
			},

			save: function () {
				var data = requestData();
				var promise = VideosService[$scope.$model.id ? 'update' : 'create'];
				promise = $scope.$model.id ? promise($scope.$model.id, data) : promise(data);
				cfpLoadingBar.start();
				Deferred.handlePromise(promise, function (response) {
					cfpLoadingBar.complete();
					if (response.success) {
						$scope.$data.state++;
						$rootScope.$broadcast('videos-list-reload');
					}
					else setFormErrors(response.errors);
				});
			},

			remove: function () {
				$scope.$model.video[CurrentUser.is_admin ? 'deleteOverall' : 'deleteByOwner']().then($scope.$close);
			},

			close: function () {
				$scope.$close();
			}
		};

		function initUserAccess() {
			var maker = getDefaultMaker();
			options.allowChangeMaker = CurrentUser.is_admin;
			options.allowViewMaker = options.allowChangeMaker || maker.type === 'group';
			options.allowSetMakerAsGroup = options.allowViewMaker && maker.type === 'group';
		}

		function loadData(video_id) {
			var deferred = $q.defer();
			var requests = {participants: null, genres: null};
			Deferred.handleAllPromises([TermsService.participantsLabels(), GenresService.list()], function (participants, genres) {
				deferred.notify(['participants', participants.success, {data: participants.items}]);
				deferred.notify(['genres', genres.success, {data: genres.items}]);
			});

			if (video_id) {
				requests.video = null;
				Deferred.handlePromise(VideosService.get(video_id), function (response) {
					deferred.notify(['video', response.success, {data: response.video}]);
				});
			}
			else {
				var maker = getDefaultMaker();
				requests.maker = null;
				Deferred.handlePromise(maker.type === 'user' ? UsersService.about(maker.id) : GroupsService.main(maker.id), function (response) {
					deferred.notify(['maker', response.success, {type: maker.type, data: response[maker.type]}]);
				});
			}

			return deferred.promise.then(angular.noop, angular.noop, function (request) {
				requests[request[0]] = angular.extend({status: request[1]}, request[2]);
				if (_.every(requests, 'status')) {
					if (requests.video) {
						setModelData(requests.video.data);
						setModelMaker(requests.video.data.maker.type, requests.video.data.maker.data);
					}
					if (requests.maker) setModelMaker(requests.maker.type, requests.maker.data);
					setFormParticipantsLabels(requests.participants.data);
					setFormGenres(requests.genres.data);
					deferred.resolve();
				}
			});
		}

		function getDefaultMaker() {
			var maker = $scope.$modalWindow.data(1);
			var is_default = !maker;
			return {
				type: is_default ? 'user' : maker.type,
				id: is_default ? CurrentUser.id : maker.id
			};
		}

		function setFormParticipantsLabels(labels) {
			$scope.$data.participantsLabels = labels;
		}

		function setFormGenres(genres) {
			$scope.$data.genres = genres;
		}

		function setModelData(data) {
			angular.extend($scope.$model, {
				id: data.id,
				title: data.title,
				service_type: data.service_type,
				service_video_id: data.service_video_id,
				description: data.description,
				genre: _.get(data, 'genre.name', null),
				tags: _.uniq(data.tags, 'id'),
				video: data
			});

			if (!_.isEmpty(data.participants)) {
				_.forEach(data.participants, function (item, index) {
					$scope.$model.participants[index].titleId = item.term_title.id;
					$scope.$participants.models[index] = _.map(item.tags, $filter('convertHostTagToParticipantTag'));
				});
			}
		}

		function setModelMaker(type, data) {
			$scope.$model.maker = {id: data.id, type: type, data: data};
		}

		function requestData() {
			var data = {
				title: $scope.$model.title,
				description: $scope.$model.description,
				genre: $scope.$model.genre,
				tags: $scope.$model.tags.length ? getTagsValues($scope.$model.tags) : [false],
				participants: [false]
			};

			if ($scope.$model.participants.length) {
				var participants = [];
				angular.forEach($scope.$model.participants, function (role, index) {
					var performers = getPerformersValues($scope.$participants.models[index]);
					if (role.titleId && performers.length) participants.push({
						term_title_id: role.titleId,
						tags: performers
					});
				});
				if (participants.length) data.participants = participants;
			}

			if ($scope.$data.action === 'create') {
				data.service_type = $scope.$model.service_type;
				data.service_video_id = $scope.$model.service_video_id;
				if (options.allowSetMakerAsGroup && $scope.$model.maker.type === 'group') {
					// разрешает задать мэйкера только если пользователь создает материал (не обновляет) от имени группы
					data.maker = {type: 'group', id: $scope.$model.maker.data.id};
				}
			}

			if (!data.maker && options.allowChangeMaker) {
				data.maker = {type: $scope.$model.maker.type, id: $scope.$model.maker.data.id};
			}

			return data;
		}

		function getPerformersValues(items) {
			return _(items).map(function (item) {
				return _.get(item, 'tag_type', 'text') === 'text' ? item.data : item.tag_id;
			}).uniq().reverse().value();
		}

		function getTagsValues(items) {
			return _.map(items, function (item) {
				return _.isEmpty(item.host_type) ? item.name : item.id;
			});
		}

		function setFormErrors(data) {
			errors.data = data;
		}

		function initWatchers() {
			$scope.$watch('$model', function (data, oldData) {
				_.forIn(data, function (value, field) {
					if ($scope.$errors.has(field) && value !== oldData[field]) errors.remove(field);
				});
			}, true);

			if ($scope.$data.action === 'create') {
				$scope.$watch('$model.url', function () {
					errors.remove('url');
				});
			}
		}

		// Initialization
		initUserAccess();
		loadData($scope.$modalWindow.data(0)).then(function () {
			$scope.$data.action = $scope.$model.id ? 'update' : 'create';
			if ($scope.$data.action === 'update') $scope.$data.state++;
			$scope.$data.isReady = true;
			initWatchers();
		});
	}]);
})(angular, NgProject);
