(function (ng, app) {
	'use strict';
	Controller.$inject = ["$scope", "Deferred", "GroupsService", "GroupRequestsService", "cfpLoadingBar"];
	function Controller($scope, Deferred, GroupsService, GroupRequestsService, cfpLoadingBar) {
		$scope.controls = {
			isReady: false
		};

		$scope.$members = {
			isReady: false,
			promise: function (params) {
				return GroupsService.members.list($scope.group.id, params);
			},
			onLoadCallback: function () {
				if (!$scope.$members.isReady) {
					$scope.$members.isReady = true;
					processLoadedTriggers();
				}
			},
			listOptions: {
				template: 'pages.groups.modal.members-list-item',
				showBadge: true,
				getBadgeType: function ($user) {
					if ($user.group_role === 'admin') return 'admin';
					return null;
				}
			},
			controls: {
				remove: function ($user) {
					cfpLoadingBar.start();
					Deferred.handlePromise(GroupsService.members.remove($scope.group.id, $user.id), function () {
						$user.excluded = true;
						cfpLoadingBar.complete();
					});
				}
			}
		};

		$scope.$requests = {
			isReady: true,
			enabled: false,
			options: {
				requestParams: {limit: 1000}
			},
			listOptions: {
				loadMore: false,
				template: 'pages.groups.modal.members-requests-list-item'
			},
			promise: function (params) {
				return GroupRequestsService.list($scope.group.id, params);
			},
			onLoadCallback: function () {
				$scope.$requests.isReady = true;
				processLoadedTriggers();
			},
			controls: {
				accept: function ($user) {
					cfpLoadingBar.start();
					Deferred.handlePromise(GroupRequestsService.accept($scope.group.id, $user.request.id), function (response) {
						cfpLoadingBar.complete();
						if (response.success) $user.request.status = 'accepted';
					});
				},
				reject: function ($user) {
					cfpLoadingBar.start();
					Deferred.handlePromise(GroupRequestsService.decline($scope.group.id, $user.request.id), function (response) {
						cfpLoadingBar.complete();
						if (response.success) $user.request.status = 'rejected';
					});
				}
			}
		};

		function processLoadedTriggers() {
			$scope.controls.isReady = _.every([$scope.$members.isReady, $scope.$requests.isReady]);
		}

		function init() {
			if ($scope.group.private && $scope.group.access.manage) {
				$scope.$requests.enabled = true;
				$scope.$requests.isReady = false;
			}
		}

		$scope.$onParentStateLoaded('group.id', init);
	}

	app.controller('GroupMembersListModalCtrl', Controller);
})(angular, NgProject);
