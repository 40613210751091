NgProject.service('CommonData', function () {
	'use strict';

	var CommonData = this;
	var observerCallbacks = [];

	CommonData.data = {};
	CommonData.setData = setData;
	CommonData.reset = reset;

	CommonData.registerCallback = registerCallback;
	CommonData.notifyObservers = notifyObservers;

	return CommonData;

	function setData(data) {
		CommonData.data = !!data ? data : {};
	}

	function reset() {
		observerCallbacks = [];
		CommonData.data = {};
	}

	function registerCallback(callback) {
		observerCallbacks.push(callback);
	}

	function notifyObservers() {
		angular.forEach(observerCallbacks, function (callback) {
			callback();
		});
	}
});
