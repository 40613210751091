(function (ng, app) {
	'use strict';
	Controller.$inject = ["$rootScope", "$scope", "$cookies", "$window", "AuthService", "CurrentUser", "modalWindow", "ImFaye", "SETTINGS"];
	function Controller($rootScope, $scope, $cookies, $window, AuthService, CurrentUser, modalWindow, ImFaye, SETTINGS) {
		$rootScope.isAuthenticated = AuthService.isAuthenticated;
		$rootScope.currentUser = CurrentUser;
		$rootScope.$modal = modalWindow;
		$rootScope.imFaye = ImFaye;
		$scope.showAudioPlayer = false;
		$scope.logout = function () {
			AuthService.logout();
		};


		$scope.hideNotificationSwitcher = false;
		$scope.switchToMobileVersion = function () {
			var expiresDate = new Date();
			expiresDate.setMonth(expiresDate.getMonth() + 1);
			$cookies.put('isMobile', 'true', {
				'path': '/',
				'domain': SETTINGS['cookieDomain'],
				'expires': expiresDate
			});
			$window.location.reload();
		};

		$scope.$on('AudioPlayer.load', function () {
			$scope.showAudioPlayer = true;
		});
		$scope.$on('AudioPlayer.destroy', function () {
			$scope.showAudioPlayer = false;
		});
	}

	app.controller('AppCtrl', Controller);
})(angular, NgProject);
