(function (ng, app) {
	'use strict';
	app.filter('contentImagesUrls', ["SETTINGS", function (SETTINGS) {
		return function (content) {
			if (DEBUG) {
				var pattern = /<img[^>]+src="(\/[^"]+)/gi;
				content = content.replace(pattern, function (string, src) {
					return string.replace(src, SETTINGS.assetsBaseUrl + src);
				});
			}
			return content;
		};
	}]);
})(angular, NgProject);
