NgProject.directive('groupTeaser', ["$parse", "$templateCache", function ($parse, $templateCache) {
	'use strict';
	controller.$inject = ["$scope", "$attrs"];
	var templateId = 'widgets.groups.group-teaser';
	var options = {};

	function compile(tElement, tAttrs) {
		var type = tAttrs.hasOwnProperty('type') ? tAttrs.type : 'default';
		var template = getTemplate(type, tElement);
		tElement.html(template);
	}

	function controller($scope, $attrs) {
		$scope.$teaser = {options: angular.copy(options)};
		if ($attrs.hasOwnProperty('object')) {
			$scope.$group = $parse($attrs.object)($scope);
		}

		if ($attrs.hasOwnProperty('options')) {
			angular.merge($scope.$teaser.options, $parse($attrs.options)($scope));
		}
	}

	function getTemplate(type, tElement) {
		var template = $templateCache.get(getTemplateIdByType(type));
		if (!template) {
			template = transcludeBlocks(tElement);
			$templateCache.put(getTemplateIdByType(type), template.prop('outerHTML'));
		}

		return template;
	}

	function getTemplateIdByType(type) {
		return templateId.concat('-', type);
	}

	function getBaseTemplate() {
		return angular.element($templateCache.get(templateId));
	}

	function transcludeBlocks(tElement) {
		var template = getBaseTemplate();
		var content = tElement.html();
		var blocks;
		if (content.length) {
			blocks = angular.element(content);
			template = angular.element(template);
			_.forEach(blocks, function (block) {
				switch (block.localName.toLowerCase()) {
					case 'transclude-append':
						template.append(block.innerHTML);
						break;
				}
			});
		}

		return template;
	}

	return {
		restrict: 'E',
		compile: compile,
		controller: controller
	};
}]);
