NgProject.directive('materialAuthor', function () {
	'use strict';
	controller.$inject = ["$scope", "$attrs", "Deferred", "TermsService"];
	var defaults = {
		labels: {
			group: 'Группы',
			user: 'Пользователи',
			text: 'Текстовое значение'
		}
	};

	function controller($scope, $attrs, Deferred, TermsService) {
		$scope.$data = {
			model: null,
			current: {
				type: $scope.model.type.toLowerCase(),
				data: angular.copy($scope.model.data)
			}
		};

		$scope.$data.model = angular.copy($scope.$data.current);

		$scope.$controls = {
			editable: $scope.editable || false,
			allowText: $attrs.hasOwnProperty('allowText'),
			label: $attrs.hasOwnProperty('label') ? $attrs['label'] : 'Автор',
			placeholder: $attrs.hasOwnProperty('placeholder') ? $attrs['placeholder'] : '',
			mode: 'view',

			setMode: function(view) {
				$scope.$data.model = null;
				$scope.$controls.mode = view ? 'view' : 'change';
			},

			cancel: function() {
				$scope.$controls.setMode(true);
				$scope.$data.model = null;
			}
		};

		$scope.$owners = {
			models: [],
			search: function (query) {
				query = $.trim(query);
				$scope.$owners.models = [];

				if ($scope.$controls.allowText && query.length) {
					$scope.$owners.models.push({
						type: 'text',
						data: {value: query}
					});
				}

				Deferred.handlePromise(TermsService.authors({q: query, limit: 30}), function (response) {
					if (response.success) {
						_.forEach(response.items, function(item) {
							$scope.$owners.models.push({
								type: item.host_type.toLowerCase(),
								data: item.data
							});
						});
					}
				});
			},

			groupBy: function(item) {
				return defaults.labels[item.type];
			}
		};

		$scope.$watch('$data.model', function(value) {
			if (!_.isEmpty(value)) {
				$scope.$data.current = angular.copy($scope.$data.model);
				$scope.$data.model = null;
				$scope.$controls.setMode(true);
			}
		});
	}

	function link(scope, element, attrs, ngModel) {
		scope.$watch('$data.current', function(value) {
			ngModel.$setViewValue(value || null);
		});
	}

	return {
		require: 'ngModel',
		scope: {
			model: '=ngModel',
			editable: '=editable'
		},
		restrict: 'E',
		templateUrl: 'widgets.material-author.material-author',
		link: link,
		controller: controller
	};
});
