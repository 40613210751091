(function (ng, app) {
	'use strict';
	app.service('ArticlesQuestionsService', ['$resource', 'serviceHelper', 'SETTINGS', function ($resource, serviceHelper, SETTINGS) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/articles/:articleId/:section/:questionId/:command', {
			articleId: '@articleId',
			section: '@section',
			questionId: '@questionId',
			command: '@command'
		}, {
			list: {
				method: 'GET',
				params: {
					section: 'questions'
				}
			},
			create: {
				method: 'POST',
				params: {
					section: 'questions'
				}
			},
			update: {
				method: 'PUT',
				params: {
					section: 'questions'
				}
			},
			remove: {
				method: 'DELETE',
				params: {
					section: 'questions'
				}
			},
			publish: {
				method: 'PUT',
				params: {
					section: 'questions',
					command: 'publish'
				}
			},
			userUnpublishedQuestions: {
				method: 'GET',
				params: {
					section: 'my_unpublished_questions'
				}
			}
		});

		return {
			list: function (articleId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'list',
					parameters: {
						articleId: articleId,
						per_page: 100
					}
				});
				return (promise);
			},

			create: function (articleId, data) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'create',
					parameters: {
						articleId: articleId,
						question: data
					}
				});
				return (promise);
			},

			update: function (articleId, questionId, data) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'update',
					parameters: {
						articleId: articleId,
						questionId: questionId,
						question: data
					}
				});
				return (promise);
			},

			remove: function (articleId, questionId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'remove',
					parameters: {
						articleId: articleId,
						questionId: questionId
					}
				});
				return (promise);
			},

			publish: function (articleId, questionId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'publish',
					parameters: {
						articleId: articleId,
						questionId: questionId
					}
				});
				return (promise);
			},

			userUnpublishedQuestions: function (articleId) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'userUnpublishedQuestions',
					parameters: {
						articleId: articleId
					}
				});
				return (promise);
			}
		};
	}]);
})(angular, NgProject);
