'use strict';
(function ($) {
	var plugin = 'redactor_images';

	$.Redactor.prototype.redactorImagesModule = function () {
		this.image.setEditable = $.noop;
		return false;
	};

	$.Redactor.prototype[plugin] = function () {
		return {
			langs: {
				ru: {
					'redactor-images': 'Изображения',
					'image-field-title': 'Название',
					'image-field-position': 'Выравнивание',
					'image-field-position-none': 'Нет',
					'image-field-position-left': 'По левую сторону',
					'image-field-position-center': 'По центру',
					'image-field-position-right': 'По правую сторону'
				}
			},

			init: function () {
				this[plugin].createButton();
				this[plugin].createModal();
				this[plugin].attachControlsEvents();
			},

			createButton: function () {
				var button = this.button.add('images', this.lang.get('redactor-images'));
				this.button.addCallback(button, this[plugin].buttonCallback);
			},

			buttonCallback: function () {
				this.selection.save();
				this.openModal('redactor-images', this);
			},

			insert: function (src) {
				var data = {
					id: Date.now(),
					url: this.link.removeSelfHostFromUrl(src)
				};
				this.selection.restore();
				this.image.insert(data, null);
				var $image = this.core.editor().find('img[data-image="' + data.id + '"]');
				$image.closest('figure').attr({
					'data-type-image': data.id,
					'data-position': 'none'
				});
			},

			update: function () {
				var $image = this.observe.image;
				var $box = $image.closest('figure[data-type-image]');
				var position = $('#redactor-image-position').val();
				if (_.includes(['left', 'right', 'center'], position) === false) position = 'none';
				$box.attr('data-position', position);
				this.image.update.call(this);
			},

			createModal: function () {
				this.opts.modal['image-edit'] = String()
					+ '<div class="redactor-modal-tab redactor-group" data-title="General" id="redactor-modal-image-update">'
					+ '<div id="redactor-image-preview" class="redactor-modal-tab-side"></div>'
					+ '<div class="redactor-modal-tab-area">'

					+ '<section>'
					+ '<label>' + this.lang.get('image-field-title') + '</label>'
					+ '<input type="text" id="redactor-image-title" />'
					+ '</section>'

					+ '<section>'
					+ '<label>' + this.lang.get('caption') + '</label>'
					+ '<input type="text" id="redactor-image-caption" aria-label="' + this.lang.get('caption') + '" />'
					+ '</section>'

					+ '<section>'
					+ '<label>' + this.lang.get('image-field-position') + '</label>'
					+ '<select id="redactor-image-position">'
					+ '<option value="none">' + this.lang.get('image-field-position-none') + '</option>'
					+ '<option value="left">' + this.lang.get('image-field-position-left') + '</option>'
					+ '<option value="center">' + this.lang.get('image-field-position-center') + '</option>'
					+ '<option value="right">' + this.lang.get('image-field-position-right') + '</option>'
					+ '</select>'
					+ '</section>'

					+ '<section>'
					+ '<button id="redactor-modal-button-action">' + this.lang.get('insert') + '</button>'
					+ '<button id="redactor-modal-button-cancel">' + this.lang.get('cancel') + '</button>'
					+ '<button id="redactor-modal-button-delete" class="redactor-modal-button-offset">' + this.lang.get('delete') + '</button>'
					+ '</section>'

					+ '</div>'
					+ '</div>';
			},

			attachControlsEvents: function () {
				var _redactor = this;
				var _plugin = this[plugin];
				this.core.editor()
					.on('click touchstart', 'img', function (e) {
						e.preventDefault();
						e.stopPropagation();
						setTimeout(function () {
							_plugin.showEditControls.call(_redactor, $(e.target));
						}, 200);
					});
			},

			showEditControls: function ($image) {
				var $box = $image.closest('figure[data-type-image]');
				if ($box[0].id === 'redactor-image-box') return;

				var _redactor = this;
				var _plugin = this[plugin];

				_plugin.hideEditControls.call(_redactor);

				var $anchor = $('<span id="redactor-image-resizer" contenteditable="false"></span>');
				var $button = $('<span id="redactor-image-button-update" class="icon-m-mode_edit" contenteditable="false"></span>');

				$anchor.attr('anchor-type', 'right');

				if ($box.attr('data-position') === 'right') {
					$anchor.attr('anchor-type', 'left');
				}

				$box.attr({'id': 'redactor-image-box'})
					.append($anchor)
					.append($button);

				$button.on('click', function () {
					_redactor.image.showEdit($image);
					_redactor.image.buttonSave.off('click').on('click', $.proxy(_plugin.update, _redactor));
					$('#redactor-image-position').val($box.attr('data-position') || 'none');
				});

				$anchor.on('mousedown', function (e) {
					e.preventDefault();
					e.stopPropagation();
					_plugin.startResizing.call(_redactor, $(e.target));
				});

				$(document).on('mousedown.redactor-image-box touchstart.redactor-image-box keydown.redactor-image-box', function (e) {
					var $target = $(e.target);
					if ($target.closest('figure[data-type-image]').length === 0)
						_plugin.hideEditControls.call(_redactor);
				});
			},

			hideEditControls: function () {
				$('figure[data-type-image]').removeAttr('id').find('span').remove();
				$(document).off('.redactor-image-box');
			},

			startResizing: function ($anchor) {
				var _redactor = this;
				var _plugin = this[plugin];

				this.events.stopDetectChanges();
				this.core.editor()
					.on('mousemove.redactor-resize-image', function (e) {
						e.preventDefault();
						e.stopPropagation();
						_plugin.applyResize.call(_redactor, $anchor, {x: e.clientX, y: e.clientY});
					})
					.on('mouseleave.redactor-resize-image mouseup.redactor-resize-image', function (e) {
						e.preventDefault();
						e.stopPropagation();
						_plugin.stopResizing.call(_redactor);
						_redactor.code.sync();
					});
			},

			stopResizing: function () {
				this.core.editor().off('.redactor-resize-image');
				this.events.startDetectChanges();
			},

			applyResize: function ($anchor, position) {
				var width = 0;
				var $image = $anchor.siblings('img');

				switch ($anchor.attr('anchor-type')) {
					case 'right':
						width = $image.width() - $anchor.offset().left + position.x;
						break;
					case 'left':
						width = $image.width() + $anchor.offset().left - position.x;
						break;
				}

				if (width > this.core.editor().width()) width = this.core.editor().width();
				else if (width < 150) width = 150;

				$image.css('width', width + 'px').attr('data-width', width);
			}
		};
	};
})(jQuery);
