NgProject.directive('selectOwner', ["$templateCache", "$parse", function ($templateCache, $parse) {
	'use strict';
	controller.$inject = ["$scope", "$attrs"];
	var defaults = {
		templateUrl: 'widgets.select-owner.select-owner'
	};

	function link(scope, element, attrs, ngModel) {
		scope.$watch('options.model', function(value) {
			ngModel.$setViewValue(_.get(value, '[0]', null));
		});
	}

	function controller($scope, $attrs) {
		var model = $attrs.hasOwnProperty('ngModel') ? $parse($attrs['ngModel'])($scope) : null;
		$scope.options = {
			model: _.isEmpty(model) ? [] : model,
			focusEventName: $attrs.hasOwnProperty('focusEvent') ? $parse($attrs['focusEvent'])($scope) : null,
			searchIsAllowed: function() {
				return _.isEmpty($scope.options.model);
			}
		};
	}

	function compile(tElement, tAttrs) {
		var template = $templateCache.get(defaults.templateUrl);
		template = angular.element(template);
		var uiSelect = template.find('ui-select');

		if (tAttrs.hasOwnProperty('autofocus'))
			uiSelect.attr('autofocus', 'true');

		if (tAttrs.hasOwnProperty('focusEvent')) {
			uiSelect.attr('ui-select-open-on-focus', '$select');
			uiSelect.attr('ui-select-open-on-focus-event', 'options.focusEventName');
		}

		uiSelect.html(tElement.html());
		tElement.html(template);
		return link;
	}

	return {
		require: 'ngModel',
		restrict: 'E',
		compile: compile,
		controller: controller
	};
}]);
