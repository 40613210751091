(function (ng, app) {
	'use strict';
	app.service('serviceHelper', ["Deferred", function (Deferred) {
		function unwrapErrorMessage(errorResponse) {
			var response;
			try {
				response = ng.fromJson(errorResponse.data);
				if (ng.isString(response)) {
					response = {code: -1, message: response};
				}
			} catch (error) {
				response = {code: -1, message: errorResponse.data};
			}

			return response;
		}

		return {
			executeRequest: function (options) {
				var resource = options.resource;
				var name = options.name;
				var parameters = ( options.parameters || {} );
				var successCallback = ( options.successCallback || ng.noop );
				var errorCallback = ( options.errorCallback || ng.noop );
				var deferred = new Deferred();

				resource[name](
					parameters,
					function (response) {
						successCallback(response);
						deferred.resolve(response);
					},
					function (response) {
						errorCallback(response);
						deferred.reject(unwrapErrorMessage(response));
					}
				);

				return deferred.promise;
			}
		};
	}]);

})(angular, NgProject);
