NgProject.controller('PageEventConfirmCtrl', ["$scope", "$state", "EventsService", function Controller($scope, $state, EventsService) {
	'use strict';

	$scope.disabled = false;
	$scope.isConfirmed = null;
	$scope.message = '';

	$scope.error_messages = {
		'token_not_found': [
			'Ваше участие уже подтверждено.',
			'Если Вы этого не далали и не можете найти себя в списке участников, то свяжитель с организаторами мероприятия для решения данного вопроса.'
		].join('\n'),
		'token_is_blank': [
			'К сожалению, мы не можем подтвердить ваше участие. Попробуйте зарегистрироваться снова.'
		].join('\n')
	};

	$scope.closeConfirm = function () {
		$state.go('root.event', $state.params, {
			reload: false,
			location: 'replace'
		});
		$scope.disabled = true;
	};

	var token = $state.params.token;

	sendToken(token).then(function () {
		if ($scope.isConfirmed) replaceLocation();
	});

	function sendToken(token) {
		return EventsService.confirm(token).then(function (response) {
			$scope.isConfirmed = response.success;
			if (response.success === false) {
				$scope.message = $scope.error_messages[response['error_code']];
			}
		});
	}

	function replaceLocation() {
		$state.go('^', $state.params, {
			reload: false,
			location: 'replace',
			notify: false
		});
	}
}]);
