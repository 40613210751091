NgProject.controller('WikiArticlesListCtrl', ["$scope", "CurrentUser", "Deferred", "ArticlesService", "CommonData", function ($scope, CurrentUser, Deferred, ArticlesService, CommonData) {
	'use strict';
	var defaultParams = {
		pinned: 1
	};

	var saveStateArticles = [];

	$scope.fData = {
		isEdited: false,
		isAdmin: CurrentUser.is_admin,
		typeArticle: (this.isEdited) ? 'edited' : 'default'
	};

	$scope.$fixedArticles = {
		items: []
	};

	$scope.controls = {
		includeEdit: function () {
			$scope.fData.isEdited = true;
			saveStateArticles = _.clone($scope.$fixedArticles.items);
		},
		saveEdit: function () {
			var ids = _.pluck($scope.$fixedArticles.items, 'id');
			var params = {pin: ids.length ? ids : [false]};
			Deferred.handlePromise(ArticlesService.pinByTerm($scope.stateTermId, params)).then(function (response) {
				if (response.success) {
					$scope.fData.isEdited = false;
					$scope.$broadcast('articles-list-reload');
				}
			});
		},
		cancelEdit: function () {
			$scope.$fixedArticles.items = _.clone(saveStateArticles);
			$scope.fData.isEdited = false;
			saveStateArticles = [];
		},
		hiddenArticle: function ($article) {
			return !!_.find($scope.$fixedArticles.items, $article);
		},
		selectArticle: function ($article) {
			if (_.find($scope.$fixedArticles.items, $article)) return;
			$scope.$fixedArticles.items.push($article);
		},
		excludeArticle: function ($article) {
			if (CommonData.data.items.length === 0 || !_.find(CommonData.data.items, $article)) {
				CommonData.data.items.unshift($article);
			}
			_.remove($scope.$fixedArticles.items, $article);
		}
	};

	function loadPinnedArticles(params) {
		Deferred.handlePromise(ArticlesService.findAllByTerm($scope.stateTermId, _.extend(defaultParams, params)), function (resolved) {
			if (resolved.success) {
				$scope.$fixedArticles.items = resolved.items;
			}
		});
	}

	loadPinnedArticles();
}]);
