NgProject.controller('GroupSettingsCtrl', ["$scope", "$state", "Deferred", "GroupsService", "CurrentUser", function ($scope, $state, Deferred, GroupsService, CurrentUser) {
	'use strict';
	$scope.$group = {};
	$scope.$controls = {
		isReady: true,
		mode: 'create'
	};

	if ($state.params.hasOwnProperty('groupId')) {
		$scope.$controls.isReady = false;
		$scope.$controls.mode = 'update';
		Deferred.handlePromise(GroupsService.main(parseInt($state.params.groupId)), function (response) {
			if (response.success) {
				$scope.$group = response.group;
				$scope.$controls.isReady = true;
				$scope.$group.permisions = {
					remove: response.group.user_is_maker || CurrentUser.is_admin
				};
			}
		});
	}
}]);
