NgProject.controller('ModalSharingOptionsCtrl', ["$scope", "$filter", "Deferred", "GroupsService", function ($scope, $filter, Deferred, GroupsService) {
	'use strict';
	var attachment = $scope.$modalWindow.data(0);

	$scope.$post = {
		message: null,
		owner: {type: 'user', group_id: null}
	};

	$scope.$groups = {
		enabled: false,
		items: [],
		focusEventName: 'need-select-group'
	};

	$scope.$controls = {
		submit: function() {
			if (!isValid()) return;
			if (_.isFunction(attachment.share)) {
				var owner;
				if ($scope.$post.owner.type === 'group') {
					owner = {type: 'group', id: $scope.$post.owner.group_id};
				}
				attachment.share($scope.$post.message, owner);
				$scope.$close();
			}
		}
	};

	function isValid() {
		if ($scope.$post.owner.type === 'group' && !$scope.$post.owner.group_id) {
			$scope.$broadcast($scope.$groups.focusEventName);
			return false;
		}
		return true;
	}

	function loadData() {
		var params = {
			limit: 500,
			user_status: 'administrator'
		};
		Deferred.handlePromise(GroupsService.findAllByCurrentUser(params), function (response) {
			if (response.success) {
				$scope.$groups.items = $filter('orderBy')(response.items, '-members_count');
				$scope.$groups.enabled = Boolean($scope.$groups.items.length);
			}
		});
	}

	loadData();

	$scope.$watch('$post.owner.group_id', function () {
		if ($scope.$post.owner.group_id) $scope.$post.owner.type = 'group';
	});

	$scope.$watch('$post.owner.type', function (type) {
		if (type === 'group') {
			$scope.$broadcast($scope.$groups.focusEventName);
		}
	});
}]);
