NgProject.controller('ModalLoginCtrl', ["$scope", "$state", "$location", "AuthService", "AUTH_EVENTS", function Controller($scope, $state, $location, AuthService, AUTH_EVENTS) {
	'use strict';
	$scope.credentials = {
		email: null,
		password: null,
		remember_me: true
	};

	$scope.errors = {};

	$scope.controls = {
		isNewPasswordLayout: $state.params.newPasswordLayout,
		submit: function () {
			$scope.errors = {};
			AuthService.login($scope.credentials).then(function(response) {
				if (!response.success) {
					$scope.credentials.password = null;
					if (_.isObject(response.errors)) {
						$scope.errors = response.errors;
					}
					else if (_.isString(response.errors)) {
						$scope.errors.email = response.errors;
					}
				}
			});
		}
	};

	function authenticationSuccessHandler() {
		if ($state.includes('system.**')) {
			$scope.goToHome();
		}
		else if (_.isEmpty($state.params.returnUrl) === false) {
			$location.url($state.params.returnUrl);
		}
		else {
			$scope.$close();
		}
	}

	$scope.$on(AUTH_EVENTS.authenticationSuccess, authenticationSuccessHandler);
	$scope.$on(AUTH_EVENTS.loginSuccess, authenticationSuccessHandler);
	$scope.$on(AUTH_EVENTS.socialAuthFailed, function () {
		////
	});
}]);

