NgProject.directive('articlesList', ["$parse", "$templateCache", function ($parse, $templateCache) {
	'use strict';
	var defaults = {
		loadMore: true,
		teaserOptions: {},
		itemClass: ''
	};

	function compile(tElement) {
		var template = $templateCache.get('widgets.articles.articles-list');
		var content = tElement.html();
		if (content.length) {
			template = angular.element(template);
			template.find('.articles-list-item').html(content);
		}
		tElement.html(template);
		return link;
	}

	function link(scope, element, attrs, controller) {
		var options = angular.extend({}, angular.copy(defaults), attrs.options ? $parse(attrs.options)(scope) : {});
		scope.$articlesList = {options: options};
		if (options.loadMore) {
			scope.$articlesList.load = function () {
				var page = controller.getSearchParams().page || 1;
				controller.loadByPage(page + 1);
			};
			scope.$articlesList.disabled = function () {
				var data = scope.$articles;
				return data.isLoading || data.endOfList;
			};
		}
	}

	return {
		require: '^^articles',
		restrict: 'E',
		compile: compile
	};
}]);
