(function (ng, app) {
	'use strict';
	Controller.$inject = ["$rootScope", "$scope"];
	var options = {
		maxIndex: 36
	};
	function Controller($rootScope, $scope) {
		$scope.images = [];
		$scope.headerBgIndex = parseInt($scope.$modalWindow.data(0));

		while($scope.images.length < options.maxIndex) {
			var index = $scope.images.length + 1;
			$scope.images.push({
				id: index,
				src: {
					thumb: 'images/headers/header-bg-' + index + '-thumb.jpg',
					original: 'images/headers/header-bg-' + index + '.jpg'
				}
			});
		}

		function updateHeader() {
			$rootScope.$broadcast('headerBg:selected', $scope.headerBgIndex);
		}

		$scope.setBg = function (image) {
			$scope.headerBgIndex = image.id;
			updateHeader();
		};

		$scope.removeBg = function () {
			$scope.headerBgIndex = null;
			updateHeader();
		};

		$scope.saveBg = function () {
			updateHeader();
			$scope.$close();
		};
	}

	app.controller('modal:select-header-bg.ctrl', Controller);
})(angular, NgProject);
