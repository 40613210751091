NgProject.controller('ArticlesListBookmarksCtrl', ["$q", "$scope", "$state", "$timeout", "ArticlesService", "CommonData", "InterfaceCounters", function ($q, $scope, $state, $timeout, ArticlesService, CommonData, InterfaceCounters) {
	'use strict';

	var requests = {bookmarks: false};

	function processLoadedTriggers() {
		if (requests.bookmarks) {
			$scope.controls.isReady = true;
		}
	}

	$scope.articlesCount = InterfaceCounters.articles;
	$scope.controls = {
		isReady: false
	};

	$scope.bookmarks = {
		promise: ArticlesService.bookmarks.all,
		action: {show: true, sref: 'root.create-article'},
		listOptions: {
			teaserOptions: {buttons: {deleteByOwner: false, deleteReading: false, deleteBookmark: true}}
		},
		onLoadCallback: function () {
			if (!requests.bookmarks) {
				requests.bookmarks = true;
				processLoadedTriggers();
			}
		}
	};

	if (!InterfaceCounters.articles.bookmarks) $timeout(function () {
		$state.go('^');
	}, 10);

	$scope.$watch('articlesCount', function (a, b) {
		if (_.eq(a, b)) return;
		if (a['bookmarks'] === 0) $state.go('^');
	}, true);

	$scope.$on('listBookmarks:remove', function (e, articleId) {
		_.remove(CommonData.data.items, {id: articleId});
		CommonData.data.count--;
	});

	$scope.$on('listBookmarks:added', function () {
		$scope.$broadcast('articles-list-reload');
	});
}]);
