'use strict';
NgProject.factory('Video', ["$rootScope", "$resource", "$q", "$filter", "SETTINGS", "CurrentUser", "SharingOptions", function ($rootScope, $resource, $q, $filter, SETTINGS, CurrentUser, SharingOptions) {
	var VideoModel = function (data) {
		if (data) this.setData(data);
	};

	var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/videos/:id/:command', {id: '@id', command: '@command'}, {
		share: {method: 'POST', params: {command: 'share'}},
		add: {method: 'POST', params: {command: 'add'}}
	});

	var resourceProfile = $resource(SETTINGS.apiBaseUrl + 'api/v1/profile/videos/own/:id', {id: '@id'});
	var resourceGroups = $resource(SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/videos/:id', {
		id: '@id',
		groupId: '@groupId'
	});
	var resourceBookmarks = $resource(SETTINGS['apiBaseUrl'] + 'api/v1/profile/videos/bookmarks/:id', {id: '@id'});

	VideoModel.prototype.setData = function (data) {
		angular.extend(this, data);
		this.access = {
			'delete': this.checkAccess('delete'),
			'update': this.checkAccess('update'),
			'deleteOverall': CurrentUser.is_admin
		};
	};

	VideoModel.prototype.setOwner = function (ownerType, ownerId) {
		this.owner = {
			id: ownerId,
			type: ownerType
		};
	};

	VideoModel.prototype.checkAccess = function (task) {
		switch (task) {
			case 'delete':
				return this.allow_update || this.is_owner;
			case 'update':
				return this.allow_update;
		}
		return false;
	};

	function deleteRequest(params) {
		var deferred = $q.defer();
		var model = this;
		var owner = null;
		if (params.overall) owner = resource;
		else if (params.groupId) owner = resourceGroups;
		else owner = resourceProfile;
		params = angular.extend({id: model.id}, params || {});
		owner.delete(params, deferred.resolve, deferred.reject);
		return deferred.promise;
	}

	VideoModel.prototype.delete = function () {
		var model = this;
		return deleteRequest.call(model).then(function (response) {
			model.is_owner = _.get(response, 'video.is_owner', model.is_owner);
		});
	};

	VideoModel.prototype.deleteByOwner = function () {
		var model = this;
		var params = _.get(model.owner, 'type', false) ? {groupId: model.owner.id} : {};
		return deleteRequest.call(model, params).then(function (response) {
			model.is_owner = _.get(response, 'video.is_owner', model.is_owner);
			$rootScope.$broadcast('videos-list-reload');
		});
	};

	VideoModel.prototype.deleteOverall = function () {
		return deleteRequest.call(this, {overall: true}).then(function () {
			$rootScope.$broadcast('videos-list-reload');
		});
	};

	VideoModel.prototype.bookmark = function () {
		var model = this, added = false;
		var defer = $q.defer();
		if (_.get(model.flags, 'bookmark', false)) {
			resourceBookmarks.delete({id: model.id}, defer.resolve, defer.reject);
			$rootScope.$broadcast('listBookmarks:remove', model.id);
		} else {
			resourceBookmarks.update({video_id: model.id}, defer.resolve, defer.reject);
			added = true;
		}
		model.flags['bookmark'] = !model.flags['bookmark'];
		return defer.promise.then(function (response) {
			$rootScope.$broadcast('InterfaceCounters:update');
			if (response.success) {
				model.bookmarks_count = _.get(response, 'video.bookmarks_count', parseInt(model.bookmarks_count) - 1);
				if (added) $rootScope.$broadcast('listBookmarks:added', response.video);
			}
		});
	};

	VideoModel.prototype.share = function (message, owner) {
		var deferred = $q.defer();
		var model = this;
		var data = angular.extend({id: model.id}, SharingOptions.getRequestData(message, owner));
		model.shared = true;
		resource.share(data, deferred.resolve, deferred.reject);
		return deferred.promise.then(function (response) {
			model.shared = _.get(response, 'success', false);
		});
	};

	return {
		create: function (data) {
			return new VideoModel(data);
		}
	};
}]);
