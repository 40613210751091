(function (ng, app) {
	'use strict';
	app.service('GenresService', ["$resource", "serviceHelper", "SETTINGS", function ($resource, serviceHelper, SETTINGS) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/genres');

		return {
			list: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: params
				});
				return (promise);
			}
		};
	}]);
})(angular, NgProject);
