'use strict';
NgProject.controller('ModalVideoViewCtrl', ["$scope", "Video", "CurrentUser", function ($scope, Video, CurrentUser) {
	$scope.$video = $scope.$modalWindow.data(0);

	if (_.isPlainObject($scope.$video)) {
		$scope.$video = Video.create($scope.$video);
	}

	$scope.$buttons = {
		'update': $scope.$video.access.update,
		'share':  Boolean(CurrentUser.id),
		'add': Boolean(CurrentUser.id)
	};
}]);
