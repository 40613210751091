NgProject.controller('GroupEventsListCtrl', ["$scope", "EventsService", function ($scope, EventsService) {
	'use strict';
	$scope.$groupEvents = {
		promise: function (params) {
			return EventsService.findAllByGroup($scope.group.id, params);
		},
		options: {
			requestParams: {
				younger_than: new Date().getTime()
			}
		},
		action: {
			show: $scope.group.access.manage && !$scope.group.is_removed,
			sref: 'root.create-event({maker_type: "group", maker_id:' + $scope.group.id  + '})'
		}
	};
}]);
