NgProject.service('ImService', ["$resource", "serviceHelper", "SETTINGS", "ImSearchParams", function ($resource, serviceHelper, SETTINGS, ImSearchParams) {
	'use strict';
	var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/chats/:section/:id/:command', {
		section: '@section',
		command: '@command',
		action: '@action',
		id: '@id'
	}, {
		// GET /api/v1/chats
		load: {
			method: 'GET'
		},
		// PUT /api/v1/chats/:chat_id/mark_as_read
		read: {
			method: 'PUT',
			params: {command: 'mark_as_read'}
		},
		// GET /api/v1/chats/users/:user_id
		users: {
			params: {section: 'users'}
		},
		// GET /api/v1/chats/groups/:group_id
		groups: {
			params: {section: 'groups'}
		},
		// PUT /api/v1/chats/groups/:group_id/leave
		leave: {
			method: 'PUT',
			params: {section: 'groups', command: 'leave'}
		},
		// PUT /api/v1/chats/groups/:group_id/join
		join: {
			method: 'PUT',
			params: {section: 'groups', command: 'join'}
		},
		// GET /api/v1/chats/:chat_id/messages
		messages: {
			params: {command: 'messages'}
		},
		// POST /api/v1/chats/:chat_id/message
		message: {
			method: 'POST',
			params: {command: 'message'}
		},
		// POST /api/v1/chats/:chat_id/message
		count: {
			method: 'GET',
			params: {command: 'count'}
		}
	});

	return {
		load: _load,
		chats: _chats,
		read: _read,
		users: _users,
		groups: _groups,
		leave: _leave,
		join: _join,
		messages: _messages,
		message: _message,
		count: _count
	};

	function _load() {
		var promise = serviceHelper.executeRequest({
			resource: resource,
			name: 'get',
			parameters: {
				per_page: 20
			}
		});
		return (promise);
	}

	function _chats(params) {
		params = ImSearchParams.list(params);
		var promise = serviceHelper.executeRequest({
			resource: resource,
			name: 'get',
			parameters: params
		});
		return (promise);
	}

	function _read(group_id) {
		var promise = serviceHelper.executeRequest({
			resource: resource,
			name: 'read',
			parameters: {
				id: group_id
			}
		});
		return (promise);
	}

	/**
	 * @user_id {Number} [require]
	 **/
	function _users(user_id) {
		var promise = serviceHelper.executeRequest({
			resource: resource,
			name: 'users',
			parameters: {id: user_id}
		});
		return (promise);
	}

	/**
	 * @groups_id {Number} [require]
	 **/
	function _groups(groups_id) {
		var promise = serviceHelper.executeRequest({
			resource: resource,
			name: 'groups',
			parameters: {
				id: groups_id
			}
		});
		return (promise);
	}

	function _leave(groups_id) {
		var promise = serviceHelper.executeRequest({
			resource: resource,
			name: 'leave',
			parameters: {
				id: groups_id
			}
		});
		return (promise);
	}

	function _join(groups_id) {
		var promise = serviceHelper.executeRequest({
			resource: resource,
			name: 'join',
			parameters: {
				id: groups_id
			}
		});
		return (promise);
	}

	/**
	 * @chat_id {Number} [require]
	 * @params {Object} [optional]
	 * * @page {Number} (1 || page)
	 * * @per_page {Number} (20 || per_page)
	 **/
	function _messages(chat_id, params) {
		params = ImSearchParams.list(params);
		params.id = chat_id;
		var promise = serviceHelper.executeRequest({
			resource: resource,
			name: 'messages',
			parameters: params
		});
		return (promise);
	}

	/**
	 * @chat_id {Number} [require]
	 * @message {String} [require]
	 **/
	function _message(params) {
		var promise = serviceHelper.executeRequest({
			resource: resource,
			name: 'message',
			parameters: params
		});
		return (promise);
	}

	/**
	 * @chat_id {Number} [require]
	 **/
	function _count(chat_id) {
		var promise = serviceHelper.executeRequest({
			resource: resource,
			name: 'count',
			parameters: {id: chat_id}
		});
		return (promise);
	}
}]);
