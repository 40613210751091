NgProject.directive('imageTeaser', ["$parse", "$templateCache", "Image", function ($parse, $templateCache, Image) {
	'use strict';
	controller.$inject = ["$scope", "$attrs", "$element"];
	var templateId = 'widgets.images.image-teaser';
	var options = {
		buttons: {
			'deleteByOwner': false
		}
	};

	function compile(tElement, tAttrs) {
		var type = tAttrs.hasOwnProperty('type') ? tAttrs.type : 'default';
		var template = getTemplate(type);
		var content = tElement.html();
		if (content.length) {
			template = angular.element(template);
			template.append(content);
		}
		tElement.html(template);
	}

	function controller($scope, $attrs, $element) {
		$scope.$teaser = {options: angular.copy(options)};
		if ($attrs.hasOwnProperty('object')) {
			$scope.$image = $parse($attrs.object)($scope);
		}

		if (_.isPlainObject($scope.$image)) {
			$scope.$image = Image.create($scope.$image);
		}

		if ($attrs.hasOwnProperty('options')) {
			angular.merge($scope.$teaser.options, $parse($attrs.options)($scope));
		}

		setResizeFile($scope.$image, $element);
	}

	function getTemplate(type) {
		var template = $templateCache.get(getTemplateIdByType(type));
		if (!template) {
			template = createTemplate(type);
			$templateCache.put(getTemplateIdByType(type), template.prop('outerHTML'));
		}

		return template;
	}

	function getTemplateIdByType(type) {
		return templateId.concat('-', type);
	}

	function createTemplate(type) {
		var template = angular.element($templateCache.get(templateId));
		template.addClass('type-' + type);
		return template;
	}

	function setResizeFile(image, $element) {
		if (image.hasOwnProperty('file')) {
			var wrap = $element.parent();
			var size = [Math.ceil(wrap.width()) || 260, Math.ceil(wrap.height()) || 180].join('x');

			var imageFile = image.file.url.split('/');
			var imageName = imageFile.pop().split('.');
			var ext = imageName.pop();

			imageName.push(size, ext);
			imageFile.push(imageName.join('.'));
			image.file.resize = imageFile.join('/');
		}
	}

	return {
		restrict: 'E',
		compile: compile,
		controller: controller
	};
}]);
