NgProject.filter('imageResize', ["SETTINGS", function (SETTINGS) {
	'use strict';
	return function (data, withoutHost) {
		if (withoutHost) {
			return data[0];
		}

		var imageFile = data[0].split('/');
		var imageName = imageFile.pop().split('.');
		var ext = imageName.pop();
		imageName.push(data[1], ext);
		imageFile.push(imageName.join('.'));
		return SETTINGS.assetsBaseUrl + imageFile.join('/');
	};
}]);
