'use strict';
NgProject.directive('articlesCarousel', ["$parse", function ($parse) {
	controller.$inject = ["$scope", "$attrs"];
	var defaults = {
		load: null,
		options: {
			emptyText: '',
			dynamicPagination: true,
			startFromItemId: false,
			highlightCurrent: false
		},
		carouselOptions: {
			nav: true,
			navText: ['', ''],
			dots: false,
			smartSpeed: 200
		}
	};

	function controller($scope, $attrs) {
		$scope.$options = {
			viewMode: 'static'
		};
		$scope.$options.load = $parse($attrs['load'])($scope);
		$scope.$options.options = angular.extend({}, defaults.options, $parse($attrs['options'])($scope));
		$scope.$options.carouselOptions = angular.extend({}, defaults.carouselOptions, $parse($attrs['carouselOptions'])($scope));

		this.getOptions = function (key) {
			return $scope.$options[key];
		};
	}

	return {
		restrict: 'E',
		scope: true,
		templateUrl: 'widgets.articles-carousel.template',
		controller: controller
	};
}]);

NgProject.directive('articlesCarouselInner', function () {
	function link(scope, element, attrs, controllers) {
		var articlesCarouselCtrl = controllers[0];
		var articlesCtrl = controllers[1];
		var carouselOptions = articlesCarouselCtrl.getOptions('carouselOptions');

		articlesCtrl.once('receivedRemoteData', function (response) {
			if (response.count === 0) {
				scope.$options.viewMode = 'empty';
			}
			else if (response.count > carouselOptions.items) {
				scope.$options.viewMode = 'slider';
			}
		});
	}

	return {
		restrict: 'A',
		require: ['^^articlesCarousel', '^^articles'],
		link: link
	};
});

NgProject.directive('articlesCarouselSlider', ["$compile", function ($compile) {
	var itemTemplate = null;

	function compile(tElement) {
		itemTemplate = tElement.html();
		tElement.html('');
		return link;
	}

	function link(scope, element, attrs, controllers) {
		var articlesCarouselCtrl = controllers[0];
		var articlesCtrl = controllers[1];
		var owl = null;

		var options = angular.copy(articlesCarouselCtrl.getOptions('options'));
		var carouselOptions = articlesCarouselCtrl.getOptions('carouselOptions');

		function addItems(items) {
			var startPosition = null;
			angular.forEach(items, function (item, index) {
				var newScope = scope.$new();
				newScope.$article = item;
				newScope.$index = index;

				if (item.id === options.startFromItemId) {
					if (options.highlightCurrent) {
						newScope.$article.highlighted = true;
					}
					if (options.startFromItemId) {
						startPosition = newScope.$index;
					}
				}

				owl.trigger('add.owl.carousel', [$compile(itemTemplate)(newScope)]);
			});

			owl.trigger('refresh.owl.carousel');

			if (options.startFromItemId && startPosition) {
				owl.trigger('to.owl.carousel', [startPosition, 0]);
			}
		}

		if (options.dynamicPagination) {
			carouselOptions.onTranslate = function (event) {
				if ((event.item.count - event.item.index - event.page.size) < event.page.size * 2) {
					articlesCtrl.loadNextPage();
				}
			};

			articlesCtrl.on('receivedRemoteData', function (response) {
				if (response.count <= carouselOptions.items) return;
				addItems(response.items);
			});
		}

		owl = $(element).owlCarousel(carouselOptions);

		addItems(scope.$articles.items);
	}

	return {
		restrict: 'A',
		require: ['^^articlesCarousel', '^^articles'],
		compile: compile
	};
}]);

