(function (ng, app) {
	'use strict';
	app.service('ImagesService', ["$resource", "serviceHelper", "SETTINGS", "ImagesSearchParams", "Image", function ($resource, serviceHelper, SETTINGS, ImagesSearchParams, Image) {
		var resource = $resource(SETTINGS.apiBaseUrl + 'api/v1/images/:imageId', {imageId: '@imageId'}, {
			allByCurrentUser: {url: SETTINGS.apiBaseUrl + 'api/v1/profile/photos'},
			allByCurrentUserInAlbum: {url: SETTINGS.apiBaseUrl + 'api/v1/profile/images/albums/:albumId/images'},
			allByUser: {url: SETTINGS.apiBaseUrl + 'api/v1/users/:userId/photos'},
			allByUserInAlbum: {url: SETTINGS.apiBaseUrl + 'api/v1/users/:userId/images/albums/:albumId/images'},
			allByGroup: {url: SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/photos'},
			allByGroupInAlbum: {url: SETTINGS.apiBaseUrl + 'api/v1/groups/:groupId/images/albums/:albumId/images'},
			allByTerm: {url: SETTINGS.apiBaseUrl + 'api/v1/terms/:termId/photos'}
		});

		var resourceBookmarks = $resource(SETTINGS.apiBaseUrl + 'api/v1/profile/images/bookmarks/:imageId', {imageId: '@imageId'});

		function processResponseCollection(response, owner) {
			if (response.success) {
				response.items = response.items.map(function (item) {
					var model = Image.create(item);
					if (owner) model.setOwner(owner.type, owner.id);
					return model;
				});
			}
		}

		return {
			findAll: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'get',
					parameters: ImagesSearchParams.process(params)
				});
				promise.then(processResponseCollection);
				return promise;
			},

			findAllByCurrentUser: function (params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByCurrentUser',
					parameters: ImagesSearchParams.process(params)
				});
				promise.then(function (response) {
					processResponseCollection(response);
				});
				return promise;
			},

			findAllByCurrentUserInAlbum: function (albumId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByCurrentUserInAlbum',
					parameters: angular.extend(ImagesSearchParams.process(params), {albumId: albumId})
				});
				promise.then(function (response) {
					processResponseCollection(response);
				});
				return promise;
			},

			findAllByUser: function (userId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByUser',
					parameters: angular.extend(ImagesSearchParams.process(params), {userId: userId})
				});
				promise.then(function (response) {
					processResponseCollection(response);
				});
				return promise;
			},

			findAllByUserInAlbum: function (userId, albumId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByUserInAlbum',
					parameters: angular.extend(ImagesSearchParams.process(params), {userId: userId, albumId: albumId})
				});
				promise.then(function (response) {
					processResponseCollection(response);
				});
				return promise;
			},

			findAllByGroup: function (groupId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByGroup',
					parameters: angular.extend(ImagesSearchParams.process(params), {groupId: groupId})
				});
				promise.then(function (response) {
					processResponseCollection(response, {type: 'group', id: groupId});
				});
				return promise;
			},

			findAllByGroupInAlbum: function (groupId, albumId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByGroupInAlbum',
					parameters: angular.extend(ImagesSearchParams.process(params), {groupId: groupId, albumId: albumId})
				});
				promise.then(function (response) {
					processResponseCollection(response, {type: 'group', id: groupId});
				});
				return promise;
			},

			findAllByTerm: function (termId, params) {
				var promise = serviceHelper.executeRequest({
					resource: resource,
					name: 'allByTerm',
					parameters: angular.extend(ImagesSearchParams.process(params), {termId: termId})
				});
				promise.then(processResponseCollection);
				return promise;
			},

			getUploadUrl: function (params) {
				var url = SETTINGS.apiBaseUrl.concat('api/v1/images');
				if (params) {
					if (params.maker) {
						params.maker_type = params.maker.type;
						params.maker_id = params.maker.id;
						delete params.maker;
					}
					url = url.concat('?', $.param(params));
				}
				return url;
			},

			bookmarks: {
				all: function (params) {
					var promise = serviceHelper.executeRequest({
						resource: resourceBookmarks,
						name: 'get',
						parameters: params
					});
					promise.then(function (response) {
						processResponseCollection(response);
					});
					return (promise);
				}
			},

			resizeImage: function (id, size, type) {
				return SETTINGS.apiBaseUrl + 'api/v1/resize_image/' + [type, id, size].join('/');
			}
		};
	}]);
})(angular, NgProject);
