NgProject.directive('videoTeaser', ["$parse", "$templateCache", "Video", "CurrentUser", function ($parse, $templateCache, Video, CurrentUser) {
	'use strict';
	controller.$inject = ["$scope", "$attrs"];
	var templateId = 'widgets.videos.video-teaser';
	var options = {
		buttons: {
			'update': false,
			'share':  false,
			'deleteByOwner': false,
			'bookmark': false
		}
	};

	function compile(tElement, tAttrs) {
		var type = tAttrs.hasOwnProperty('type') ? tAttrs.type : 'default';
		var template = getTemplate(type);
		var content = tElement.html();
		if (content.length) {
			template = angular.element(template);
			template.append(content);
		}
		tElement.html(template);
	}

	function getTemplate(type) {
		var template = $templateCache.get(getTemplateIdByType(type));
		if (!template) {
			template = createTemplate(type);
			$templateCache.put(getTemplateIdByType(type), template.prop('outerHTML'));
		}

		return template;
	}

	function getTemplateIdByType(type) {
		return templateId.concat('-', type);
	}

	function createTemplate(type) {
		var template = angular.element($templateCache.get(templateId));
		switch (type) {
			case 'default':
				template.find('.video-teaser-info .title').remove();
				template.find('.tags-list').remove();
				template.find('.controls').remove();
				break;
			case 'compact':
				template.find('.details-container .description').remove();
				break;
		}
		template.addClass('type-' + type);
		return template;
	}

	function controller($scope, $attrs) {
		$scope.$teaser = {options: angular.copy(options)};
		if ($attrs.hasOwnProperty('object')) {
			$scope.$video = $parse($attrs.object)($scope);
		}
		if (_.isPlainObject($scope.$video)) {
			$scope.$video = Video.create($scope.$video);
		}

		angular.extend($scope.$teaser.options.buttons, {
			'update': $scope.$video.access.update,
			'share':  Boolean(CurrentUser.id),
			'bookmark': Boolean(CurrentUser.id)
		});

		if ($attrs.hasOwnProperty('options')) {
			angular.merge($scope.$teaser.options, $parse($attrs.options)($scope));
		}
	}

	return {
		restrict: 'E',
		compile: compile,
		controller: controller
	};
}]);
