NgProject.directive('termsSearch', ["$state", "CurrentUser", "$parse", function ($state, CurrentUser, $parse) {
	'use strict';
	controller.$inject = ["$scope", "$attrs"];
	var defaults = {
		showFiltersPanel: true,
		enableFilters: false,
		action: {label: 'Создать термин'}
	};

	function controller($scope, $attrs) {
		$scope.bSearchOptions = angular.copy(defaults);
		if ($attrs.hasOwnProperty('options')) {
			angular.extend($scope.bSearchOptions, $parse($attrs.options)($scope));
		}
		if ($attrs.hasOwnProperty('showQueryPanel'))
			$scope.bSearchOptions.enableQueryPane = $parse($attrs.showQueryPanel)($scope);
		if ($attrs.hasOwnProperty('action'))
			angular.extend($scope.bSearchOptions.action, $parse($attrs.action)($scope));
		if ($attrs.hasOwnProperty('query'))
			$scope.bSearchOptions.query = $parse($attrs.query)($scope);
	}

	return {
		templateUrl: 'widgets.terms.terms-search',
		restrict: 'E',
		controller: controller
	};
}]);
